import { Dispatch, SetStateAction, createContext, useState } from 'react';

export type SearchBarContextType = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

const defaultContextValue = {
  searchValue: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSearchValue: () => {},
};

const SearchBarContext = createContext<SearchBarContextType>(defaultContextValue);

const SearchBarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchValue, setSearchValue] = useState('');

  const SearchBarContextValue = {
    searchValue,
    setSearchValue,
  };

  return <SearchBarContext.Provider value={SearchBarContextValue}>{children}</SearchBarContext.Provider>;
};

export { SearchBarContext, SearchBarContextProvider };
