import { colors } from '../../styles/variables';

interface FinishedIconProps {
  color?: string;
}

const FinishedIcon = ({ color }: FinishedIconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8666 7.38918V8.00251C14.8658 9.44013 14.4003 10.839 13.5395 11.9904C12.6787 13.1418 11.4688 13.9842 10.0902 14.3918C8.71158 14.7994 7.23813 14.7504 5.88961 14.2522C4.54108 13.754 3.38973 12.8332 2.60727 11.6272C1.82481 10.4212 1.45316 8.99456 1.54775 7.56006C1.64234 6.12556 2.1981 4.76006 3.13215 3.66723C4.0662 2.57439 5.32849 1.81277 6.73075 1.49594C8.13302 1.17912 9.60014 1.32407 10.9133 1.90918M14.8666 2.66536L8.19996 9.3387L6.19996 7.3387"
        stroke={color ?? colors.error}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinishedIcon;
