import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

type ToggleChargerAvailabilityProps = {
  status: boolean;
  chargerId: string;
};

const useToggleChargerAvailability = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync } = useMutation(
    ({ status, chargerId }: ToggleChargerAvailabilityProps) =>
      axiosInstance.post(api.endpoints.SINGLE_CHARGER.TURN_ON_OFF(chargerId), { status }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryAliases.USER_CHARGERS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.chargers.changeChargerStateFailed`));
      },
    },
  );

  const changeChargerAvailability = async (status: boolean, chargerId: string) => {
    await mutateAsync({ status, chargerId });
  };

  return { changeChargerAvailability };
};

export default useToggleChargerAvailability;
