import { useTranslation } from 'react-i18next';
import BorderCard from '../../../components/common/cards/BorderCard';
import LightBorderMobileCard from '../../../components/common/cards/LightBorderMobileCard';
import UserAccountWrapper from '../components/UserAccountWrapper';
import Faq from './components/Faq/Faq';

const UserAccountHelp = () => {
  const { t } = useTranslation();

  return (
    <UserAccountWrapper>
      <div className="pb-78">
        <LightBorderMobileCard>
          <h4 className="font-medium text-xl lg:text-base">{t('userAccount.help.contact.title')}</h4>

          <BorderCard className="grid grid-cols-2 mt-8 lg:mb-8">
            <div className="flex flex-col gap-6 border-r border-black700">
              <h5 className="text-sm font-bold text-black700">{t('userAccount.help.contact.polishBranch.company')}</h5>
              <div className="text-black900 text-sm flex flex-col gap-1">
                <p>{t('userAccount.help.contact.polishBranch.phone')}</p>
                <p>{t('userAccount.help.contact.polishBranch.email')}</p>
              </div>
              <div className="text-black900 text-sm flex flex-col gap-1">
                <p>{t('userAccount.help.contact.polishBranch.addressLine1')}</p>
                <p>{t('userAccount.help.contact.polishBranch.addressLine2')}</p>
                <p>{t('userAccount.help.contact.polishBranch.country')}</p>
              </div>
            </div>

            <div className="flex flex-col gap-6 pl-6">
              <h5 className="text-sm font-bold text-black700">{t('userAccount.help.contact.swissBranch.company')}</h5>
              <div className="text-black900 text-sm flex flex-col gap-1">
                <p>{t('userAccount.help.contact.swissBranch.phone')}</p>
                <p>{t('userAccount.help.contact.swissBranch.email')}</p>
              </div>
              <div className="text-black900 text-sm flex flex-col gap-1">
                <p>{t('userAccount.help.contact.swissBranch.addressLine1')}</p>
                <p>{t('userAccount.help.contact.swissBranch.addressLine2')}</p>
                <p>{t('userAccount.help.contact.swissBranch.country')}</p>
              </div>
            </div>
          </BorderCard>
        </LightBorderMobileCard>

        <LightBorderMobileCard>
          <h4 className="font-medium text-xl lg:text-base">{t('userAccount.help.faq.title')}</h4>
          <Faq />
        </LightBorderMobileCard>
      </div>
    </UserAccountWrapper>
  );
};

export default UserAccountHelp;
