import {
  ApiSingleZoneParkingSubscriptionsItem,
  ISingleZoneParkingSubscriptionsItem,
} from '../../../types/api/ApiTypes';
import { transformParkingDetails } from '../transformParkingDetails/transformParkingDetails';

export const transformSingleZoneParkingSubscriptionsItem = (
  apiSubscription: ApiSingleZoneParkingSubscriptionsItem,
): ISingleZoneParkingSubscriptionsItem => {
  const transformedSubscription: ISingleZoneParkingSubscriptionsItem = {
    id: apiSubscription.id,
    name: apiSubscription.name,
    parkings: apiSubscription.parkings.map(parking => transformParkingDetails(parking)),
    approvalEmail: apiSubscription.approval_email,
    currency: apiSubscription.currency,
    duration: apiSubscription.duration,
    hasTimeRestrictions: apiSubscription.has_time_restrictions,
    parkingZones: apiSubscription.parking_zones,
    price: apiSubscription.price,
    requiresApproval: apiSubscription.requires_approval,
    timeRestrictions: apiSubscription.time_restrictions,
    description: apiSubscription.description,
  };

  return transformedSubscription;
};
