import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { parkingsFilters } from '../../../static/parkings/parkingFilters';
import { DropdownOption } from '../../../types/Dropdown.types';
import { parkingFilterType } from '../../../types/parkings/parkingsFilters';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkings } from '../../../utils/dataTransformers/transformParkings/transformParkings';
import { parkingsFiltersMaper } from '../../../utils/parkingsFiltersMaper/parkingsFiltersMaper';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useUserParkings = () => {
  const noFilterOption = (parkingsFilters.find(prop => prop.value === parkingFilterType.ALL) ??
    parkingsFilters[0]) as DropdownOption;

  const [filter, setFilter] = useState<DropdownOption>(noFilterOption);
  const [filterOptions, setFilterOptions] = useState(parkingsFilters);
  const [breaker, setBreaker] = useState(false);

  const axiosInstance = useAxiosInstance();

  const accessType = filter.value === parkingFilterType.ALL ? undefined : { access_type: filter.value };

  const { data, refetch } = useQuery([QueryAliases.USER_PARKINGS], () =>
    axiosInstance.get(api.endpoints.USER_PARKINGS, { params: accessType }),
  );

  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    if (data && filter?.value === parkingFilterType.ALL && !breaker) {
      setFilterOptions(parkingsFiltersMaper(data.data));
      setBreaker(true);
    }
    return;
  }, [data]);

  return { parkings: transformParkings(data?.data), noFilterOption, filter, setFilter, filterOptions };
};

export default useUserParkings;
