import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ComponentState, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BottomActionBox } from '../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { LabeledStateDropdown } from '../../../../components/common/dropdowns/LabeledStateDropdown';
import { LabeledStateMultiDropdown } from '../../../../components/common/dropdowns/LabeledStateMultiDropdown';
import FormSectionTitle from '../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import useGroupsDropdownOptions from '../../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import { routes } from '../../../../static/routes';
import { addChargerRuleSchema } from '../../../../static/validationSchema/chargers/addChargerRuleSchema';
import { ComponentStates } from '../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { CallbackDefault } from '../../../../types/Types';
import { ICharger } from '../../../../types/api/ApiTypes';
import { PriceListTypes } from '../../../../types/price-lists/PriceListTypes';
import usePriceListDropdownOptions from '../hooks/usePriceListDropdownOptions';

export interface IChargingRuleFormInputs {
  name: string;
  groups: DropdownOption[];
  priceList: DropdownOption;
  connectors: DropdownOption[];
}

type InputsKeys = keyof IChargingRuleFormInputs;

interface ChargingRuleFormProps {
  onSubmitForm: (props: IChargingRuleFormInputs, isApplyToAllChecked: boolean) => Promise<void>;
  submitBtnText: string;
  submitBtnIcon: React.ReactNode;
  onDelete?: CallbackDefault;
  isSubmitBtnDisabled?: boolean;
  charger?: ICharger;
  initialValues?: IChargingRuleFormInputs;
}

export const ChargingRuleForm = (props: ChargingRuleFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListDropdownOptions } = usePriceListDropdownOptions();
  const connectorDropdownOptions = props.charger?.connectors.map(item => ({
    id: item.id,
    value: `${t('chargingRuleForm.connector')} ${item.port}`,
  }));

  const [isApplyToAllChecked, setIsApplyToAllChecked] = useState(props.initialValues?.groups.length === 0);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm<IChargingRuleFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addChargerRuleSchema({ groupsRequired: !isApplyToAllChecked })),
    defaultValues: {
      name: props.initialValues?.name ?? '',
      groups: props.initialValues?.groups ?? [],
      connectors: props.initialValues?.connectors ?? [],
      priceList: props.initialValues?.priceList ?? undefined,
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IChargingRuleFormInputs> = async body => {
    props.onSubmitForm(body, isApplyToAllChecked);
    reset();
  };

  return (
    <div className="flex justify-between gap-16 pb-8 grow">
      <div className="w-full max-w-5xl grow">
        <div className="pb-12 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={1} title={t('chargingRuleForm.nameTitle')} />
          <div className="lg:pl-8">
            <LabeledStateInput
              id="charging-rule-name"
              state={getInputState('name')}
              labeledProps={{
                wrapperClassName: 'mb-5 mt-6 max-w-xl',
                label: t('chargingRuleForm.nameLabel'),
                errorMessage: t(`errorMessages.${errors.name?.message}`),
              }}
              inputProps={{
                placeholder: t('chargingRuleForm.namePlaceholder'),
                register: {
                  ...register('name'),
                },
              }}
            />
          </div>
        </div>

        <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={2} title={t('chargingRuleForm.refererTitle')} />
          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 mb-5">
              <div className="flex gap-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isApplyToAllChecked}
                      onChange={e => {
                        setValue('groups', []);
                        setIsApplyToAllChecked(e.target.checked);
                      }}
                    />
                  }
                  label={t('chargingRuleForm.refersAll')}
                />
              </div>
            </div>
            <div className="max-w-xl mt-6 mb-5">
              <p className="font-bold">{t('chargingRuleForm.addGroup')}</p>
            </div>
            <div className="max-w-xl mt-6 mb-5">
              <Controller
                name="groups"
                control={control}
                render={({ field }) => (
                  <LabeledStateMultiDropdown
                    id="groups"
                    state={getInputState('groups')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      errorMessage: t(`errorMessages.${errors.groups?.message}`),
                    }}
                    dropdownProps={{
                      buttonClassName: 'w-full flex justify-between  items-center',
                      options: groupsDropdownOptions ?? [],
                      currentOptions: field.value,
                      setNewOptions: newOptions => {
                        setIsApplyToAllChecked(false);
                        field.onChange(newOptions);
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="max-w-xl mt-6 mb-5">
              <PrimaryButton
                onClick={() => navigate(routes.addGroup)}
                prefixIcon={props.submitBtnIcon}
                className="w-max"
              >
                {t('chargingRuleForm.addGroupButton')}
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={3} title={t('chargingRuleForm.addPriceListTitle')} />
          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 mb-5">
              <Controller
                name="priceList"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="price-list"
                    state={getInputState('priceList')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      errorMessage: t(`errorMessages.${errors.priceList?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('chargingRuleForm.addPriceListPlaceholder'),
                      options: priceListDropdownOptions,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
            <div className="max-w-xl mt-6 mb-5">
              <PrimaryButton
                onClick={() => navigate(routes.addPriceList({ type: PriceListTypes.EV }))}
                prefixIcon={props.submitBtnIcon}
                className="w-max"
              >
                {t('chargingRuleForm.addPriceListButton')}
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div className="pb-48 mt-16 lg:pb-12">
          <FormSectionTitle number={4} title={t('chargingRuleForm.connectorsTitle')} />
          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 mb-5">
              <Controller
                name="connectors"
                control={control}
                render={({ field }) => (
                  <LabeledStateMultiDropdown
                    id="connectors"
                    state={getInputState('connectors')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      errorMessage: t(`errorMessages.${errors.connectors?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('chargingRuleForm.connectorsPlaceholder'),
                      buttonClassName: 'w-full flex justify-between items-center',
                      options: connectorDropdownOptions ?? [],
                      currentOptions: field.value,
                      setNewOptions: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={props.isSubmitBtnDisabled}
          onClick={handleSubmit(onSubmit)}
          className="w-fit h-fit"
          prefixIcon={props.submitBtnIcon}
        >
          {props.submitBtnText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!props.onDelete && <DeleteIconButton onClick={props.onDelete} />}
          </div>

          <PrimaryButton
            disabled={props.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            prefixIcon={props.submitBtnIcon}
          >
            {props.submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};
