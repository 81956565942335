import MyDataTable from '../../../components/dataTable/MyDataTable';
import useSubscriptionsPriceLists from '../../../hooks/api/priceLists/useSubscriptionsPriceLists';
import { PriceListTypes } from '../../../types/price-lists/PriceListTypes';
import PriceListsWrapper from '../components/PriceListsWrapper';
import useSubscriptionsPriceListsTableHeaders from './hooks/useSubscriptionsPriceListsTableHeaders';

const PriceListsSubscriptions = () => {
  const { headers } = useSubscriptionsPriceListsTableHeaders();
  const { priceLists } = useSubscriptionsPriceLists();

  return (
    <PriceListsWrapper type={PriceListTypes.SUBSCRIPTION}>
      {!!priceLists && <MyDataTable headers={headers} data={priceLists} />}
    </PriceListsWrapper>
  );
};

export default PriceListsSubscriptions;
