import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../static/api';
import { LoginRequestType } from '../../types/api/ApiTypes';
import { setAccessToken, setRefreshToken } from '../../utils/cookieHelpers/cookieHelpers';
import { getApiErrorMessage } from '../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../axios/useAxiosInstance';
import useToast from '../useToast';

const useLogin = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ password, username, phone_number }: LoginRequestType) =>
      axiosInstance.post(api.endpoints.LOGIN, { password, username, phone_number }),
    {
      onSuccess: async response => {
        setAccessToken(response.data.access);
        setRefreshToken(response.data.refresh);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.loginFailed'));
      },
    },
  );

  return { login: mutateAsync, isLoading };
};

export default useLogin;
