import { DropdownOption } from '../../types/Dropdown.types';
import useParkingSpots from '../api/parkings/useParkingSpots';

interface useParkingSpotsDropdownOptions {
  parkingId: string;
}

const useParkingSpotsDropdownOptions = ({ parkingId }: useParkingSpotsDropdownOptions) => {
  const { parkingSpots } = useParkingSpots({ parkingId });

  let spotsDropdownOptions: DropdownOption[] | undefined;

  if (parkingSpots) {
    spotsDropdownOptions = parkingSpots.map(spot => ({
      id: spot.id,
      value: spot.parkingSpotNumber,
    }));
  }

  return { spotsDropdownOptions };
};

export default useParkingSpotsDropdownOptions;
