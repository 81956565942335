import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { muiStyles } from '../../../styles/muiStyles';

export interface TimepickerProps {
  date: Date | null | undefined;
  setDate: (date: Date | null | undefined) => void;
}
const Timepicker = ({ date, setDate }: TimepickerProps) => {
  const { t } = useTranslation();

  return (
    <MobileTimePicker
      value={dayjs(date)}
      onChange={date => {
        setDate(!!date ? date.toDate() : null);
      }}
      ampm={false}
      localeText={{
        toolbarTitle: t('timepicker.toolbarTitle'),
        okButtonLabel: t('timepicker.okBtnLabel'),
        cancelButtonLabel: t('timepicker.cancelBtnLabel'),
      }}
      sx={{
        ...muiStyles.dateTimePickersSx,
      }}
    />
  );
};

export default Timepicker;
