import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import Popup from '../../../../components/common/modals/Popup';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import SaveIcon from '../../../../components/icons/SaveIcon';
import useValidateUsersPhone from '../../../../hooks/api/useValidateUsersPhone';
import { addSpotCoUserSchema } from '../../../../static/validationSchema/parkings/addSpotCoUserSchema';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';
import { ICoUser, ParkingSpotPermission, ParkingSpotPermissionsState } from '../../../../types/api/ApiTypes';
import TableRemoveCoUserButton from './TableRemoveCoUserButton';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { DefaultParkingSpotPermissions } from '../../../../static/parkingSpots/ParkingSpotPermissionsStatics';
import { LabeledStateDropdown } from '../../../../components/common/dropdowns/LabeledStateDropdown';
import { DropdownOption } from '../../../../types/Dropdown.types';
import useGroupsDropdownOptions from '../../../../hooks/dopdownOptions/useGroupsDropdownOptions';

interface ParkingSpotCoUsersFormInputs {
  phone: string;
  group: DropdownOption | undefined;
}

enum UserType {
  User = 'user',
  Group = 'group',
}

type InputsKeys = keyof ParkingSpotCoUsersFormInputs;

interface ParkingSpotCoUsersProps {
  coUsers: ICoUser[];
  onDeleteCoUser: ({ phone, groupId, isGroup }: { phone?: string; groupId?: number; isGroup: boolean }) => void;
  onAddCoUser: (newUser: ICoUser) => void;
}

const ParkingSpotCoUsers = ({ coUsers, onDeleteCoUser, onAddCoUser }: ParkingSpotCoUsersProps) => {
  const { t } = useTranslation();
  const { validatePhone } = useValidateUsersPhone();
  const { groupsDropdownOptions } = useGroupsDropdownOptions();

  const [isAddCoUserModalOpen, setIsAddCoUserModalOpen] = useState(false);
  const [permissions, setPermissions] = useState<ParkingSpotPermissionsState>(DefaultParkingSpotPermissions);
  const [userType, setUserType] = useState(UserType.User);
  const isUser = userType === UserType.User;
  const isGroup = userType === UserType.Group;

  const existingPhoneNumbers = coUsers.map(couser => couser.phoneNumber ?? '');

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm<ParkingSpotCoUsersFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(
      addSpotCoUserSchema({
        arePhoneNumberRequired: isUser,
        areGroupRequired: isGroup,
        existingPhoneNumbers,
      }),
    ),
    defaultValues: {
      phone: '',
      group: undefined,
    },
  });

  const onSubmit: SubmitHandler<ParkingSpotCoUsersFormInputs> = async ({ phone, group }) => {
    try {
      if (isUser) {
        await validatePhone({
          phone,
        });
      }

      const permissionsArray = Object.keys(permissions).filter(
        key => permissions[key as ParkingSpotPermission],
      ) as ParkingSpotPermission[];
      const newCoUser = {
        phoneNumber: phone,
        permissions: permissionsArray,
        groupId: group?.id ?? undefined,
      };
      onAddCoUser(newCoUser);
      reset();
      setIsAddCoUserModalOpen(false);
    } catch (e) {
      const error = e as { message?: string };
      setError('phone', {
        message: error.message,
      });
    }
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const handleChangePermissions = (key: ParkingSpotPermission) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [key]: !prevPermissions[key],
    }));
  };

  const coUsersHeaders = [
    {
      field: 'isGroup',
      headerName: t('editParkingSpot.cousers.table.type'),
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <p className="flex align-middle">
            {params.row.isGroup ? t('editParkingSpot.cousers.group') : t('editParkingSpot.cousers.user')}
          </p>
        );
      },
    },
    {
      field: 'fullName',
      headerName: t('editParkingSpot.cousers.table.fullName'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{`${params.row.firstName ?? ''} ${params.row.lastName ?? ''}`}</p>;
      },
    },

    {
      field: 'phone',
      headerName: t('editParkingSpot.cousers.table.phone'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.phoneNumber}</p>;
      },
    },
    {
      field: 'groupName',
      headerName: t('editParkingSpot.cousers.table.groupName'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.groupName}</p>;
      },
    },
    {
      field: 'numberOfUsers',
      headerName: t('editParkingSpot.cousers.table.numberOfUsers'),
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.numberOfUsers}</p>;
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end">
            <TableRemoveCoUserButton
              phone={params.row.phone}
              onConfirm={() => {
                const isGroup = !params.row.phoneNumber;
                onDeleteCoUser({
                  ...(!isGroup && { phone: params.row.phoneNumber }),
                  ...(isGroup && { groupId: params.row.id }),
                  isGroup,
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <MyDataTable headers={coUsersHeaders} data={coUsers ?? []} getRowId={row => row.id} />
      <SecondaryButton
        onClick={() => setIsAddCoUserModalOpen(true)}
        className="lg:w-fit mt-8 lg:mt-6"
        prefixIcon={<PlusIcon width={16} height={16} />}
      >
        {t('editParkingSpot.cousers.addNew')}
      </SecondaryButton>

      <Popup isOpen={isAddCoUserModalOpen} setIsOpen={setIsAddCoUserModalOpen}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <p className="font-bold mt-8">{t('editParkingSpot.cousers.permissionType')}</p>

          <div className="mt-6 flex gap-4">
            <SecondaryButton
              markActive={isUser}
              onClick={() => setUserType(UserType.User)}
              prefixIcon={isUser && <CheckIcon width={16} />}
            >
              {t('editParkingSpot.cousers.user')}
            </SecondaryButton>
            <SecondaryButton
              markActive={isGroup}
              onClick={() => setUserType(UserType.Group)}
              prefixIcon={isGroup && <CheckIcon width={16} />}
            >
              {t('editParkingSpot.cousers.group')}
            </SecondaryButton>
          </div>

          {isUser && (
            <>
              <p className="font-bold mt-8">{t('editParkingSpot.cousers.add.title')}</p>

              <LabeledStateInput
                id="phone"
                state={getInputState('phone')}
                labeledProps={{
                  wrapperClassName: 'mb-2 mt-6 max-w-xl',
                  label: t('editParkingSpot.cousers.add.inputLabel'),
                  errorMessage: t(`errorMessages.${errors.phone?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('phone'),
                  },
                }}
              />
            </>
          )}

          {isGroup && (
            <>
              <Controller
                name="group"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="group"
                    state={getInputState('group')}
                    labeledProps={{
                      wrapperClassName: 'mb-2 mt-6 max-w-xl',
                      label: t('editParkingSpot.cousers.add.group.label'),
                      errorMessage: t(`errorMessages.${errors.group?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('editParkingSpot.cousers.add.group.placeholder'),
                      buttonClassName: 'w-full flex justify-between',
                      options: groupsDropdownOptions ?? [],
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </>
          )}

          <p className="font-bold mt-8">{t('editParkingSpot.cousers.add.group.grantPermissions')}</p>

          <div className="mt-4 pl-4">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t('editParkingSpot.cousers.add.group.permissions.view')}
                disabled
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.share_parkingspot}
                    onChange={() => handleChangePermissions(ParkingSpotPermission.ParkingSpotShare)}
                  />
                }
                label={t('editParkingSpot.cousers.add.group.permissions.share')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.free_access_parkingspot}
                    onChange={() => handleChangePermissions(ParkingSpotPermission.ParkingSpotFreeAccess)}
                  />
                }
                label={t('editParkingSpot.cousers.add.group.permissions.freeAccess')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.assign_permissions_parkingspot}
                    onChange={() => handleChangePermissions(ParkingSpotPermission.AssignPermission)}
                  />
                }
                label={t('editParkingSpot.cousers.add.group.permissions.assignPermissions')}
              />
            </FormGroup>
          </div>

          <PrimaryButton type="submit" className="mt-6 lg:mt-12 lg:w-fit place-self-end" prefixIcon={<SaveIcon />}>
            {t('editParkingSpot.cousers.add.submitBtn')}
          </PrimaryButton>
        </form>
      </Popup>
    </div>
  );
};

export default ParkingSpotCoUsers;
