import MyDataTable from '../../../components/dataTable/MyDataTable';
import useHistoryParking from '../../../hooks/api/history/useHistoryParking';
import HistoryWrapper from '../components/HistoryWrapper';
import HistoryParkingFilter from './components/HistoryParkingFilter';
import useHistoryParkingTableHeaders from './hooks/useHistoryParkingTableHeaders';

const HistoryParking = () => {
  const { headers } = useHistoryParkingTableHeaders();
  const { history, filter, setFilter, noFilterOption, filterOptions } = useHistoryParking();

  return (
    <HistoryWrapper>
      {history && (
        <div className="relative w-full">
          <div className="mb-6">
            <HistoryParkingFilter
              filter={filter}
              setFilter={setFilter}
              noFilterOption={noFilterOption}
              filterOptions={filterOptions}
            />
          </div>
          <div className="w-full mb-12">
            <MyDataTable headers={headers} data={history} />
          </div>
        </div>
      )}
    </HistoryWrapper>
  );
};

export default HistoryParking;
