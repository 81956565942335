import { useState } from 'react';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import usePartnerActivities from '../../../../hooks/api/partner/usePartnerActivities';
import { partnerParkingActivityFilters } from '../../../../static/partnerParkingActivities/partnerParkingActivityFilters';
import { DropdownOption } from '../../../../types/Dropdown.types';
import {
  PartnerParkingActivities,
  PartnerParkingActivity,
} from '../../../../types/partnerParkingActivities/partnerParkingActivities';
import ParkingServiceWrapper from '../components/ParkingServiceWrapper';
import PartnerServiceActivitiesFilter from './components/PartnerServiceActivitiesFilter';
import useParkingServiceActivitiesTable from './hooks/useParkingServiceActivitiesTable';

const noFilterOption = partnerParkingActivityFilters.find(
  option => option.value === PartnerParkingActivities.ALL,
) as DropdownOption;

const ParkingServiceActivities = () => {
  const { headers } = useParkingServiceActivitiesTable();
  const [filter, setFilter] = useState<DropdownOption>(noFilterOption);

  const handleResetFilter = () => setFilter(noFilterOption);
  const isFiltering = filter.value !== PartnerParkingActivities.ALL;

  const { activities } = usePartnerActivities({
    activityType: filter.value === PartnerParkingActivities.ALL ? null : (filter.value as PartnerParkingActivity),
  });

  return (
    <ParkingServiceWrapper>
      <div className="relative w-full pb-40">
        <div className="">
          <PartnerServiceActivitiesFilter
            filter={filter}
            setFilter={setFilter}
            onResetFilter={handleResetFilter}
            isFiltering={isFiltering}
          />
        </div>
        {activities && (
          <div className="w-full">
            <MyDataTable
              headers={headers}
              data={activities}
              getRowId={row => `${row.parkingSpotId}-${row.start}-${row.end}`}
              showPagination
            />
          </div>
        )}
      </div>
    </ParkingServiceWrapper>
  );
};

export default ParkingServiceActivities;
