import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiSingleZoneParkingRulesItem, ISingleZoneParkingRulesItem } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformSingleZoneParkingRulesItem } from '../../../../utils/dataTransformers/transformSingleZoneParkingRulesItem/transformSingleZoneParkingRulesItem';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useSingleZoneParkingRulesProps {
  zoneId: string;
}

const useSingleZoneParkingRules = ({ zoneId }: useSingleZoneParkingRulesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([`${QueryAliases.SINGLE_PARKING_ZONE_RULES}-${zoneId}`], () =>
    axiosInstance.get(api.endpoints.SINGLE_PARKING_ZONE_RULES({ id: zoneId })),
  );

  const apiRules: ApiSingleZoneParkingRulesItem[] | undefined = data?.data;

  const parkingRules: ISingleZoneParkingRulesItem[] | undefined = !!apiRules
    ? apiRules.map(rule => transformSingleZoneParkingRulesItem(rule))
    : undefined;

  return {
    parkingRules,
  };
};

export default useSingleZoneParkingRules;
