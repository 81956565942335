import LanguageSwitcher from './components/LanguageSwitcher';
import { Logo } from './components/Logo';

interface NavbarProps {
  buttonsTop: React.ReactNode;
  buttonsBottom: React.JSX.Element;
}

export const Navbar = ({ buttonsTop, buttonsBottom }: NavbarProps) => {
  return (
    <nav className="z-50 flex flex-col justify-between h-screen pb-12 border-r-2 bg-white900 px-7 border-grey200">
      <div className="flex flex-col gap-1 mb-8">
        <div className="px-10 pt-[3.75rem] pb-8 cursor-pointer">
          <Logo width={118} />
        </div>

        {buttonsTop}
      </div>

      <div>
        <div className="flex flex-col gap-1 mb-8">{buttonsBottom}</div>
        <LanguageSwitcher />
      </div>
    </nav>
  );
};
