import { useContext } from 'react';
import { SearchBarContext } from './SearchBarContextProvider';

const useSearchBar = () => {
  const { searchValue, setSearchValue } = useContext(SearchBarContext);

  return {
    searchValue,
    setSearchValue,
  };
};

export default useSearchBar;
