import { useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { historyParkingFilters } from '../../../static/history/historyParkingFilters';
import { DropdownOption } from '../../../types/Dropdown.types';
import { historyParkingFilterType } from '../../../types/history/historyParkingFilters';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformHistoryParkings } from '../../../utils/dataTransformers/transformHistoryParkings/transformHistoryParkings';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useHistoryParking = () => {
  const noFilterOption = (historyParkingFilters.find(prop => prop.value === historyParkingFilterType.ALL) ??
    historyParkingFilters[0]) as DropdownOption;

  const [filter, setFilter] = useState<DropdownOption>(noFilterOption);
  const filterOptions = historyParkingFilters;

  const axiosInstance = useAxiosInstance();

  const status = filter.value === historyParkingFilterType.ALL ? undefined : { status: filter.value };

  const { data } = useQuery([`${QueryAliases.HISTORY_PARKING}-${filter.value}`], () =>
    axiosInstance.get(api.endpoints.HISTORY.PARKING, { params: status }),
  );

  const history = transformHistoryParkings(data?.data.results);

  return { history: history, noFilterOption, filter, setFilter, filterOptions };
};

export default useHistoryParking;
