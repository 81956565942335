import { CheckIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentState, useState } from 'react';
import { Controller, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import { LabeledStateDropdown } from '../../../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../../components/common/inputs/LabeledStateInput';
import {
  TimeInterval,
  TimeIntervalsModal,
  TimeIntervalsModalSaveProps,
} from '../../../../../components/common/modals/TimeIntervalsModal';
import Switch from '../../../../../components/common/switch/Switch';
import EditIcon from '../../../../../components/icons/EditIcon';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import { addPriceListSubscriptionSchema } from '../../../../../static/validationSchema/priceList/addPriceListSubscriptionSchema';
import { ComponentStates } from '../../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { ApiSubscriptionsPriceListRequest } from '../../../../../types/api/ApiTypes';
import { CurrencyTypes, currencyDropdownOptions } from '../../../../../types/currency/CurrencyTypes';
import { Weekdays } from '../../../../../types/weekdays/Weekdays';
import { isEndOfDay } from '../../../../../utils/dates/isEndOfDay/isEndOfDay';
import { isStartOfDay } from '../../../../../utils/dates/isStartOfDay/isStartOfDay';
import { getDaysFromDDHHMMssDuration } from '../../../../../utils/priceLists/getDaysFromDDHHMMssDuration/getDaysFromDDHHMMssDuration';
import { getTimeIntervalsFromTimeRestrictions } from '../../../../../utils/priceLists/getTimeIntervalsFromTimeRestrictions/getTimeIntervalsFromTimeRestrictions';
import { getTimeRestrictionsFromTimeIntervals } from '../../../../../utils/priceLists/getTimeRestrictionsFromTimeIntervals/getTimeRestrictionsFromTimeIntervals';
import { PriceListFormProps } from '../PriceListForm';

export interface ISubscriptionFormInputs {
  name: string;
  subscriptionPrice: number | undefined;
  subscriptionCurrency: DropdownOption;
  subscriptionDuration: number;
  limits: boolean;
  timeIntervals: TimeInterval[];
  requireApproval: boolean;
  approvalEmail: string | null;
}

type InputsKeys = keyof ISubscriptionFormInputs;

interface PriceListSubscriptionFormProps {
  priceListFormProps: PriceListFormProps;
}

export const PriceListSubscriptionForm = ({ priceListFormProps }: PriceListSubscriptionFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const initialValues = priceListFormProps.initialValues?.subscriptionValues;

  const [limitsOn, setLimitsOn] = useState<boolean>(initialValues?.hasTimeRestrictions ?? false);
  const [approvalEmailOn, setApprovalEmailOn] = useState<boolean>(initialValues?.requiresApproval ?? false);
  const [isModalOpenIdx, setIsModalOpenIdx] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<ISubscriptionFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addPriceListSubscriptionSchema(approvalEmailOn)),
    defaultValues: {
      name: initialValues?.name ?? '',
      subscriptionPrice: !!initialValues?.price ? +initialValues.price : undefined,
      subscriptionCurrency: currencyDropdownOptions.find(option => option.id === initialValues?.currency.toString()),
      subscriptionDuration: !!initialValues?.duration ? getDaysFromDDHHMMssDuration(initialValues.duration) : undefined,
      limits: initialValues?.hasTimeRestrictions ?? false,
      requireApproval: initialValues?.requiresApproval ?? false,
      timeIntervals:
        !!initialValues?.timeRestrictions && initialValues?.timeRestrictions.length !== 0
          ? getTimeIntervalsFromTimeRestrictions(initialValues.timeRestrictions)
          : Object.values(Weekdays).map(item => ({
              time: 'unavailable',
              weekday: item,
            })),
      approvalEmail: initialValues?.approvalEmail ?? '',
    },
  });

  const { fields: timeIntervalsFields, update } = useFieldArray({
    control,
    name: 'timeIntervals',
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<ISubscriptionFormInputs> = async data => {
    const requestData: ApiSubscriptionsPriceListRequest = {
      name: data.name,
      description: '',
      duration: `${data.subscriptionDuration} 00:00:00`,
      price: data.subscriptionPrice?.toString() ?? '',
      currency: data.subscriptionCurrency.id as unknown as CurrencyTypes,
      requires_approval: data.requireApproval,
      approval_email: data.approvalEmail,
      has_time_restrictions: data.limits,
      time_restrictions: getTimeRestrictionsFromTimeIntervals(timeIntervalsFields),
    };

    await priceListFormProps.onSubmitPriceListSubscription(requestData);

    reset();
  };

  const getDisplayTime = (field: FieldArrayWithId<ISubscriptionFormInputs, 'timeIntervals', 'id'>) => {
    if (field.time === 'unavailable') {
      return t(`priceListForm.subscriptionForm.times.unavailable`);
    }

    const isFullDay = field.time.length === 1 && isStartOfDay(field.time?.[0]?.from) && isEndOfDay(field.time?.[0]?.to);

    if (isFullDay) {
      return t(`priceListForm.subscriptionForm.times.full-day`);
    }

    if (field.time.length !== 0) {
      return (
        <>
          {field.time?.map(timeItem => {
            const key = `${timeItem.from.toString()}-${timeItem.to.toString()}`;

            return <p key={key}>{`${dayjs(timeItem.from).format('HH:mm')} - ${dayjs(timeItem.to).format('HH:mm')}`}</p>;
          })}
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={2} title={t('priceListForm.name.title')} />
        <div className="lg:pl-8">
          <LabeledStateInput
            id="price-list-name"
            state={getInputState('name')}
            labeledProps={{
              wrapperClassName: 'mb-5 mt-6 max-w-xl',
              label: t('priceListForm.name.label'),
              errorMessage: t(`errorMessages.${errors.name?.message}`),
            }}
            inputProps={{
              register: {
                ...register('name'),
              },
            }}
          />
        </div>
      </div>

      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={3} title={t('priceListForm.subscriptionForm.subscriptionPriceTitle')} />
        <div className="lg:pl-8">
          <div className="flex flex-col lg:flex-row items-center max-w-xl gap-3 mt-6 mb-5">
            <LabeledStateInput
              id="subscription-price"
              state={getInputState('subscriptionPrice')}
              labeledProps={{
                wrapperClassName: 'w-full',
                label: t('priceListForm.subscriptionForm.subscriptionPrice'),
                errorMessage: t(`errorMessages.${errors.subscriptionPrice?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('subscriptionPrice'),
                },
                type: 'number',
                placeholder: t('priceListForm.pricePlaceholder'),
              }}
            />
            <Controller
              name="subscriptionCurrency"
              control={control}
              render={({ field }) => (
                <LabeledStateDropdown
                  id="subscription-currency"
                  state={getInputState('subscriptionCurrency')}
                  labeledProps={{
                    wrapperClassName: 'w-full',
                    label: t('priceListForm.subscriptionForm.subscriptionCurrency'),
                    errorMessage: t(`errorMessages.${errors.subscriptionCurrency?.message}`),
                  }}
                  dropdownProps={{
                    placeholder: t('priceListForm.currencyPlaceholder'),
                    options: currencyDropdownOptions,
                    currentOption: field.value,
                    setNewOption: field.onChange,
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={4} title={t('priceListForm.subscriptionForm.subscriptionDurationTitle')} />
        <div className="lg:pl-8">
          <div className="flex items-center max-w-xl gap-3 mt-6 mb-5">
            <LabeledStateInput
              id="subscription-duration"
              state={getInputState('subscriptionDuration')}
              labeledProps={{
                wrapperClassName: 'w-full',
                label: t('priceListForm.subscriptionForm.subscriptionDuration'),
                errorMessage: t(`errorMessages.${errors.subscriptionDuration?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('subscriptionDuration'),
                },
                type: 'number',
                placeholder: t('priceListForm.time30MinutesPlaceholder'),
              }}
            />
          </div>
        </div>
      </div>

      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={5} title={t('priceListForm.subscriptionForm.timeIntervals')} />
        <Controller
          name="limits"
          control={control}
          render={({ field }) => (
            <div className="flex flex-col lg:flex-row max-w-xl gap-4 mt-6 mb-12 lg:ml-8">
              <SecondaryButton
                markActive={field.value === false}
                onClick={() => {
                  field.onChange(false);
                  setLimitsOn(false);
                }}
                prefixIcon={field.value === false && <CheckIcon width={16} />}
              >
                {t('priceListForm.subscriptionForm.timeIntervalsNoLimits')}
              </SecondaryButton>
              <SecondaryButton
                markActive={field.value === true}
                onClick={() => {
                  field.onChange(true);
                  setLimitsOn(true);
                }}
                prefixIcon={field.value === true && <CheckIcon width={16} />}
              >
                {t('priceListForm.subscriptionForm.timeIntervalsLimits')}
              </SecondaryButton>
            </div>
          )}
        />
        {limitsOn && (
          <>
            {timeIntervalsFields.map((field, idx) => (
              <div
                key={idx}
                className={`py-6 ml-8 ${
                  idx === timeIntervalsFields.length - 1 ? '' : 'border-b'
                } border-grey300 max-w-xl`}
              >
                <div className="flex justify-between items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        className="mr-4"
                        isChecked={field.time !== 'unavailable'}
                        onChange={() =>
                          update(idx, {
                            time:
                              field.time !== 'unavailable'
                                ? 'unavailable'
                                : [
                                    {
                                      from: dayjs().startOf('day').toDate(),
                                      to: dayjs().endOf('day').toDate(),
                                    },
                                  ],
                            weekday: field.weekday,
                          })
                        }
                      />
                    }
                    label={t(`weekdays.${field.weekday}`)}
                  />
                  <div className="flex justify-between items-center w-[30%]">
                    <div className="flex flex-col">{getDisplayTime(field)}</div>
                    {field.time !== 'unavailable' && (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setIsModalOpenIdx(idx);
                        }}
                      >
                        <EditIcon />
                      </div>
                    )}
                  </div>
                  <TimeIntervalsModal
                    title={t('priceListForm.subscriptionForm.modal.title')}
                    description={t('priceListForm.subscriptionForm.modal.description')}
                    subtitle={t('priceListForm.subscriptionForm.modal.title')}
                    onSave={(props: TimeIntervalsModalSaveProps) => {
                      if (props.useForAll) {
                        timeIntervalsFields.forEach((item, idx) => {
                          update(idx, {
                            time: props.addedIntervals,
                            weekday: item.weekday,
                          });
                        });
                      } else {
                        update(idx, {
                          time: props.addedIntervals,
                          weekday: field.weekday,
                        });
                      }
                    }}
                    isOpen={isModalOpenIdx === idx}
                    setIsOpen={setIsModalOpenIdx}
                    field={field}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="pb-12 mt-16 lg:pb-16">
        <FormSectionTitle number={6} title={t('priceListForm.subscriptionForm.confirmationTitle')} />
        <Controller
          name="requireApproval"
          control={control}
          render={({ field }) => (
            <div className="flex flex-col lg:flex-row max-w-xl gap-4 mt-6 lg:ml-8">
              <SecondaryButton
                markActive={field.value === false}
                onClick={() => {
                  field.onChange(false);
                  setApprovalEmailOn(false);
                }}
                prefixIcon={field.value === false && <CheckIcon width={16} />}
              >
                {t('priceListForm.subscriptionForm.confirmationOff')}
              </SecondaryButton>
              <SecondaryButton
                markActive={field.value === true}
                onClick={() => {
                  field.onChange(true);
                  setApprovalEmailOn(true);
                }}
                prefixIcon={field.value === true && <CheckIcon width={16} />}
              >
                {t('priceListForm.subscriptionForm.confirmationOn')}
              </SecondaryButton>
            </div>
          )}
        />
        {approvalEmailOn && (
          <div className="flex flex-col max-w-xl gap-4 mt-6 lg:ml-8">
            <p>{t('priceListForm.subscriptionForm.confirmationInfo')}</p>

            <LabeledStateInput
              id="approval-email"
              state={getInputState('approvalEmail')}
              labeledProps={{
                wrapperClassName: 'mb-5 mt-6 max-w-xl',
                label: t('priceListForm.subscriptionForm.email'),
                errorMessage: t(`errorMessages.${errors.approvalEmail?.message}`),
              }}
              inputProps={{
                placeholder: t('priceListForm.subscriptionForm.emailPlaceholder'),
                register: {
                  ...register('approvalEmail'),
                },
              }}
            />
          </div>
        )}
      </div>

      {!isTablet && (
        <div className="absolute right-0 -top-4">
          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            className="w-fit h-fit"
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </div>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!priceListFormProps.onDeletePriceList && (
              <DeleteIconButton onClick={priceListFormProps.onDeletePriceList} />
            )}
          </div>

          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </>
  );
};
