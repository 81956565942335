import XMarkIcon from '../../icons/XmarkIcon';
import { Modal, ModalProps } from './Modal';

const Popup = ({ children, isOpen, setIsOpen }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="px-6 py-2 border-b border-grey300 bg-grey100 flex justify-end">
        <button onClick={() => setIsOpen(false)} className="w-8 h-8 min-w-8 flex items-center justify-end">
          <XMarkIcon />
        </button>
      </div>

      <div className="px-6 py-8">{children}</div>
    </Modal>
  );
};

export default Popup;
