import { CheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import FormSectionTitle from '../../../../components/common/formSectionTitle/FormSectionTitle';
import { CallbackDefault } from '../../../../types/Types';
import {
  ApiEvPriceListRequest,
  ApiParkingPriceListRequest,
  ApiSubscriptionsPriceListRequest,
  IEvPriceListDetails,
  IParkingPriceListDetails,
  ISubscriptionsPriceListDetails,
} from '../../../../types/api/ApiTypes';
import { PriceListType, PriceListTypes } from '../../../../types/price-lists/PriceListTypes';
import { PriceListChargerForm } from './chargerForm/PriceListChargerForm';
import { PriceListParkingForm } from './parkingForm/PriceListParkingForm';
import { PriceListSubscriptionForm } from './subscriptionForm/PriceListSubscriptionForm';

export interface PriceListFormInitialValues {
  type: PriceListType;
  evValues?: IEvPriceListDetails;
  parkingValues?: IParkingPriceListDetails;
  subscriptionValues?: ISubscriptionsPriceListDetails;
}

export interface PriceListFormProps {
  onSubmitPriceListParking: (data: ApiParkingPriceListRequest) => Promise<void>;
  onSubmitPriceListEv: (data: ApiEvPriceListRequest) => Promise<void>;
  onSubmitPriceListSubscription: (data: ApiSubscriptionsPriceListRequest) => Promise<void>;
  onDeletePriceList?: CallbackDefault;

  disableTypeChange?: boolean;
  submitBtnText: string;
  submitBtnIcon: React.ReactNode;
  isSubmitBtnDisabled?: boolean;
  initialValues?: PriceListFormInitialValues;
}

const PriceListForm = (props: PriceListFormProps) => {
  const { t } = useTranslation();

  const [priceListType, setPriceListType] = useState(props.initialValues?.type ?? PriceListTypes.PARKING);

  const isParkingType = () => priceListType === PriceListTypes.PARKING;
  const isChargerType = () => priceListType === PriceListTypes.EV;
  const isSubscriptionType = () => priceListType === PriceListTypes.SUBSCRIPTION;

  return (
    <div className="flex justify-between gap-16 grow pb-52 lg:pb-16 relative">
      <div className="w-full max-w-5xl grow">
        <div className="lg:pb-16 lg:border-b lg:border-grey300">
          <FormSectionTitle number={1} title={t('priceListForm.type.title')} />
          <div className="flex max-w-xl gap-4 mt-6 ml-0 flex-col lg:flex-row lg:ml-8">
            <SecondaryButton
              disabled={props.disableTypeChange && !isParkingType()}
              markActive={isParkingType()}
              onClick={() => setPriceListType(PriceListTypes.PARKING)}
              prefixIcon={isParkingType() && <CheckIcon width={16} />}
            >
              {t('priceListForm.type.parking')}
            </SecondaryButton>
            <SecondaryButton
              disabled={props.disableTypeChange && !isChargerType()}
              markActive={isChargerType()}
              onClick={() => setPriceListType(PriceListTypes.EV)}
              prefixIcon={isChargerType() && <CheckIcon width={16} />}
            >
              {t('priceListForm.type.charger')}
            </SecondaryButton>
            <SecondaryButton
              disabled={props.disableTypeChange && !isSubscriptionType()}
              markActive={isSubscriptionType()}
              onClick={() => setPriceListType(PriceListTypes.SUBSCRIPTION)}
              prefixIcon={isSubscriptionType() && <CheckIcon width={16} />}
            >
              {t('priceListForm.type.subscription')}
            </SecondaryButton>
          </div>
        </div>

        {isParkingType() && <PriceListParkingForm priceListFormProps={props} />}
        {isChargerType() && <PriceListChargerForm priceListFormProps={props} />}
        {isSubscriptionType() && <PriceListSubscriptionForm priceListFormProps={props} />}
      </div>
    </div>
  );
};

export default PriceListForm;
