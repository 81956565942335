import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { routes } from '../../static/routes';
import { colors } from '../../styles/variables';
import CalendarIcon from '../icons/CalendarIcon';
import ChargerIcon from '../icons/ChargerIcon';
import DollarIcon from '../icons/DollarIcon';
import GroupIcon from '../icons/GroupIcon';
import HomeIcon from '../icons/HomeIcon';
import ParkingIcon from '../icons/ParkingIcon';
import { Navbar } from '../navigation/Navbar';
import { NavbarMobile } from '../navigation/NavbarMobile';
import NavButton from '../navigation/components/NavButton';
import NavButtonAccount from '../navigation/components/NavButtons/NavButtonAccount';
import NavButtonLogout from '../navigation/components/NavButtons/NavButtonLogout';
import LayoutWrapper from './LayoutWrapper';

export interface LayoutAdminProps {
  children: React.ReactNode;
}

export const LayoutAdmin = (props: LayoutAdminProps) => {
  const { children } = props;
  const { isTablet } = useIsTablet();
  const { t } = useTranslation();

  const buttonsTop = (
    <>
      <NavButton
        href={routes.home}
        text={t('navigation.home')}
        icons={{
          default: <HomeIcon />,
          active: <HomeIcon color={colors.white900} />,
        }}
      />

      <NavButton
        href={routes.parkings}
        text={t('navigation.parkings')}
        icons={{
          default: <ParkingIcon />,
          active: <ParkingIcon color={colors.white900} />,
        }}
      />

      <NavButton
        href={routes.groups}
        text={t('navigation.groups')}
        icons={{
          default: <GroupIcon />,
          active: <GroupIcon color={colors.white900} />,
        }}
      />

      <NavButton
        href={routes.chargers}
        text={t('navigation.chargers')}
        icons={{
          default: <ChargerIcon />,
          active: <ChargerIcon color={colors.white900} />,
        }}
      />
    </>
  );

  const buttonsBottom = (
    <>
      <NavButton
        href={routes.priceLists.parkings}
        text={t('navigation.price-lists')}
        icons={{
          default: <DollarIcon width={24} />,
          active: <DollarIcon width={24} color={colors.white900} />,
        }}
      />
      <NavButton
        href={routes.history.parking}
        text={t('navigation.history')}
        icons={{
          default: <CalendarIcon width={24} />,
          active: <CalendarIcon width={24} color={colors.white900} />,
        }}
      />
      <NavButtonAccount />
      <NavButtonLogout />
    </>
  );
  return (
    <LayoutWrapper
      navbar={
        isTablet ? (
          <NavbarMobile buttonsTop={buttonsTop} buttonsBottom={buttonsBottom} />
        ) : (
          <Navbar buttonsTop={buttonsTop} buttonsBottom={buttonsBottom} />
        )
      }
    >
      {children}
    </LayoutWrapper>
  );
};

export default LayoutAdmin;
