import * as yup from 'yup';
import * as Schema from '../validationFields';

export const addChargerRuleSchema = ({ groupsRequired }: { groupsRequired: boolean }) =>
  yup.object({
    name: Schema.requiredString,
    groups: Schema.conditionallyRequiredArray(groupsRequired),
    priceList: Schema.requiredObject,
    connectors: Schema.requiredArray,
  });
