import { colors } from '../../styles/variables';

interface TicketIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const TicketIcon = ({ color, width, height }: TicketIconProps) => {
  return (
    <svg
      width={width ?? '17'}
      height={height ?? '16'}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06662 5.33464V4.66797M7.06662 8.33464V7.66797M7.06662 11.3346V10.668M3.86662 2.66797H12.9333C13.68 2.66797 14.0534 2.66797 14.3386 2.81329C14.5895 2.94112 14.7935 3.1451 14.9213 3.39598C15.0666 3.6812 15.0666 4.05457 15.0666 4.8013V5.66797C13.778 5.66797 12.7333 6.71264 12.7333 8.0013C12.7333 9.28997 13.778 10.3346 15.0666 10.3346V11.2013C15.0666 11.948 15.0666 12.3214 14.9213 12.6066C14.7935 12.8575 14.5895 13.0615 14.3386 13.1893C14.0534 13.3346 13.68 13.3346 12.9333 13.3346H3.86663C3.11989 13.3346 2.74652 13.3346 2.4613 13.1893C2.21042 13.0615 2.00645 12.8575 1.87862 12.6066C1.73329 12.3214 1.73329 11.948 1.73329 11.2013V10.3346C3.02196 10.3346 4.06662 9.28997 4.06662 8.0013C4.06662 6.71264 3.02196 5.66797 1.73329 5.66797V4.8013C1.73329 4.05457 1.73329 3.6812 1.87862 3.39598C2.00645 3.1451 2.21042 2.94112 2.4613 2.81329C2.74652 2.66797 3.11989 2.66797 3.86662 2.66797Z"
        stroke={color ?? colors.black500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TicketIcon;
