import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { ApiUserRequest } from '../../../types/api/ApiTypes';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useUpdateUser = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('avatar', file);

    const response = await axiosInstance.put(api.endpoints.UPLOAD_AVATAR, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const { mutateAsync: uploadAvatar, isLoading: isLoadingUploadAvatar } = useMutation(uploadFile, {
    onSuccess: async () => {
      showSuccessToast(t('successMessages.userAvatarUploaded'));
    },
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err);

      showErrorToast(errorMessage ?? t('errorMessages.userAvatarUploadFailed'));
    },
  });

  const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } = useMutation(
    (user: ApiUserRequest) => axiosInstance.put(api.endpoints.USER_DETAILS, { ...user }),
    {
      onSuccess: async () => {
        showSuccessToast(t('successMessages.userDataUpdated'));
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.userDataUpdateFailed'));
      },
    },
  );

  return { uploadAvatar, isLoadingUploadAvatar, updateUser, isLoadingUpdateUser };
};

export default useUpdateUser;
