import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonSize } from '../../../types/ButtonSizes';
import { OnClickDefault } from '../../../types/Types';

interface SecondaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: OnClickDefault;
  size?: ButtonSize;
  prefixIcon?: React.ReactNode;
  markActive?: boolean;
}

export const SecondaryButton = ({
  children,
  onClick,
  type,
  disabled,
  className,
  prefixIcon,
  size = ButtonSize.Large,
  markActive = false,
}: SecondaryButtonProps) => {
  const getTextStyles = () => {
    switch (size) {
      case ButtonSize.Small:
        return 'text-[.8125rem] font-normal leading-4';
      case ButtonSize.Medium:
        return 'text-sm font-normal leading-[1.0625rem]';
      default:
        return 'text-base font-semibold lg:font-semibold leading-[1.125rem]';
    }
  };

  return (
    <button
      type={type ?? 'button'}
      className={twMerge(
        `flex items-center justify-center gap-2 px-3 py-4 rounded-lg text-blue700 disabled:text-black300 bg-transparent hover:bg-blueBtnBg ${getTextStyles()} w-full border-[1.5px] border-blue500 disabled:border-black300 disabled:hover:bg-transparent disabled:shadow-transparent active:shadow-activeButton visited:shadow-activeButton ${
          markActive && 'shadow-activeButton bg-blueBtnBg'
        }`,
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {prefixIcon && prefixIcon}
      {children}
    </button>
  );
};
