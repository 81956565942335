import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useMaxHeight from '../../../hooks/devices/useMaxHeight';
import { routes } from '../../../static/routes';

interface UserAccountWrapperProps {
  children: React.ReactNode;
}

const UserAccountWrapper = ({ children }: UserAccountWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { elementRef, maxHeight } = useMaxHeight();

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('userAccount.userInfo.title'),
      routeTo: routes.userAccountData,
    },
    {
      text: t('userAccount.help.title'),
      routeTo: routes.userAccountHelp,
    },
  ];

  return (
    <TitleWrapper title={t('userAccount.title')} breadcrumbsProps={{ limit: 2 }}>
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />
      <div
        ref={elementRef}
        style={{
          maxHeight: `${maxHeight}px`,
        }}
        className="lg:px-8 px-0 py-8 bg-white900 lg:overflow-y-scroll lg:no-scrollbar"
      >
        {children}
      </div>
    </TitleWrapper>
  );
};

export default UserAccountWrapper;
