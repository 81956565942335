import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteChargingRule = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, ruleId }: { id: string; ruleId: string }) =>
      axiosInstance.delete(api.endpoints.CHARGING_RULE({ id, ruleId })),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: [QueryAliases.USER_CHARGERS] });
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.deleteRuleFailed'));
      },
    },
  );
  return { deleteRule: mutateAsync, isLoading };
};

export default useDeleteChargingRule;
