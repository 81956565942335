import { ITarrifsFormInputs } from '../../../pages/priceLists/addPriceList/components/parkingForm/PriceListTarrifsForm';
import { ApiPriceListPeriodRequest, PricePerTypes, TimeUnits } from '../../../types/api/ApiTypes';
import { calculatePriceListDuration } from '../calculateDuration/calculatePriceListDuration';

export const transformTarrifsToPeriods = (tarrifs: ITarrifsFormInputs[]) => {
  if (tarrifs.length === 0) {
    return [];
  }

  let currentPricingBandIndicator = 1;

  const periods = tarrifs.map((tarrif, index) => {
    const isNewPricingBand = tarrif.resetPrice;

    if (isNewPricingBand) {
      currentPricingBandIndicator++;
    }

    const isLast = index === tarrifs.length - 1;

    const period: ApiPriceListPeriodRequest = {
      ...(!isLast && {
        duration: calculatePriceListDuration({
          appliesFrom: tarrif?.appliesFrom as number,
          appliesFromTimeUnit: +(tarrif.appliesFromTimeUnit!.id as string),
          appliesTo: tarrif?.appliesTo as number,
          appliesToTimeUnit: +(tarrif.appliesToTimeUnit!.id as string),
          ignoreFromTime: isNewPricingBand,
        }),
      }),
      price: tarrif.price!.toString(),
      ...(tarrif.intervalTimeUnit.id !== PricePerTypes.PERIOD.toString() && { price_per: +tarrif.intervalTimeUnit.id }),
      minute_based_billing: tarrif.minuteBilling,
      pricing_band: currentPricingBandIndicator,
      ...(!isLast && { time_unit: TimeUnits.MINUTE }),
    };

    return period;
  });

  return periods;
};
