import { yupResolver } from '@hookform/resolvers/yup';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import { LabeledStateDropdown } from '../../../../components/common/dropdowns/LabeledStateDropdown';
import Popup from '../../../../components/common/modals/Popup';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import CheckListIcon from '../../../../components/icons/CheckListIcon';
import PlusIcon from '../../../../components/icons/PlusIcon';
import SaveIcon from '../../../../components/icons/SaveIcon';
import useParkingRules from '../../../../hooks/api/parkings/rules/useParkingRules';
import { routes } from '../../../../static/routes';
import { addSpotRuleSchema } from '../../../../static/validationSchema/parkings/addParkingSpotRuleSchema';
import { colors } from '../../../../styles/variables';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { Availability, IParkingRulesItem, IParkingSpotRule } from '../../../../types/api/ApiTypes';
import { formatStringList } from '../../../../utils/formatStringList/formatStringList';
import TableRemoveRuleButton from './TableRemoveRuleButton';

interface ParkingSpotRulesFormInputs {
  rule: DropdownOption;
}

type InputsKeys = keyof ParkingSpotRulesFormInputs;

interface ParkingSpotRulesProps {
  parkingId: string;
  rules: (IParkingSpotRule | IParkingRulesItem)[];
  onDeleteRule: ({ id }: { id: string }) => void;
  onAddRule: (rule: IParkingRulesItem) => void;
}

const ParkingSpotRules = ({ parkingId, rules, onDeleteRule, onAddRule }: ParkingSpotRulesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const spotId = params.spotId as string;

  const { parkingRules } = useParkingRules({ parkingId });

  const parkingRulesOptions = parkingRules
    ?.map(rule => {
      const option: DropdownOption = {
        id: rule.id,
        value: rule.name,
      };

      return option;
    })
    .filter(option => !rules?.some(existingRule => existingRule.id === option.id));

  const [isAssignRuleModalOpen, setIsAssignRuleModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ParkingSpotRulesFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addSpotRuleSchema),
  });

  const findRuleByOptionId = (id: string) => parkingRules?.find(rule => rule.id === id);

  const onSubmit: SubmitHandler<ParkingSpotRulesFormInputs> = async ({ rule }) => {
    const foundRule = findRuleByOptionId(rule.id);

    if (!!foundRule) {
      onAddRule(foundRule);
    }

    setIsAssignRuleModalOpen(false);
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const rulesHeaders = [
    {
      field: 'name',
      headerName: t('editParkingSpot.rules.table.name'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.name}</p>;
      },
    },

    {
      field: 'users',
      headerName: t('editParkingSpot.rules.table.users'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const users: string[] = params.row.groups.map((group: { id: string; name: string }) => group.name);

        const usersToDisplay = formatStringList(users);

        return <p className="flex align-middle">{usersToDisplay}</p>;
      },
    },

    {
      field: 'priceList',
      headerName: t('editParkingSpot.rules.table.priceList'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.priceList}</p>;
      },
    },

    {
      field: 'parkingSpots',
      headerName: t('editParkingSpot.rules.table.parkingSpots'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const parkingSpots: { id: string; parkingSpotNumber: string }[] = params.row.parkingSpots;
        const spotNumbers = parkingSpots.map(spot => spot.parkingSpotNumber);

        const spotNumbersToDisplay = formatStringList(spotNumbers);

        return <p className="flex align-middle">{spotNumbersToDisplay}</p>;
      },
    },

    {
      field: 'availability',
      headerName: t('editParkingSpot.rules.table.availability'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const availabilities: Availability[] = params.row.availabilities;

        return (
          <div className="my-2">
            {availabilities.map((av, index) => {
              return (
                <p key={index} className="flex align-middle">
                  {`${t(`weekdays.${av.weekday}`)}: ${av.start.slice(0, 5)}-${av.end.slice(0, 5)}`}
                </p>
              );
            })}
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end">
            <TableRemoveRuleButton onConfirm={() => onDeleteRule({ id: params.row.id })} />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <MyDataTable headers={rulesHeaders} data={rules ?? []} getRowId={row => row.id} />
      <div className="flex flex-col lg:flex-row gap-4 mt-8 lg:mt-6">
        <SecondaryButton
          onClick={() => setIsAssignRuleModalOpen(true)}
          className="lg:w-fit"
          prefixIcon={<CheckListIcon />}
        >
          {t('editParkingSpot.rules.assignRuleBtn')}
        </SecondaryButton>

        <SecondaryButton
          className="lg:w-fit"
          prefixIcon={<PlusIcon color={colors.blue700} />}
          onClick={() => navigate(routes.addParkingRule({ parkingId, spotId }))}
        >
          {t('editParkingSpot.rules.addNewRuleBtn')}
        </SecondaryButton>
      </div>

      <Popup isOpen={isAssignRuleModalOpen} setIsOpen={setIsAssignRuleModalOpen}>
        <h5 className="text-black700 text-base font-semibold">{t('editParkingSpot.rules.add.title')}</h5>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <Controller
            name="rule"
            control={control}
            render={({ field }) => (
              <LabeledStateDropdown
                id="rule"
                state={getInputState('rule')}
                labeledProps={{
                  wrapperClassName: 'mb-8 mt-6',
                  errorMessage: t(`errorMessages.${errors.rule?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('editParkingSpot.rules.add.dropdownPlaceholder'),
                  buttonClassName: 'w-full flex justify-between',
                  options: parkingRulesOptions ?? [],
                  currentOption: field.value,
                  setNewOption: field.onChange,
                }}
              />
            )}
          />

          <PrimaryButton type="submit" className="mt-6 lg:mt-12 lg:w-fit place-self-end" prefixIcon={<SaveIcon />}>
            {t('editParkingSpot.rules.add.saveBtn')}
          </PrimaryButton>
        </form>
      </Popup>
    </div>
  );
};

export default ParkingSpotRules;
