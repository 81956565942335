import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import FormSectionTitle from '../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { addGroupValidationSchema } from '../../../static/validationSchema/addGroupValidationSchema';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';
import { CallbackDefault } from '../../../types/Types';
import { GroupPermission, IGroupUser } from '../../../types/api/ApiTypes';
import AddUserToGroupForm from './AddUserToGroupForm';
import GroupUsersTable from './GroupUsersTable';

interface IFormInputs {
  displayName: string;
}

type InputsKeys = keyof IFormInputs;

interface GroupFormProps {
  initialName?: string;
  initialUsers?: IGroupUser[];
  onSubmitForm: ({ displayName, groupUsers }: { displayName: string; groupUsers: IGroupUser[] }) => void;
  submitBtnText: string;
  submitBtnIcon: React.ReactNode;
  onDeleteGroup?: CallbackDefault;
  isSubmitBtnDisabled?: boolean;
}

const GroupForm = ({
  initialName,
  initialUsers,
  onSubmitForm,
  submitBtnText,
  submitBtnIcon,
  onDeleteGroup,
  isSubmitBtnDisabled = false,
}: GroupFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const [groupUsers, setGroupUsers] = useState<IGroupUser[]>(initialUsers ?? []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addGroupValidationSchema),
    defaultValues: {
      displayName: initialName ?? '',
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async ({ displayName }) => {
    onSubmitForm({ displayName, groupUsers });
    reset();
  };

  const handleAddUserToGroup = (newUser: IGroupUser) => {
    setGroupUsers(prev => [...prev, newUser]);
  };

  const handleDeleteUserFromGroup = ({ phone }: { phone: string }) => {
    setGroupUsers(prev => {
      const newUsers = prev.filter(user => user.phone !== phone);
      return newUsers;
    });
  };

  const handleChangeUsersPermissions = ({
    phone,
    newPermissions,
  }: {
    phone: string;
    newPermissions: GroupPermission[];
  }) => {
    const users = [...groupUsers];

    const editedUser = users.find(user => user.phone === phone);

    if (!editedUser) return;

    editedUser.permissions = newPermissions;

    setGroupUsers(users);
  };

  return (
    <div className="flex justify-between gap-16 pb-8 mb-52 lg:mb-0 grow">
      <div className="w-full max-w-5xl grow">
        <div className="pb-12 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={1} title={t('addGroup.name.title')} />
          <div className="lg:pl-8">
            <LabeledStateInput
              id="group-name"
              state={getInputState('displayName')}
              labeledProps={{
                wrapperClassName: 'mb-5 mt-6 max-w-xl',
                label: t('addGroup.name.label'),
                errorMessage: t(`errorMessages.${errors.displayName?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('displayName'),
                },
              }}
            />
          </div>
        </div>

        <div className="mt-16">
          <FormSectionTitle number={2} title={t('addGroup.usersForm.title')} />
          <div className="lg:pl-8">
            <AddUserToGroupForm
              onAddUser={handleAddUserToGroup}
              existingPhoneNumbers={groupUsers.map(user => user.phone)}
            />
          </div>
        </div>

        {groupUsers.length !== 0 && (
          <div className="pt-16 mt-16 border-t border-grey300">
            <FormSectionTitle number={3} title={t('addGroup.usersList.title')} />
            <div className="mt-6 lg:pl-8">
              <GroupUsersTable
                users={groupUsers}
                onDeleteUser={handleDeleteUserFromGroup}
                onEditUserPermision={handleChangeUsersPermissions}
              />
            </div>
          </div>
        )}
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={isSubmitBtnDisabled}
          onClick={handleSubmit(onSubmit)}
          className="w-fit h-fit"
          prefixIcon={submitBtnIcon}
        >
          {submitBtnText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!onDeleteGroup && <DeleteIconButton onClick={onDeleteGroup} />}
          </div>

          <PrimaryButton disabled={isSubmitBtnDisabled} onClick={handleSubmit(onSubmit)} prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};

export default GroupForm;
