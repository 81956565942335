import React from 'react';

import SingleZoneWrapper from '../components/SingleZoneWrapper';
import { useParams } from 'react-router-dom';

import useSingleParkingZone from '../../../../../hooks/api/parkings/zones/useSingleParkingZone';
import { ReservationTypeEnum } from '../../../../../types/api/ApiTypes';
import ActivitiesTabs from '../../../components/ActivitiesTabs/ActivitiesTabs';

const ActivityType = {
  Calendar: 'calendar',
  ActivitiesList: 'activitiesList',
};
const SingleZoneActivities = () => {
  const params = useParams();

  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;
  const [activityType, setActivityType] = React.useState<string>(ActivityType.ActivitiesList);
  const { parkingZone } = useSingleParkingZone({ zoneId });

  return (
    <SingleZoneWrapper>
      <ActivitiesTabs
        activityType={activityType}
        setActivityType={setActivityType}
        parkingId={parkingId}
        withCalendar={parkingZone?.reservationType === ReservationTypeEnum.NO_RESERVATIONS}
      />
    </SingleZoneWrapper>
  );
};

export default SingleZoneActivities;
