import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import { editParkingSpotNameSchema } from '../../../../static/validationSchema/parkings/editParkingSpotNameSchema';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';
import { CallbackDefault } from '../../../../types/Types';

interface ParkingSpotNameFormInputs {
  name: string;
}

type InputsKeys = keyof ParkingSpotNameFormInputs;

interface ParkingSpotNameFormProps {
  initialName: string;
  onSubmitForm: (newName: string) => void;
  onClose: CallbackDefault;
}

const ParkingSpotNameForm = ({ initialName, onSubmitForm, onClose }: ParkingSpotNameFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ParkingSpotNameFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(editParkingSpotNameSchema),
    defaultValues: {
      name: initialName,
    },
  });

  const onSubmit: SubmitHandler<ParkingSpotNameFormInputs> = async data => {
    onSubmitForm(data.name);
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:max-w-xl">
      <LabeledStateInput
        id="name"
        state={getInputState('name')}
        labeledProps={{
          wrapperClassName: 'mb-2  grow ',
          label: t('editParkingSpot.name.label'),
          errorMessage: t(`errorMessages.${errors.name?.message}`),
        }}
        inputProps={{
          register: {
            ...register('name'),
          },
        }}
      />

      <div className="mt-2 place-self-end">
        <button type="button" onClick={onClose} className="pr-4 text-blue-500 font-medium text-sm">
          {t('editParkingSpot.cancelBtn')}
        </button>
        <button type="submit" className="text-blue-500 font-medium text-sm">
          {t('editParkingSpot.saveBtn')}
        </button>
      </div>
    </form>
  );
};

export default ParkingSpotNameForm;
