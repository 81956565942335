import { useNavigate } from 'react-router-dom';
import { colors } from '../../../styles/variables';
import ArrowLeft from '../../icons/ArrowLeft';
import IconButton from './IconButton';

interface BackButtonProps {
  className?: string;
}

const BackButton = ({ className }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => navigate(-1)}
      icon={<ArrowLeft color={colors.black700} />}
      className={`border border-grey400 bg-grey10 ${className}`}
    />
  );
};

export default BackButton;
