import { ApiCharger, ICharger } from '../../../types/api/ApiTypes';

export const transformCharger = (charger: ApiCharger): ICharger => {
  const transformedCharger: ICharger = {
    id: charger.id,
    name: charger.name,
    parkingName: charger.parking_name,
    parkingSpots: charger.parking_spots,
    serialNumber: charger.serial_number,
    status: charger.status,
    isEnabled: charger.is_enabled,
    connectors: charger.connectors,
    specification: charger.specification,
  };

  return transformedCharger;
};
