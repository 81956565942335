import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowRight from '../../../../../components/icons/ArrowRight';
import { routes } from '../../../../../static/routes';

interface useParkingZonesTableProps {
  parkingId: string;
}

const useParkingZonesTable = ({ parkingId }: useParkingZonesTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parkingZones = [
    {
      field: 'name',
      headerName: t('parkingDetails.zones.table.name'),
      minWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">{params.row.name}</p>
          </div>
        );
      },
    },
    {
      field: 'spotCount',
      headerName: t('parkingDetails.zones.table.spotCount'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex flex-col items-start w-full">
            <p className="text-sm font-medium text-black700">{params.row.spotCount}</p>
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const zoneId = params.id as string;

        return (
          <div className="flex justify-end w-full py-4 pl-7">
            <div
              className="hover:cursor-pointer"
              onClick={() => navigate(routes.parkingAdvanced.zoneView.generalInfo(parkingId, zoneId))}
            >
              <ArrowRight />
            </div>
          </div>
        );
      },
    },
  ];

  return { headers: parkingZones };
};

export default useParkingZonesTable;
