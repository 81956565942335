import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { ApiEditParkingSpotRequest } from '../../../types/api/ApiTypes';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { queryClient } from '../../../queryClient';

const useEditParkingSpot = () => {
  const { showErrorToast } = useToast();
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const uploadImage = async ({ spotId, file }: { spotId: string; file: File }) => {
    const formData = new FormData();
    formData.append('main_image', file);

    const response = await axiosInstance.put(api.endpoints.PARKING_SPOT_MAIN_IMAGE({ spotId }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const uploadPdf = async ({ spotId, file }: { spotId: string; file: File }) => {
    const formData = new FormData();
    formData.append('file_name', file.name);
    formData.append('file', file);

    const response = await axiosInstance.post(api.endpoints.PARKING_SPOT_PDFS({ spotId }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const { mutateAsync: uploadSpotMainImage, isLoading: isLoadingUploadImage } = useMutation(uploadImage, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err);

      showErrorToast(errorMessage ?? t(`errorMessages.parkingSpotImageUploadFailed`));
    },
  });

  const { mutateAsync: uploadRegulation, isLoading: isLoadingUploadPdf } = useMutation(uploadPdf, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err);

      showErrorToast(errorMessage ?? t(`errorMessages.parkingSpotRegulationUploadFailed`));
    },
  });

  const { mutateAsync: deleteSpotMainImage, isLoading: isLoadingDeleteImage } = useMutation(
    ({ spotId }: { spotId: string }) => axiosInstance.delete(api.endpoints.PARKING_SPOT_MAIN_IMAGE({ spotId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.deletingParkingSpotImageFailed`));
      },
    },
  );

  const { mutateAsync: updateParkingSpot, isLoading: isLoadingUpdateSpot } = useMutation(
    ({ spotId, body }: { spotId: string; body: ApiEditParkingSpotRequest }) =>
      axiosInstance.patch(api.endpoints.SINGLE_PARKING_SPOT({ id: spotId }), {
        ...body,
      }),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries({ queryKey: [`${QueryAliases.SINGLE_PARKING_SPOT}-${variables.spotId}`] });
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editingParkingSpotFailed`));
      },
    },
  );

  const isLoading = isLoadingUploadImage || isLoadingUploadPdf || isLoadingDeleteImage || isLoadingUpdateSpot;

  return { uploadSpotMainImage, uploadRegulation, deleteSpotMainImage, updateParkingSpot, isLoading };
};

export default useEditParkingSpot;
