import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiParkingSpotDetailsByParking, IParkingSpotDetailsByParking } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformParkingSpotDetailsByParking } from '../../../../utils/dataTransformers/transformParkingSpotDetailsByParking/transformParkingSpotDetailsByParking';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useSingleZoneParkingSpotsProps {
  zoneId: string;
}

const useSingleZoneParkingSpots = ({ zoneId }: useSingleZoneParkingSpotsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_ZONE_SPOTS}-${zoneId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_ZONE_SPOTS({ id: zoneId })),
    {
      cacheTime: 1,
    },
  );

  const apiParkingSpots: ApiParkingSpotDetailsByParking[] | undefined = data?.data;

  const parkingSpots: IParkingSpotDetailsByParking[] | undefined = !!apiParkingSpots
    ? apiParkingSpots?.map(spot => transformParkingSpotDetailsByParking(spot))
    : undefined;

  return { parkingSpots: !!parkingSpots ? parkingSpots : undefined };
};

export default useSingleZoneParkingSpots;
