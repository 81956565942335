import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteSubscriptionPriceList = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    (id: string) => axiosInstance.delete(api.endpoints.SINGLE_SUBSCRIPTION_PRICE_LIST(id)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: [QueryAliases.SUBSCRIPTION_PRICE_LISTS] });
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.deletePriceListFailed`));
      },
    },
  );

  return { deleteSubscriptionPriceList: mutateAsync, isLoading };
};

export default useDeleteSubscriptionPriceList;
