import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../../static/api';
import { ApiParkingRuleRequest } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';

const useAddParkingRule = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ parkingId, rule }: { parkingId: string; rule: ApiParkingRuleRequest }) =>
      axiosInstance.post(api.endpoints.PARKING_RULES({ id: parkingId }), {
        ...rule,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([QueryAliases.PARKING_RULES]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.addingParkingRuleFailed`));
      },
    },
  );

  return { addRule: mutateAsync, isLoading };
};

export default useAddParkingRule;
