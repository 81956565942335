import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiSingleParkingZone, ISingleParkingZone } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformSingleParkingZone } from '../../../../utils/dataTransformers/transformSingleParkingZone/transformSingleParkingZone';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useSingleParkingZoneProps {
  zoneId: string;
}

const useSingleParkingZone = ({ zoneId }: useSingleParkingZoneProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_ZONES}-${zoneId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_ZONE({ id: zoneId })),
    {
      cacheTime: 1,
    },
  );

  const apiParkingZone: ApiSingleParkingZone | undefined = data?.data;

  const parkingZone: ISingleParkingZone | undefined = !!apiParkingZone
    ? transformSingleParkingZone(apiParkingZone)
    : undefined;

  return { parkingZone: !!parkingZone ? parkingZone : undefined };
};

export default useSingleParkingZone;
