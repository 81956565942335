import { colors } from '../../styles/variables';

interface PlusIconProps {
  color?: string;
  small?: boolean;
}

const PlusIcon = ({ color, small }: PlusIconProps) => {
  return (
    <svg
      width={small ? '16' : '24'}
      height={small ? '16' : '24'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 3.3335V12.6668M3.33301 8.00016H12.6663"
        stroke={color ?? colors.white900}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
