import { useTranslation } from 'react-i18next';
import IncomeIcon from '../../../components/icons/IncomeIcon';
import TransactionsCountIcon from '../../../components/icons/TransactionsCountIcon';

interface TransactionCardsProps {
  income?: string;
  transactionCount?: number;
}

export const TransactionCards = ({ income, transactionCount }: TransactionCardsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full gap-4 lg:flex-row">
      <div className="flex flex-col w-full gap-4 p-8 rounded-xl bg-grey100">
        <div className="p-2 bg-white rounded-full w-min">
          <IncomeIcon />
        </div>
        <p>{t('dashboard.transactionCards.income')}</p>
        <p className="font-bold">{income}</p>
      </div>
      <div className="flex flex-col w-full gap-4 p-8 rounded-xl bg-grey100">
        <div className="p-2 bg-white rounded-full w-min">
          <TransactionsCountIcon />
        </div>
        <p>{t('dashboard.transactionCards.count')}</p>
        <p className="font-bold">{transactionCount}</p>
      </div>
    </div>
  );
};
