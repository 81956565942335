import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackButton from '../../components/common/buttons/BackButton';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useCreateSubscription from '../../hooks/api/parkings/zones/useCreateSubscription';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import AddSubscriptionForm from './components/AddSubscriptionForm';

const AddSubscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const [searchParams] = useSearchParams();
  const zoneId = searchParams.get('zoneId');

  const { createSubscription, isLoading } = useCreateSubscription({ zoneId: zoneId! });

  const handleCreateSubscription = async ({ subscriptionId }: { subscriptionId: string }) => {
    if (zoneId) {
      await createSubscription({ subscriptionId });
    }

    navigate(-1);
  };

  return (
    <TitleWrapper title={t('parkingDetails.zones.subscriptions.title')} breadcrumbsProps={{ limit: 2 }}>
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      <AddSubscriptionForm
        onSubmitForm={handleCreateSubscription}
        submitBtnText={t('addGroup.submitBtn')}
        submitBtnIcon={<PlusIcon height={16} />}
        isSubmitBtnDisabled={isLoading}
      />
    </TitleWrapper>
  );
};
export default AddSubscription;
