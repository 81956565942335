import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Logo } from '../../components/navigation/components/Logo';
import AuthWrapper from '../../components/wrappers/authWrapper/AuthWrapper';
import useResetPassword from '../../hooks/api/auth/useResetPassword';
import { routes } from '../../static/routes';
import { getResetPasswordToken } from '../../utils/cookieHelpers/cookieHelpers';
import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPassword = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const phone = params.phone;
  const token = getResetPasswordToken();

  const { resetPassword, isLoading } = useResetPassword();

  if (!phone || !token) {
    navigate(routes.home);
  }

  const handleResetPassword = async (password: string) => {
    await resetPassword({ password, token: token as string, phone: phone as string });
  };

  return (
    <AuthWrapper>
      <Logo />
      <h1 className="mt-12 text-black700">{t('resetPassword.title')}</h1>
      <ResetPasswordForm onSubmitForm={handleResetPassword} isLoading={isLoading} />
    </AuthWrapper>
  );
};

export default ResetPassword;
