import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const multipartHeaders = {
  'Content-Type': 'multipart/form-data',
};

const useEditParking = () => {
  const { showErrorToast } = useToast();
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const uploadMainImage = async ({ file, endpoint }: { file: File; endpoint: string }) => {
    const formData = new FormData();
    formData.append('main_image', file);

    const response = await axiosInstance.put(endpoint, formData, {
      headers: multipartHeaders,
    });

    return response.data;
  };

  const uploadPdf = async ({ parkingId, file }: { parkingId: string; file: File }) => {
    const formData = new FormData();
    formData.append('file_name', file.name);
    formData.append('file', file);

    const response = await axiosInstance.post(api.endpoints.SINGLE_PARKING_PDFS({ id: parkingId }), formData, {
      headers: multipartHeaders,
    });

    return response.data;
  };

  const { mutateAsync: uploadParkingMainImage, isLoading: isLoadingUploadMainImage } = useMutation(
    (params: { parkingId: string; file: File }) =>
      uploadMainImage({ ...params, endpoint: api.endpoints.SINGLE_PARKING_MAIN_IMAGE({ id: params.parkingId }) }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.parkingImageUploadFailed`));
      },
    },
  );

  const { mutateAsync: uploadParkingGalleryImage, isLoading: isLoadingUploadGalleryImage } = useMutation(
    async ({ parkingId, file }: { parkingId: string; file: File }) => {
      const formData = new FormData();
      formData.append('image', file);

      const response = await axiosInstance.post(api.endpoints.SINGLE_PARKING_GALLERY({ id: parkingId }), formData, {
        headers: multipartHeaders,
      });

      return response.data;
    },
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.parkingImageUploadFailed`));
      },
    },
  );

  const { mutateAsync: uploadRegulation, isLoading: isLoadingUploadRegulation } = useMutation(uploadPdf, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err);

      showErrorToast(errorMessage ?? t(`errorMessages.parkingRegulationUploadFailed`));
    },
  });

  const { mutateAsync: deleteParkingMainImage, isLoading: isLoadingDeleteMainImage } = useMutation(
    ({ parkingId }: { parkingId: string }) =>
      axiosInstance.delete(api.endpoints.SINGLE_PARKING_MAIN_IMAGE({ id: parkingId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.parkingImageDeleteFailed`));
      },
    },
  );

  const { mutateAsync: deleteParkingGalleryImage, isLoading: isLoadingDeleteGalleryImage } = useMutation(
    ({ parkingId, galleryEntryId }: { parkingId: string; galleryEntryId: string }) =>
      axiosInstance.delete(api.endpoints.SINGLE_PARKING_GALLERY_DELETE({ id: parkingId, galleryEntryId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.parkingImageDeleteFailed`));
      },
    },
  );

  const { mutateAsync: updateParking, isLoading: isLoadingUpdateParking } = useMutation(
    ({
      parkingId,
      body,
    }: {
      parkingId: string;
      body: {
        description: string;
      };
    }) =>
      axiosInstance.patch(api.endpoints.SINGLE_PARKING({ id: parkingId }), {
        ...body,
      }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editingParkingFailed`));
      },
    },
  );

  const isLoading =
    isLoadingUploadMainImage ||
    isLoadingUploadGalleryImage ||
    isLoadingUploadRegulation ||
    isLoadingDeleteMainImage ||
    isLoadingDeleteGalleryImage ||
    isLoadingUpdateParking;

  return {
    uploadParkingMainImage,
    uploadRegulation,
    uploadParkingGalleryImage,
    deleteParkingMainImage,
    deleteParkingGalleryImage,
    updateParking,
    isLoading,
  };
};

export default useEditParking;
