import MyDataTable from '../../../../components/dataTable/MyDataTable';
import useParkingActivitiesTable from '../../parkingBasic/activities/hooks/useParkingActivitiesTable';
import useActivitiesList from '../../../../hooks/api/parkings/useActivitiesList';

interface ActivitiesListViewProps {
  parkingId: string;
}

const ActivitiesListView = ({ parkingId }: ActivitiesListViewProps) => {
  const { headers } = useParkingActivitiesTable({ parkingId });
  const { activities } = useActivitiesList({ parkingId });

  return <MyDataTable headers={headers} data={activities} />;
};

export default ActivitiesListView;
