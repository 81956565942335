import MyDataTable from '../../../components/dataTable/MyDataTable';
import useParkingPriceLists from '../../../hooks/api/priceLists/useParkingPriceLists';
import { PriceListTypes } from '../../../types/price-lists/PriceListTypes';
import PriceListsWrapper from '../components/PriceListsWrapper';
import useParkingPriceListsTableHeaders from './hooks/useParkingPriceListsTableHeaders';

const PriceListsParkings = () => {
  const { headers } = useParkingPriceListsTableHeaders();
  const { priceLists } = useParkingPriceLists();

  return (
    <PriceListsWrapper type={PriceListTypes.PARKING}>
      {!!priceLists && <MyDataTable headers={headers} data={priceLists} />}
    </PriceListsWrapper>
  );
};

export default PriceListsParkings;
