import { colors } from '../../../styles/variables';

interface SpotRegulationsIconProps {
  color?: string;
}

const SpotRegulationsIcon = ({ color }: SpotRegulationsIconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 2.26953V6.40007C14.5 6.96012 14.5 7.24015 14.609 7.45406C14.7049 7.64222 14.8578 7.7952 15.046 7.89108C15.2599 8.00007 15.5399 8.00007 16.1 8.00007H20.2305M9.5 16L11.5 18L16 13.5M14.5 2H9.3C7.61984 2 6.77976 2 6.13803 2.32698C5.57354 2.6146 5.1146 3.07354 4.82698 3.63803C4.5 4.27976 4.5 5.11984 4.5 6.8V17.2C4.5 18.8802 4.5 19.7202 4.82698 20.362C5.1146 20.9265 5.57354 21.3854 6.13803 21.673C6.77976 22 7.61984 22 9.3 22H15.7C17.3802 22 18.2202 22 18.862 21.673C19.4265 21.3854 19.8854 20.9265 20.173 20.362C20.5 19.7202 20.5 18.8802 20.5 17.2V8L14.5 2Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpotRegulationsIcon;
