import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import useToast from '../../../../../../hooks/useToast';
import useAxiosInstance from '../../../../../../hooks/axios/useAxiosInstance';
import { ApiParkingRuleRequest } from '../../../../../../types/api/ApiTypes';
import { api } from '../../../../../../static/api';
import { QueryAliases } from '../../../../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../../../../utils/getApiErrorMessage/getApiErrorMessage';

const useAddParkingZoneRule = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ zoneId, rule }: { zoneId: string; rule: ApiParkingRuleRequest }) =>
      axiosInstance.post(api.endpoints.SINGLE_PARKING_ZONE_RULES({ id: zoneId }), {
        ...rule,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([QueryAliases.SINGLE_PARKING_ZONE_RULES]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.addingParkingRuleFailed`));
      },
    },
  );

  return { addRule: mutateAsync, isLoading };
};

export default useAddParkingZoneRule;
