import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { api } from '../../static/api';
import { ValidateUserPhoneRequestType } from '../../types/api/ApiTypes';
import useAxiosInstance from '../axios/useAxiosInstance';

const useValidateUsersPhone = () => {
  const axiosInstance = useAxiosInstance();

  const { mutateAsync } = useMutation(({ phone_number, group_id }: ValidateUserPhoneRequestType) =>
    axiosInstance.post(api.endpoints.VALIDATE_USER_PHONE, { phone_number, group_id }),
  );

  const validatePhone = async ({ phone }: { phone: string }) => {
    try {
      await mutateAsync({ phone_number: phone });
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorCode = error.response?.data.errors[0]?.code;

        throw Error(errorCode);
      }
    }
  };

  return { validatePhone };
};

export default useValidateUsersPhone;
