import React from 'react';

import ParkingBasicWrapper from '../ParkingBasicWrapper';
import { useParams } from 'react-router-dom';

import ActivitiesTabs from '../../components/ActivitiesTabs/ActivitiesTabs';
import { ActivityType } from '../../../../types/parkings/activityType';

const Activities = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;
  const [activityType, setActivityType] = React.useState<string>(ActivityType.ActivitiesList);

  return (
    <ParkingBasicWrapper>
      <ActivitiesTabs
        activityType={activityType}
        setActivityType={setActivityType}
        parkingId={parkingId}
        withCalendar={false}
      />
    </ParkingBasicWrapper>
  );
};

export default Activities;
