import { ApiParkingZoneDetailsByParking, IParkingZoneDetailsByParking } from '../../../types/api/ApiTypes';

export const transformParkingZoneDetailsByParking = (
  apiParkingZone: ApiParkingZoneDetailsByParking,
): IParkingZoneDetailsByParking => {
  const transformedParkingZone: IParkingZoneDetailsByParking = {
    id: apiParkingZone.id,
    name: apiParkingZone.name,
    reservationType: apiParkingZone.reservation_type,
    spotCount: apiParkingZone.spot_count,
  };

  return transformedParkingZone;
};
