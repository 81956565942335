import React from 'react';
import { useTranslation } from 'react-i18next';

interface CalendarEventProps {
  colorClass: string;
  parkingSpotName: string;
  start?: string;
  end?: string;
}

const CalendarEvent = ({ colorClass, parkingSpotName, start, end }: CalendarEventProps) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="my-title inline-flex items-center text-xs gap-4">
        <div
          className={`relative grid select-none items-center whitespace-nowrap rounded-full ${colorClass} py-1 px-2 uppercase text-white`}
        >
          <span>{parkingSpotName}</span>
        </div>
        <span className="text-black500">
          {start ? `${t('parkingSpotCalendar.from')} ${start}` : ''}{' '}
          {end ? `${t('parkingSpotCalendar.to')} ${end}` : ''}
          {!start && !end && t('parkingSpotCalendar.allDay')}
        </span>
      </div>
    </React.Fragment>
  );
};

export default CalendarEvent;
