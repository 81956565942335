import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TrashIcon from '../../../../../../components/icons/TrashIcon';

export interface ITarrifsHeaders {
  price: string;
  time: string;
  timeIntervals: string;
  reset: boolean;
  minuteBilling: boolean;
}

interface UseTarrifsTableHeadersProps {
  lastItemId: string | undefined;
  deleteRow: (id: string) => void;
  hideResetColumn?: boolean;
  hideMinuteBilllingColumn?: boolean;
}

const useTarrifsTableHeaders = ({
  lastItemId,
  deleteRow,
  hideResetColumn,
  hideMinuteBilllingColumn,
}: UseTarrifsTableHeadersProps) => {
  const { t } = useTranslation();

  const tarrifsHeaders = [
    {
      field: 'price',
      headerName: t('priceListForm.tarrifsHeaders.price'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'time',
      headerName: t('priceListForm.tarrifsHeaders.time'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'timeIntervals',
      headerName: t('priceListForm.tarrifsHeaders.timeIntervals'),
      minWidth: 130,
      flex: 1,
    },
    ...(!hideResetColumn
      ? [
          {
            field: 'reset',
            headerName: t('priceListForm.tarrifsHeaders.reset'),
            minWidth: 130,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div className={`flex items-center justify-center gap-4`}>
                  {params.row.reset && <div className="w-2 h-2 min-w-2 rounded-full bg-success" />}
                  <p>{params.row.reset ? t('priceListForm.tarrifsHeaders.on') : ''}</p>
                </div>
              );
            },
          },
        ]
      : []),

    ...(!hideMinuteBilllingColumn
      ? [
          {
            field: 'minuteBilling',
            headerName: t('priceListForm.tarrifsHeaders.minuteBilling'),
            minWidth: 130,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div className="flex items-center justify-center gap-4">
                  {params.row.minuteBilling && <div className="w-2 h-2 min-w-2 rounded-full bg-success" />}
                  <p>{params.row.minuteBilling ? t('priceListForm.tarrifsHeaders.on') : ''}</p>
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isHidden = params.row.id !== lastItemId;

        return (
          <div className={`flex w-full justify-end gap-2.5 px-7 min-h-[49px] `}>
            <button className={`p-3 ${isHidden && 'hidden'}`} onClick={() => deleteRow(params.row.id)}>
              <TrashIcon />
            </button>
          </div>
        );
      },
    },
  ];

  return { tarrifsHeaders };
};

export default useTarrifsTableHeaders;
