import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { IGroupUser } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useEditGroupUser = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, user }: { id: string; user: IGroupUser }) =>
      axiosInstance.put(api.endpoints.SINGLE_GROUP_USERS({ groupId: id }), {
        phone_number: user.phone,
        permissions: user.permissions,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryAliases.USER_GROUPS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editGroupUserFailed`));
      },
    },
  );

  return { editGroupUser: mutateAsync, isLoading };
};

export default useEditGroupUser;
