interface AuthInfoPaginationProps {
  currentSlideIndex: number;
  numOfSlides: number;
  className?: string;
}

const AuthInfoPagination = ({ currentSlideIndex, numOfSlides, className }: AuthInfoPaginationProps) => {
  return (
    <div className={`w-full grid grid-cols-${numOfSlides} gap-1 ${className}`}>
      {[...Array(numOfSlides)].map((_, index) => (
        <div
          key={index}
          className={`h-1 rounded-full bg-white900 ${currentSlideIndex === index ? '' : 'opacity-50'}`}
        />
      ))}
    </div>
  );
};

export default AuthInfoPagination;
