/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ComponentState, Dispatch, SetStateAction } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import XMarkButton from '../../../../../components/common/buttons/XMarkButton';
import { LabeledStateDropdown } from '../../../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../../components/common/inputs/LabeledStateInput';
import MyDataTable from '../../../../../components/dataTable/MyDataTable';
import HintPopup from '../../../../../components/dataTable/components/HintPopup';
import { usePricePerUnitTypes } from '../../../../../hooks/pricePer/usePricePerUnitTypes';
import { useTimeUnitTypes } from '../../../../../hooks/timeUnit/useTimeUnitTypes';
import useToast from '../../../../../hooks/useToast';
import { addPriceListTarrifsSchema } from '../../../../../static/validationSchema/priceList/addPriceListTarrifsSchema';
import { ComponentStates } from '../../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { TimeUnits } from '../../../../../types/api/ApiTypes';
import { PriceListFormProps } from '../PriceListForm';
import useTarrifsTableHeaders, { ITarrifsHeaders } from './hooks/useTarrifsTableHeaders';

const getNewAppliesFrom = (tarrifs: ITarrifsFormInputs[], defaultValue = 0) => {
  if (tarrifs.length === 0) {
    return defaultValue;
  }

  const lastTarrif = tarrifs[tarrifs.length - 1];
  return lastTarrif?.appliesTo ?? defaultValue;
};

const getNewAppliesFromUnitType = (tarrifs: ITarrifsFormInputs[], defaultValue: DropdownOption) => {
  if (tarrifs.length === 0) {
    return defaultValue;
  }

  const lastTarrif = tarrifs[tarrifs.length - 1];
  return lastTarrif?.appliesToTimeUnit ?? defaultValue;
};

export interface ITarrifsFormInputs {
  id?: string;
  price: number | null;
  intervalTimeUnit: DropdownOption;
  appliesFrom: number | null;
  appliesFromTimeUnit: DropdownOption;
  appliesTo: number | null;
  appliesToTimeUnit: DropdownOption;
  minuteBilling: boolean;
  resetPrice: boolean;
}

type InputsKeys = keyof ITarrifsFormInputs;

interface PriceListTarrifsFormProps {
  priceListFormProps: PriceListFormProps;
  currentTarrifs: ITarrifsFormInputs[];
  setCurrentTarrifs: Dispatch<SetStateAction<ITarrifsFormInputs[]>>;
  onSubmitForm: (values: ITarrifsFormInputs) => void;
  tableData: ITarrifsHeaders[];
}

export const PriceListTarrifsForm = ({
  priceListFormProps,
  currentTarrifs,
  setCurrentTarrifs,
  onSubmitForm,
  tableData,
}: PriceListTarrifsFormProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { timeUnitTypes } = useTimeUnitTypes();
  const { pricePerUnitTypes } = usePricePerUnitTypes();

  const minuteOption = timeUnitTypes.find(type => type.id === TimeUnits.MINUTE.toString()) as DropdownOption;

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm<ITarrifsFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addPriceListTarrifsSchema),
    defaultValues: {
      price: null,
      appliesFrom: 0,
      appliesFromTimeUnit: minuteOption,
      appliesTo: null,
      minuteBilling: false,
      resetPrice: false,
    },
  });

  const { tarrifsHeaders } = useTarrifsTableHeaders({
    lastItemId: currentTarrifs[currentTarrifs.length - 1]?.id,
    deleteRow: (id: string) => {
      setCurrentTarrifs(prevState => {
        const newTarrifs = prevState.filter(item => item.id !== id.toString());

        const newAppliesFrom = getNewAppliesFrom(newTarrifs);
        const newAppliesFromUnitType = getNewAppliesFromUnitType(newTarrifs, minuteOption);

        setValue('appliesFrom', newAppliesFrom);
        setValue('appliesFromTimeUnit', newAppliesFromUnitType);

        return newTarrifs;
      });
    },
    hideResetColumn: currentTarrifs.every(tarrif => !tarrif.resetPrice),
    hideMinuteBilllingColumn: currentTarrifs.every(tarrif => !tarrif.minuteBilling),
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<ITarrifsFormInputs> = async values => {
    try {
      onSubmitForm(values);

      reset();

      setValue('appliesFrom', values.appliesTo);
      setValue('appliesFromTimeUnit', values.appliesToTimeUnit);
    } catch (e) {
      const error = e as { message?: string };
      showErrorToast(error?.message ?? '', {
        autoClose: 10000,
      });
    }
  };

  return (
    <>
      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={4} title={t('priceListForm.parkingForm.title')} />
        <div className="lg:pl-8">
          <div className="flex flex-col lg:flex-row lg:items-center max-w-xl gap-1 mt-6 mb-5 relative">
            <p className="w-1/2">{t('priceListForm.parkingForm.priceTitle')}</p>
            <div className="flex justify-between lg:justify-normal lg:w-1/2 items-center gap-2 mt-3 lg:mt-0">
              <LabeledStateInput
                id="price"
                state={getInputState('price')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.parkingForm.price'),
                  errorMessage: t(`errorMessages.${errors.price?.message}`),
                }}
                inputProps={{
                  register: { ...register('price') },
                  type: 'number',
                  min: 1,
                  placeholder: t('priceListForm.pricePlaceholder'),
                }}
              />

              <XMarkButton
                onClick={() => resetField('price')}
                className={`mt-1 lg:mt-0 lg:absolute lg:-right-12 lg:top-1/2 lg:-translate-y-1/2 ${
                  !!watch('price') ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </div>
          </div>
        </div>
        <div className="lg:pl-8">
          <div className="flex flex-col lg:flex-row lg:items-center max-w-xl gap-1 mt-6 mb-5 relative">
            <p className="w-1/2">{t('priceListForm.parkingForm.intervalTitle')}</p>

            <div className="flex justify-between lg:justify-normal lg:w-1/2 items-center gap-2 mt-3 lg:mt-0">
              <Controller
                name="intervalTimeUnit"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="interval-time-unit"
                    state={getInputState('intervalTimeUnit')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.parkingForm.timeUnit'),
                      errorMessage: t(`errorMessages.${errors.intervalTimeUnit?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('priceListForm.timeUnitHourPlaceholder'),
                      options: pricePerUnitTypes,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />

              <XMarkButton
                onClick={() => resetField('intervalTimeUnit')}
                className={`mt-1 lg:mt-0 lg:absolute lg:-right-12 lg:top-1/2 lg:-translate-y-1/2 ${
                  !!watch('intervalTimeUnit') ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </div>
          </div>
        </div>
        <div className="lg:pl-8">
          <div className="flex flex-col lg:flex-row lg:items-center max-w-xl gap-2 mt-6 mb-5 relative">
            <p className="lg:w-1/2">{t('priceListForm.parkingForm.appliesFrom')}</p>
            <div className="flex justify-between lg:justify-normal lg:w-1/2 items-center gap-2 mt-3 lg:mt-0">
              <LabeledStateInput
                id="applies-from"
                state={getInputState('appliesFrom')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.parkingForm.parkingTime'),
                  errorMessage: t(`errorMessages.${errors.appliesFrom?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('appliesFrom'),
                  },
                  disabled: true,
                  type: 'number',
                  placeholder: t('priceListForm.intervalTimePlaceholder'),
                }}
              />
              <Controller
                name="appliesFromTimeUnit"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="applies-from-time-unit"
                    state={getInputState('appliesFromTimeUnit')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.parkingForm.timeUnit'),
                      errorMessage: t(`errorMessages.${errors.appliesFromTimeUnit?.message}`),
                    }}
                    dropdownProps={{
                      disabled: true,
                      placeholder: t('priceListForm.timeUnitHourPlaceholder'),
                      options: timeUnitTypes,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="lg:pl-8">
          <div className="flex flex-col lg:flex-row lg:items-center max-w-xl gap-2 mt-6 mb-5 relative">
            <p className="lg:w-1/2">{t('priceListForm.parkingForm.appliesTo')}</p>
            <div className="flex justify-between lg:justify-normal lg:w-1/2 items-center gap-2 mt-3 lg:mt-0">
              <LabeledStateInput
                id="applies-to"
                state={getInputState('appliesTo')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.parkingForm.parkingTime'),
                  errorMessage: t(`errorMessages.${errors.appliesTo?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('appliesTo'),
                  },
                  type: 'number',
                  min: 1,
                  placeholder: t('priceListForm.intervalTimePlaceholder'),
                }}
              />
              <Controller
                name="appliesToTimeUnit"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="applies-to-time-unit"
                    state={getInputState('appliesToTimeUnit')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.parkingForm.timeUnit'),
                      errorMessage: t(`errorMessages.${errors.appliesToTimeUnit?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('priceListForm.timeUnitHourPlaceholder'),
                      options: timeUnitTypes,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />

              <XMarkButton
                onClick={() => {
                  resetField('appliesTo');
                  resetField('appliesToTimeUnit');
                }}
                className={`mt-1 lg:mt-0 lg:absolute lg:-right-12 lg:top-1/2 lg:-translate-y-1/2 ${
                  !!watch('appliesTo') || !!watch('appliesToTimeUnit') ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col pt-4 lg:pl-8">
          <Controller
            name="minuteBilling"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-1">
                <FormControlLabel
                  control={<Checkbox checked={field.value} onChange={field.onChange} />}
                  label={t('priceListForm.parkingForm.minuteBillingCheckbox')}
                />
                <HintPopup
                  info={<p className="text-center">{t('priceListForm.parkingForm.minuteBillingTooltip')}</p>}
                />
              </div>
            )}
          />
          <Controller
            name="resetPrice"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-1">
                <FormControlLabel
                  control={<Checkbox checked={field.value} onChange={field.onChange} />}
                  label={t('priceListForm.parkingForm.resetPriceCheckbox')}
                />
                <HintPopup info={<p className="text-center">{t('priceListForm.parkingForm.resetPriceTooltip')}</p>} />
              </div>
            )}
          />
        </div>

        <div className="pt-4 lg:pl-8">
          <div className="flex max-w-xl gap-4">
            <SecondaryButton
              onClick={() => {
                reset();

                const newAppliesFrom = getNewAppliesFrom(currentTarrifs);
                const newAppliesFromUnitType = getNewAppliesFromUnitType(currentTarrifs, minuteOption);

                setValue('appliesFrom', newAppliesFrom);
                setValue('appliesFromTimeUnit', newAppliesFromUnitType);
              }}
              className="ml-auto lg:mr-0 lg:w-fit h-fit"
            >
              {t('priceListForm.parkingForm.clearBtn')}
            </SecondaryButton>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              className="lg:w-fit h-fit"
              prefixIcon={priceListFormProps.submitBtnIcon}
            >
              {t('priceListForm.parkingForm.submitBtn')}
            </PrimaryButton>
          </div>
        </div>
      </div>

      <MyDataTable headers={tarrifsHeaders} data={tableData} />
    </>
  );
};
