import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WalletIcon from '../../../components/icons/WalletIcon';
import useUserLatestTransactions from '../../../hooks/api/user/wallet/useUserLatestTransactions';
import { routes } from '../../../static/routes';

interface DashboardSidebarProps {
  balance?: string;
}

export const DashboardSidebar = ({ balance }: DashboardSidebarProps) => {
  const { t } = useTranslation();

  const { userLatestTransactions } = useUserLatestTransactions();

  return (
    <div className="flex flex-col h-full p-4 rounded-xl bg-grey100">
      <div className="flex flex-col gap-4 p-4 text-white rounded-xl bg-blue500">
        <div className="p-2 bg-white rounded-full w-min">
          <WalletIcon />
        </div>
        <p>{t('dashboard.sidebar.wallet')}</p>
        <p className="font-bold">{balance}</p>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-5">
          <p className="pt-6">{t('dashboard.sidebar.lastTransactions')}</p>
          {userLatestTransactions?.slice(0, 6).map((item, idx) => (
            <div key={idx} className="flex gap-2">
              <div className="p-2 bg-white rounded-full shadow-sidebarIcon w-min h-min">
                <WalletIcon />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm">{t(`dashboard.transactionReason.${item.reason}`)}</p>
                <p className="text-sm text-black300">{new Date(item.createdAt).toLocaleString()}</p>
              </div>
              <p className="ml-auto mr-0 font-bold text-end">{`${item.userPrice.price} ${item.userPrice.currency}`}</p>
            </div>
          ))}
        </div>
        <Link to={routes.history.parking} className="mb-3 ml-auto mr-0 text-sm text-blue500">
          {t('dashboard.sidebar.historyLink')}
        </Link>
      </div>
    </div>
  );
};
