import MyDataTable from '../../../components/dataTable/MyDataTable';
import useHistoryEv from '../../../hooks/api/history/useHistoryEv';
import HistoryWrapper from '../components/HistoryWrapper';
import HistoryEvFilter from './components/HistoryEvFilter';
import useHistoryEvTableHeaders from './hooks/useHistoryEvTableHeaders';

const HistoryEv = () => {
  const { headers } = useHistoryEvTableHeaders();
  const { historyEv, filter, setFilter, noFilterOption, filterOptions } = useHistoryEv();

  return (
    <HistoryWrapper>
      {historyEv && (
        <div className="relative w-full">
          <div className="mb-6">
            <HistoryEvFilter
              filter={filter}
              setFilter={setFilter}
              noFilterOption={noFilterOption}
              filterOptions={filterOptions}
            />
          </div>
          <div className="w-full mb-12">
            <MyDataTable headers={headers} data={historyEv} />
          </div>
        </div>
      )}
    </HistoryWrapper>
  );
};

export default HistoryEv;
