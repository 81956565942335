import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiEvPriceListDetails, IEvPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformEvPriceListDetails } from '../../../utils/dataTransformers/transformEvPriceListDetails/transformEvPriceListDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useSingleEvPriceList = ({ id, enabled }: { id: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_EV_PRICE_LIST}-${id}`],
    () => axiosInstance.get(api.endpoints.SINGLE_EV_PRICE_LIST(id)),
    {
      refetchOnMount: true,
      enabled,
    },
  );

  const apiPriceList: ApiEvPriceListDetails | undefined = data?.data;

  const priceList: IEvPriceListDetails | undefined = !!apiPriceList
    ? transformEvPriceListDetails(apiPriceList)
    : undefined;

  return { priceList };
};

export default useSingleEvPriceList;
