import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../components/icons/TrashIcon';
import useDeleteGroup from '../../../hooks/api/groups/useDeleteGroup';
import { colors } from '../../../styles/variables';

interface TableRemoveGroupButtonProps {
  id: string;
}

const TableRemoveGroupButton = ({ id }: TableRemoveGroupButtonProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { deleteGroup } = useDeleteGroup();

  const handleDeleteGroup = async () => {
    await deleteGroup(id);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <button className="hover:cursor-pointer p-3" onClick={() => setIsDeleteModalOpen(true)}>
        <TrashIcon />
      </button>

      <ConfirmModal
        title={t('groups.delete.title')}
        confirmQuestion={t('groups.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('groups.delete.cancelBtnText')}
        confirmBtnText={t('groups.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteGroup}
      />
    </>
  );
};

export default TableRemoveGroupButton;
