import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BottomActionBox } from '../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import XMarkButton from '../../../../components/common/buttons/XMarkButton';
import { LabeledStateDatepicker } from '../../../../components/common/datepicker/LabeledDatepicker';
import { LabeledStateDropdown } from '../../../../components/common/dropdowns/LabeledStateDropdown';
import { LabeledStateMultiDropdown } from '../../../../components/common/dropdowns/LabeledStateMultiDropdown';
import FormSectionTitle from '../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import {
  TimeInterval,
  TimeIntervalsModal,
  TimeIntervalsModalSaveProps,
} from '../../../../components/common/modals/TimeIntervalsModal';
import Switch from '../../../../components/common/switch/Switch';
import EditIcon from '../../../../components/icons/EditIcon';
import useParkingSpots from '../../../../hooks/api/parkings/useParkingSpots';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import useGroupsDropdownOptions from '../../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import useParkingPriceListsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingPriceListsDropdownOptions';
import useParkingSpotsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { routes } from '../../../../static/routes';
import { addParkingRuleSchema } from '../../../../static/validationSchema/rules/addParkingRuleSchema';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { CallbackDefault } from '../../../../types/Types';
import {
  ApiEditParkingRuleRequest,
  IParkingRuleDetails,
  ParkingType,
  ParkingZoneRuleTypeEnum,
} from '../../../../types/api/ApiTypes';
import { PriceListTypes } from '../../../../types/price-lists/PriceListTypes';
import { Weekdays } from '../../../../types/weekdays/Weekdays';
import { isEndOfDay } from '../../../../utils/dates/isEndOfDay/isEndOfDay';
import { isStartOfDay } from '../../../../utils/dates/isStartOfDay/isStartOfDay';
import { getFullAvailability } from '../../../../utils/parkingRules/getFullAvailability/getFullAvailability';
import { getTimeRestrictionsFromTimeIntervals } from '../../../../utils/priceLists/getTimeRestrictionsFromTimeIntervals/getTimeRestrictionsFromTimeIntervals';
import { getInitialIntervals } from '../utils/getInitialIntervals/getInitialIntervals';
import { getParkingSpotIds } from '../utils/getParkingSpotIds/getParkingSpotIds';
import { isFullAvailabilityByInterval } from '../utils/isFullAvailability/isFullAvailabilityByInterval';

export enum AppliesTo {
  PARKING = 'PARKING',
  SPOTS = 'SPOTS',
}

interface IFormInputs {
  name: string;
  parkingSpots: DropdownOption[];
  groups: DropdownOption[];
  priceList: DropdownOption;
  timeIntervals: TimeInterval[];
  validFrom: Date | null;
  validTo: Date | null;
  ruleType?: ParkingZoneRuleTypeEnum;
}

type InputsKeys = keyof IFormInputs;

interface ParkingRuleFormProps {
  parkingId: string;
  parkingType: ParkingType;
  onSubmitForm: (data: ApiEditParkingRuleRequest) => Promise<void>;
  isSubmitBtnLoading: boolean;
  submitBtnText: string;
  initialValues?: {
    rule: IParkingRuleDetails;
    spotsOptions: DropdownOption[];
    groupsOptions: DropdownOption[];
    priceListOption: DropdownOption | undefined;
  };
  onDeleteRule?: CallbackDefault;
  predefinedSpotOption?: DropdownOption;
}

const ParkingRuleForm = ({
  parkingId,
  parkingType,
  onSubmitForm,
  isSubmitBtnLoading,
  submitBtnText,
  initialValues,
  onDeleteRule,
  predefinedSpotOption,
}: ParkingRuleFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();
  const navigate = useNavigate();

  const [appliesTo, setAppliesTo] = useState(
    !!predefinedSpotOption || (!!initialValues?.rule?.parkingSpots?.length ?? false)
      ? AppliesTo.SPOTS
      : AppliesTo.PARKING,
  );

  const [ruleType, setRuleType] = useState(
    (initialValues?.rule?.ruleType ?? null) || parkingType === ParkingType.ADVANCED
      ? ParkingZoneRuleTypeEnum.RESERVATIONS
      : null,
  );
  const [refersToAllUsers, setRefersToAllUsers] = useState(
    !!initialValues ? initialValues?.rule.groups.length === 0 : false,
  );
  const [isSpotTimeRestricted, setIsSpotTimeRestricted] = useState(
    !!initialValues ? !isFullAvailabilityByInterval(getInitialIntervals(initialValues.rule.availabilities)) : false,
  );
  const [isModalOpenIdx, setIsModalOpenIdx] = useState<number | null>(null);
  const [isRuleTimeRestricted, setIsRuleTimeRestricted] = useState(
    !!initialValues ? !!initialValues.rule.validFrom || !!initialValues.rule.validTo : false,
  );

  const { parkingSpots: allParkingSpots } = useParkingSpots({ parkingId });
  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });
  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListsDropdownOptions } = useParkingPriceListsDropdownOptions();

  const getSpotsOptions = () => {
    if (!!predefinedSpotOption) {
      return [predefinedSpotOption];
    } else {
      return initialValues?.spotsOptions ?? [];
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(
      addParkingRuleSchema({
        areParkingSpotsRequired: appliesTo === AppliesTo.SPOTS,
        areGroupsRequired: !refersToAllUsers,
        areDatesRequired: isRuleTimeRestricted,
      }),
    ),
    defaultValues: {
      name: initialValues?.rule.name ?? '',
      parkingSpots: getSpotsOptions(),
      groups: initialValues?.groupsOptions ?? [],
      timeIntervals:
        !!initialValues?.rule.availabilities && initialValues.rule.availabilities.length !== 0
          ? getInitialIntervals(initialValues.rule.availabilities)
          : Object.values(Weekdays).map(item => ({
              time: 'unavailable',
              weekday: item,
            })),
      priceList: initialValues?.priceListOption ?? undefined,
      validFrom: !!initialValues?.rule.validFrom ? dayjs(initialValues?.rule.validFrom).toDate() : null,
      validTo: !!initialValues?.rule.validTo ? dayjs(initialValues?.rule.validTo).toDate() : null,
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const { fields: timeIntervalsFields, update } = useFieldArray({
    control,
    name: 'timeIntervals',
  });

  React.useEffect(() => {
    if (ruleType === ParkingZoneRuleTypeEnum.TICKETS) {
      setAppliesTo(AppliesTo.PARKING);
    }
  }, [ruleType]);

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const newRule: ApiEditParkingRuleRequest = {
      name: data.name,
      price_list: data.priceList.id,
      availabilities: isSpotTimeRestricted
        ? getTimeRestrictionsFromTimeIntervals(timeIntervalsFields)
        : getFullAvailability(),
      ...getParkingSpotIds({ appliesTo, spots: data.parkingSpots, parkingType, allParkingSpots }),
      groups: refersToAllUsers ? [] : data.groups.map(group => group.id),
      valid_from: isRuleTimeRestricted ? dayjs(data.validFrom).format('YYYY-MM-DD') : null,
      valid_to: isRuleTimeRestricted ? dayjs(data.validTo).format('YYYY-MM-DD') : null,
      ...((!ruleType || ruleType === ParkingZoneRuleTypeEnum.RESERVATIONS) && {
        apply_for_all_spots: appliesTo === AppliesTo.PARKING,
      }),
      ...(!!ruleType && { rule_type: ruleType }),
    };

    onSubmitForm(newRule);
  };

  const getDisplayTime = (field: FieldArrayWithId<IFormInputs, 'timeIntervals', 'id'>) => {
    if (field.time === 'unavailable') {
      return <p>{t(`priceListForm.subscriptionForm.times.unavailable`)}</p>;
    }

    const isFullDay = field.time.length === 1 && isStartOfDay(field.time?.[0]?.from) && isEndOfDay(field.time?.[0]?.to);

    if (isFullDay) {
      return <p>{t(`priceListForm.subscriptionForm.times.full-day`)}</p>;
    }

    if (field.time.length !== 0) {
      return (
        <>
          {field.time?.map(timeItem => {
            const key = `${timeItem.from.toString()}-${timeItem.to.toString()}`;

            return <p key={key}>{`${dayjs(timeItem.from).format('HH:mm')} - ${dayjs(timeItem.to).format('HH:mm')}`}</p>;
          })}
        </>
      );
    }

    return <></>;
  };

  const submitBtnIcon = <PlusIcon height={16} />;

  return (
    <div className="flex justify-between gap-16 pb-8 grow">
      <div className="w-full max-w-5xl grow pb-52">
        <div className="pb-12 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={1} title={t('parkingRuleForm.name.title')} />
          <div className="lg:pl-8">
            <LabeledStateInput
              id="name"
              state={getInputState('name')}
              labeledProps={{
                wrapperClassName: ' mt-6 max-w-xl',
                label: t('parkingRuleForm.name.label'),
                errorMessage: t(`errorMessages.${errors.name?.message}`),
              }}
              inputProps={{
                placeholder: t('parkingRuleForm.name.placeholder'),
                register: {
                  ...register('name'),
                },
              }}
            />
          </div>
        </div>
        {ruleType && (
          <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
            <FormSectionTitle number={2} title={t('parkingRuleForm.ruleType.title')} />
            <div className="flex max-w-xl gap-4 mt-6 ml-0 flex-col lg:flex-row lg:ml-8">
              <SecondaryButton
                markActive={ruleType === ParkingZoneRuleTypeEnum.TICKETS}
                onClick={() => setRuleType(ParkingZoneRuleTypeEnum.TICKETS)}
                prefixIcon={ruleType === ParkingZoneRuleTypeEnum.TICKETS && <CheckIcon width={16} />}
              >
                {t('parkingRuleForm.ruleType.ticket')}
              </SecondaryButton>
              <SecondaryButton
                markActive={ruleType === ParkingZoneRuleTypeEnum.RESERVATIONS}
                onClick={() => setRuleType(ParkingZoneRuleTypeEnum.RESERVATIONS)}
                prefixIcon={ruleType === ParkingZoneRuleTypeEnum.RESERVATIONS && <CheckIcon width={16} />}
              >
                {t('parkingRuleForm.ruleType.reservation')}
              </SecondaryButton>
            </div>
          </div>
        )}
        {!predefinedSpotOption && (
          <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
            <FormSectionTitle number={2} title={t('parkingRuleForm.appliesTo.title')} />

            <div className="flex max-w-xl gap-4 mt-6 ml-0 flex-col lg:flex-row lg:ml-8">
              <SecondaryButton
                markActive={appliesTo === AppliesTo.PARKING}
                onClick={() => setAppliesTo(AppliesTo.PARKING)}
                prefixIcon={appliesTo === AppliesTo.PARKING && <CheckIcon width={16} />}
              >
                {t('parkingRuleForm.appliesTo.wholeParking')}
              </SecondaryButton>
              <SecondaryButton
                disabled={ruleType === ParkingZoneRuleTypeEnum.TICKETS}
                markActive={appliesTo === AppliesTo.SPOTS}
                onClick={() => setAppliesTo(AppliesTo.SPOTS)}
                prefixIcon={appliesTo === AppliesTo.SPOTS && <CheckIcon width={16} />}
              >
                {t('parkingRuleForm.appliesTo.parkingSpot')}
              </SecondaryButton>
            </div>

            {appliesTo === AppliesTo.SPOTS && (
              <div className="lg:pl-8">
                <div className="max-w-xl mt-6 ">
                  <Controller
                    name="parkingSpots"
                    control={control}
                    render={({ field }) => (
                      <LabeledStateMultiDropdown
                        id="parking-spots"
                        state={getInputState('parkingSpots')}
                        labeledProps={{
                          wrapperClassName: 'w-full',
                          errorMessage: t(`errorMessages.${errors.parkingSpots?.message}`),
                        }}
                        dropdownProps={{
                          placeholder: t('parkingRuleForm.appliesTo.dropdownPlaceholder'),
                          buttonClassName: 'w-full flex justify-between items-center',
                          options: spotsDropdownOptions ?? [],
                          currentOptions: field.value,
                          setNewOptions: field.onChange,
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
          <FormSectionTitle number={!!predefinedSpotOption ? 2 : 3} title={t('parkingRuleForm.groups.title')} />

          <div className="lg:pl-8 my-6">
            <FormControlLabel
              control={<Checkbox checked={refersToAllUsers} onChange={e => setRefersToAllUsers(e.target.checked)} />}
              label={t('parkingRuleForm.groups.checkboxLabel')}
            />
          </div>

          {!refersToAllUsers && (
            <div className="lg:pl-8">
              <div className="max-w-xl mt-6 ">
                <p className="text-black700 font-bold mb-6">{t('parkingRuleForm.groups.selectGroupDescription')}</p>

                <Controller
                  name="groups"
                  control={control}
                  render={({ field }) => (
                    <LabeledStateMultiDropdown
                      id="groups"
                      state={getInputState('groups')}
                      labeledProps={{
                        wrapperClassName: 'w-full',
                        errorMessage: t(`errorMessages.${errors.groups?.message}`),
                      }}
                      dropdownProps={{
                        buttonClassName: 'w-full flex justify-between items-center',
                        options: groupsDropdownOptions ?? [],
                        currentOptions: field.value,
                        setNewOptions: field.onChange,
                      }}
                    />
                  )}
                />

                <PrimaryButton
                  onClick={() => navigate(routes.addGroup)}
                  className="w-fit mt-6"
                  prefixIcon={<PlusIcon height={16} />}
                >
                  {t('parkingRuleForm.groups.addGroupBtn')}
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
        <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
          <FormSectionTitle number={!!predefinedSpotOption ? 3 : 4} title={t('parkingRuleForm.priceList.title')} />

          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 ">
              <Controller
                name="priceList"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="priceList"
                    state={getInputState('priceList')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      errorMessage: t(`errorMessages.${errors.priceList?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('parkingRuleForm.priceList.title'),
                      buttonClassName: 'w-full flex justify-between items-center',
                      options: priceListsDropdownOptions ?? [],
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
              <PrimaryButton
                onClick={() => navigate(routes.addPriceList({ type: PriceListTypes.PARKING }))}
                className="w-fit mt-6"
                prefixIcon={<PlusIcon height={16} />}
              >
                {t('parkingRuleForm.priceList.addPriceListBtn')}
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
          <FormSectionTitle
            number={!!predefinedSpotOption ? 4 : 5}
            title={t('parkingRuleForm.spotTimeRestrictions.title')}
          />
          <div className="flex flex-col lg:flex-row max-w-xl gap-4 mt-6 mb-12 lg:ml-8">
            <SecondaryButton
              markActive={!isSpotTimeRestricted}
              onClick={() => {
                setIsSpotTimeRestricted(false);
              }}
              prefixIcon={!isSpotTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingRuleForm.spotTimeRestrictions.restrictions.off')}
            </SecondaryButton>
            <SecondaryButton
              markActive={isSpotTimeRestricted}
              onClick={() => {
                setIsSpotTimeRestricted(true);
              }}
              prefixIcon={isSpotTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingRuleForm.spotTimeRestrictions.restrictions.on')}
            </SecondaryButton>
          </div>

          {isSpotTimeRestricted && (
            <>
              {timeIntervalsFields.map((field, idx) => (
                <div
                  key={idx}
                  className={`py-6 ml-8 ${
                    idx === timeIntervalsFields.length - 1 ? '' : 'border-b'
                  } border-grey300 max-w-xl`}
                >
                  <div className="flex justify-between items-center">
                    <FormControlLabel
                      control={
                        <Switch
                          className="mr-4"
                          isChecked={field.time !== 'unavailable'}
                          onChange={() =>
                            update(idx, {
                              time:
                                field.time !== 'unavailable'
                                  ? 'unavailable'
                                  : [
                                      {
                                        from: dayjs().startOf('day').toDate(),
                                        to: dayjs().endOf('day').toDate(),
                                      },
                                    ],
                              weekday: field.weekday,
                            })
                          }
                        />
                      }
                      label={t(`weekdays.${field.weekday}`)}
                    />
                    <div className="flex justify-between items-center w-[30%]">
                      <div className="flex flex-col">{getDisplayTime(field)}</div>
                      {field.time !== 'unavailable' && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setIsModalOpenIdx(idx);
                          }}
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>

                    <TimeIntervalsModal
                      title={t('parkingRuleForm.spotTimeRestrictions.modal.title')}
                      description={t('parkingRuleForm.spotTimeRestrictions.modal.description')}
                      subtitle={t('parkingRuleForm.spotTimeRestrictions.modal.subtitle')}
                      onSave={(props: TimeIntervalsModalSaveProps) => {
                        if (props.useForAll) {
                          timeIntervalsFields.forEach((item, idx) => {
                            update(idx, {
                              time: props.addedIntervals,
                              weekday: item.weekday,
                            });
                          });
                        } else {
                          update(idx, {
                            time: props.addedIntervals,
                            weekday: field.weekday,
                          });
                        }
                      }}
                      isOpen={isModalOpenIdx === idx}
                      setIsOpen={setIsModalOpenIdx}
                      field={field}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="pb-12 lg:pb-16 mt-16">
          <FormSectionTitle
            number={!!predefinedSpotOption ? 5 : 6}
            title={t('parkingRuleForm.ruleTimeRestrictions.title')}
          />

          <div className="flex max-w-xl gap-4 mt-6 ml-0 flex-col lg:flex-row lg:ml-8">
            <SecondaryButton
              markActive={!isRuleTimeRestricted}
              onClick={() => setIsRuleTimeRestricted(false)}
              prefixIcon={!isRuleTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingRuleForm.ruleTimeRestrictions.restrictions.off')}
            </SecondaryButton>
            <SecondaryButton
              markActive={isRuleTimeRestricted}
              onClick={() => setIsRuleTimeRestricted(true)}
              prefixIcon={isRuleTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingRuleForm.ruleTimeRestrictions.restrictions.on')}
            </SecondaryButton>
          </div>

          {isRuleTimeRestricted && (
            <div className="lg:pl-8">
              <div className="max-w-xl mt-6 ">
                <p className="text-black700 font-bold mb-6">
                  {t('parkingRuleForm.ruleTimeRestrictions.selectDatesDescription')}
                </p>
                <div className="flex gap-4 justify-between">
                  <div className="flex gap-2">
                    <Controller
                      control={control}
                      name="validFrom"
                      render={({ field }) => (
                        <LabeledStateDatepicker
                          id="valid-from"
                          state={getInputState('validFrom')}
                          labeledProps={{
                            wrapperClassName: 'mb-4 max-w-md',
                            label: t('parkingRuleForm.ruleTimeRestrictions.from'),
                            errorMessage: t(`errorMessages.${errors.validFrom?.message}`),
                          }}
                          datepickerProps={{
                            date: field.value,
                            setDate: field.onChange,
                          }}
                        />
                      )}
                    />
                    <XMarkButton onClick={() => setValue('validFrom', null)} className="mt-5" />
                  </div>

                  <div className="flex gap-2">
                    <Controller
                      control={control}
                      name="validTo"
                      render={({ field }) => (
                        <LabeledStateDatepicker
                          id="valid-to"
                          state={getInputState('validTo')}
                          labeledProps={{
                            wrapperClassName: 'mb-4 max-w-md',
                            label: t('parkingRuleForm.ruleTimeRestrictions.to'),
                            errorMessage: t(`errorMessages.${errors.validTo?.message}`),
                          }}
                          datepickerProps={{
                            date: field.value,
                            setDate: field.onChange,
                          }}
                        />
                      )}
                    />
                    <XMarkButton onClick={() => setValue('validTo', null)} className="mt-5" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={isSubmitBtnLoading}
          onClick={handleSubmit(onSubmit)}
          className="w-fit h-fit"
          prefixIcon={submitBtnIcon}
        >
          {submitBtnText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!onDeleteRule && <DeleteIconButton onClick={onDeleteRule} />}
          </div>

          <PrimaryButton disabled={isSubmitBtnLoading} onClick={handleSubmit(onSubmit)} prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};

export default ParkingRuleForm;
