import { ApiGroup, IGroup } from '../../../types/api/ApiTypes';
import { transformGroupUsers } from '../transformGroupUser/transformGroupUser';

export const transformGroup = (apiGroup: ApiGroup): IGroup => {
  return {
    id: apiGroup.id,
    displayName: apiGroup.display_name,
    userCount: apiGroup.user_count,
    inUse: apiGroup.in_use,
    permissions: apiGroup.permissions,
    users: transformGroupUsers(apiGroup.users),
  };
};
