import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGroup } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useUserGroups = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([QueryAliases.USER_GROUPS], () => axiosInstance.get(api.endpoints.USER_GROUPS), {
    refetchOnMount: true,
  });

  const groups: ApiGroup[] | undefined = data?.data;

  return { groups };
};

export default useUserGroups;
