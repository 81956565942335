import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import FilterDropdown from '../../../../components/common/dropdowns/FilterDropdown';
import FilterIcon from '../../../../components/icons/FilterIcon';
import XMarkIcon from '../../../../components/icons/XmarkIcon';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { historyParkingFilterType } from '../../../../types/history/historyParkingFilters';

interface HistoryParkingFilterProps {
  filter: DropdownOption;
  setFilter: Dispatch<SetStateAction<DropdownOption>>;
  noFilterOption: DropdownOption;
  filterOptions: DropdownOption[];
}

const HistoryEvFilter = ({ filter, filterOptions, noFilterOption, setFilter }: HistoryParkingFilterProps) => {
  const { t } = useTranslation();

  const isFiltering = filter?.value !== historyParkingFilterType.ALL;

  const resetFilter = () => setFilter(noFilterOption);

  return (
    <div className="flex min-h-[2.5rem] relative">
      <div className="w-fit max-lg:absolute max-lg:right-0 max-lg:top-[-160%]">
        <FilterDropdown
          label={t('history.filtersLabel')}
          options={filterOptions}
          currentOption={filter}
          setNewOption={newOption => {
            setFilter(newOption);
          }}
          iconLeft={<FilterIcon />}
          showIndicator={isFiltering}
          translationPath="history.ev.filters"
        />
      </div>

      {isFiltering && filter?.value && (
        <div className={twMerge(`px-4 py-2 flex justify-center items-center rounded-md ml-2 gap-3 ${filter.color}`)}>
          <p className="font-medium text-black700">{t(`history.ev.filters.${filter.value}`)}</p>
          <button onClick={resetFilter}>
            <XMarkIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default HistoryEvFilter;
