import { ApiParking, IParking } from '../../../types/api/ApiTypes';
import { transformAddress } from '../transformAddress/transformAddress';

export const transformParkings = (parkings: ApiParking[] | undefined) => {
  if (parkings) {
    const transformedData: IParking[] = parkings.map(parking => ({
      id: parking.id,
      name: parking.name,
      address: transformAddress(parking.address),
      mainImage: parking.main_image,
      type: parking.type,
      parkingSpotsCount: parking.parking_spots_count,
      accessType: parking.access_type,
    }));

    return transformedData;
  }
  return [];
};
