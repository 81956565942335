import { Fragment, useState } from 'react';
import { HorizontalDivider } from '../../../../../components/common/dividers/HorizontalDivider';
import FaqItem from './FaqItem';

interface FaqItemsProps {
  items: { question: string; answer: string }[];
}

const FaqItems = ({ items }: FaqItemsProps) => {
  const [expandedItems, setExpandedItems] = useState<boolean[]>(Array(items.length).fill(false));

  const handleExpandItem = (index: number) => {
    setExpandedItems(prevState => {
      const newArray = [...prevState];
      newArray[index] = !prevState[index];

      return newArray;
    });
  };

  return (
    <div>
      {items.map((item, index) => (
        <Fragment key={index}>
          <FaqItem
            isExpanded={!!expandedItems[index]}
            onClick={handleExpandItem}
            question={item.question}
            answer={item.answer}
            index={index}
          />
          {index !== items.length - 1 && <HorizontalDivider />}
        </Fragment>
      ))}
    </div>
  );
};

export default FaqItems;
