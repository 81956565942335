import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../../../../components/icons/CalendarIcon';
import LoadingIcon from '../../../../components/icons/LoadingIcon';
import ToastSuccessIcon from '../../../../components/icons/ToastSuccessIcon';
import { colors } from '../../../../styles/variables';
import { historyParkingFilterType } from '../../../../types/history/historyParkingFilters';

const useHistoryParkingTableHeaders = () => {
  const { t } = useTranslation();

  const headers = [
    {
      field: 'parkingName',
      headerName: t('history.parking.tableHeaders.parking'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex flex-col w-full my-4">
            {!!params.row.parkingName && <p className="flex align-middle">{params.row.parkingName}</p>}
            {!!params.row.parkingSpotNumber && (
              <p className="flex text-xs align-middle">{params.row.parkingSpotNumber}</p>
            )}
          </div>
        );
      },
    },
    {
      field: 'licensePlate',
      headerName: t('history.parking.tableHeaders.licensePlate'),
      minWidth: 140,
      flex: 1,
      sortable: true,
    },
    {
      field: 'parkingTime',
      headerName: t('history.parking.tableHeaders.parkingTime'),
      minWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex flex-col w-full">
            {!!params.row.start && (
              <p className="flex align-middle">
                {t('history.start')} {new Date(params.row.start).toLocaleDateString()}{' '}
                {new Date(params.row.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </p>
            )}
            {!!params.row.end && (
              <p className="flex align-middle">
                {t('history.end')} {new Date(params.row.end).toLocaleDateString()}{' '}
                {new Date(params.row.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{' '}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: t('history.parking.tableHeaders.status'),
      minWidth: 130,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-2">
            {!!params.row.status && (
              <>
                {params.row.status === historyParkingFilterType.FUTURE && (
                  <>
                    <CalendarIcon />
                    <p className="text-grey700">{t(`history.status.${params.row.status}`)}</p>
                  </>
                )}
                {params.row.status === historyParkingFilterType.ONGOING && (
                  <>
                    <LoadingIcon />
                    <p className="text-success">{t(`history.status.${params.row.status}`)}</p>
                  </>
                )}
                {params.row.status === historyParkingFilterType.FINISHED && (
                  <>
                    <ToastSuccessIcon color={colors.error} />
                    <p className="text-error">{t(`history.status.${params.row.status}`)}</p>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: 'paid',
      headerName: t('history.parking.tableHeaders.paid'),
      minWidth: 130,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4">
            {!!params.row.paidTotal && (
              <p className="flex font-bold align-middle">
                {params.row.paidTotal.price} {params.row.paidTotal.currency}
              </p>
            )}
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useHistoryParkingTableHeaders;
