import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface StatusIndicatorProps {
  text: string;
  textColor: string;
  bgColor: string;
  textClassName?: string;
}

const StatusIndicator = ({ text, textColor, bgColor, textClassName }: StatusIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 items-center">
      <span className={`w-2 h-2 rounded-full ${bgColor}`}></span>
      <span className={twMerge(`${textColor} ${textClassName ?? ''}`)}>{t(text)}</span>
    </div>
  );
};

export default StatusIndicator;
