import { useTranslation } from 'react-i18next';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import usePartnerPromotions from '../../../hooks/api/partner/usePartnerPromotions';
import PromoCodes from './components/PromoCodes';

const PromoCodesView = () => {
  const { t } = useTranslation();
  const { promotions } = usePartnerPromotions();

  return (
    <TitleWrapper title={t('partnerPromoCodes.title')}>
      {!!promotions && promotions?.length !== 0 && <PromoCodes promotions={promotions} />}
    </TitleWrapper>
  );
};

export default PromoCodesView;
