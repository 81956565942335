import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useSingleParking from '../../../hooks/api/parkings/useSingleParking';
import { routes } from '../../../static/routes';

interface ParkingBasicWrapperProps {
  children: React.ReactNode;
}

const ParkingBasicWrapper = ({ children }: ParkingBasicWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();

  const parkingId = params.parkingId as string;

  const { parking } = useSingleParking({ parkingId });

  const extraBreadcrumbs = [
    {
      text: parking?.name ?? '',
    },
  ];

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('parkingDetails.generalInfo.title'),
      routeTo: routes.parkingBasic.generalInfo(parkingId),
    },
    {
      text: t('parkingDetails.parkingRules.title'),
      routeTo: routes.parkingBasic.parkingRules(parkingId),
    },
    {
      text: t('parkingDetails.parkingSpots.title'),
      routeTo: routes.parkingBasic.parkingSpots(parkingId),
    },
    {
      text: t('parkingDetails.activities.title'),
      routeTo: routes.parkingBasic.activities(parkingId),
    },
    {
      text: t('parkingDetails.devices.title'),
      routeTo: routes.parkingBasic.devices(parkingId),
    },
  ];

  return (
    <TitleWrapper
      title={`${t('parkingDetails.title')}${!!parking?.name ? ` (${parking.name})` : ''}`}
      breadcrumbsProps={{ limit: 2, extraBreadcrumbs }}
    >
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />

      <div className="px-0 mt-6 bg-white900 lg:overflow-y-scroll lg:no-scrollbar mb-40 lg:mb-0">{children}</div>
    </TitleWrapper>
  );
};

export default ParkingBasicWrapper;
