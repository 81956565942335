import MyDataTable from '../../../../components/dataTable/MyDataTable';
import { IParkingZoneDetailsByParking } from '../../../../types/api/ApiTypes';
import ParkingAdvancedWrapper from '../ParkingAdvancedWrapper';
import useParkingZonesTable from './hooks/useParkingZonesTable';

interface ParkingZonesViewProps {
  zones: IParkingZoneDetailsByParking[] | undefined;
  parkingId: string;
}

const ParkingZonesView = ({ zones, parkingId }: ParkingZonesViewProps) => {
  const { headers } = useParkingZonesTable({ parkingId });

  return (
    <ParkingAdvancedWrapper>
      <MyDataTable headers={headers} data={zones ?? []} />
    </ParkingAdvancedWrapper>
  );
};

export default ParkingZonesView;
