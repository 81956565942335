import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';

interface UseTarrifsTableHeadersProps {
  onOpenClick: (id: string) => void;
}

const useParkingServiceDevicesTable = ({ onOpenClick }: UseTarrifsTableHeadersProps) => {
  const { t } = useTranslation();

  const headers = [
    {
      field: 'name',
      headerName: t('partnerParkingService.devices.tableHeaders.name'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('partnerParkingService.devices.tableHeaders.type'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <p>{t(`gateTypes.${params.row.gateType}`)}</p>
          </div>
        );
      },
    },
    {
      field: 'description',
      headerName: t('partnerParkingService.devices.tableHeaders.description'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: '',
      headerName: '',
      minWidth: 100,
      maxWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex justify-end py-2.5">
            <PrimaryButton onClick={() => onOpenClick(params.row.id)} className="w-fit">
              {t('partnerParkingService.devices.tableHeaders.openBtn')}
            </PrimaryButton>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useParkingServiceDevicesTable;
