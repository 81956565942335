import { ApiParkingSpot, IParkingSpot } from '../../../types/api/ApiTypes';

export const transformParkingSpot = (apiParkingSpot: ApiParkingSpot): IParkingSpot => {
  const transformedParkingSpot: IParkingSpot = {
    id: apiParkingSpot.id,
    parking: apiParkingSpot.parking,
    parkingZone: apiParkingSpot.parking_zone,
    parkingName: apiParkingSpot.parking_name,
    parkingSpotNumber: apiParkingSpot.parking_spot_number,
    description: apiParkingSpot.description,
    owner: {
      phoneNumber: apiParkingSpot?.owner?.phone_number,
      email: apiParkingSpot?.owner?.email ?? '',
    },
    coUsers: apiParkingSpot.co_users,
    mainImage: apiParkingSpot.main_image,
    createdAt: apiParkingSpot.created_at,
    updatedAt: apiParkingSpot.updated_at,
    firstPricingPeriod: apiParkingSpot.first_pricing_period,
    secondPricingPeriod: apiParkingSpot.second_pricing_period,
    thirdPricingPeriod: apiParkingSpot.third_pricing_period,
    pricePerFirstPeriod: apiParkingSpot.price_per_first_period,
    pricePerSecondPeriod: apiParkingSpot.price_per_second_period,
    pricePerThirdPeriod: apiParkingSpot.price_per_third_period,
    currency: apiParkingSpot.currency,
    pdfFiles: apiParkingSpot.pdf_files?.map(file => ({
      id: file.id,
      file: file.file,
      fileName: file.file_name,
    })),
  };

  return transformedParkingSpot;
};
