import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiEvPriceListDetails, IEvPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformEvPriceListDetails } from '../../../utils/dataTransformers/transformEvPriceListDetails/transformEvPriceListDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useEvPriceLists = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([QueryAliases.EV_PRICE_LISTS], () => axiosInstance.get(api.endpoints.EV_PRICE_LISTS), {
    refetchOnMount: true,
    cacheTime: 1,
  });

  const apiPriceLists: ApiEvPriceListDetails[] | undefined = data?.data;

  const priceLists: IEvPriceListDetails[] | undefined = !!apiPriceLists
    ? apiPriceLists.map(priceList => transformEvPriceListDetails(priceList))
    : undefined;

  return { priceLists };
};

export default useEvPriceLists;
