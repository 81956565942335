import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';
import TrashIcon from '../../icons/TrashIcon';
import IconButton from './IconButton';

interface DeleteIconButtonProps {
  onClick: CallbackDefault;
}

const DeleteIconButton = ({ onClick }: DeleteIconButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<TrashIcon color={colors.error} />}
      className={`border border-grey400 bg-grey10`}
    />
  );
};

export default DeleteIconButton;
