import { Link } from 'react-router-dom';
import ArrowNarrow from '../../../../components/icons/ArrowNarrowIcon';

interface TabInfoCardProps {
  title: string;
  description: string;
  routeTo: string;
}

const TabInfoCard = ({ title, description, routeTo }: TabInfoCardProps) => {
  return (
    <div className="p-6 rounded-md border border-grey300 bg-grey100">
      <div className="flex gap-2 items-center justify-between">
        <h3 className="text-black900 text-base font-bold leading-tigh uppercase">{title}</h3>
        <Link to={routeTo}>
          <div className="p-2 border border-grey700 rounded-full -rotate-45">
            <ArrowNarrow />
          </div>
        </Link>
      </div>
      <p className="mt-4">{description}</p>
    </div>
  );
};

export default TabInfoCard;
