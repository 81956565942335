import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiCharger, ICharger } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformCharger } from '../../../utils/dataTransformers/transformCharger/transformCharger';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useChargerDetailsProps {
  id: string;
}

const useSingleCharger = ({ id }: useChargerDetailsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery(
    [`${QueryAliases.CHARGER_DETAILS}-${id}`],
    () => axiosInstance.get(api.endpoints.SINGLE_CHARGER.DATA(id)),
    {
      cacheTime: 1,
    },
  );

  const apiCharger: ApiCharger | undefined = data?.data;

  const charger: ICharger | undefined = !!apiCharger ? transformCharger(apiCharger) : undefined;

  return { charger, refetchCharger: refetch };
};

export default useSingleCharger;
