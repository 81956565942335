import { colors } from '../../styles/variables';

interface CheckListIconProps {
  color?: string;
  height?: number;
  width?: number;
}

const CheckListIcon = ({ color, height, width }: CheckListIconProps) => {
  return (
    <svg
      width={width ?? '16'}
      height={height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71484 4.43359H14.0006"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.42857 2.66797H2V6.19757H5.42857V2.66797Z"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71484 11.4922H14.0006"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.4898L3.14286 12.6663L6.57143 9.13672"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckListIcon;
