import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useCreateChargingRule from '../../../hooks/api/chargers/useCreateChargingRule';
import useSingleCharger from '../../../hooks/api/chargers/useSingleCharger';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import { ChargingRuleForm, IChargingRuleFormInputs } from './components/ChargingRuleForm';

const AddChargingRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();
  const { id } = useParams();

  const { charger } = useSingleCharger({ id: id as string });

  const { createChargingRule, isLoading } = useCreateChargingRule(id ?? '');

  const handleAddChargingRule = async (props: IChargingRuleFormInputs, isApplyToAllChecked: boolean) => {
    await createChargingRule({
      name: props.name,
      connector_ids: props.connectors.map(item => item.id),
      group_ids: isApplyToAllChecked ? [] : props.groups.map(item => item.id),
      price_list_id: props.priceList.id,
    });

    navigate(routes.singleChargerRulesTable(id ?? ''));
  };

  const modifiedBreadcrumbs =
    !!charger?.id && !!charger?.name ? [{ path: charger?.id as string, text: charger?.name as string }] : [];

  return (
    <TitleWrapper title={charger?.name ?? ''} breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}>
      {!isTablet && <BackButton className="mt-8 mb-16 " />}

      <ChargingRuleForm
        onSubmitForm={handleAddChargingRule}
        submitBtnText={t('priceListForm.submitBtn')}
        submitBtnIcon={<PlusIcon height={16} />}
        isSubmitBtnDisabled={isLoading}
        charger={charger}
      />
    </TitleWrapper>
  );
};

export default AddChargingRule;
