import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ParkingIcon from '../../../../../components/icons/ParkingIcon';
import SettingsIcon from '../../../../../components/icons/SettingsIcon';
import SmartphoneIcon from '../../../../../components/icons/SmartphoneIcon';
import { colors } from '../../../../../styles/variables';
import { FaqPage } from './Faq';
import FaqButton from './FaqButton';

interface FaqButtonsProps {
  activePage: FaqPage;
  setActivePage: Dispatch<SetStateAction<FaqPage>>;
  className?: string;
}

const FaqButtons = ({ activePage, setActivePage, className }: FaqButtonsProps) => {
  const { t } = useTranslation();

  const activeColor = colors.white900;
  const inactiveColor = colors.black500;

  return (
    <div className={className}>
      <FaqButton
        isActive={activePage === FaqPage.App}
        onClick={() => setActivePage(FaqPage.App)}
        icons={{ default: <SmartphoneIcon color={inactiveColor} />, active: <SmartphoneIcon color={activeColor} /> }}
      >
        {t('userAccount.help.faq.application.title')}
      </FaqButton>

      <FaqButton
        isActive={activePage === FaqPage.GateModule}
        onClick={() => setActivePage(FaqPage.GateModule)}
        icons={{ default: <SettingsIcon color={inactiveColor} />, active: <SettingsIcon color={activeColor} /> }}
      >
        {t('userAccount.help.faq.gateModule.title')}
      </FaqButton>

      <FaqButton
        isActive={activePage === FaqPage.Parking}
        onClick={() => setActivePage(FaqPage.Parking)}
        icons={{ default: <ParkingIcon color={inactiveColor} />, active: <ParkingIcon color={activeColor} /> }}
      >
        {t('userAccount.help.faq.parking.title')}
      </FaqButton>
    </div>
  );
};

export default FaqButtons;
