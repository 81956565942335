import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { eventStatusesStyles } from '../../../../../static/events/eventStatusesStyles';
import { partnerParkingActivityIcons } from '../../../../../static/partnerParkingActivities/partnerParkingActivityIcons';
import { PartnerParkingActivityWithoutAll } from '../../../../../types/partnerParkingActivities/partnerParkingActivities';
import { getEventStatus } from '../../../../../utils/getEventStatus/getEventStatus';

const useParkingServiceActivitiesTable = () => {
  const { t } = useTranslation();

  const headers = [
    {
      field: 'licensePlate',
      headerName: t('partnerParkingService.activities.tableHeaders.licensePlate'),
      minWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="py-4">
            <p>{`${params.row.licensePlate}`}</p>
          </div>
        );
      },
    },
    {
      field: 'parkingSpot',
      headerName: t('partnerParkingService.activities.tableHeaders.parkingSpot'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="py-4">
            <p>{params.row.parkingSpot}</p>
          </div>
        );
      },
    },
    {
      field: 'start',
      headerName: t('partnerParkingService.activities.tableHeaders.start'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const startDate = !!params.row.start ? new Date(params.row.start) : null;
        const displayedDate = !!startDate && dayjs(startDate).format('DD.MM.YYYY HH:mm');

        return (
          <div className="py-4">
            <p>{displayedDate}</p>
          </div>
        );
      },
    },
    {
      field: 'end',
      headerName: t('partnerParkingService.activities.tableHeaders.end'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const endDate = !!params?.row?.end ? new Date(params.row.end) : null;
        const displayedDate = !!endDate && dayjs(endDate).format('DD.MM.YYYY HH:mm');

        return (
          <div className="py-4">
            <p>{displayedDate}</p>
          </div>
        );
      },
    },
    {
      field: 'entitlementReason',
      headerName: t('partnerParkingService.activities.tableHeaders.entitlementReason'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const type = params.row.entitlementReason as PartnerParkingActivityWithoutAll;

        return (
          <div className="py-4 flex items-center gap-1.5">
            {partnerParkingActivityIcons[type]}
            <p>{t(`partnerParkingService.activities.transactionTypes.${params.row.entitlementReason}`)}</p>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: t('partnerParkingService.activities.tableHeaders.status'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const startDate = new Date(params.row.start);
        const endDate = !!params.row?.end ? new Date(params.row.end) : null;

        const status = getEventStatus({ startDate, endDate });

        return (
          <div className="py-4 flex items-center gap-1.5">
            {eventStatusesStyles[status].icon}
            <p className={`${eventStatusesStyles[status].textColor}`}>{t(`eventStatuses.${status}`)}</p>
          </div>
        );
      },
    },
    {
      field: 'totalPrice.price',
      valueGetter: (params: GridValueGetterParams) => params.row.totalPrice?.price,
      headerName: t('partnerParkingService.activities.tableHeaders.price'),
      minWidth: 120,
      maxWidth: 160,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="py-4">
            <p className="font-bold">{`${params.row.totalPrice?.price ?? ''} ${
              params.row.totalPrice?.currency ?? ''
            }`}</p>
          </div>
        );
      },
    },
  ];

  return { headers: headers };
};

export default useParkingServiceActivitiesTable;
