import { ApiPriceListPeriod, IPriceListPeriod } from '../../../types/api/ApiTypes';

export const transformPriceListPeriod = (apiPriceListPeriod: ApiPriceListPeriod): IPriceListPeriod => {
  const transformedPriceListPeriod: IPriceListPeriod = {
    id: apiPriceListPeriod.id,
    priceList: apiPriceListPeriod.price_list,
    duration: apiPriceListPeriod.duration,
    timeUnit: apiPriceListPeriod.time_unit,
    price: apiPriceListPeriod.price,
    position: apiPriceListPeriod.position,
    pricePer: apiPriceListPeriod.price_per,
    minuteBasedBilling: apiPriceListPeriod.minute_based_billing,
    pricingBand: apiPriceListPeriod.pricing_band,
  };

  return transformedPriceListPeriod;
};

export const transformPriceListPeriods = (periods: ApiPriceListPeriod[]): IPriceListPeriod[] =>
  periods.map(period => transformPriceListPeriod(period));
