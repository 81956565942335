import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiPromotion, IPromotion } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformPromotions } from '../../../utils/dataTransformers/transformPromotion/transformPromotion';
import useAxiosInstance from '../../axios/useAxiosInstance';

const usePartnerPromotions = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.PARTNER_PROMOTIONS}}`],
    () => axiosInstance.get(api.endpoints.PARTNER.PROMOTION_PRICE_LISTS),
    {
      refetchOnMount: true,
    },
  );

  const apiPromotions: ApiPromotion[] | undefined = data?.data;

  const promotions: IPromotion[] | undefined = !!apiPromotions ? transformPromotions(apiPromotions) : undefined;

  return { promotions };
};

export default usePartnerPromotions;
