import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import { routes } from '../../../static/routes';

interface HistoryWrapperProps {
  children: React.ReactNode;
}

const HistoryWrapper = ({ children }: HistoryWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('history.parking.title'),
      routeTo: routes.history.parking,
    },
    {
      text: t('history.ev.title'),
      routeTo: routes.history.ev,
    },
  ];

  return (
    <TitleWrapper title={t('history.title')} breadcrumbsProps={{ limit: 2 }}>
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />

      <div className="px-0 mt-6 mb-40 bg-white900 lg:overflow-y-scroll lg:no-scrollbar lg:mb-0">{children}</div>
    </TitleWrapper>
  );
};

export default HistoryWrapper;
