import { PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline';
import IconButton from '../../../../../components/common/buttons/IconButton';
import { colors } from '../../../../../styles/variables';

interface FaqItemProps {
  isExpanded: boolean;
  onClick: (index: number) => void;
  question: string;
  answer: string;
  index: number;
}

const FaqItem = ({ isExpanded, onClick, question, answer, index }: FaqItemProps) => {
  return (
    <div>
      <div className="flex justify-between items-start gap-8 py-4 px-2 cursor-pointer" onClick={() => onClick(index)}>
        <p className="text-base text-black700 font-medium">{question}</p>
        <IconButton
          icon={
            isExpanded ? (
              <XMarkIcon color={colors.white900} height={18} width={18} />
            ) : (
              <PlusSmallIcon height={18} width={18} color={colors.white900} />
            )
          }
          className="bg-blue500 bg-opacity-100 rounded-full h-6 w-6 min-w-[1.5rem]"
        />
      </div>
      <p
        className={`whitespace-pre-wrap text-black500 ${
          isExpanded ? 'max-h-[500px] opacity-1 pb-4 visible' : 'max-h-0 opacity-0 invisible'
        } transition-all duration-200 ease-out-expo`}
      >
        {answer}
      </p>
    </div>
  );
};

export default FaqItem;
