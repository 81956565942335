import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEditParking from '../../../../../hooks/api/parkings/useEditParking';
import useDeletePdf from '../../../../../hooks/api/useDeletePdf';
import { routes } from '../../../../../static/routes';
import { IParkingDetails, IPdfDetails } from '../../../../../types/api/ApiTypes';

interface useGeneralInfoFormProps {
  parkingId: string;
  parking: IParkingDetails;
  numOfGalleryImages: number;
}

const useGeneralInfoForm = ({ parkingId, parking, numOfGalleryImages }: useGeneralInfoFormProps) => {
  const navigate = useNavigate();

  const {
    updateParking,
    uploadParkingMainImage,
    deleteParkingMainImage,
    uploadParkingGalleryImage,
    uploadRegulation,
    deleteParkingGalleryImage,
    isLoading,
  } = useEditParking();
  const { deletePdf } = useDeletePdf();

  const [oldRegulation, setOldRegulation] = useState<IPdfDetails | null>(
    !!parking && parking.pdfFiles.length !== 0 ? parking.pdfFiles[0]! : null,
  );
  const [oldMainImageUrl, setOldMainImageUrl] = useState<string | null>(
    !!parking?.mainImage ? parking?.mainImage : null,
  );
  const [oldGalleryUrls, setOldGalleryUrls] = useState(parking.gallery.slice(0, numOfGalleryImages));

  const [selectedRegulation, setSelectedRegulation] = useState<File | null>(null);
  const [selectedNewMainImg, setSelectedNewMainImg] = useState<File | null>(null);
  const [newDescription, setNewDescription] = useState(parking?.description);
  const [selectedGalleryFiles, setSelectedGalleryFiles] = useState<File[]>([]);

  const handleMainImage = async () => {
    if (!!selectedNewMainImg) {
      await uploadParkingMainImage({ parkingId, file: selectedNewMainImg });
    } else if (!oldMainImageUrl && !!parking.mainImage) {
      await deleteParkingMainImage({ parkingId });
    }
  };

  const handleGalleryImages = async () => {
    selectedGalleryFiles.forEach(async file => {
      await uploadParkingGalleryImage({ parkingId, file });
    });

    const deletedImages = parking.gallery.filter(image => !oldGalleryUrls.some(item => item.id === image.id));

    deletedImages.forEach(async image => {
      await deleteParkingGalleryImage({ parkingId, galleryEntryId: image.id });
    });
  };

  const handleRegulations = async () => {
    if (parking.pdfFiles.length !== 0 && !oldRegulation) {
      await deletePdf({ pdfEntryId: parking.pdfFiles[0]!.id });
    }

    if (!!selectedRegulation) {
      await uploadRegulation({ parkingId, file: selectedRegulation });
    }
  };

  const onSubmitForm = async () => {
    if (!!newDescription && newDescription !== parking.description) {
      await updateParking({
        parkingId,
        body: {
          description: newDescription,
        },
      });
    }

    await handleMainImage();
    await handleGalleryImages();
    await handleRegulations();

    navigate(routes.parkings);
  };

  return {
    oldRegulation,
    setOldRegulation,
    selectedRegulation,
    setSelectedRegulation,
    oldMainImageUrl,
    setOldMainImageUrl,
    selectedNewMainImg,
    setSelectedNewMainImg,
    oldGalleryUrls,
    setOldGalleryUrls,
    selectedGalleryFiles,
    setSelectedGalleryFiles,
    newDescription,
    setNewDescription,
    onSubmitForm,
    isLoading,
  };
};

export default useGeneralInfoForm;
