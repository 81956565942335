import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingPriceListDetails, IParkingPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingPriceListDetails } from '../../../utils/dataTransformers/transformParkingPriceListDetails/transformParkingPriceListDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useParkingPriceLists = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [QueryAliases.PARKING_PRICE_LISTS],
    () => axiosInstance.get(api.endpoints.PARKING_PRICE_LISTS),
    {
      refetchOnMount: true,
      cacheTime: 1,
    },
  );

  const apiPriceLists: ApiParkingPriceListDetails[] | undefined = data?.data;

  const priceLists: IParkingPriceListDetails[] | undefined = !!apiPriceLists
    ? apiPriceLists.map(priceList => transformParkingPriceListDetails(priceList))
    : undefined;

  return { priceLists };
};

export default useParkingPriceLists;
