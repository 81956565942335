import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteRuleFromSpot = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ spotId, ruleId }: { spotId: string; ruleId: string }) =>
      axiosInstance.delete(api.endpoints.REMOVE_RULE_FROM_SPOT({ spotId, ruleId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.deleteRuleFromSpotFailed`));
      },
    },
  );
  return { deleteRule: mutateAsync, isLoading };
};

export default useDeleteRuleFromSpot;
