import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiUserResponse, IUser } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformUser } from '../../../utils/dataTransformers/transformUser/transformUser';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useUserProps {
  enabled?: boolean;
}

const useUser = ({ enabled = true }: useUserProps = {}) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery([QueryAliases.USER_DETAILS], () => axiosInstance.get(api.endpoints.USER_DETAILS), {
    enabled,
    retry: 1,
  });

  const apiUser: ApiUserResponse | undefined = data?.data;

  const user: IUser | undefined = !!apiUser ? transformUser(data?.data) : undefined;

  return { user, refetch };
};

export default useUser;
