import { LabeledComponent, LabeledComponentProps } from '../inputs/LabeledComponent';
import { ComponentState } from '../../../types/ComponentStates.types';
import Datetimepicker, { DatetimepickerProps } from './DatetimePicker';

interface LabeledProps extends Omit<LabeledComponentProps, 'children'> {
  errorMessage?: string | undefined;
}

interface LabeledStateDatetimepickerProps {
  id: string;
  state: ComponentState;

  labeledProps: LabeledProps;
  datetimepickerProps: DatetimepickerProps;
}

export const LabeledStateDatetimepicker = ({
  id,
  labeledProps,
  datetimepickerProps,
  state,
}: LabeledStateDatetimepickerProps) => {
  const { errorMessage, stateProperties: labeledStateProperties } = labeledProps;

  const myStateProperties = {
    error: {
      message: errorMessage,
      color: 'text-error',
    },
    default: {},
    ...labeledStateProperties,
  };

  const labeledComponentProps = {
    id,
    state,
    stateProperties: myStateProperties,
    ...labeledProps,
  };

  return (
    <LabeledComponent {...labeledComponentProps}>
      <Datetimepicker {...datetimepickerProps} />
    </LabeledComponent>
  );
};
