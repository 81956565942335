import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiSubscriptionsPriceListDetails, ISubscriptionsPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformSubscriptionsPriceListDetails } from '../../../utils/dataTransformers/transformSubscriptionsPriceListsDetails/transformSubscriptionsPriceListsDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useSubscriptionsPriceLists = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [QueryAliases.SUBSCRIPTION_PRICE_LISTS],
    () => axiosInstance.get(api.endpoints.SUBSCRIPTION_PRICE_LISTS),
    {
      refetchOnMount: true,
      cacheTime: 1,
    },
  );

  const apiPriceLists: ApiSubscriptionsPriceListDetails[] | undefined = data?.data;

  const priceLists: ISubscriptionsPriceListDetails[] | undefined = !!apiPriceLists
    ? apiPriceLists.map(priceList => transformSubscriptionsPriceListDetails(priceList))
    : undefined;

  return { priceLists };
};

export default useSubscriptionsPriceLists;
