import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BottomActionBox } from '../../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import { routes } from '../../../../static/routes';
import { IParkingSpotDetailsByParking } from '../../../../types/api/ApiTypes';
import ParkingBasicWrapper from '../ParkingBasicWrapper';
import useParkingSpotsTable from './hooks/useParkingSpotsTable';

interface ParkingSpotsViewProps {
  spots: IParkingSpotDetailsByParking[] | undefined;
  parkingId: string;
}

const ParkingSpotsView = ({ spots, parkingId }: ParkingSpotsViewProps) => {
  const { isTablet } = useIsTablet();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { headers } = useParkingSpotsTable();

  const newSpotBtnText = t('parkingDetails.parkingSpots.addSpotBtn');
  const newSpotBtnIcon = <PlusIcon height={16} />;

  const newSpotBtnOnClick = () => {
    navigate(routes.addParkingSpot(parkingId));
  };

  return (
    <ParkingBasicWrapper>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <PrimaryButton onClick={newSpotBtnOnClick} className="w-fit self-end" prefixIcon={newSpotBtnIcon}>
            {newSpotBtnText}
          </PrimaryButton>
        </div>
      )}

      <MyDataTable headers={headers} data={spots ?? []} />

      {isTablet && (
        <BottomActionBox>
          <PrimaryButton onClick={newSpotBtnOnClick} className="w-full" prefixIcon={newSpotBtnIcon}>
            {newSpotBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </ParkingBasicWrapper>
  );
};

export default ParkingSpotsView;
