import { useState } from 'react';
import useAddParkingSpotCoUser from '../../../../hooks/api/parkings/useAddSpotCoUser';
import useAssingParkingSpotRule from '../../../../hooks/api/parkings/useAssingParkingSpotRule';
import useDeleteRuleFromSpot from '../../../../hooks/api/parkings/useDeleteRuleFromSpot';
import useDeleteSpotCoUser from '../../../../hooks/api/parkings/useDeleteSpotCoUser';
import useEditParkingSpot from '../../../../hooks/api/parkings/useEditParkingSpot';
import useDeletePdf from '../../../../hooks/api/useDeletePdf';
import {
  ApiCoUser,
  ICoUser,
  IParkingRulesItem,
  IParkingSpot,
  IParkingSpotRule,
  IPdfDetails,
} from '../../../../types/api/ApiTypes';
import { transformBasicUser } from '../../../../utils/dataTransformers/transformBasicUser/transformBasicUser';

interface useEditSpotFormProps {
  parkingSpot: IParkingSpot;
  coUsers: ICoUser[];
  rules: IParkingSpotRule[] | IParkingRulesItem[] | undefined;
}

const useEditSpotForm = ({ parkingSpot, coUsers, rules }: useEditSpotFormProps) => {
  const {
    uploadSpotMainImage,
    uploadRegulation,
    deleteSpotMainImage,
    updateParkingSpot,
    isLoading: isLoadingBasicSpotInfo,
  } = useEditParkingSpot();
  const { addCoUser, isLoading: isLoadingAddCoUser } = useAddParkingSpotCoUser();
  const { deleteCoUser, isLoading: isLoadingDeleteCoUser } = useDeleteSpotCoUser();

  const { assignRule, isLoading: isLoadingAssingRule } = useAssingParkingSpotRule();
  const { deleteRule, isLoading: isLoadingDeleteRule } = useDeleteRuleFromSpot();

  const { deletePdf } = useDeletePdf();

  const isLoading =
    isLoadingBasicSpotInfo || isLoadingAddCoUser || isLoadingDeleteCoUser || isLoadingAssingRule || isLoadingDeleteRule;

  const newName = parkingSpot.parkingSpotNumber;
  const [newDescription, setNewDescription] = useState(parkingSpot.description);
  const [selectedRegulation, setSelectedRegulation] = useState<File | null>(null);
  const [oldImageUrl, setOldImageUrl] = useState<string | null>(parkingSpot.mainImage);
  const [oldRegulation, setOldRegulation] = useState<IPdfDetails | null>(
    parkingSpot.pdfFiles.length !== 0 ? parkingSpot.pdfFiles[0]! : null,
  );
  const [selectedNewImg, setSelectedNewImg] = useState<File | null>(null);
  const [internalCoUsers, setInternalCoUsers] = useState<ICoUser[]>(coUsers);
  const [internalRules, setInternalRules] = useState<(IParkingSpotRule | IParkingRulesItem)[]>(rules || []);

  const handleRegulations = async () => {
    if (parkingSpot.pdfFiles.length !== 0 && !oldRegulation) {
      await deletePdf({ pdfEntryId: parkingSpot.pdfFiles[0]!.id });
    }

    if (!!selectedRegulation) {
      await uploadRegulation({ spotId: parkingSpot.id, file: selectedRegulation });
    }
  };

  const handleImages = async () => {
    if (!!selectedNewImg) {
      await uploadSpotMainImage({ spotId: parkingSpot.id, file: selectedNewImg });
    } else if (!oldImageUrl) {
      await deleteSpotMainImage({ spotId: parkingSpot.id });
    }
  };

  const handleDescription = async () => {
    if (newDescription !== parkingSpot.description) {
      await updateParkingSpot({
        spotId: parkingSpot.id,
        body: {
          description: newDescription,
        },
      });
    }
  };

  const handleName = async (name: string) => {
    if (name !== parkingSpot.parkingSpotNumber) {
      await updateParkingSpot({
        spotId: parkingSpot.id,
        body: {
          parking_spot_number: name,
        },
      });
    }
  };

  const handleDeleteCoUser = async ({
    phone,
    groupId,
    isGroup,
  }: {
    phone?: string;
    groupId?: number;
    isGroup: boolean;
  }) => {
    const result = await deleteCoUser({ spotId: parkingSpot.id, phone: phone, groupId: groupId, isGroup: isGroup });
    const apiSpotCoUsers: ApiCoUser[] | undefined = result?.data;
    const spotCoUsers: ICoUser[] = !!apiSpotCoUsers ? apiSpotCoUsers.map(couser => transformBasicUser(couser)) : [];
    setInternalCoUsers(spotCoUsers);
  };

  const handleAddCoUser = async (user: ICoUser) => {
    const newCoUser: ICoUser = {
      phoneNumber: user.phoneNumber,
      groupId: user.groupId,
      permissions: user.permissions,
    };
    const result = await addCoUser({ spotId: parkingSpot.id, user: newCoUser });
    const apiSpotCoUsers: ApiCoUser[] | undefined = result?.data;
    const spotCoUsers: ICoUser[] = !!apiSpotCoUsers ? apiSpotCoUsers.map(couser => transformBasicUser(couser)) : [];
    setInternalCoUsers(spotCoUsers);
  };

  const handleDeleteRule = async ({ id }: { id: string }) => {
    await deleteRule({ spotId: parkingSpot.id, ruleId: id });
    setInternalRules(prevRules => {
      return prevRules?.filter(rule => rule?.id !== id);
    });
  };

  const handleAddRule = async (newRule: IParkingRulesItem) => {
    await assignRule({ ruleId: newRule.id, spotId: parkingSpot.id });
    setInternalRules(prevRules => [...prevRules, newRule]);
  };

  return {
    newName,
    selectedRegulation,
    setSelectedRegulation,
    oldImageUrl,
    setOldImageUrl,
    oldRegulation,
    setOldRegulation,
    selectedNewImg,
    setSelectedNewImg,
    newDescription,
    setNewDescription,
    internalCoUsers,
    isLoading,
    handleDeleteCoUser,
    handleAddCoUser,
    handleDescription,
    handleImages,
    handleRegulations,
    handleName,
    handleAddRule,
    handleDeleteRule,
    internalRules,
  };
};

export default useEditSpotForm;
