import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import {
  ApiSingleZoneParkingSubscriptionsItem,
  ISingleZoneParkingSubscriptionsItem,
} from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformSingleZoneParkingSubscriptionsItem } from '../../../../utils/dataTransformers/transformSingleZoneParkingSubscriptionsItem/transformSingleZoneParkingSubscriptionsItem';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useSingleZoneParkingSubscriptionsProps {
  zoneId: string;
}

const useSingleZoneParkingSubscriptions = ({ zoneId }: useSingleZoneParkingSubscriptionsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery([`${QueryAliases.SINGLE_PARKING_ZONE_SUBSCRIPTIONS}-${zoneId}`], () =>
    axiosInstance.get(api.endpoints.SINGLE_PARKING_ZONE_SUBSCRIPTIONS({ id: zoneId })),
  );

  const apiSubscriptions: ApiSingleZoneParkingSubscriptionsItem[] | undefined = data?.data;

  const subscriptions: ISingleZoneParkingSubscriptionsItem[] | undefined = !!apiSubscriptions
    ? apiSubscriptions.map(subscription => transformSingleZoneParkingSubscriptionsItem(subscription))
    : undefined;

  return {
    subscriptions,
    refetch,
  };
};

export default useSingleZoneParkingSubscriptions;
