import { ApiSubscriptionsPriceListDetails, ISubscriptionsPriceListDetails } from '../../../types/api/ApiTypes';
import { transformSimpleParking } from '../transformSimpleParking/transformSimpleParking';

export const transformSubscriptionsPriceListDetails = (
  apiPriceListDetails: ApiSubscriptionsPriceListDetails,
): ISubscriptionsPriceListDetails => {
  const transformedSubscriptionsPriceListDetails: ISubscriptionsPriceListDetails = {
    id: apiPriceListDetails.id,
    name: apiPriceListDetails.name,
    parkings: apiPriceListDetails.parkings.map(parking => transformSimpleParking(parking)),
    parkingZones: apiPriceListDetails.parking_zones,
    description: apiPriceListDetails.description,

    duration: apiPriceListDetails.duration,
    price: apiPriceListDetails.price,
    currency: apiPriceListDetails.currency,
    requiresApproval: apiPriceListDetails.requires_approval,
    approvalEmail: apiPriceListDetails.approval_email,

    hasTimeRestrictions: apiPriceListDetails.has_time_restrictions,
    timeRestrictions: apiPriceListDetails.time_restrictions,
  };

  return transformedSubscriptionsPriceListDetails;
};
