import { useTranslation } from 'react-i18next';
import TabSwitcher, { TabSwitcherRoute } from '../../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../../components/wrappers/titleWrapper/TitleWrapper';
import { routes } from '../../../../static/routes';

interface ParkingServiceWrapperProps {
  children: React.ReactNode;
}

const ParkingServiceWrapper = ({ children }: ParkingServiceWrapperProps) => {
  const { t } = useTranslation();

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('partnerParkingService.activities.title'),
      routeTo: routes.partner.parkingServiceActivities,
    },
    {
      text: t('partnerParkingService.devices.title'),
      routeTo: routes.partner.parkingServiceDevices,
    },
  ];

  return (
    <TitleWrapper title={t('partnerParkingService.title')} hideBreadcrumbs>
      <div className="lg:mt-16">
        <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />
        <div className="lg:px-8 px-0 py-0 lg:py-8 lg:overflow-y-scroll lg:no-scrollbar">{children}</div>
      </div>
    </TitleWrapper>
  );
};

export default ParkingServiceWrapper;
