import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { editParkingSpotDescriptionSchema } from '../../../../../static/validationSchema/parkings/editParkingSpotDescriptionSchema';
import { ComponentState, ComponentStates } from '../../../../../types/ComponentStates.types';
import { CallbackDefault } from '../../../../../types/Types';
import { LabeledStateTextarea } from '../../../inputs/LabeledStateTextarea';

interface DescriptionFormInputs {
  description: string;
}

type InputsKeys = keyof DescriptionFormInputs;

interface DescriptionFormProps {
  initialDescription: string | undefined;
  onSubmitForm: (newDescription: string) => void;
  onClose: CallbackDefault;
  label: string;
}

const DescriptionForm = ({ initialDescription, onSubmitForm, onClose, label }: DescriptionFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DescriptionFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(editParkingSpotDescriptionSchema),
    defaultValues: {
      description: initialDescription,
    },
  });

  const onSubmit: SubmitHandler<DescriptionFormInputs> = async data => {
    onSubmitForm(data.description);
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LabeledStateTextarea
        id="description"
        state={getInputState('description')}
        labeledProps={{
          wrapperClassName: 'mb-2 mt-6 max-w-xl',
          label,
          errorMessage: t(`errorMessages.${errors.description?.message}`),
        }}
        textareaProps={{
          className: 'resize',
          register: {
            ...register('description'),
          },
        }}
      />

      <div className="float-right">
        <button type="button" onClick={onClose} className="pr-4 text-blue-500 font-medium text-sm">
          {t('common.cancel')}
        </button>
        <button type="submit" className="text-blue-500 font-medium text-sm">
          {t('common.save')}
        </button>
      </div>
    </form>
  );
};

export default DescriptionForm;
