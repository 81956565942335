import * as yup from 'yup';
import * as Schema from './validationFields';

export const userValidationSchema = yup.object({
  firstName: Schema.requiredString,
  lastName: Schema.requiredString,
  address: yup.string(),
  postCode: yup.string(),
  city: yup.string(),
  country: Schema.dropdownOption,
});
