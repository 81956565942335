import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useVerifyPasswordReset = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ phone, code }: { phone: string; code: string }) =>
      axiosInstance.post(api.endpoints.VERIFY_PASSWORD_RESET, { phone_number: phone, code }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.errorGeneral'));
      },
    },
  );

  return { verifyPasswordReset: mutateAsync, isLoading };
};

export default useVerifyPasswordReset;
