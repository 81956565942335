import { parkingsFilters } from '../../static/parkings/parkingFilters';
import { ApiParking } from '../../types/api/ApiTypes';
import { parkingFilterType } from '../../types/parkings/parkingsFilters';

export const parkingsFiltersMaper = (data: ApiParking[] | undefined) => {
  if (!data) return parkingsFilters;

  const dataOptions = data.map((parking: ApiParking) => parking.access_type);

  const uniqeOptions = dataOptions.filter(
    (value: string, index: number, array: string[]) => array.indexOf(value) === index,
  );

  const options = parkingsFilters.filter(option => {
    if (option.value === parkingFilterType.ALL) {
      return option;
    } else {
      return uniqeOptions.find((item: string) => item === option.value);
    }
  });

  return options;
};
