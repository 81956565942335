import { PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../components/common/buttons/BackButton';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { LabeledStateMultiDropdown } from '../../../../components/common/dropdowns/LabeledStateMultiDropdown';
import FormSectionTitle from '../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import TooltipIcon from '../../../../components/icons/TooltipIcon';
import useParkingDevices from '../../../../hooks/api/parkings/useParkingsDevices';
import useValidateUsersPhone from '../../../../hooks/api/useValidateUsersPhone';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import { addParkingSpotSchema } from '../../../../static/validationSchema/parkings/addParkingSpotSchema';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { ApiCreateParkingSpotRequest } from '../../../../types/api/ApiTypes';

interface IFormInputs {
  name: string;
  phone: string;
  gates: DropdownOption[];
}

type InputsKeys = keyof IFormInputs;

interface AddParkingSpotFormProps {
  parkingId: string;
  onSubmitForm: (data: ApiCreateParkingSpotRequest) => Promise<void>;
  isSubmitBtnLoading: boolean;
  submitBtnText: string;
}

const AddParkingSpotForm = ({
  parkingId,
  onSubmitForm,
  isSubmitBtnLoading,
  submitBtnText,
}: AddParkingSpotFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const { validatePhone } = useValidateUsersPhone();

  const { entryDevicesOptions } = useParkingDevices({ parkingId });

  const [isDifferentUserChecked, setIsDifferentUserChecked] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    control,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addParkingSpotSchema({ isPhoneRequired: isDifferentUserChecked })),
    defaultValues: {
      name: '',
      phone: '',
      gates: [],
    },
  });

  const submitHandler = async (data: IFormInputs) => {
    await onSubmitForm({
      parking_spot_number: data.name,
      entry_devices: data.gates.map(gate => gate.id),
      ...(isDifferentUserChecked && { owner_phone_number: data.phone }),
    });
    reset();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    if (isDifferentUserChecked) {
      try {
        await validatePhone({
          phone: data.phone,
        });

        submitHandler(data);
      } catch (e) {
        const error = e as { message?: string };
        setError('phone', {
          message: error.message,
        });
      }
    } else {
      submitHandler(data);
    }
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const submitBtnIcon = <PlusIcon height={16} />;

  return (
    <div className="flex justify-between gap-16 pb-8 grow">
      <div className="w-full max-w-5xl grow">
        <div className="pb-12 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={1} title={t('addParkingSpot.form.name.title')} />
          <div className="lg:pl-8">
            <LabeledStateInput
              id="name"
              state={getInputState('name')}
              labeledProps={{
                wrapperClassName: 'mb-5 mt-6 max-w-xl',
                label: t('addParkingSpot.form.name.label'),
                errorMessage: t(`errorMessages.${errors.name?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('name'),
                },
              }}
            />

            <div className="flex gap-1 items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDifferentUserChecked}
                    onChange={e => setIsDifferentUserChecked(e.target.checked)}
                  />
                }
                label={t('addParkingSpot.form.differentUser.checkboxLabel')}
              />
              {/* //TODO: change for "HintPopup" component after merge */}
              <Tooltip title={t('addParkingSpot.form.differentUser.tooltip')} placement="top" arrow>
                <div>
                  <TooltipIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        {isDifferentUserChecked && (
          <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
            <FormSectionTitle number={2} title={t('addParkingSpot.form.differentUser.title')} />
            <div className="lg:pl-8">
              <LabeledStateInput
                id="phone"
                state={getInputState('phone')}
                labeledProps={{
                  wrapperClassName: 'mb-5 mt-6 max-w-xl',
                  label: t('addParkingSpot.form.differentUser.label'),
                  errorMessage: t(`errorMessages.${errors.phone?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('phone'),
                  },
                  placeholder: t('addParkingSpot.form.differentUser.placeholder'),
                }}
              />
            </div>
          </div>
        )}

        <div className="pb-12 lg:pb-16 mt-16 mb-40 lg:mb-0">
          <FormSectionTitle number={isDifferentUserChecked ? 3 : 2} title={t('addParkingSpot.form.gateAccess.title')} />
          <div className="lg:pl-8">
            <Controller
              name="gates"
              control={control}
              render={({ field }) => (
                <LabeledStateMultiDropdown
                  id="gates"
                  state={getInputState('gates')}
                  labeledProps={{
                    wrapperClassName: 'mb-5 mt-6 max-w-xl',
                    errorMessage: t(`errorMessages.${errors.gates?.message}`),
                  }}
                  dropdownProps={{
                    options: entryDevicesOptions ?? [],
                    currentOptions: field.value,
                    setNewOptions: field.onChange,
                    buttonClassName: 'w-full flex justify-between items-center',
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={isSubmitBtnLoading}
          onClick={handleSubmit(onSubmit)}
          className="w-fit h-fit"
          prefixIcon={submitBtnIcon}
        >
          {submitBtnText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <BackButton className="mb-4" />

          <PrimaryButton disabled={isSubmitBtnLoading} onClick={handleSubmit(onSubmit)} prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};

export default AddParkingSpotForm;
