import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import FilterDropdown from '../../../../components/common/dropdowns/FilterDropdown';
import FilterIcon from '../../../../components/icons/FilterIcon';
import XMarkIcon from '../../../../components/icons/XmarkIcon';
import useUserParkings from '../../../../hooks/api/parkings/useUserParkings';
import { parkingFilterType } from '../../../../types/parkings/parkingsFilters';

const ParkingsFilter = () => {
  const { t } = useTranslation();
  const { filter, setFilter, noFilterOption, filterOptions } = useUserParkings();

  const isFiltering = filter?.value !== parkingFilterType.ALL;

  const resetFilter = () => setFilter(noFilterOption);

  return (
    <div className="flex min-h-[2.5rem] relative">
      <div className="w-fit max-lg:absolute max-lg:right-0 max-lg:top-[-160%]">
        <FilterDropdown
          label={t('parkings.filters.label')}
          options={filterOptions}
          currentOption={filter}
          setNewOption={newOption => {
            setFilter(newOption);
          }}
          iconLeft={<FilterIcon />}
          showIndicator={isFiltering}
        />
      </div>

      {isFiltering && filter?.value && (
        <div className={twMerge(`px-4 py-2 flex justify-center items-center rounded-md ml-2 gap-3 ${filter.color}`)}>
          <p className="text-black700 font-medium">{t(`parkings.filters.${filter.value}`)}</p>
          <button onClick={resetFilter}>
            <XMarkIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default ParkingsFilter;
