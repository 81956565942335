import { Navigate, Route, Routes } from 'react-router-dom';
import LayoutPartner from '../components/layout/LayoutPartner';
import Login from '../pages/login/Login';
import ParkingServiceActivities from '../pages/partner/parkingService/parkingServiceActivities/ParkingServiceActivities';
import ParkingServiceDevices from '../pages/partner/parkingService/parkingServiceDevices/ParkingServiceDevices';
import PromoCodesView from '../pages/partner/promoCodes/PromoCodesView';
import RequestResetPassword from '../pages/requestResetPassword/RequestResetPassword';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import { paths, routes } from '../static/routes';
import { ProtectedRoute } from './ProtectedRoute';

const withLayout = (Component: React.ReactNode) => <LayoutPartner>{Component}</LayoutPartner>;

const PartnerRoutes = () => {
  return (
    <Routes>
      <Route path={paths.login} element={<Login />} />
      <Route path={paths.requestResetPassword} element={<RequestResetPassword />} />
      <Route path={paths.resetPassword} element={<ResetPassword />} />

      <Route element={<ProtectedRoute />}>
        <Route path={paths.partner.parkingServiceActivities} element={withLayout(<ParkingServiceActivities />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.partner.parkingServiceDevices} element={withLayout(<ParkingServiceDevices />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.partner.promoCodes} element={withLayout(<PromoCodesView />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate replace to={routes.partner.parkingServiceActivities} />} />
      </Route>
    </Routes>
  );
};

export default PartnerRoutes;
