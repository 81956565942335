import { PlusIcon } from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectFile from '../../../../hooks/useSelectFile';
import { colors } from '../../../../styles/variables';
import { CallbackDefault } from '../../../../types/Types';
import { IPdfDetails } from '../../../../types/api/ApiTypes';
import RegulationIcon from '../../../icons/RegulationIcon';
import TrashIcon from '../../../icons/TrashIcon';
import IconButton from '../../buttons/IconButton';

interface RegulationPickerProps {
  headerText: string;
  oldRegulation: IPdfDetails | null;
  removeOldRegulation: CallbackDefault;
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
}

const RegulationPicker = ({
  headerText,
  oldRegulation,
  removeOldRegulation,
  selectedFile,
  setSelectedFile,
}: RegulationPickerProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { selectFile } = useSelectFile();

  const handleClickAddRegulation = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getActionButton = () => {
    if (!!oldRegulation) {
      return (
        <IconButton
          onClick={removeOldRegulation}
          icon={<TrashIcon color={colors.grey700} />}
          className="bg-grey100 h-8 w-8"
        />
      );
    } else if (!!selectedFile) {
      return (
        <IconButton
          onClick={() => setSelectedFile(null)}
          icon={<TrashIcon color={colors.grey700} />}
          className="bg-grey100 h-8 w-8"
        />
      );
    } else {
      return (
        <IconButton
          onClick={handleClickAddRegulation}
          icon={<PlusIcon width={16} color={colors.grey700} />}
          className="bg-grey100 h-8 w-8"
        />
      );
    }
  };

  const getRegulationName = () => {
    if (!!oldRegulation) {
      return oldRegulation.fileName;
    } else if (!!selectedFile) {
      return selectedFile.name;
    } else {
      return t('regulationPicker.noRegulationAdded');
    }
  };
  return (
    <div>
      <div className="flex justify-between gap-2 items-center">
        <h5 className="text-sm font-bold text-black700 leading-tight uppercase">{headerText}</h5>

        {getActionButton()}
      </div>

      <div className="flex gap-2 items-center">
        {(!!selectedFile || !!oldRegulation) && (
          <div>
            <RegulationIcon />
          </div>
        )}
        <p className="text-sm font-medium text-black400">{getRegulationName()}</p>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf"
        onChange={async event => {
          await selectFile({
            event,
            onSelectFile: setSelectedFile,
            sizeLimitKb: 2048,
            allowedFileTypes: ['application/pdf'],
          });
        }}
        className="hidden"
      />
    </div>
  );
};

export default RegulationPicker;
