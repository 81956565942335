import dayjs from 'dayjs';
import { TimeInterval } from '../../../components/common/modals/TimeIntervalsModal';
import { ParkingSubscriptionTimeRestriction } from '../../../types/api/ApiTypes';

export const getTimeRestrictionsFromTimeIntervals = (
  timeIntervals: TimeInterval[],
): ParkingSubscriptionTimeRestriction[] => {
  const inputWithTimeResctictions = timeIntervals.filter(interval => typeof interval.time === 'object');

  if (inputWithTimeResctictions.length === 0) {
    return [];
  }

  const restrictions: ParkingSubscriptionTimeRestriction[] = [];

  inputWithTimeResctictions.forEach(day => {
    if (day.time === 'unavailable') {
      return;
    }

    const dayRestrictions = day.time.map(restriction => ({
      weekday: day.weekday,
      start: typeof restriction === 'object' ? dayjs(restriction.from).format('HH:mm') : '',
      end: typeof restriction === 'object' ? dayjs(restriction.to).format('HH:mm') : '',
    }));

    restrictions.push(...dayRestrictions);
  });

  return restrictions;
};
