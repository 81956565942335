import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiUserStatistics, IUserStatistics } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformUserStatistics } from '../../../../utils/dataTransformers/transformUserStatistics/transformUserStatistics';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useUserStatisticsProps {
  period?: string;
  type?: string;
}

const useUserStatistics = ({ period, type }: useUserStatisticsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery([`${QueryAliases.USER_DETAILS_STATISTICS}-${period}-${type}`], () =>
    axiosInstance.get(api.endpoints.USER_DETAILS_STATISTICS({ period, type })),
  );

  const apiUserStatistics: ApiUserStatistics[] | undefined = data?.data;

  const userStatistics: IUserStatistics[] | undefined = !!apiUserStatistics
    ? transformUserStatistics(apiUserStatistics)
    : undefined;

  return { userStatistics, refetch };
};

export default useUserStatistics;
