import { ApiHistoryParking, IHistoryParking } from '../../../types/api/ApiTypes';

export const transformHistoryParkings = (history: ApiHistoryParking[] | undefined) => {
  if (history) {
    const transformedData: IHistoryParking[] = history.map((item, idx) => ({
      id: idx,
      licensePlate: item.license_plate,
      start: item.start,
      end: item.end,
      status: item.status,
      paidTotal: item.paid_total,
      parkingName: item.parking_name,
      parkingSpotNumber: item.parking_spot_number,
      historyType: item.history_type,
    }));

    return transformedData;
  }
  return [];
};
