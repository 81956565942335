import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ComponentState, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, FieldArrayWithId, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ISubscriptionFormInputs } from '../../../pages/priceLists/addPriceList/components/subscriptionForm/PriceListSubscriptionForm';
import { timeIntervalsModalSchema } from '../../../static/validationSchema/priceList/timeIntervalsModalSchema';
import { ComponentStates } from '../../../types/ComponentStates.types';
import { Weekday } from '../../../types/weekdays/Weekdays';
import SaveIcon from '../../icons/SaveIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SecondaryButton } from '../buttons/SecondaryButton';
import Switch from '../switch/Switch';
import { LabeledStateTimepicker } from '../timepicker/LabeledStateTimepicker';
import { Modal } from './Modal';

export interface TimeInterval {
  time:
    | 'unavailable'
    | {
        from: Date;
        to: Date;
      }[];
  weekday: Weekday;
}

export interface TimeIntervalsModalSaveProps {
  addedIntervals: {
    from: Date;
    to: Date;
  }[];
  useForAll: boolean;
}

interface TimeIntervalsModalProps {
  title: string;
  description: string;
  subtitle: string;
  onSave: (props: TimeIntervalsModalSaveProps) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<number | null>>;
  field: FieldArrayWithId<ISubscriptionFormInputs, 'timeIntervals', 'id'>;
}

interface IFormInputs {
  from: Date;
  to: Date;
}
type InputsKeys = keyof IFormInputs;

export const TimeIntervalsModal = ({
  title,
  description,
  subtitle,
  onSave,
  isOpen,
  setIsOpen,
  field,
}: TimeIntervalsModalProps) => {
  const { t } = useTranslation();

  const initIntervals =
    field.time === 'unavailable'
      ? []
      : field.time.map(timeItem => ({
          from: timeItem.from,
          to: timeItem.to,
        }));

  const [useForAll, setUseForAll] = useState(false);
  const [addedIntervals, setAddedIntervals] = useState<IFormInputs[]>(initIntervals);

  useEffect(() => {
    setAddedIntervals(initIntervals);
  }, [field]);

  const {
    handleSubmit,
    reset,
    control,

    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(timeIntervalsModalSchema),
    defaultValues: {
      from: dayjs().startOf('day').toDate(),
      to: dayjs().endOf('day').toDate(),
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const handleAdd: SubmitHandler<IFormInputs> = async props => {
    if (!!errors.to || !!errors.from) {
      return;
    }

    const isAlreadyAdded = addedIntervals.some(
      interval =>
        interval.from.toTimeString() === props.from.toTimeString() &&
        interval.to.toTimeString() === props.to.toTimeString(),
    );

    if (isAlreadyAdded) {
      return;
    }

    setAddedIntervals(prev => [...prev, props]);
    reset();
  };

  const handleRemove = (index: number) => {
    setAddedIntervals(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleConfirm = () => {
    if (addedIntervals.length === 0) {
      setIsOpen(null);
      return;
    }

    onSave({
      addedIntervals,
      useForAll,
    });

    setIsOpen(null);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={() => setIsOpen(null)}>
      <div>
        <div className="relative p-6 border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium text-black900">{title}</h2>
          <div
            onClick={() => setIsOpen(null)}
            className="absolute flex items-center justify-center w-8 h-8 cursor-pointer min-w-8 top-6 right-6 lg:left-6"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="p-6">
          <div className="flex flex-col gap-6 pb-6 border-b border-grey300">
            <h2 className="text-[1.375rem] font-medium text-black700">{t(`weekdays.${field.weekday}`)}</h2>
            <p className="text-black700">{description}</p>
          </div>
          <div className="flex flex-col pb-6 mt-6 border-b border-grey300">
            <p className="text-black500">{subtitle}</p>

            <div className="grid grid-cols-2 lg:flex lg:items-end w-full gap-4 mt-6">
              <Controller
                control={control}
                name="from"
                render={({ field }) => (
                  <LabeledStateTimepicker
                    id="intervals-from"
                    state={getInputState('from')}
                    labeledProps={{
                      wrapperClassName: 'w-full lg:max-w-xl',
                      label: t('timeIntervalsModal.from'),
                      errorMessage: t(`errorMessages.${errors.from?.message}`),
                    }}
                    timePickerProps={{
                      date: field.value,
                      setDate: field.onChange,
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="to"
                render={({ field }) => (
                  <LabeledStateTimepicker
                    id="intervals-to"
                    state={getInputState('to')}
                    labeledProps={{
                      wrapperClassName: ' w-full lg:max-w-xl',
                      label: t('timeIntervalsModal.to'),
                      errorMessage: t(`errorMessages.${errors.to?.message}`),
                    }}
                    timePickerProps={{
                      date: field.value,
                      setDate: field.onChange,
                    }}
                  />
                )}
              />

              <PrimaryButton
                className="lg:w-fit h-min lg:h-14"
                onClick={handleSubmit(handleAdd)}
                prefixIcon={<PlusIcon height={16} width={16} />}
              >
                {t('timeIntervalsModal.add')}
              </PrimaryButton>
            </div>
            {addedIntervals.length !== 0 &&
              addedIntervals.map((addedInterval, index) => (
                <div
                  key={`${addedInterval.from.toString()}-${addedInterval.to.toString()}`}
                  className="flex items-center gap-2 mt-6"
                >
                  <SuccessIcon />
                  <p className="text-black700">{t(`weekdays.${field.weekday}`)}</p>
                  <p className="text-black700">{`${dayjs(addedInterval.from).format('HH:mm')} - ${dayjs(
                    addedInterval.to,
                  ).format('HH:mm')}`}</p>
                  <button onClick={() => handleRemove(index)}>
                    <XMarkIcon width={20} />
                  </button>
                </div>
              ))}
          </div>

          <div className="flex items-center gap-2 mt-4">
            <Switch isChecked={!!useForAll} onChange={() => setUseForAll(prev => !prev)} />
            <p className="text-black500">{t('timeIntervalsModal.useForAll')}</p>
          </div>

          <div className="flex justify-end gap-4 mt-6">
            <SecondaryButton className="lg:w-fit lg:min-w-[6rem]" onClick={() => setIsOpen(null)}>
              {t('timeIntervalsModal.cancel')}
            </SecondaryButton>
            <PrimaryButton
              className="lg:w-fit"
              onClick={() => {
                handleConfirm();
              }}
              prefixIcon={<SaveIcon />}
            >
              {t('timeIntervalsModal.save')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
