import { ApiProfile, IProfile } from '../../../types/api/ApiTypes';
import { transformAddress } from '../transformAddress/transformAddress';

export const transformProfile = (apiProfile: ApiProfile): IProfile => {
  const transformedProfile: IProfile = {
    birthDate: apiProfile?.birth_date,
    address: transformAddress(apiProfile.address),
    avatar: apiProfile.avatar,
  };

  return transformedProfile;
};
