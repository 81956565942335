import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteGroup = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (id: string) => axiosInstance.delete(api.endpoints.SINGLE_GROUP({ id })),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([QueryAliases.USER_GROUPS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.deleteGroupFailed'));
      },
    },
  );
  return { deleteGroup: mutateAsync, isLoading };
};

export default useDeleteGroup;
