import { ConnectorStatusesEnum } from './connectorStatusesEnum';

export const connectorStatuses = Object.freeze({
  available: {
    value: ConnectorStatusesEnum.AVAILABLE,
    textColor: 'text-success',
    bgColor: 'bg-success',
  },
  unavailable: {
    value: ConnectorStatusesEnum.UNAVAILABLE,
    textColor: 'text-error',
    bgColor: 'bg-error',
  },
  preparing: {
    value: ConnectorStatusesEnum.PREPARING,
    textColor: 'text-black700',
    bgColor: 'bg-black700',
  },
  charging: {
    value: ConnectorStatusesEnum.CHARGING,
    textColor: 'text-success',
    bgColor: 'bg-success',
  },
  finishing: {
    value: ConnectorStatusesEnum.FINISHING,
    textColor: 'text-black700',
    bgColor: 'bg-black700',
  },
  suspendedEV: {
    value: ConnectorStatusesEnum.SUSPENDED_EV,
    textColor: 'text-error',
    bgColor: 'bg-error',
  },
});
