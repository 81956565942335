import Dropdown from '../../../../components/common/dropdowns/Dropdown';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { useDashboardFiltersOptions } from './useDashboardFiltersOptions';

interface DashboardFiltersProps {
  currentTypeOption: DropdownOption | undefined;
  setCurrentTypeOption: (option: DropdownOption) => void;
  currentPeriodOption: DropdownOption | undefined;
  setCurrentPeriodOption: (option: DropdownOption) => void;
}

export const DashboardFilters = ({
  currentTypeOption,
  currentPeriodOption,
  setCurrentTypeOption,
  setCurrentPeriodOption,
}: DashboardFiltersProps) => {
  const { typeDropdownOptions, periodDropdownOptions } = useDashboardFiltersOptions();

  return (
    <div className="flex gap-4">
      <Dropdown
        options={typeDropdownOptions}
        currentOption={currentTypeOption}
        setNewOption={setCurrentTypeOption}
        wrapperClassName="h-min bg-grey100"
        buttonClassName="justify-start text-nowrap"
      />
      <Dropdown
        options={periodDropdownOptions}
        currentOption={currentPeriodOption}
        setNewOption={setCurrentPeriodOption}
        wrapperClassName="h-min bg-grey100"
        buttonClassName="justify-start text-nowrap"
      />
    </div>
  );
};
