export function getDateFromHour(hourInfo: string): Date {
  const [hour, minute, second] = hourInfo.split(':');
  const date = new Date();
  date.setHours(parseInt(hour as string, 10));
  date.setMinutes(parseInt(minute as string, 10));
  if (second) {
    date.setSeconds(parseInt(second as string, 10));
  }
  date.setMilliseconds(0);
  return date;
}
