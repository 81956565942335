import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useToggleChargingState = (chargerId: string) => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const endpointToUse = (turnOn: boolean, connectorId: string) => {
    if (turnOn) {
      return api.endpoints.SINGLE_CHARGER.CHARGING_START(connectorId);
    } else {
      return api.endpoints.SINGLE_CHARGER.CHARGING_STOP(connectorId);
    }
  };

  const { mutateAsync } = useMutation(
    ({ turnOn, connectorId }: { turnOn: boolean; connectorId: string }) =>
      axiosInstance.post(endpointToUse(turnOn, connectorId)),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.chargers.changeConnectorStateFailed`));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([`${QueryAliases.CHARGER_DETAILS}-${chargerId}`]);
      },
    },
  );

  return { toggleCharging: mutateAsync };
};

export default useToggleChargingState;
