import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CopyIcon from '../../../../components/icons/CopyIcon';
import EditIcon from '../../../../components/icons/EditIcon';
import { routes } from '../../../../static/routes';
import { PriceListTypes } from '../../../../types/price-lists/PriceListTypes';
import { getDaysFromDDHHMMssDuration } from '../../../../utils/priceLists/getDaysFromDDHHMMssDuration/getDaysFromDDHHMMssDuration';

const useSubscriptionsPriceListsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      field: 'name',
      headerName: t('priceLists.subscriptions.tableHeaders.name'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">{params.row.name}</p>
          </div>
        );
      },
    },

    {
      field: 'price',
      headerName: t('priceLists.subscriptions.tableHeaders.price'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <div className="flex align-middle">
              <p className="flex align-middle">
                {params.row.price} {params.row.currency}
              </p>
            </div>
          </div>
        );
      },
    },

    {
      field: 'duration',
      headerName: t('priceLists.subscriptions.tableHeaders.duration'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">
              <p className="flex align-middle">{getDaysFromDDHHMMssDuration(params.row.duration)}</p>
            </p>
          </div>
        );
      },
    },

    {
      field: 'timeRestrictions',
      headerName: t('priceLists.subscriptions.tableHeaders.timeRestrictions'),
      minWidth: 170,
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const isOn = !!params.row.hasTimeRestrictions;

        return (
          <div className="w-full flex items-center gap-4">
            <div className={`w-2 h-2 min-w-2 rounded-full ${isOn ? 'bg-success' : 'bg-error'}`} />
            <p className="flex align-middle">
              {isOn
                ? t('priceLists.subscriptions.timeRestrictionsStatus.on')
                : t('priceLists.subscriptions.timeRestrictionsStatus.off')}
            </p>
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end gap-2.5">
            <button
              className="p-3"
              onClick={() => {
                navigate(routes.copyPriceList({ id: params.row.id, type: PriceListTypes.SUBSCRIPTION }));
              }}
            >
              <CopyIcon />
            </button>

            <button
              className="p-3"
              onClick={() => {
                navigate(routes.editPriceList({ id: params.row.id, type: PriceListTypes.SUBSCRIPTION }));
              }}
            >
              <EditIcon />
            </button>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useSubscriptionsPriceListsTableHeaders;
