import { Listbox } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { colors } from '../../../styles/variables';
import { DropdownOption, DropdownStyleProps } from '../../../types/Dropdown.types';
import XMarkIcon from '../../icons/XmarkIcon';

export interface MultiDropdownProps extends DropdownStyleProps {
  options: DropdownOption[];
  currentOptions: DropdownOption[];
  setNewOptions: (options: DropdownOption[]) => void;
  buttonDataTestId?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const MultiDropdown = ({
  options,
  currentOptions,
  setNewOptions,
  buttonClassName,
  optionsClassName,
  optionClassName,
  buttonDataTestId,
  disabled,
  placeholder,
}: MultiDropdownProps) => {
  const { t } = useTranslation();

  const isSelected = (option: DropdownOption) => {
    return currentOptions?.some(selectedOption => selectedOption.id === option.id);
  };

  const toggleOption = (option: DropdownOption) => {
    if (isSelected(option)) {
      setNewOptions(currentOptions.filter(selectedOption => selectedOption.id !== option.id));
    } else {
      setNewOptions([...currentOptions, option]);
    }
  };

  return (
    <div
      className={
        'relative outline outline-1.5 outline-grey500 rounded-md px-3 py-2 min-h-[3.625rem] flex flex-col justify-center text-sm text-black500 font-medium w-full placeholder:text-grey500 focus:outline-2 focus:outline-blue500'
      }
    >
      <Listbox value={currentOptions} onChange={setNewOptions} multiple by="id" disabled={disabled}>
        {({ open }) => (
          <>
            <Listbox.Button data-testid={buttonDataTestId} className={twMerge(`h-full ${buttonClassName}`)}>
              <div className="flex flex-wrap gap-2">
                {currentOptions?.length > 0 ? (
                  currentOptions.map(option => (
                    <div key={option.id} className="flex items-center gap-2 px-4 py-2.5 rounded-md bg-grey200">
                      <span>{option.value}</span>
                      <button
                        type="button"
                        className="text-2xl text-black500 focus:outline-none"
                        onClick={e => {
                          e.stopPropagation();
                          toggleOption(option);
                        }}
                      >
                        <XMarkIcon size="12" />
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-black500">{placeholder}</p>
                )}
              </div>
              {open ? (
                <ChevronUpIcon color={colors.black700} width={24} />
              ) : (
                <ChevronDownIcon color={colors.black700} width={24} />
              )}
            </Listbox.Button>
            {open && (
              <Listbox.Options
                className={twMerge(
                  `absolute top-16 w-full left-0 rounded-md max-h-60 overflow-auto ring-2 ring-grey500 py-2 border-grey500 bg-white z-50 ${
                    optionsClassName ?? ''
                  }`,
                )}
              >
                {options?.length > 0 ? (
                  options.map(option => (
                    <Listbox.Option
                      key={option.id}
                      value={option}
                      className={twMerge(`cursor-pointer py-3 hover:bg-blue100 ${optionClassName ?? ''}`)}
                      onClick={() => toggleOption(option)}
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-2 py-2 pl-4">
                          <input type="checkbox" checked={selected} readOnly className="w-4 h-4 cursor-pointer" />
                          <p className="w-full">{option.value}</p>
                        </div>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <li className={twMerge(`py-2 px-3 ${optionClassName}`)}>{t('errorMessages.noData')}</li>
                )}
              </Listbox.Options>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MultiDropdown;
