import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomActionBox } from '../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../components/common/buttons/BackButton';
import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import MyDataTable from '../../components/dataTable/MyDataTable';
import PlusIcon from '../../components/icons/PlusIcon';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import useSingleChargerRulesPageTable from '../../hooks/pages/useSingleChargerRulesPageTable';
import { routes } from '../../static/routes';

const SingleChargerRulesTable = () => {
  const { data, headers } = useSingleChargerRulesPageTable();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const handleClick = () => {
    navigate(routes.addChargingRule(id as string));
  };
  return (
    <>
      {data && (
        <div className="pb-[11rem] lg:pb-0">
          <div className="flex justify-end pb-4">
            {!isTablet && (
              <PrimaryButton className="w-fit" prefixIcon={<PlusIcon small />} onClick={handleClick}>
                {t('singleCharger.rulesTableHeaders.addNewRule')}
              </PrimaryButton>
            )}
          </div>
          <MyDataTable headers={headers} data={data} />
        </div>
      )}
      {isTablet && (
        <BottomActionBox>
          <BackButton />
          <PrimaryButton prefixIcon={<PlusIcon small />} onClick={handleClick}>
            {t('singleCharger.rulesTableHeaders.addNewRule')}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </>
  );
};

export default SingleChargerRulesTable;
