import MyDataTable from '../../../../components/dataTable/MyDataTable';
import useOpenPartnerEntryDevice from '../../../../hooks/api/partner/useOpenPartnerEntryDevice';
import usePartnerDevices from '../../../../hooks/api/partner/usePartnerDevices';
import ParkingServiceWrapper from '../components/ParkingServiceWrapper';
import useParkingServiceDevicesTable from './hooks/useParkingServiceDevicesTable';

const ParkingServiceDevices = () => {
  const { devices } = usePartnerDevices();
  const { openEntryDevice } = useOpenPartnerEntryDevice();

  const { headers } = useParkingServiceDevicesTable({
    onOpenClick: (id: string) => {
      openEntryDevice(id);
    },
  });

  return (
    <ParkingServiceWrapper>
      {!!devices && (
        <div className="mt-12">
          <MyDataTable headers={headers} data={devices} />
        </div>
      )}
    </ParkingServiceWrapper>
  );
};

export default ParkingServiceDevices;
