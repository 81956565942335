import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../components/common/buttons/DeleteIconButton';
import ConfirmModal from '../../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../../components/icons/TrashIcon';
import TitleWrapper from '../../../../components/wrappers/titleWrapper/TitleWrapper';
import useDeleteParkingRule from '../../../../hooks/api/parkings/rules/useDeleteParkingRule';
import useEditParkingRule from '../../../../hooks/api/parkings/rules/useEditParkingRule';
import useParkingRule from '../../../../hooks/api/parkings/rules/useParkingRule';
import useSingleParking from '../../../../hooks/api/parkings/useSingleParking';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import useGroupsDropdownOptions from '../../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import useParkingPriceListsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingPriceListsDropdownOptions';
import useParkingSpotsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { routes } from '../../../../static/routes';
import { colors } from '../../../../styles/variables';
import { ApiEditParkingRuleRequest } from '../../../../types/api/ApiTypes';
import ParkingRuleForm from '../components/ParkingRuleForm';

const EditParkingRule = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;
  const ruleId = params.ruleId as string;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { parking } = useSingleParking({ parkingId });
  const { rule } = useParkingRule({ parkingId, ruleId });
  const { editRule, isLoading: isEditLoading } = useEditParkingRule();
  const { deleteRule } = useDeleteParkingRule();

  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });
  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListsDropdownOptions } = useParkingPriceListsDropdownOptions();

  const handleEditParkingRule = async (editedRule: ApiEditParkingRuleRequest) => {
    if (zoneId) {
      editedRule.parking_zone_id = zoneId;
    }
    await editRule({ parkingId, ruleId, rule: editedRule });

    navigate(
      zoneId
        ? routes.parkingAdvanced.zoneView.parkingRules(parkingId, zoneId)
        : routes.parkingBasic.parkingRules(parkingId),
    );
  };

  const handleDeleteRule = async () => {
    await deleteRule({ parkingId, ruleId });
    if (!!rule?.parkingZone) {
      navigate(routes.parkingAdvanced.zoneView.parkingRules(parkingId, rule.parkingZone));
    }
    navigate(routes.parkingBasic.parkingRules(parkingId));
  };

  const isReady =
    !!rule && !!spotsDropdownOptions && !!groupsDropdownOptions && !!priceListsDropdownOptions && !!parking;

  const modifiedBreadcrumbs =
    !!parkingId && !!parking?.name ? [{ path: parkingId as string, text: parking?.name as string }] : [];

  return (
    <>
      <TitleWrapper
        title={`${t('editParkingRule.title')}${!!parking?.name && ` (${parking.name})`}`}
        breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}
      >
        {!isTablet && (
          <div className="flex justify-between items-center mt-8 mb-16">
            <BackButton />
            <DeleteIconButton onClick={() => setIsDeleteModalOpen(true)} />
          </div>
        )}

        {isReady && (
          <ParkingRuleForm
            onSubmitForm={handleEditParkingRule}
            isSubmitBtnLoading={isEditLoading}
            parkingId={parkingId}
            parkingType={parking.type}
            submitBtnText={t('editParkingRule.submitBtn')}
            initialValues={{
              rule,
              spotsOptions: spotsDropdownOptions.filter(option => rule.parkingSpots.some(spot => spot === option.id)),
              groupsOptions: groupsDropdownOptions.filter(option => rule.groups.some(group => group.id === option.id)),
              priceListOption: priceListsDropdownOptions.find(option => option.id === rule.priceList),
            }}
            onDeleteRule={() => setIsDeleteModalOpen(true)}
          />
        )}
      </TitleWrapper>

      <ConfirmModal
        title={t('editParkingRule.delete.title')}
        confirmQuestion={t('editParkingRule.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('editParkingRule.delete.cancelBtnText')}
        confirmBtnText={t('editParkingRule.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteRule}
      />
    </>
  );
};

export default EditParkingRule;
