import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../../../components/common/buttons/IconButton';
import { PrimaryButton } from '../../../../../../components/common/buttons/PrimaryButton';
import BorderCard from '../../../../../../components/common/cards/BorderCard';
import TrashIcon from '../../../../../../components/icons/TrashIcon';
import UserIcon from '../../../../../../components/icons/UserIcon';
import useSelectFile from '../../../../../../hooks/useSelectFile';

interface UserImageProps {
  oldAvatarUrl: string;
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
}

const UserImage = ({ oldAvatarUrl, selectedFile, setSelectedFile }: UserImageProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { selectFile } = useSelectFile();

  const { t } = useTranslation();

  const isExistingAvatar = !!selectedFile || !!oldAvatarUrl;

  const handleClickChangeImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClearImg = () => {
    setSelectedFile(null);
  };

  const getImg = useCallback(() => {
    if (!!selectedFile) {
      return <img src={URL.createObjectURL(selectedFile)} alt="avatar" className="rounded-full" />;
    } else if (!!oldAvatarUrl) {
      return <img src={oldAvatarUrl} alt="avatar" className="rounded-full" />;
    } else {
      return <UserIcon />;
    }
  }, [selectedFile, oldAvatarUrl]);

  return (
    <BorderCard className="flex justify-between items-center gap-2">
      <div className="flex gap-6 items-center">
        <div className="rounded-full bg-grey100 h-20 w-20 min-w-[5rem] flex justify-center items-center">
          {getImg()}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={async event => {
            await selectFile({
              event,
              onSelectFile: setSelectedFile,
              sizeLimitKb: 800,
              allowedFileTypes: ['image/jpeg', 'image/png'],
            });
          }}
          className="hidden"
        />
        <div>
          {!isExistingAvatar && (
            <p className="font-medium text-sm text-black700">{t('userAccount.userInfo.image.noFileSelected')}</p>
          )}
          <p className="text-xs font-normal text-black400">{t('userAccount.userInfo.image.description')}</p>
        </div>
      </div>
      <div className="flex gap-6">
        <PrimaryButton onClick={handleClickChangeImg}>
          {!!isExistingAvatar ? t('userAccount.userInfo.image.change') : t('userAccount.userInfo.image.add')}
        </PrimaryButton>

        {!!selectedFile && <IconButton onClick={handleClearImg} icon={<TrashIcon />} />}
      </div>
    </BorderCard>
  );
};

export default UserImage;
