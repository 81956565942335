import { colors } from '../../styles/variables';

interface SmartphoneIconProps {
  color?: string;
}

const SmartphoneIcon = ({ color }: SmartphoneIconProps) => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1V2.4C11 2.96005 11 3.24008 10.891 3.45399C10.7951 3.64215 10.6422 3.79513 10.454 3.89101C10.2401 4 9.96005 4 9.4 4H6.6C6.03995 4 5.75992 4 5.54601 3.89101C5.35785 3.79513 5.20487 3.64215 5.10899 3.45399C5 3.24008 5 2.96005 5 2.4V1M4.2 21H11.8C12.9201 21 13.4802 21 13.908 20.782C14.2843 20.5903 14.5903 20.2843 14.782 19.908C15 19.4802 15 18.9201 15 17.8V4.2C15 3.07989 15 2.51984 14.782 2.09202C14.5903 1.71569 14.2843 1.40973 13.908 1.21799C13.4802 1 12.9201 1 11.8 1H4.2C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V17.8C1 18.9201 1 19.4802 1.21799 19.908C1.40973 20.2843 1.71569 20.5903 2.09202 20.782C2.51984 21 3.07989 21 4.2 21Z"
        stroke={color ?? colors.white900}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmartphoneIcon;
