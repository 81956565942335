import * as yup from 'yup';
import * as Schema from '../validationFields';

export const addSpotCoUserSchema = ({
  existingPhoneNumbers,
  arePhoneNumberRequired,
  areGroupRequired,
}: {
  existingPhoneNumbers: string[];
  arePhoneNumberRequired: boolean;
  areGroupRequired: boolean;
}) =>
  yup.object({
    ...(arePhoneNumberRequired && { phone: Schema.uniquePhoneNumber(existingPhoneNumbers) }),
    ...(areGroupRequired && { group: Schema.requiredObject }),
  });
