import { ApiActivity, IActivity } from '../../../types/api/ApiTypes';

export const transformActivitiesList = (apiActivity: ApiActivity): IActivity => {
  return {
    id: apiActivity.id,
    user: {
      id: apiActivity.user?.id,
      firstName: apiActivity.user?.first_name,
      lastName: apiActivity.user?.last_name,
      phoneNumber: apiActivity.user?.phone_number,
    },
    licensePlate: apiActivity.license_plate,
    entitlementReason: apiActivity.entitlement_reason,
    start: apiActivity.start,
    end: apiActivity.end,
  };
};
