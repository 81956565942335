import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { LabeledStateDatetimepicker } from '../../../../components/common/datetimepicker/LabeledDatetimePicker';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getInitialEndDate from '../../../../utils/getInitialEndDate/getInitialEndDate';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';

interface ValidateFormProps {
  start: string;
  licensePlate: string;
  onSubmitForm: (data: ValidateFormInputs) => Promise<void>;
  isLoading: boolean;
}

interface ValidateFormInputs {
  validTo: Date;
}

type InputsKeys = keyof ValidateFormInputs;

const ValidateForm = ({ start, licensePlate, onSubmitForm, isLoading }: ValidateFormProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ValidateFormInputs>({ mode: 'onSubmit', defaultValues: { validTo: getInitialEndDate(new Date(), 15) } });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }
    return ComponentStates.DEFAULT;
  };
  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col">
      <p className="text-black700 font-normal mb-1">{t('parkingDetails.activities.validate.licensePlate')}</p>
      <span className="text-xs mb-8">{licensePlate}</span>
      <p className="text-black700 font-normal mb-1">{t('parkingDetails.activities.validate.start')}</p>
      <span className="text-xs mb-8">{dayjs(start).format('DD.MM.YYYY HH:mm')}</span>
      <p className="text-black700 font-normal">{t('parkingDetails.activities.validate.end')}</p>
      <Controller
        control={control}
        name="validTo"
        render={({ field }) => (
          <LabeledStateDatetimepicker
            id="valid-to"
            state={getInputState('validTo')}
            labeledProps={{
              wrapperClassName: 'mb-8 max-w-md',
              errorMessage: t(`errorMessages.${errors.validTo?.message}`),
            }}
            datetimepickerProps={{
              date: field.value,
              setDate: field.onChange,
            }}
          />
        )}
      />
      <PrimaryButton type="submit" className="mt-6 lg:mt-12 lg:w-fit place-self-end" disabled={isLoading}>
        {t('parkingDetails.activities.validate.submitBtn')}
      </PrimaryButton>
    </form>
  );
};

export default ValidateForm;
