import { colors } from '../../../styles/variables';

interface SpotImagesIconProps {
  color?: string;
}

const SpotImagesIcon = ({ color }: SpotImagesIconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 5L18.5 7L22.5 3M13 3H8.3C6.61984 3 5.77976 3 5.13803 3.32698C4.57354 3.6146 4.1146 4.07354 3.82698 4.63803C3.5 5.27976 3.5 6.11984 3.5 7.8V16.2C3.5 17.8802 3.5 18.7202 3.82698 19.362C4.1146 19.9265 4.57354 20.3854 5.13803 20.673C5.77976 21 6.61984 21 8.3 21H17.5C18.43 21 18.895 21 19.2765 20.8978C20.3117 20.6204 21.1204 19.8117 21.3978 18.7765C21.5 18.395 21.5 17.93 21.5 17M11 8.5C11 9.60457 10.1046 10.5 9 10.5C7.89543 10.5 7 9.60457 7 8.5C7 7.39543 7.89543 6.5 9 6.5C10.1046 6.5 11 7.39543 11 8.5ZM15.49 11.9181L7.03115 19.608C6.55536 20.0406 6.31747 20.2568 6.29643 20.4442C6.27819 20.6066 6.34045 20.7676 6.46319 20.8755C6.60478 21 6.92628 21 7.56929 21H16.956C18.3951 21 19.1147 21 19.6799 20.7582C20.3894 20.4547 20.9547 19.8894 21.2582 19.1799C21.5 18.6147 21.5 17.8951 21.5 16.456C21.5 15.9717 21.5 15.7296 21.4471 15.5042C21.3805 15.2208 21.253 14.9554 21.0733 14.7264C20.9303 14.5442 20.7412 14.3929 20.3631 14.0905L17.5658 11.8527C17.1874 11.5499 16.9982 11.3985 16.7898 11.3451C16.6061 11.298 16.4129 11.3041 16.2325 11.3627C16.0279 11.4291 15.8486 11.5921 15.49 11.9181Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpotImagesIcon;
