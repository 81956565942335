import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/common/buttons/BackButton';
import DeleteIconButton from '../../components/common/buttons/DeleteIconButton';
import ConfirmModal from '../../components/common/modals/ConfirmModal';
import SaveIcon from '../../components/icons/SaveIcon';
import TrashIcon from '../../components/icons/TrashIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useAddUserToGroup from '../../hooks/api/groups/useAddUserToGroup';
import useDeleteGroup from '../../hooks/api/groups/useDeleteGroup';
import useDeleteUserFromGroup from '../../hooks/api/groups/useDeleteUserFromGroup';
import useEditGroupName from '../../hooks/api/groups/useEditGroupName';
import useEditGroupUser from '../../hooks/api/groups/useEditGroupUser';
import useGroupDetails from '../../hooks/api/groups/useGroupDetails';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { routes } from '../../static/routes';
import { colors } from '../../styles/variables';
import { IGroupUser } from '../../types/api/ApiTypes';
import GroupForm from '../groups/components/GroupForm';

const EditGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isTablet } = useIsTablet();

  const { group } = useGroupDetails({ groupId: id as string });
  const { deleteGroup, isLoading: isLoadingDelete } = useDeleteGroup();
  const { editGroupName, isLoading: isLoadingEditName } = useEditGroupName();
  const { editGroupUser, isLoading: isLoadingEditUser } = useEditGroupUser();
  const { deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUserFromGroup();
  const { addUser, isLoading: isLoadingAddUser } = useAddUserToGroup();
  const isLoading =
    isLoadingDelete || isLoadingEditName || isLoadingEditUser || isLoadingDeleteUser || isLoadingAddUser;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditName = async (displayName: string) => {
    await editGroupName({ id: id as string, displayName });

    navigate(routes.groups);
  };

  const handleEditUsers = async (users: IGroupUser[]) => {
    const isInOldGroup = (user: IGroupUser) => group?.users.some(oldUser => oldUser.phone === user.phone);

    const existingUsers = users.filter(user => isInOldGroup(user));
    const newUsers = users.filter(user => !isInOldGroup(user));

    const deletedUsers = group?.users.filter(oldUser => !users.some(user => user.phone === oldUser.phone));

    existingUsers.forEach(async user => {
      await editGroupUser({ id: id as string, user });
    });

    newUsers.forEach(async user => {
      await addUser({ groupId: id as string, user });
    });

    deletedUsers?.forEach(async user => {
      await deleteUser({ groupId: id as string, phone: user.phone });
    });

    navigate(routes.groups);
  };

  const handleEditGroup = async ({ displayName, groupUsers }: { displayName: string; groupUsers: IGroupUser[] }) => {
    if (displayName !== group?.displayName) {
      await handleEditName(displayName);
    } else if (groupUsers !== group?.users) {
      await handleEditUsers(groupUsers);
    } else {
      navigate(routes.groups);
    }
  };

  const handleDeleteGroup = () => {
    deleteGroup(id as string);
    setIsDeleteModalOpen(false);
    navigate(routes.groups);
  };

  const modifiedBreadcrumbs =
    !!group?.id && !!group?.displayName ? [{ path: group?.id as string, text: group?.displayName as string }] : [];

  return (
    <>
      <TitleWrapper title={t('addGroup.title')} breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}>
        {!isTablet && (
          <div className="flex justify-between items-center mt-8 mb-16">
            <BackButton />
            <DeleteIconButton onClick={() => setIsDeleteModalOpen(true)} />
          </div>
        )}
        {!!group && (
          <GroupForm
            initialName={group.displayName}
            initialUsers={group.users}
            onSubmitForm={handleEditGroup}
            submitBtnText={t('editGroup.submitBtn')}
            submitBtnIcon={<SaveIcon />}
            onDeleteGroup={() => setIsDeleteModalOpen(true)}
            isSubmitBtnDisabled={isLoading}
          />
        )}
      </TitleWrapper>

      <ConfirmModal
        title={t('groups.delete.title')}
        confirmQuestion={t('groups.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('groups.delete.cancelBtnText')}
        confirmBtnText={t('groups.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteGroup}
      />
    </>
  );
};

export default EditGroup;
