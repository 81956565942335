import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MyTooltip from '../../../../components/common/myTooltip/MyTooltip';
import CopyIcon from '../../../../components/icons/CopyIcon';
import EditIcon from '../../../../components/icons/EditIcon';
import TooltipIcon from '../../../../components/icons/TooltipIcon';
import { routes } from '../../../../static/routes';
import { PriceListTypes } from '../../../../types/price-lists/PriceListTypes';
import { formatPrice } from '../../../../utils/formatPrice/formatPrice';

const useParkingPriceListsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      field: 'name',
      headerName: t('priceLists.parkings.tableHeaders.name'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">{params.row.name}</p>
          </div>
        );
      },
    },

    {
      field: 'periodsCount',
      headerName: t('priceLists.parkings.tableHeaders.periodsCount'),
      minWidth: 140,
      maxWidth: 160,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">{params.row.periods.length}</p>

            <MyTooltip content={<p className="text-xs text-center">{t('priceLists.parkings.tooltip')}</p>}>
              <div>
                <TooltipIcon />
              </div>
            </MyTooltip>
          </div>
        );
      },
    },

    {
      field: 'firstPeriodPrice',
      headerName: t('priceLists.parkings.tableHeaders.firstPeriodPrice'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const price = params.row.pricePerFirstPeriod;

        if (!price && price !== 0) {
          return <></>;
        }

        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">
              {formatPrice(price)} {params.row.currency}
            </p>
          </div>
        );
      },
    },

    {
      field: 'secondPeriodPrice',
      headerName: t('priceLists.parkings.tableHeaders.secondPeriodPrice'),
      minWidth: 130,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const price = params.row.pricePerSecondPeriod;

        if (!price && price !== 0) {
          return <></>;
        }

        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">
              {formatPrice(price)} {params.row.currency}
            </p>
          </div>
        );
      },
    },

    {
      field: 'thirdPeriodPrice',
      headerName: t('priceLists.parkings.tableHeaders.thirdPeriodPrice'),
      minWidth: 130,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const price = params.row.pricePerThirdPeriod;

        if (!price && price !== 0) {
          return <></>;
        }

        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">
              {formatPrice(price)} {params.row.currency}
            </p>
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end gap-2.5">
            <button
              className="p-3"
              onClick={() => {
                navigate(routes.copyPriceList({ id: params.row.id, type: PriceListTypes.PARKING }));
              }}
            >
              <CopyIcon />
            </button>

            <button
              className="p-3"
              onClick={() => {
                navigate(routes.editPriceList({ id: params.row.id, type: PriceListTypes.PARKING }));
              }}
            >
              <EditIcon />
            </button>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useParkingPriceListsTableHeaders;
