import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useOpenPartnerEntryDevice = () => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync } = useMutation(
    (id: string) => axiosInstance.post(api.endpoints.PARTNER.OPEN_ENTRY_DEVICE({ id })),
    {
      onSuccess: () => {
        showSuccessToast(t('successMessages.entryOpeningSuccess'));
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.entryOpeningFailed'));
      },
    },
  );

  return { openEntryDevice: mutateAsync };
};

export default useOpenPartnerEntryDevice;
