import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../../../components/wrappers/titleWrapper/TitleWrapper';
import useSingleParking from '../../../../../hooks/api/parkings/useSingleParking';
import useSingleParkingZone from '../../../../../hooks/api/parkings/zones/useSingleParkingZone';
import { routes } from '../../../../../static/routes';

interface SingleZoneWrapperProps {
  children: React.ReactNode;
}

const SingleZoneWrapper = ({ children }: SingleZoneWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();

  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;

  const { parking } = useSingleParking({ parkingId });
  const { parkingZone } = useSingleParkingZone({ zoneId });

  const extraBreadcrumbs = [
    {
      text: `${parking?.name} ${t('parkingDetails.zones.zone')} ${parkingZone?.name}` ?? '',
    },
  ];

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('parkingDetails.generalInfo.title'),
      routeTo: routes.parkingAdvanced.zoneView.generalInfo(parkingId, zoneId),
    },
    {
      text: t('parkingDetails.parkingRules.title'),
      routeTo: routes.parkingAdvanced.zoneView.parkingRules(parkingId, zoneId),
    },
    {
      text: t('parkingDetails.parkingSpots.title'),
      routeTo: routes.parkingAdvanced.zoneView.parkingSpots(parkingId, zoneId),
    },
    {
      text: t('parkingDetails.activities.title'),
      routeTo: routes.parkingAdvanced.zoneView.activities(parkingId, zoneId),
    },
  ];

  return (
    <TitleWrapper
      title={`${t('parkingDetails.title')}${
        !!parking?.name && !!parkingZone?.name
          ? ` ${parking.name} - ${t('parkingDetails.zones.zone')} ${parkingZone.name}`
          : ''
      }`}
      breadcrumbsProps={{ limit: 3, extraBreadcrumbs }}
    >
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />

      <div className="px-0 mt-6 mb-40 bg-white900 lg:overflow-y-scroll lg:no-scrollbar lg:mb-0">{children}</div>
    </TitleWrapper>
  );
};

export default SingleZoneWrapper;
