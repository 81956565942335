import { ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import CustomToastContainer from './components/toastContainer/CustomToastContainer';
import { SearchBarContextProvider } from './context/searchBarContextProvider/SearchBarContextProvider';
import MuiLocalizationProvider from './providers/muiLocalizationProvider/MuiLocalizationProvider';
import { queryClient } from './queryClient';
import AppRoutes from './routes/AppRoutes';
import { theme } from './styles/theme';

const App: FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MuiLocalizationProvider>
          <ThemeProvider theme={theme}>
            <SearchBarContextProvider>
              <AppRoutes />
              <CustomToastContainer />
              <ReactQueryDevtools initialIsOpen={false} />
            </SearchBarContextProvider>
          </ThemeProvider>
        </MuiLocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
