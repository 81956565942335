import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CopyIcon from '../../../../components/icons/CopyIcon';
import EditIcon from '../../../../components/icons/EditIcon';
import { routes } from '../../../../static/routes';
import { PriceListTypes } from '../../../../types/price-lists/PriceListTypes';

const useEvPriceListsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      field: 'name',
      headerName: t('priceLists.ev.tableHeaders.name'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">{params.row.name}</p>
          </div>
        );
      },
    },

    {
      field: 'kwhPrice',
      headerName: t('priceLists.ev.tableHeaders.kwhPrice'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">
              {!!params.row.pricePerKwh ? `${params.row.pricePerKwh} ${params.row.currency}` : '-'}
            </p>
          </div>
        );
      },
    },

    {
      field: 'timePrice',
      headerName: t('priceLists.ev.tableHeaders.timePrice'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">
              {!!params.row.pricePerH ? `${params.row.pricePerH} ${params.row.currency}` : '-'}
            </p>
          </div>
        );
      },
    },

    {
      field: 'backoffTime',
      headerName: t('priceLists.ev.tableHeaders.backoffTime'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">
              {!!params.row.backoffTime
                ? `${t('priceLists.ev.backoffTimeFrom')} ${params.row.backoffTime} ${t(
                    'priceLists.ev.backoffTimeUnit',
                  )}`
                : '-'}
            </p>
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end gap-2.5">
            <button
              className="p-3"
              onClick={() => {
                navigate(routes.copyPriceList({ id: params.row.id, type: PriceListTypes.EV }));
              }}
            >
              <CopyIcon />
            </button>

            <button
              className="p-3"
              onClick={() => {
                navigate(routes.editPriceList({ id: params.row.id, type: PriceListTypes.EV }));
              }}
            >
              <EditIcon />
            </button>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useEvPriceListsTableHeaders;
