import { ITarrifsFormInputs } from '../../../pages/priceLists/addPriceList/components/parkingForm/PriceListTarrifsForm';
import { DropdownOption } from '../../../types/Dropdown.types';
import { TimeUnits } from '../../../types/api/ApiTypes';

export const formatTarrifsToDisplay = ({
  tarrifs,
  timeUnitTypes,
}: {
  tarrifs: ITarrifsFormInputs[];
  timeUnitTypes: DropdownOption[];
}) => {
  const tarrifsFormatted: ITarrifsFormInputs[] = tarrifs.map(tarrif => {
    const tarrifFormatted = {
      ...tarrif,
    };

    if (!!tarrif.appliesFrom && tarrif.appliesFrom % TimeUnits.MONTH === 0) {
      tarrifFormatted.appliesFrom = tarrif.appliesFrom / TimeUnits.MONTH;
      tarrifFormatted.appliesFromTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.MONTH.toString(),
      ) as DropdownOption;
    } else if (!!tarrif.appliesFrom && tarrif.appliesFrom % TimeUnits.DAY === 0) {
      tarrifFormatted.appliesFrom = tarrif.appliesFrom / TimeUnits.DAY;
      tarrifFormatted.appliesFromTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.DAY.toString(),
      ) as DropdownOption;
    } else if (!!tarrif.appliesFrom && tarrif.appliesFrom % TimeUnits.HOUR === 0) {
      tarrifFormatted.appliesFrom = tarrif.appliesFrom / TimeUnits.HOUR;
      tarrifFormatted.appliesFromTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.HOUR.toString(),
      ) as DropdownOption;
    }

    if (!!tarrif.appliesTo && tarrif.appliesTo % TimeUnits.MONTH === 0) {
      tarrifFormatted.appliesTo = tarrif.appliesTo / TimeUnits.MONTH;
      tarrifFormatted.appliesToTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.MONTH.toString(),
      ) as DropdownOption;
    } else if (!!tarrif.appliesTo && tarrif.appliesTo % TimeUnits.DAY === 0) {
      tarrifFormatted.appliesTo = tarrif.appliesTo / TimeUnits.DAY;
      tarrifFormatted.appliesToTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.DAY.toString(),
      ) as DropdownOption;
    } else if (!!tarrif.appliesTo && tarrif.appliesTo % TimeUnits.HOUR === 0) {
      tarrifFormatted.appliesTo = tarrif.appliesTo / TimeUnits.HOUR;
      tarrifFormatted.appliesToTimeUnit = timeUnitTypes.find(
        type => type.id === TimeUnits.HOUR.toString(),
      ) as DropdownOption;
    }

    return tarrifFormatted;
  });

  return tarrifsFormatted;
};
