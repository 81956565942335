import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LinkButon } from '../../../../components/common/buttons/LinkButton';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import ArrowRight from '../../../../components/icons/ArrowRight';
import { Logo } from '../../../../components/navigation/components/Logo';
import useLogin from '../../../../hooks/api/useLogin';
import useUser from '../../../../hooks/api/user/useUser';
import { routes } from '../../../../static/routes';
import { loginValidationSchema } from '../../../../static/validationSchema/loginValidationSchema';
import { colors } from '../../../../styles/variables';
import { ButtonSize } from '../../../../types/ButtonSizes';
import { ComponentState, ComponentStates } from '../../../../types/ComponentStates.types';

interface IFormInput {
  user: string;
  password: string;
}

type InputsKeys = keyof IFormInput;

const LoginForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, isLoading } = useLogin();
  const { refetch: refetchUser } = useUser({ enabled: false });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      user: '',
      password: '',
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({ password, user }) => {
    await login({ password, phone_number: user, username: user });
    await refetchUser();

    reset();
    navigate(routes.home);
  };

  return (
    <>
      <Logo />
      <h1 className="mt-12 text-black700">{t('login.title')}</h1>

      <p className="mt-4 text-black500 font-medium leading-snug">{t('login.description')}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        <LabeledStateInput
          id="login-user"
          state={getInputState('user')}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('login.userLabel'),
            errorMessage: t(`errorMessages.${errors.user?.message}`),
          }}
          inputProps={{
            placeholder: t('login.userPlaceholder') as string,
            register: {
              ...register('user'),
            },
          }}
        />

        <LabeledStateInput
          id="login-password"
          state={getInputState('password')}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('login.passwordLabel'),
            errorMessage: t(`errorMessages.${errors.password?.message}`),
          }}
          inputProps={{
            type: 'password',
            register: {
              ...register('password'),
            },
          }}
        />

        <PrimaryButton type="submit" disabled={isLoading}>
          {t('login.loginButton')}
        </PrimaryButton>
      </form>

      <LinkButon
        onClick={() => {
          navigate(routes.requestResetPassword);
        }}
        className="mt-11 mb-16 lg:mb-0"
        size={ButtonSize.Small}
        suffixIcon={<ArrowRight color={colors.black700} small />}
      >
        {t('login.forgotPasswordButton')}
      </LinkButon>
    </>
  );
};

export default LoginForm;
