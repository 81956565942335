import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentState, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTimeUnitTypes } from '../../../../../hooks/timeUnit/useTimeUnitTypes';
import { addPriceListParkingSchema } from '../../../../../static/validationSchema/priceList/addPriceListParkingSchema';
import { ComponentStates } from '../../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { ApiParkingPriceListRequest, PricePerTypes } from '../../../../../types/api/ApiTypes';
import { CurrencyTypes, currencyDropdownOptions } from '../../../../../types/currency/CurrencyTypes';

import { BottomActionBox } from '../../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { LabeledStateDropdown } from '../../../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../../components/common/inputs/LabeledStateInput';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import { usePricePerUnitTypes } from '../../../../../hooks/pricePer/usePricePerUnitTypes';
import useToast from '../../../../../hooks/useToast';
import { formatPrice } from '../../../../../utils/formatPrice/formatPrice';
import { transformPeriodsToTarrifs } from '../../../../../utils/priceLists/transformPeriodsToTarrifs/transformPeriodsToTarrifs';
import { transformTarrifsToPeriods } from '../../../../../utils/priceLists/transformTarrifsToPeriods/transformTarrifsToPeriods';
import { PriceListFormProps } from '../PriceListForm';
import { ITarrifsFormInputs, PriceListTarrifsForm } from './PriceListTarrifsForm';
import { ITarrifsHeaders } from './hooks/useTarrifsTableHeaders';

interface IFormInputs {
  name: string;
  currency: DropdownOption;
}

type InputsKeys = keyof IFormInputs;

interface PriceListParkingFormProps {
  priceListFormProps: PriceListFormProps;
}

export const PriceListParkingForm = ({ priceListFormProps }: PriceListParkingFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();
  const { showErrorToast } = useToast();

  const { timeUnitTypes } = useTimeUnitTypes();
  const { pricePerUnitTypes } = usePricePerUnitTypes();
  const initialValues = priceListFormProps.initialValues?.parkingValues;

  const initialTarrifs = !!initialValues?.periods
    ? transformPeriodsToTarrifs({ periods: initialValues?.periods, timeUnitTypes, pricePerUnitTypes })
    : [];

  const [tarrifs, setTarrifs] = useState<ITarrifsFormInputs[]>(initialTarrifs);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addPriceListParkingSchema),
    defaultValues: {
      name: initialValues?.name,
      currency: currencyDropdownOptions.find(option => option.id === initialValues?.currency.toString()),
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async inputs => {
    const data: ApiParkingPriceListRequest = {
      name: inputs.name,
      currency: inputs.currency.value as unknown as CurrencyTypes,
      periods: transformTarrifsToPeriods(tarrifs),
    };

    const isLastTarrifWithPeriodPricePer =
      tarrifs[tarrifs.length - 1]?.intervalTimeUnit.id === PricePerTypes.PERIOD.toString();

    if (isLastTarrifWithPeriodPricePer) {
      showErrorToast(t('priceListForm.parkingForm.lastTarrifWithPeriodPricePer'), {
        autoClose: 10000,
      });
    } else {
      priceListFormProps.onSubmitPriceListParking(data);
      reset();
    }
  };

  const mapTarrifsData: () => ITarrifsHeaders[] = () => {
    const currency = getValues('currency')?.value ?? '';

    return tarrifs.map((item, idx) => {
      const isLast = idx === tarrifs.length - 1;

      const timeIntervalFrom = `${t('priceListForm.tarrifsHeaders.from')} ${item.appliesFrom} ${t(
        `timeUnitsShort.${item.appliesFromTimeUnit.id}`,
      )}`;

      const timeIntervalTo = !!item.appliesTo
        ? ` ${t('priceListForm.tarrifsHeaders.to')} ${item.appliesTo} ${t(
            `timeUnitsShort.${item.appliesToTimeUnit.id}`,
          )}`
        : '';

      const time =
        item.intervalTimeUnit.id === pricePerUnitTypes[0]!.id
          ? t(`pricePerUnitsShort.${item.intervalTimeUnit.id}`)
          : `1 ${t(`pricePerUnitsShort.${item.intervalTimeUnit.id}`)}`;

      const price = !item.price && item.price !== 0 ? '' : `${formatPrice(item.price)} ${currency}`;

      return {
        id: item.id ?? idx,
        price,
        time,
        timeIntervals: isLast ? timeIntervalFrom : timeIntervalFrom + timeIntervalTo,
        reset: item.resetPrice,
        minuteBilling: item.minuteBilling,
      };
    });
  };

  return (
    <>
      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={2} title={t('priceListForm.name.title')} />
        <div className="lg:pl-8">
          <LabeledStateInput
            id="price-list-name"
            state={getInputState('name')}
            labeledProps={{
              wrapperClassName: 'mb-5 mt-6 max-w-xl',
              label: t('priceListForm.name.label'),
              errorMessage: t(`errorMessages.${errors.name?.message}`),
            }}
            inputProps={{
              register: {
                ...register('name'),
              },
            }}
          />
        </div>
      </div>

      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={3} title={t('priceListForm.parkingForm.currencyTitle')} />
        <div className="lg:pl-8">
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <LabeledStateDropdown
                id="currency"
                state={getInputState('currency')}
                labeledProps={{
                  wrapperClassName: 'mb-5 mt-6 lg:max-w-[12.5rem]',
                  label: t('priceListForm.parkingForm.currency'),
                  errorMessage: t(`errorMessages.${errors.currency?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('priceListForm.currencyPlaceholder'),
                  options: currencyDropdownOptions,
                  currentOption: field.value,
                  setNewOption: field.onChange,
                }}
              />
            )}
          />
        </div>
      </div>

      <PriceListTarrifsForm
        priceListFormProps={priceListFormProps}
        currentTarrifs={tarrifs}
        setCurrentTarrifs={setTarrifs}
        tableData={mapTarrifsData() ?? []}
        onSubmitForm={(values: ITarrifsFormInputs) => {
          const isTarrifWithoutEndTime = tarrifs.some(tarrif => !tarrif.appliesTo);

          if (isTarrifWithoutEndTime) {
            throw Error(t('priceListForm.parkingForm.deleteTarrifWithoutEndTime'));
          } else {
            setTarrifs(prev => [...prev, { ...values, id: tarrifs.length.toString() }]);
          }
        }}
      />

      {!isTablet && (
        <div className="absolute -top-4 right-0">
          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            className="w-fit h-fit"
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </div>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!priceListFormProps.onDeletePriceList && (
              <DeleteIconButton onClick={priceListFormProps.onDeletePriceList} />
            )}
          </div>

          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </>
  );
};
