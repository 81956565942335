import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useParkingSpot from '../../../hooks/api/parkings/useParkingSpot';
import useParkingSpotCoUsers from '../../../hooks/api/parkings/useParkingSpotCoUsers';
import useParkingSpotRules from '../../../hooks/api/parkings/useParkingSpotRules';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import EditParkingSpotForm from './components/EditParkingSpotForm';

const EditParkingSpot = () => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();
  const params = useParams();
  const spotId = params.spotId as string;

  const { parkingSpot } = useParkingSpot({ spotId });
  const { spotCoUsers } = useParkingSpotCoUsers({ spotId });
  const { spotRules } = useParkingSpotRules({ spotId });

  const extraBreadcrumbs = [
    {
      text: parkingSpot?.parkingName ?? '',
    },
  ];

  return (
    <TitleWrapper
      title={`${t('editParkingSpot.title')} ${!!parkingSpot?.parkingName ? `(${parkingSpot?.parkingName})` : ''}`}
      breadcrumbsProps={{ limit: 2, extraBreadcrumbs }}
    >
      <div className="relative lg:p-8 lg:bg-white900">
        {!isTablet && (
          <div className="flex justify-between">
            <BackButton />
          </div>
        )}

        {!!parkingSpot && <EditParkingSpotForm parkingSpot={parkingSpot} coUsers={spotCoUsers} rules={spotRules} />}
      </div>
    </TitleWrapper>
  );
};

export default EditParkingSpot;
