import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../../../components/common/modals/ConfirmModal';
import HoverableTooltip from '../../../../../../components/dataTable/components/HoverableTooltip';
import InfoIcon from '../../../../../../components/icons/InfoIcon';
import TrashIcon from '../../../../../../components/icons/TrashIcon';
import useDeleteSubscription from '../../../../../../hooks/api/parkings/zones/useDeleteSubscription';
import { colors } from '../../../../../../styles/variables';
import { Availability } from '../../../../../../types/api/ApiTypes';
import { isFullAvailability } from '../../../../../parkings/rules/utils/isFullAvailability/isFullAvailability';

interface useParkingSubscriptionsParkingTableProps {
  zoneId: string;
}

const useParkingSubscriptionsParkingTable = ({ zoneId }: useParkingSubscriptionsParkingTableProps) => {
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const { deleteSubscription } = useDeleteSubscription({ zoneId });

  const handleDelete = async (id: string) => {
    deleteSubscription(id);
    setIsDeleteModalOpen(false);
  };

  const headers = [
    {
      field: 'name',
      headerName: t('parkingDetails.parkingSubscriptions.table.name'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.name}</p>;
      },
    },

    {
      field: 'duration',
      headerName: t('parkingDetails.parkingSubscriptions.table.duration'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.duration}</p>;
      },
    },
    {
      field: 'availability',
      headerName: t('parkingDetails.parkingSubscriptions.table.availability'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const timeRestrictions: Availability[] = params.row.timeRestrictions;

        const isFull = isFullAvailability(timeRestrictions);

        return (
          <div className="my-2">
            {isFull ? (
              <p>{t('parkingDetails.parkingRules.table.fullAvailability')}</p>
            ) : (
              <div className="flex items-center gap-2">
                <p>{t('parkingDetails.parkingRules.table.notFullAvailability')}</p>
                <HoverableTooltip
                  tooltipPlacement="left"
                  tooltipContent={
                    <div>
                      {timeRestrictions.map(av => (
                        <div key={av.weekday} className="flex justify-between gap-3">
                          <p>{`${t(`weekdays.${av.weekday}`)}: `}</p>
                          <p>
                            {av.start.slice(0, 5)}-{av.end.slice(0, 5)}
                          </p>
                        </div>
                      ))}
                    </div>
                  }
                >
                  {isHovered => <InfoIcon color={isHovered ? colors.blue500 : colors.grey700} />}
                </HoverableTooltip>
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: 'requiresApproval',
      headerName: t('parkingDetails.parkingSubscriptions.table.requiresApproval'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <p className="flex align-middle">
            {params.row.requiresApproval
              ? t('parkingDetails.parkingSubscriptions.table.yes')
              : t('parkingDetails.parkingSubscriptions.table.no')}
          </p>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div className="flex justify-end w-full">
              <button
                className="p-3"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setIdToDelete(params.row.id);
                }}
              >
                <HoverableTooltip tooltipContent={<p>{t('common.delete')}</p>}>
                  {isHovered => <TrashIcon color={isHovered ? colors.blue500 : colors.grey700} />}
                </HoverableTooltip>
              </button>
            </div>
            <ConfirmModal
              title={t('parkingDetails.zones.subscriptions.delete.title')}
              confirmQuestion={t('parkingDetails.zones.subscriptions.delete.confirmQuestion')}
              isOpen={isDeleteModalOpen && idToDelete === params.row.id}
              setIsOpen={() => {
                setIsDeleteModalOpen(false);
              }}
              cancelBtnText={t('parkingDetails.zones.subscriptions.delete.cancelBtnText')}
              confirmBtnText={t('parkingDetails.zones.subscriptions.delete.confirmBtnText')}
              confirmBtnIcon={<TrashIcon color={colors.white900} />}
              confirmBtnAction={() => handleDelete(idToDelete)}
            />
          </>
        );
      },
    },
  ];

  return { headers };
};

export default useParkingSubscriptionsParkingTable;
