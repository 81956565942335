import { useParams } from 'react-router-dom';
import useParkingSpots from '../../../../hooks/api/parkings/useParkingSpots';
import ParkingSpotsView from './ParkingSpotsView';

const ParkingSpots = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;

  const { parkingSpots } = useParkingSpots({ parkingId });

  return <ParkingSpotsView spots={parkingSpots} parkingId={parkingId} />;
};

export default ParkingSpots;
