import { ChangeEvent, useRef } from 'react';

export interface CodeInputProps {
  value: string;
  setValue: (val: string) => void;
}

const CodeInput = ({ value, setValue }: CodeInputProps) => {
  const inputRefs = useRef(Array(5).fill(null));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setValue(getNewValue(index, inputValue));
    if (index < 4 && inputValue) {
      inputRefs.current[index + 1].focus();
    }
  };

  const getNewValue = (index: number, inputValue: string) => {
    const newValue = value.split('');
    newValue[index] = inputValue;
    return newValue.join('').slice(0, 5);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    const parsedNumber = parseInt(e.key);
    const isNumber = !isNaN(parsedNumber);

    if (!!value[index] && index < 4 && isNumber) {
      setValue(getNewValue(index + 1, e.key));
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className="flex gap-2.5 justify-between">
      {[...Array(5)].map((_, index) => (
        <input
          key={index}
          ref={el => (inputRefs.current[index] = el)}
          type="tel"
          maxLength={1}
          value={value[index] || ''}
          onChange={e => handleInputChange(e, index)}
          onKeyUp={e => handleKeyPress(e, index)}
          className="rounded-md border-[1.5px] border-grey500 flex items-center justify-center text-center w-14 h-14 text-base font-medium trxt-black500 leading-tight py-4"
        />
      ))}
    </div>
  );
};

export default CodeInput;
