import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import FilterDropdown from '../../../../../components/common/dropdowns/FilterDropdown';
import FilterIcon from '../../../../../components/icons/FilterIcon';
import XMarkIcon from '../../../../../components/icons/XmarkIcon';
import { partnerParkingActivityFilters } from '../../../../../static/partnerParkingActivities/partnerParkingActivityFilters';
import { partnerParkingActivityIcons } from '../../../../../static/partnerParkingActivities/partnerParkingActivityIcons';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { CallbackDefault } from '../../../../../types/Types';
import { PartnerParkingActivityWithoutAll } from '../../../../../types/partnerParkingActivities/partnerParkingActivities';

interface PartnerServiceActivitiesFilterProps {
  filter: DropdownOption;
  setFilter: Dispatch<SetStateAction<DropdownOption>>;
  onResetFilter: CallbackDefault;
  isFiltering: boolean;
}

const PartnerServiceActivitiesFilter = ({
  filter,
  setFilter,
  onResetFilter,
  isFiltering,
}: PartnerServiceActivitiesFilterProps) => {
  const { t } = useTranslation();

  const type = filter.value as PartnerParkingActivityWithoutAll;

  return (
    <div className={`flex min-h-[2.5rem] ${isFiltering && 'my-7 lg:mt-0'} lg:mb-7 relative`}>
      <div
        className={`w-fit max-lg:absolute max-lg:right-0 ${isFiltering ? 'max-lg:top-[-350%]' : 'max-lg:top-[-280%]'}`}
      >
        <FilterDropdown
          label={t('partnerParkingService.activities.filtersLabel')}
          options={partnerParkingActivityFilters}
          currentOption={filter}
          setNewOption={newOption => {
            setFilter(newOption);
          }}
          iconLeft={<FilterIcon />}
          showIndicator={isFiltering}
          translationPath="partnerParkingService.activities.transactionTypes"
        />
      </div>

      {isFiltering && filter?.value && (
        <div className={twMerge(`px-4 py-2 flex justify-center items-center rounded-md ml-2 gap-3 bg-grey200`)}>
          {partnerParkingActivityIcons[type]}
          <p className="text-black700 font-normal text-[0.8125rem]">
            {t(`partnerParkingService.activities.transactionTypes.${filter.value}`)}
          </p>
          <button onClick={onResetFilter}>
            <XMarkIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default PartnerServiceActivitiesFilter;
