import { useTranslation } from 'react-i18next';
import { PricePerTypes } from '../../types/api/ApiTypes';

export const usePricePerUnitTypes = () => {
  const { t } = useTranslation();

  const pricePerUnitTypes = [
    {
      id: PricePerTypes.PERIOD.toString(),
      value: t('pricePerUnits.period'),
    },
    {
      id: PricePerTypes.MINUTE.toString(),
      value: t('pricePerUnits.minutes'),
    },
    {
      id: PricePerTypes.HOUR.toString(),
      value: t('pricePerUnits.hours'),
    },
    {
      id: PricePerTypes.DAY.toString(),
      value: t('pricePerUnits.days'),
    },
    {
      id: PricePerTypes.MONTH.toString(),
      value: t('pricePerUnits.months'),
    },
  ];

  return { pricePerUnitTypes };
};
