import { Dispatch, SetStateAction, useState } from 'react';
import { colors } from '../../../../styles/variables';
import EditIcon from '../../../icons/EditIcon';
import IconButton from '../../buttons/IconButton';
import DescriptionForm from './components/DescriptionForm';

interface DescriptionEditorProps {
  newDescription?: string;
  setNewDescription: Dispatch<SetStateAction<string | undefined>>;
  title: string;
  placeholder: string;
  label: string;
}

const DescriptionEditor = ({
  newDescription,
  setNewDescription,
  title,
  placeholder,
  label,
}: DescriptionEditorProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="flex flex-col gap-4 grow">
      <div className="relative h-8 flex items-center">
        <h5 className="text-sm font-bold text-black700 leading-tight uppercase pr-20">{title}</h5>

        {!isEditing && (
          <IconButton
            onClick={() => setIsEditing(true)}
            icon={<EditIcon color={colors.grey700} height="16" />}
            className="bg-grey100 h-8 w-8 absolute top-0 right-0"
          />
        )}
      </div>
      {isEditing ? (
        <DescriptionForm
          initialDescription={newDescription}
          onClose={() => setIsEditing(false)}
          onSubmitForm={(description: string) => {
            setNewDescription(description);
            setIsEditing(false);
          }}
          label={label}
        />
      ) : (
        <p className="text-sm font-medium text-black400">{newDescription || placeholder}</p>
      )}
    </div>
  );
};

export default DescriptionEditor;
