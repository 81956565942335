import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import { min1NumberRegex, min1SpecialCharRegex, min1UppercaseRegex } from '../../../static/regex';
import { resetPasswordSchema } from '../../../static/validationSchema/resetPasswordSchema';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';
import PasswordCriteriaItem from './PasswordCriteriaItem';

interface IFormInput {
  password: string;
  repeatPassword: string;
}

type InputsKeys = keyof IFormInput;

interface ResetPasswordFormProps {
  onSubmitForm: (password: string) => Promise<void>;
  isLoading: boolean;
}

const ResetPasswordForm = ({ onSubmitForm, isLoading }: ResetPasswordFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ password }) => {
    await onSubmitForm(password);

    reset();
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const passwordCurrentValue = watch('password');

  return (
    <>
      <p className="mt-4 text-black500 font-medium leading-snug">{t('resetPassword.description')}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        <LabeledStateInput
          id="new-password"
          state={getInputState('password')}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('resetPassword.passwordLabel'),
            errorMessage: t(`errorMessages.${errors.password?.message}`),
          }}
          inputProps={{
            type: 'password',
            register: {
              ...register('password'),
            },
          }}
        />
        <LabeledStateInput
          id="repear-new-password"
          state={getInputState('repeatPassword')}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('resetPassword.repeatPasswordLabel'),
            errorMessage: t(`errorMessages.${errors.repeatPassword?.message}`),
          }}
          inputProps={{
            type: 'password',
            register: {
              ...register('repeatPassword'),
            },
          }}
        />

        <div className="mb-10">
          <PasswordCriteriaItem
            isSuccess={min1UppercaseRegex.test(passwordCurrentValue)}
            text={t('errorMessages.min1Uppercase')}
          />
          <PasswordCriteriaItem
            isSuccess={min1NumberRegex.test(passwordCurrentValue)}
            text={t('errorMessages.min1Number')}
          />
          <PasswordCriteriaItem
            isSuccess={min1SpecialCharRegex.test(passwordCurrentValue)}
            text={t('errorMessages.min1SpecialChar')}
          />
        </div>

        <PrimaryButton type="submit" disabled={isLoading}>
          {t('resetPassword.submitBtn')}
        </PrimaryButton>
      </form>
    </>
  );
};

export default ResetPasswordForm;
