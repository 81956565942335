import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteSpotCoUser = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ spotId, phone, groupId, isGroup }: { spotId: string; phone?: string; groupId?: number; isGroup: boolean }) =>
      axiosInstance.post(api.endpoints.REMOVE_PAKING_SPOT_COUSER({ id: spotId }), {
        phone_number: phone,
        group_id: groupId,
        is_group: isGroup,
      }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.removingSpotCoUserFailed`));
      },
    },
  );
  return { deleteCoUser: mutateAsync, isLoading };
};

export default useDeleteSpotCoUser;
