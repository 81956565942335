import { TimeInterval } from '../../../../../components/common/modals/TimeIntervalsModal';
import { isEndOfDay } from '../../../../../utils/dates/isEndOfDay/isEndOfDay';
import { isStartOfDay } from '../../../../../utils/dates/isStartOfDay/isStartOfDay';

export const isFullAvailabilityByInterval = (intervals: TimeInterval[]) => {
  return intervals.every(interval => {
    const isDayStart =
      typeof interval.time === 'object' && interval.time.length === 1 && isStartOfDay(interval.time?.[0]?.from);
    const isDayEnd =
      typeof interval.time === 'object' && interval.time.length === 1 && isEndOfDay(interval.time?.[0]?.to);

    return isDayStart && isDayEnd;
  });
};
