import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SearchBar from '../common/searchBar/SearchBar';
import HamburgerOpen from '../icons/HamburgerOpen';
import XMarkIcon from '../icons/XmarkIcon';
import LanguageSwitcher from './components/LanguageSwitcher';
import { Logo } from './components/Logo';

interface NavbarMobileProps {
  buttonsTop: React.ReactNode;
  buttonsBottom: React.JSX.Element;
}

export const NavbarMobile = ({ buttonsTop, buttonsBottom }: NavbarMobileProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const closeNavbar = () => setIsOpen(false);

  return (
    <nav
      className={twMerge(`fixed top-0 left-0 z-10 w-full flex flex-col  ${isOpen ? 'h-screen' : 'h-20'} bg-white900`)}
    >
      <div className="flex items-center justify-between w-full min-h-[5rem] max-h-[5rem] px-4 border-b-2 border-grey200 bg-white900 mb-6 grow">
        {isHeaderVisible && <Logo width={105} />}

        <div className={`flex items-center ${!isHeaderVisible && 'grow'}`}>
          <SearchBar onOpen={() => setIsHeaderVisible(false)} onClose={() => setIsHeaderVisible(true)} showCancelBtn />

          {isHeaderVisible && (
            <button onClick={() => setIsOpen(prev => !prev)} className="flex justify-end p-4">
              {isOpen ? <XMarkIcon /> : <HamburgerOpen />}
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col justify-between gap-4 overflow-auto mx-9 grow pb-9">
          <div onClick={closeNavbar} className="flex flex-col gap-1 mb-10 rounded-md bg-white700">
            {buttonsTop}
          </div>

          <div>
            <div onClick={closeNavbar} className="flex flex-col gap-1 mb-10">
              {buttonsBottom}
            </div>
            <LanguageSwitcher />
          </div>
        </div>
      )}
    </nav>
  );
};
