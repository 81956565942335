import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../static/api';
import { routes } from '../../../static/routes';
import { removeResetPasswordToken } from '../../../utils/cookieHelpers/cookieHelpers';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useResetPassword = () => {
  const { t } = useTranslation();
  const { showErrorToast, showSuccessToast } = useToast();
  const navigate = useNavigate();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ phone, password, token }: { phone: string; password: string; token: string }) =>
      axiosInstance.post(api.endpoints.RESET_PASSWORD, { phone_number: phone, password, token }),
    {
      onSuccess: async () => {
        showSuccessToast(t('successMessages.passwordChanged'));
        removeResetPasswordToken();
        navigate(routes.login);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.passwordChangingFailed'));
      },
    },
  );

  return { resetPassword: mutateAsync, isLoading };
};

export default useResetPassword;
