import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useUserStatistics from '../../hooks/api/user/wallet/useUserStatistics';
import useUserWallet from '../../hooks/api/user/wallet/useUserWallet';
import { DashboardSidebar } from './components/DashboardSidebar';
import { TransactionCards } from './components/TransactionCards';
import { DashboardFilters } from './components/filters/DashboardFilters';
import {
  IFilterPeriod,
  IFilterType,
  useDashboardFiltersOptions,
} from './components/filters/useDashboardFiltersOptions';
import { DashboardStatistics } from './components/statistics/DashboardStatistics';

const Dashboard = () => {
  const { t } = useTranslation();

  const { typeDropdownOptions, periodDropdownOptions } = useDashboardFiltersOptions();

  const [type, setType] = useState(typeDropdownOptions[0]);
  const [period, setPeriod] = useState(periodDropdownOptions[0]);

  const filters = (
    <DashboardFilters
      currentTypeOption={type}
      currentPeriodOption={period}
      setCurrentTypeOption={option => setType(option)}
      setCurrentPeriodOption={option => setPeriod(option)}
    />
  );

  const { userStatistics } = useUserStatistics({ period: period?.id, type: type?.id });
  const { userWallet } = useUserWallet({ period: period?.id, type: type?.id });

  return (
    <TitleWrapper
      title={t('dashboard.title')}
      sideComponent={filters}
      hideBreadcrumbs={true}
      innerClassName="flex-col gap-6 lg:flex-row"
    >
      <div className="relative flex flex-col w-full gap-4 mt-12 lg:flex-row">
        <div className="flex flex-col w-full gap-4 xl:w-2/3 lg:w-3/5">
          <TransactionCards income={userWallet?.totalIncome} transactionCount={userWallet?.transactionCount} />
          <DashboardStatistics
            userStatistics={userStatistics}
            period={period?.id as IFilterPeriod}
            type={type?.id as IFilterType}
          />
        </div>
        <div className="w-full xl:w-1/3 lg:w-2/5">
          <DashboardSidebar balance={userWallet?.balance} />
        </div>
      </div>
    </TitleWrapper>
  );
};

export default Dashboard;
