import { Tooltip } from '@mui/material';

interface MyTooltipProps {
  content: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
}

const MyTooltip = ({ content, children }: MyTooltipProps) => {
  return (
    <Tooltip title={content} placement="top" arrow>
      {children}
    </Tooltip>
  );
};

export default MyTooltip;
