import { ApiCoUser, ICoUser } from '../../../types/api/ApiTypes';

export const transformBasicUser = (apiBasicUser: ApiCoUser): ICoUser => {
  return {
    id: apiBasicUser.id,
    phoneNumber: apiBasicUser.phone_number,
    groupId: apiBasicUser.group_id,
    groupName: apiBasicUser.group_name,
    permissions: apiBasicUser.permissions,
    isGroup: apiBasicUser.is_group,
    firstName: apiBasicUser.first_name,
    lastName: apiBasicUser.last_name,
    numberOfUsers: apiBasicUser.number_of_users,
  };
};
