import { connectorStatuses } from '../../static/chargers/chargerConnectorStatuses';

const findConnectorStatus = (status: string) => {
  const { available, unavailable, preparing, charging, finishing, suspendedEV } = connectorStatuses;
  const turnOnStates = [available.value, preparing.value];
  const turnOffStates = [charging.value, finishing.value, suspendedEV.value];
  const turnOff = turnOffStates.some(state => state === status);
  const turnOn = turnOnStates.some(state => state === status);
  const disable = status === unavailable.value;
  return { turnOn, turnOff, disable };
};

export default findConnectorStatus;
