import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useSingleParking from '../../../hooks/api/parkings/useSingleParking';
import { routes } from '../../../static/routes';

interface ParkingAdvancedWrapperProps {
  children: React.ReactNode;
}

const ParkingAdvancedWrapper = ({ children }: ParkingAdvancedWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();

  const parkingId = params.parkingId as string;

  const { parking } = useSingleParking({ parkingId });

  const extraBreadcrumbs = [
    {
      text: parking?.name ?? '',
    },
  ];

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('parkingDetails.zones.title'),
      routeTo: routes.parkingAdvanced.zones(parkingId),
    },
    {
      text: t('parkingDetails.generalInfo.title'),
      routeTo: routes.parkingAdvanced.generalInfo(parkingId),
    },
    {
      text: t('parkingDetails.devices.title'),
      routeTo: routes.parkingAdvanced.devices(parkingId),
    },
  ];

  return (
    <TitleWrapper
      title={`${t('parkingDetails.title')}${!!parking?.name ? ` (${parking.name})` : ''}`}
      breadcrumbsProps={{ limit: 2, extraBreadcrumbs }}
    >
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />

      <div className="px-0 mt-6 mb-40 bg-white900 lg:overflow-y-scroll lg:no-scrollbar lg:mb-0">{children}</div>
    </TitleWrapper>
  );
};

export default ParkingAdvancedWrapper;
