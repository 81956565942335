import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useToggleChargerConnectorAvailability = () => {
  const axiosInstance = useAxiosInstance();
  const { id } = useParams();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync } = useMutation(
    ({ status, connectorId }: { status: boolean; connectorId: string }) =>
      axiosInstance.post(api.endpoints.SINGLE_CHARGER.CONNECTOR_ON_OFF(connectorId), { status }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.chargers.changeConnectorStateFailed`));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([`${QueryAliases.CHARGER_DETAILS}-${id}`]);
      },
    },
  );

  const changeChargerConnectorAvailability = async (state: boolean, connectorId: string) => {
    await mutateAsync({ status: state, connectorId });
  };

  return { changeChargerConnectorAvailability };
};

export default useToggleChargerConnectorAvailability;
