import { ApiChargingRule, IChargingRule } from '../../../types/api/ApiTypes';
import { transformChargingRuleGroup } from '../transformChargerRuleGroup/transformChargerRuleGroup';

export const transformChargingRule = (rule: ApiChargingRule): IChargingRule => {
  const transformedRule: IChargingRule = {
    id: rule.id,
    name: rule.name,
    connectors: rule.connectors,
    groups: rule.groups?.map(item => transformChargingRuleGroup(item)),
    priceList: rule.price_list,
  };

  return transformedRule;
};
