import { twMerge } from 'tailwind-merge';

interface BottomActionBoxProps {
  children: React.ReactNode;
  className?: string;
}

export const BottomActionBox = ({ children, className }: BottomActionBoxProps) => {
  return (
    <div
      className={twMerge(
        `fixed bottom-0 left-0 w-full max-w-screen border-t-2 px-4 pt-4 pb-8 flex flex-col gap-3 bg-white900 z-10 ${className}`,
      )}
    >
      {children}
    </div>
  );
};
