import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import LightBorderMobileCard from '../../../../../components/common/cards/LightBorderMobileCard';
import DescriptionEditor from '../../../../../components/common/detailsPageComponents/descriptionEditor/DescriptionEditor';
import MultiImagePicker from '../../../../../components/common/detailsPageComponents/multiImagePicker/MultiImagePicker';
import RegulationPicker from '../../../../../components/common/detailsPageComponents/regulationPicker/RegulationPicker';
import SingleImagePicker from '../../../../../components/common/detailsPageComponents/singleImagePicker/SingleImagePicker';
import LocationIcon from '../../../../../components/icons/LocationIcon';
import SaveIcon from '../../../../../components/icons/SaveIcon';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import { ButtonSize } from '../../../../../types/ButtonSizes';
import { IParkingDetails } from '../../../../../types/api/ApiTypes';
import ParkingAdvancedWrapper from '../../ParkingAdvancedWrapper';
import useGeneralInfoForm from '../hooks/useGeneralInfoForm';

const numOfGalleryImages = 4;

interface GeneralInfoViewProps {
  parkingId: string;
  parking: IParkingDetails;
}

const GeneralInfoView = ({ parkingId, parking }: GeneralInfoViewProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const {
    oldRegulation,
    setOldRegulation,
    selectedRegulation,
    setSelectedRegulation,
    oldMainImageUrl,
    setOldMainImageUrl,
    selectedNewMainImg,
    setSelectedNewMainImg,
    oldGalleryUrls,
    setOldGalleryUrls,
    selectedGalleryFiles,
    setSelectedGalleryFiles,
    newDescription,
    setNewDescription,
    onSubmitForm,
    isLoading,
  } = useGeneralInfoForm({ parkingId, parking, numOfGalleryImages });

  const googleMapsUrl = `https://www.google.com/maps?q=${parking?.latitude},${parking?.longitude}`;

  const isSubmitBtnDisabled = isLoading;
  const submitBtnText = t('parkingDetails.generalInfo.submitBtn');
  const submitBtnIcon = <SaveIcon />;

  return (
    <ParkingAdvancedWrapper>
      <LightBorderMobileCard className="lg:p-8">
        <div className="flex flex-col justify-between gap-12 lg:flex-row grow lg:mt-10">
          <div className="lg:max-w-[20rem] w-full">
            <RegulationPicker
              headerText={t('parkingDetails.generalInfo.regulationTitle')}
              oldRegulation={oldRegulation}
              removeOldRegulation={() => setOldRegulation(null)}
              selectedFile={selectedRegulation}
              setSelectedFile={setSelectedRegulation}
            />

            <div className="mt-8">
              <h5 className="pr-20 text-sm font-bold leading-tight uppercase text-black700">
                {t('parkingDetails.generalInfo.address.title')}
              </h5>
              <div className="mt-1.5">
                <p className="text-sm font-medium text-black400">{parking?.address.addressLine1}</p>
                <p className="text-sm font-medium text-black400">{`${parking?.address.postCode}, ${parking?.address.city}`}</p>
                <p className="text-sm font-medium text-black400">{t(`countries.${parking?.address.country}`)}</p>
              </div>

              <h5 className="pr-20 mt-8 text-sm font-bold leading-tight uppercase text-black700">
                {t('parkingDetails.zones.generalInfo.latitude')}
              </h5>
              <p className="text-sm font-medium text-black400">{parking?.latitude.toFixed(6)}</p>

              <h5 className="pr-20 mt-8 text-sm font-bold leading-tight uppercase text-black700">
                {t('parkingDetails.zones.generalInfo.longitude')}
              </h5>
              <p className="text-sm font-medium text-black400">{parking?.longitude.toFixed(6)}</p>

              <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                <SecondaryButton className="mt-8 w-fit" size={ButtonSize.Medium} prefixIcon={<LocationIcon />}>
                  {t('parkingDetails.generalInfo.address.showOnMap')}
                </SecondaryButton>
              </a>
            </div>
          </div>

          <div className="lg:max-w-[20rem] grow w-full flex flex-col gap-8">
            <SingleImagePicker
              title={t('parkingDetails.generalInfo.mainImage.title')}
              subtitle={t('parkingDetails.generalInfo.mainImage.subtitle')}
              oldImageUrl={oldMainImageUrl}
              removeOldImage={() => setOldMainImageUrl(null)}
              selectedFile={selectedNewMainImg}
              setSelectedFile={setSelectedNewMainImg}
            />

            <MultiImagePicker
              numOfImages={numOfGalleryImages}
              title={t('parkingDetails.generalInfo.gallery.title')}
              subtitle={t('parkingDetails.generalInfo.gallery.subtitle')}
              oldImageUrls={oldGalleryUrls}
              removeOldImage={id => {
                setOldGalleryUrls(prev => prev.filter(image => image.id !== id));
              }}
              selectedFiles={selectedGalleryFiles}
              setSelectedFiles={setSelectedGalleryFiles}
            />
          </div>

          <div className="grow max-w-lg flex-col">
            <DescriptionEditor
              newDescription={newDescription}
              setNewDescription={setNewDescription}
              title={t('parkingDetails.generalInfo.description.title')}
              placeholder={t('parkingDetails.generalInfo.description.placeholder')}
              label={t('parkingDetails.generalInfo.description.label')}
            />

            {!isTablet && (
              <PrimaryButton
                className="mt-4 w-fit ml-auto"
                disabled={isSubmitBtnDisabled}
                onClick={onSubmitForm}
                prefixIcon={submitBtnIcon}
              >
                {submitBtnText}
              </PrimaryButton>
            )}
          </div>

          {isTablet && (
            <BottomActionBox>
              <PrimaryButton disabled={isSubmitBtnDisabled} onClick={onSubmitForm} prefixIcon={submitBtnIcon}>
                {submitBtnText}
              </PrimaryButton>
            </BottomActionBox>
          )}
        </div>
      </LightBorderMobileCard>
    </ParkingAdvancedWrapper>
  );
};

export default GeneralInfoView;
