import { ApiParkingCalendar, IParkingCalendar, ParkingCalendarEntitlementReason } from '../../../types/api/ApiTypes';

// unfortunately, we need to switch start with event_start and end with event_end for better calendar work (it's a library thing)
export const transformParkingCalendar = (apiParkingCalendar: ApiParkingCalendar): IParkingCalendar => {
  return {
    id: apiParkingCalendar.id,
    eventID: apiParkingCalendar.event_id,
    start: apiParkingCalendar.event_start,
    end: apiParkingCalendar.event_end,
    user: {
      id: apiParkingCalendar.user.id,
      firstName: apiParkingCalendar.user.first_name,
      lastName: apiParkingCalendar.user.last_name,
      phoneNumber: apiParkingCalendar.user.phone_number,
    },
    parkingSpotId: apiParkingCalendar.parking_spot_id,
    parkingSpotName: apiParkingCalendar.parking_spot_name,
    resource: apiParkingCalendar.parking_spot_id,
    licensePlate: apiParkingCalendar.license_plate,
    entitlementReason: apiParkingCalendar.entitlement_reason,
    eventStart: apiParkingCalendar.start,
    eventEnd: apiParkingCalendar.end,
    colorClass:
      apiParkingCalendar.entitlement_reason === ParkingCalendarEntitlementReason.ParkingReservation
        ? 'bg-blue700'
        : 'bg-orange700',
    color:
      apiParkingCalendar.entitlement_reason === ParkingCalendarEntitlementReason.ParkingReservation
        ? '#175A93'
        : '#E5742E',
  };
};
