import { CalendarMonth } from '@mui/icons-material';
import FinishedIcon from '../../components/icons/FinishedIcon';
import LoadingIcon from '../../components/icons/LoadingIcon';
import { EventStatus } from '../../types/eventStatuses/eventStatuses';

export const eventStatusesStyles: Record<EventStatus, { textColor: string; icon: React.ReactNode }> = Object.freeze({
  ONGOING: {
    textColor: 'text-success',
    icon: <LoadingIcon />,
  },
  FINISHED: {
    textColor: 'text-error',
    icon: <FinishedIcon />,
  },
  UPCOMING: {
    textColor: 'text-grey700',
    icon: <CalendarMonth />,
  },
});
