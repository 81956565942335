import { Listbox } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { colors } from '../../../styles/variables';
import { DropdownOption, DropdownStyleProps } from '../../../types/Dropdown.types';

export interface DropdownProps extends DropdownStyleProps {
  options: DropdownOption[];
  currentOption: DropdownOption | undefined;
  setNewOption: (option: DropdownOption) => void;
  buttonDataTestId?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const Dropdown = ({
  options,
  currentOption,
  setNewOption,
  wrapperClassName,
  buttonClassName,
  optionsClassName,
  optionClassName,
  buttonDataTestId,
  disabled,
  placeholder,
}: DropdownProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        `relative outline outline-1.5 outline-grey500  rounded-md px-4 py-[1.1875rem] text-sm text-black500 font-medium w-full placeholder:text-grey500 focus:outline-2 focus:outline-blue500 ${
          disabled && 'outline-grey300 text-grey500'
        } ${wrapperClassName}`,
      )}
    >
      <Listbox value={currentOption} onChange={setNewOption} by="id" disabled={disabled}>
        {({ open }) => (
          <>
            <Listbox.Button
              data-testid={buttonDataTestId}
              className={twMerge(`w-full flex justify-between items-center ${buttonClassName}`)}
            >
              <p
                className={
                  placeholder && !currentOption?.value ? 'text-grey500 whitespace-nowrap' : 'whitespace-nowrap'
                }
              >
                {currentOption?.value ?? placeholder}
              </p>
              {open ? (
                <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
              ) : (
                <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
              )}
            </Listbox.Button>
            <Listbox.Options
              className={twMerge(
                `absolute top-16 w-full left-0 rounded-md max-h-60 overflow-auto ring-2 ring-grey500 pt-2 border-grey500 bg-white z-50 ${
                  optionsClassName ?? ''
                }`,
              )}
            >
              {options?.length > 0 ? (
                options.map(option => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    className={twMerge(`cursor-pointer ${optionClassName ?? ''}`)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center gap-2 py-2 pl-4">
                        <input type="radio" checked={selected} readOnly className="w-4 h-4 cursor-pointer" />
                        <p className="w-full">{option.value}</p>
                      </div>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <li className={twMerge(`py-2 px-3 ${optionClassName}`)}>{t('errorMessages.noData')}</li>
              )}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Dropdown;
