import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../../static/api';
import { getApiErrorMessage } from '../../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';

const multipartHeaders = {
  'Content-Type': 'multipart/form-data',
};

const useEditParkingZone = () => {
  const { showErrorToast } = useToast();
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const uploadPdf = async ({ zoneId, file }: { zoneId: string; file: File }) => {
    const formData = new FormData();
    formData.append('file_name', file.name);
    formData.append('file', file);

    const response = await axiosInstance.post(api.endpoints.SINGLE_PARKING_ZONE_PDFS({ id: zoneId }), formData, {
      headers: multipartHeaders,
    });

    return response.data;
  };

  const { mutateAsync: uploadRegulation, isLoading: isLoadingUploadRegulation } = useMutation(uploadPdf, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err);

      showErrorToast(errorMessage ?? t(`errorMessages.parkingRegulationUploadFailed`));
    },
  });

  const { mutateAsync: updateParkingZone, isLoading: isLoadingUpdateParkingZone } = useMutation(
    ({
      zoneId,
      body,
    }: {
      zoneId: string;
      body: {
        description: string;
      };
    }) =>
      axiosInstance.patch(api.endpoints.SINGLE_PARKING_ZONE({ id: zoneId }), {
        ...body,
      }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editingParkingFailed`));
      },
    },
  );

  const isLoading = isLoadingUploadRegulation || isLoadingUpdateParkingZone;

  return {
    uploadRegulation,
    updateParkingZone,
    isLoading,
  };
};

export default useEditParkingZone;
