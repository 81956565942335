import { ApiParkingSpotDetailsByParking, IParkingSpotDetailsByParking } from '../../../types/api/ApiTypes';

export const transformParkingSpotDetailsByParking = (
  apiParkingSpot: ApiParkingSpotDetailsByParking,
): IParkingSpotDetailsByParking => {
  const transformedParkingSpot: IParkingSpotDetailsByParking = {
    id: apiParkingSpot.id,
    parking: apiParkingSpot.parking,
    parkingName: apiParkingSpot.parking_name,
    parkingZone: apiParkingSpot.parking_zone,
    parkingZoneName: apiParkingSpot.parking_zone_name,
    parkingSpotNumber: apiParkingSpot.parking_spot_number,
    createdAt: apiParkingSpot.created_at,
    updatedAt: apiParkingSpot.updated_at,
    owner: {
      phoneNumber: apiParkingSpot?.owner?.phone_number,
      email: apiParkingSpot?.owner?.email ?? '',
    },
    hasImages: apiParkingSpot.has_images,
    hasCoUsers: apiParkingSpot.has_co_users,
    hasPdfs: apiParkingSpot.has_pdfs,
    hasRules: apiParkingSpot.has_rules,
    permissions: {
      canEdit: apiParkingSpot.permissions.can_edit,
      canDelete: apiParkingSpot.permissions.can_delete,
    },
  };

  return transformedParkingSpot;
};
