import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

interface EditChargingRuleProps {
  name?: string;
  price_list_id?: string;
  connector_ids?: string[];
  group_ids?: string[];
}

interface IUseEditChargingRuleProps {
  chargerId: string;
  ruleId: string;
}

const useEditChargingRule = ({ chargerId, ruleId }: IUseEditChargingRuleProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    (body: EditChargingRuleProps) =>
      axiosInstance.put(api.endpoints.CHARGING_RULE({ id: chargerId, ruleId: ruleId }), body),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.chargers.editChargingRuleFailed`));
      },
    },
  );

  return { editChargingRule: mutateAsync, isLoading };
};

export default useEditChargingRule;
