import { useParams } from 'react-router-dom';
import useSingleZoneParkingSpots from '../../../../../hooks/api/parkings/zones/useParkingSpots';
import SingleZoneParkingSpotsView from './ParkingSpotsView';

const SingleZoneParkingSpots = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;

  const { parkingSpots } = useSingleZoneParkingSpots({ zoneId });

  return <SingleZoneParkingSpotsView spots={parkingSpots} parkingId={parkingId} zoneId={zoneId} />;
};

export default SingleZoneParkingSpots;
