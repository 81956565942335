import { useParams } from 'react-router-dom';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import useParkingEntryDevices from '../../../../hooks/api/parkings/useParkingsDevices';
import ParkingAdvancedWrapper from '../ParkingAdvancedWrapper';
import useParkingDevicesTable from './hooks/useParkingDevicesTable';

const Devices = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;

  const { entryDevices } = useParkingEntryDevices({ parkingId });

  const { headers } = useParkingDevicesTable({
    parkingId,
  });

  return (
    <ParkingAdvancedWrapper>
      <div className="mt-12">
        <MyDataTable headers={headers} data={entryDevices} />
      </div>
    </ParkingAdvancedWrapper>
  );
};

export default Devices;
