import useUpdateUser from '../../../../../hooks/api/user/useUpdateUser';
import useUser from '../../../../../hooks/api/user/useUser';
import { queryClient } from '../../../../../queryClient';
import { ApiUserRequest } from '../../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../../types/queries/QueryAliases';
import UserForm from './components/UserForm';

const UserInfo = () => {
  const { user } = useUser();
  const { uploadAvatar, isLoadingUploadAvatar, updateUser, isLoadingUpdateUser } = useUpdateUser();

  const handleSubmitForm = async ({ newUser, file }: { newUser: ApiUserRequest; file: File | null }) => {
    if (!!file) {
      await uploadAvatar(file);
    }

    await updateUser(newUser);

    queryClient.invalidateQueries(QueryAliases.USER_DETAILS);
  };

  return !!user ? (
    <UserForm
      user={user}
      onSubmitForm={handleSubmitForm}
      isSubmitBtnDisabled={isLoadingUploadAvatar || isLoadingUpdateUser}
    />
  ) : null;
};

export default UserInfo;
