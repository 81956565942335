import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/navigation/components/Logo';
import AuthWrapper from '../../components/wrappers/authWrapper/AuthWrapper';
import useRequestPasswordReset from '../../hooks/api/auth/useRequestPasswordReset';
import useVerifyPasswordReset from '../../hooks/api/auth/useVerifyPasswordReset';
import { routes } from '../../static/routes';
import { setResetPasswordToken } from '../../utils/cookieHelpers/cookieHelpers';
import RequestResetPasswordCodeForm from './components/RequestResetPasswordCodeForm';
import RequestResetPasswordPhoneForm from './components/RequestResetPasswordPhoneForm';

enum RequestStep {
  EnterPhone = 'enter-phone',
  EnterCode = 'enter-code',
}

const RequestResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [requestStep, setRequestStep] = useState(RequestStep.EnterPhone);
  const [phone, setPhone] = useState('');

  const { requestPasswordReset, isLoading: isLoadingRequestReset } = useRequestPasswordReset();
  const { verifyPasswordReset, isLoading: isLoadingVerify } = useVerifyPasswordReset();

  const handleRequestPasswordReset = async (phone: string) => {
    await requestPasswordReset(phone);

    setPhone(phone);
    setRequestStep(RequestStep.EnterCode);
  };

  const handleResetPassword = async (code: string) => {
    const res = await verifyPasswordReset({ phone, code });
    const data: { token: string } = res.data;

    setResetPasswordToken(data.token);
    navigate(routes.resetPassword({ phone }));
  };

  return (
    <AuthWrapper>
      <Logo />
      <h1 className="mt-12 text-black700">{t('requestResetPassword.title')}</h1>

      {requestStep === RequestStep.EnterPhone && (
        <RequestResetPasswordPhoneForm onSubmitForm={handleRequestPasswordReset} isLoading={isLoadingRequestReset} />
      )}
      {requestStep === RequestStep.EnterCode && (
        <RequestResetPasswordCodeForm onSubmitForm={handleResetPassword} isLoading={isLoadingVerify} />
      )}
    </AuthWrapper>
  );
};

export default RequestResetPassword;
