import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BorderCard from '../../../../../components/common/cards/BorderCard';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import FaqButtons from './FaqButtons';
import FaqItems from './FaqItems';

export enum FaqPage {
  App = 'app',
  GateModule = 'gateModule',
  Parking = 'parking',
}

export interface FaqItem {
  question: string;
  answer: string;
}

const Faq = () => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const [activePage, setActivePage] = useState<FaqPage>(FaqPage.App);

  const applicationItems: FaqItem[] = t('userAccount.help.faq.application.items', {
    returnObjects: true,
  });
  const gateModuleItems: FaqItem[] = t('userAccount.help.faq.gateModule.items', {
    returnObjects: true,
  });
  const parkingItems: FaqItem[] = t('userAccount.help.faq.parking.items', {
    returnObjects: true,
  });

  return (
    <>
      {isTablet && (
        <FaqButtons
          className="flex gap-1.5 overflow-x-scroll no-scrollbar mt-6"
          activePage={activePage}
          setActivePage={setActivePage}
        />
      )}

      <BorderCard className="flex gap-16 mt-8 lg:mb-8">
        {!isTablet && (
          <FaqButtons
            className="flex flex-col gap-1 min-w-[200px]"
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}

        <div>
          {activePage === FaqPage.App && <FaqItems items={applicationItems} />}
          {activePage === FaqPage.GateModule && <FaqItems items={gateModuleItems} />}
          {activePage === FaqPage.Parking && <FaqItems items={parkingItems} />}
        </div>
      </BorderCard>
    </>
  );
};

export default Faq;
