import { ApiRuleGroup, IRuleGroup } from '../../../types/api/ApiTypes';

export const transformChargingRuleGroup = (apiGroup: ApiRuleGroup): IRuleGroup => {
  const transformedRuleGroup = {
    id: apiGroup.id,
    name: apiGroup.name,
    userCount: apiGroup.user_count,
    inUse: apiGroup.in_use,
    permissions: apiGroup.permissions,
  };

  return transformedRuleGroup;
};
