import useUser from '../hooks/api/user/useUser';
import { UserType, UserTypes } from '../types/user/userTypes';
import AdminRoutes from './AdminRoutes';
import PartnerRoutes from './PartnerRoutes';

const routeOptions = [
  {
    userType: UserTypes.PARTNER,
    element: <PartnerRoutes />,
  },
  {
    userType: UserTypes.ADMIN,
    element: <AdminRoutes />,
  },
];

const getRoutesByUserType = (userType: UserType) => {
  return routeOptions.find(option => option.userType === userType)?.element;
};

const AppRoutes = () => {
  const { user } = useUser();
  const userType: UserType = !!user?.partner ? UserTypes.PARTNER : UserTypes.ADMIN;

  const userRoutes = getRoutesByUserType(userType);

  return userRoutes ?? <></>;
};

export default AppRoutes;
