import dayjs from 'dayjs';

export const isStartOfDay = (date: Date | null | undefined) => {
  if (!date) {
    return false;
  }

  const startTime = dayjs(date).format('HH:mm');
  return startTime === '00:00' || startTime === '00:00:00';
};
