import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../queryClient';
import { api } from '../../../../static/api';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';

interface useDeleteSubscriptionProps {
  zoneId: string;
}

const useDeleteSubscription = ({ zoneId }: useDeleteSubscriptionProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    (id: string) =>
      axiosInstance.delete(api.endpoints.ZONES_SUBSCRIPTIONS({ id }), { data: { parking_zone_ids: [zoneId] } }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: [`${QueryAliases.SINGLE_PARKING_ZONE_SUBSCRIPTIONS}-${zoneId}`] });
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.deleteZoneSubscriptionFailed'));
      },
    },
  );
  return { deleteSubscription: mutateAsync, isLoading };
};

export default useDeleteSubscription;
