import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../components/common/buttons/IconButton';
import LightBorderMobileCard from '../../../../components/common/cards/LightBorderMobileCard';
import DescriptionEditor from '../../../../components/common/detailsPageComponents/descriptionEditor/DescriptionEditor';
import RegulationPicker from '../../../../components/common/detailsPageComponents/regulationPicker/RegulationPicker';
import SingleImagePicker from '../../../../components/common/detailsPageComponents/singleImagePicker/SingleImagePicker';
import EditIcon from '../../../../components/icons/EditIcon';
import { colors } from '../../../../styles/variables';
import { ICoUser, IParkingSpot, IParkingSpotRule } from '../../../../types/api/ApiTypes';
import useEditSpotForm from '../hooks/useEditSpotForm';
import ParkingSpotCoUsers from './ParkingSpotCoUsers';
import ParkingSpotNameForm from './ParkingSpotNameForm';
import ParkingSpotRules from './ParkingSpotRules';

interface EditParkingSpotFormProps {
  parkingSpot: IParkingSpot;
  coUsers: ICoUser[];
  rules: IParkingSpotRule[] | undefined;
}

const EditParkingSpotForm = ({ parkingSpot, coUsers, rules }: EditParkingSpotFormProps) => {
  const { t } = useTranslation();

  const {
    newName,
    selectedRegulation,
    setSelectedRegulation,
    oldImageUrl,
    setOldImageUrl,
    oldRegulation,
    setOldRegulation,
    selectedNewImg,
    setSelectedNewImg,
    newDescription,
    setNewDescription,
    internalCoUsers,
    handleName,
    handleImages,
    handleRegulations,
    handleDescription,
    handleDeleteCoUser,
    handleAddCoUser,
    handleAddRule,
    handleDeleteRule,
    internalRules,
  } = useEditSpotForm({ parkingSpot, coUsers, rules });

  const [isEditingName, setIsEditingName] = useState(false);
  useEffect(() => {
    handleDescription();
  }, [newDescription]);

  useEffect(() => {
    handleImages();
  }, [selectedNewImg, oldImageUrl]);

  useEffect(() => {
    handleRegulations();
  }, [selectedRegulation, oldRegulation]);

  return (
    <>
      <LightBorderMobileCard className="flex flex-col gap-12 bg-white900 lg:bg-transparent p-[1.875rem] mb-40 lg:p-0 lg:mt-12">
        {!!parkingSpot && (
          <>
            <div className="flex flex-col gap-8 lg:gap-4 ">
              <div className="relative h-8 flex items-center justify-start gap-4">
                <h5 className="text-sm font-bold text-black700 leading-tight uppercase">
                  {t('editParkingSpot.name.title')}
                </h5>

                {!isEditingName && (
                  <IconButton
                    onClick={() => setIsEditingName(true)}
                    icon={<EditIcon color={colors.grey700} height="16" />}
                    className="bg-grey100 h-8 w-8 absolute top-0 right-0 lg:static"
                  />
                )}
              </div>
              {isEditingName ? (
                <ParkingSpotNameForm
                  initialName={newName}
                  onClose={() => setIsEditingName(false)}
                  onSubmitForm={async (name: string) => {
                    await handleName(name);
                    setIsEditingName(false);
                  }}
                />
              ) : (
                <p className="text-sm font-medium text-black400">{newName}</p>
              )}
            </div>

            <div className="flex flex-col gap-12 lg:flex-row lg:justify-between">
              <div className="flex flex-col gap-8 lg:gap-4 ">
                <RegulationPicker
                  headerText={t('editParkingSpot.regulation.title')}
                  oldRegulation={oldRegulation}
                  removeOldRegulation={() => setOldRegulation(null)}
                  selectedFile={selectedRegulation}
                  setSelectedFile={setSelectedRegulation}
                />
              </div>

              <SingleImagePicker
                title={t('editParkingSpot.image.title')}
                subtitle={t('editParkingSpot.image.subtitle')}
                oldImageUrl={oldImageUrl}
                removeOldImage={() => setOldImageUrl(null)}
                selectedFile={selectedNewImg}
                setSelectedFile={setSelectedNewImg}
              />

              <div className="grow max-w-lg flex-col">
                <DescriptionEditor
                  newDescription={newDescription}
                  setNewDescription={setNewDescription}
                  title={t('editParkingSpot.description.title')}
                  placeholder={t('editParkingSpot.description.placeholder')}
                  label={t('editParkingSpot.description.label')}
                />
              </div>
            </div>
          </>
        )}

        {!!coUsers && (
          <div>
            <h5 className="text-sm font-bold text-black700 leading-tight uppercase mb-8 lg:mb-4">
              {t('editParkingSpot.cousers.title')}
            </h5>

            <ParkingSpotCoUsers
              coUsers={internalCoUsers}
              onDeleteCoUser={handleDeleteCoUser}
              onAddCoUser={handleAddCoUser}
            />
          </div>
        )}

        {!!rules && (
          <div>
            <h5 className="text-sm font-bold text-black700 leading-tight uppercase mb-8 lg:mb-4">
              {t('editParkingSpot.rules.title')}
            </h5>

            <ParkingSpotRules
              parkingId={parkingSpot.parking}
              rules={internalRules}
              onDeleteRule={handleDeleteRule}
              onAddRule={handleAddRule}
            />
          </div>
        )}
      </LightBorderMobileCard>
    </>
  );
};

export default EditParkingSpotForm;
