import dayjs from 'dayjs';

export const isEndOfDay = (date: Date | null | undefined) => {
  if (!date) {
    return false;
  }

  const endTime = dayjs(date).format('HH:mm');
  return endTime === '23:59' || endTime === '23:59:59';
};
