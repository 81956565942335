import React, { useState } from 'react';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import Popup from '../../../../components/common/modals/Popup';
import { useTranslation } from 'react-i18next';
import useValidateTransaction from '../../../../hooks/api/parkings/useValidateTransaction';
import ValidateForm from './ValidateForm';

interface TableValidateButtonProps {
  id: string;
  parkingId: string;
  disabled: boolean;
  licensePlate: string;
  start: string;
}

interface ValidateFormInputs {
  validTo: Date;
}

const TableValidateButton = ({ id, parkingId, disabled, licensePlate, start }: TableValidateButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { validateTransaction, isLoading } = useValidateTransaction({ parkingId, transactionId: id });

  const onSubmit = async ({ validTo }: ValidateFormInputs) => {
    await validateTransaction({ validTo });
    setIsOpen(false);
  };

  return (
    <>
      <SecondaryButton className="lg:w-fit" onClick={() => setIsOpen(true)} disabled={disabled}>
        {t('parkingDetails.activities.table.validate')}
      </SecondaryButton>

      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <ValidateForm start={start} licensePlate={licensePlate} onSubmitForm={onSubmit} isLoading={isLoading} />
      </Popup>
    </>
  );
};

export default TableValidateButton;
