import { EventStatus, EventStatuses } from '../../types/eventStatuses/eventStatuses';

export const getEventStatus = ({ startDate, endDate }: { startDate: Date; endDate: Date | null }): EventStatus => {
  const currentDate = new Date();

  if (endDate && endDate < currentDate) {
    return EventStatuses.FINISHED;
  } else if (startDate <= currentDate && (!endDate || endDate >= currentDate)) {
    return EventStatuses.ONGOING;
  } else {
    return EventStatuses.UPCOMING;
  }
};
