import { ApiSingleParkingZone, ISingleParkingZone } from '../../../types/api/ApiTypes';

export const transformSingleParkingZone = (apiParkingZone: ApiSingleParkingZone): ISingleParkingZone => {
  const transformedParkingZone: ISingleParkingZone = {
    id: apiParkingZone.id,
    name: apiParkingZone.name,
    reservationType: apiParkingZone.reservation_type,
    parking: apiParkingZone.parking,
    parkingName: apiParkingZone.parking_name,
    pdfFiles: apiParkingZone.pdf_files?.map(file => ({
      id: file.id,
      file: file.file,
      fileName: file.file_name,
    })),
    description: apiParkingZone.description || '',
  };

  return transformedParkingZone;
};
