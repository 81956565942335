import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiParkingRuleDetails, IParkingRuleDetails } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformParkingRuleDetails } from '../../../../utils/dataTransformers/transformParkingRuleDetails/transformParkingRuleDetails';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useParkingRuleProps {
  parkingId: string;
  ruleId: string;
}

const useParkingRule = ({ parkingId, ruleId }: useParkingRuleProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.PARKING_RULE}-${parkingId}-${ruleId}`],
    () => axiosInstance.get(api.endpoints.PARKING_RULE({ parkingId, ruleId })),
    {
      cacheTime: 1,
    },
  );

  const apiRule: ApiParkingRuleDetails | undefined = data?.data;

  const rule: IParkingRuleDetails | undefined = !!apiRule ? transformParkingRuleDetails(apiRule) : undefined;

  return {
    rule,
  };
};

export default useParkingRule;
