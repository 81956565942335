import { ApiUserStatistics, IUserStatistics } from '../../../types/api/ApiTypes';

export const transformUserStatistics = (apiUserStatistics: ApiUserStatistics[]): IUserStatistics[] => {
  const transformedUserStatistics: IUserStatistics[] = apiUserStatistics.map(item => ({
    chargingTotal: item.charging_total,
    parkingTotal: item.parking_total,
    date: item.date,
    total: item.total,
  }));

  return transformedUserStatistics;
};
