import React from 'react';
import { twMerge } from 'tailwind-merge';
import Breadcrumbs, { BreadcrumbsProps } from '../../common/breadcrumbs/Breadcrumbs';
import SectionWrapper from '../sectionWrappers/SectionWrapper';

type TileWrapperProps = {
  title: string;
  children: React.ReactNode;
  sideComponent?: React.ReactElement | null;
  hideBreadcrumbs?: boolean;
  breadcrumbsProps?: BreadcrumbsProps;
  className?: string;
  innerClassName?: string;
};

const TitleWrapper = ({
  title,
  children,
  sideComponent,
  hideBreadcrumbs,
  breadcrumbsProps,
  className,
  innerClassName,
}: TileWrapperProps) => {
  return (
    <SectionWrapper>
      <div className={twMerge(`flex flex-col w-full h-full pt-5 lg:pt-0 ${className}`)}>
        <div className={twMerge(`flex justify-between w-full mb-7 ${innerClassName}`)}>
          <div className="flex flex-col-reverse w-full gap-6 lg:flex-col">
            <h1 className="text-black700 w-full max-lg:tracking-[-1px] max-w-[50%] lg:max-w-none">{title}</h1>
            {!hideBreadcrumbs && <Breadcrumbs {...breadcrumbsProps} />}
          </div>

          <div className="flex gap-6">{sideComponent}</div>
        </div>
        <article>{children}</article>
      </div>
    </SectionWrapper>
  );
};

export default TitleWrapper;
