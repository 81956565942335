import { ApiAddress, IAddress } from '../../../types/api/ApiTypes';

export const transformAddress = (apiAddress: ApiAddress): IAddress => {
  const transformedAddress: IAddress = {
    addressLine1: apiAddress?.address_line_1,
    addressLine2: apiAddress?.address_line_2,
    postCode: apiAddress?.post_code,
    city: apiAddress?.city,
    country: apiAddress?.country,
  };

  return transformedAddress;
};
