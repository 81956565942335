import { ApiHistoryCharging, IHistoryCharging } from '../../../types/api/ApiTypes';

export const transformHistoryCharging = (history: ApiHistoryCharging[] | undefined) => {
  if (history) {
    const transformedData: IHistoryCharging[] = history.map((item, idx) => ({
      id: idx,
      licensePlate: item.license_plate,
      start: item.start,
      end: item.end,
      status: item.status,
      paidTotal: item.paid_total,
      parkingName: item.parking_name,
      chargePointName: item.charge_point_name,
      chargePointPort: item.charge_point_port,
      chargingConsumedWatts: item.charging_consumed_watts,
    }));

    return transformedData;
  }
  return [];
};
