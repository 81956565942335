import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

interface useDeleteSpotProps {
  parkingId: string;
  parkingZoneId?: string;
}

const useDeleteSpot = ({ parkingId, parkingZoneId }: useDeleteSpotProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const queryKey = [
    parkingZoneId
      ? `${QueryAliases.SINGLE_PARKING_ZONE_SPOTS}-${parkingZoneId}`
      : `${QueryAliases.PARKING_SPOTS}-${parkingId}`,
  ];

  const { mutateAsync, isLoading } = useMutation(
    (id: string) => axiosInstance.delete(api.endpoints.SINGLE_PARKING_SPOT({ id })),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([queryKey]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.deletingParkingSpotFailed'));
      },
    },
  );
  return { deleteSpot: mutateAsync, isLoading };
};

export default useDeleteSpot;
