import { ApiUserLatestTransactions, IUserLatestTransactions } from '../../../types/api/ApiTypes';

export const transformUserLatestTransactions = (
  apiUserLatestTransactions: ApiUserLatestTransactions[],
): IUserLatestTransactions[] => {
  const transformedUserLatestTransactions: IUserLatestTransactions[] = apiUserLatestTransactions.map(item => ({
    canRequestInvoice: item.can_request_invoice,
    chargingConsumedWatts: item.charging_consumed_watts,
    createdAt: item.created_at,
    id: item.id,
    licensePlate: item.license_plate,
    parkingName: item.parking_name,
    paymentRequest: item.payment_request,
    price: item.price,
    reason: item.reason,
    receiptAvailable: item.receipt_available,
    receiptRequested: item.receipt_requested,
    totalTime: item.total_time,
    userPrice: item.user_price,
  }));

  return transformedUserLatestTransactions;
};
