import { DropdownOption } from '../../types/Dropdown.types';
import { PartnerParkingActivities } from '../../types/partnerParkingActivities/partnerParkingActivities';

export const partnerParkingActivityFilters: DropdownOption[] = [
  { id: '1', value: PartnerParkingActivities.ALL },
  { id: '2', value: PartnerParkingActivities.CHARGING_TRANSACTION },
  { id: '3', value: PartnerParkingActivities.PARKING_SUBSCRIPTION },
  { id: '4', value: PartnerParkingActivities.PARKING_TRANSACTION },
  { id: '5', value: PartnerParkingActivities.RESERVATION },
];
