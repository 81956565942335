import { useEffect, useRef, useState } from 'react';

function useMaxHeight() {
  const elementRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const calculateMaxHeight = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const distanceFromTop = rect.top + window.scrollY;
        const height = window.innerHeight - distanceFromTop;
        setMaxHeight(height);
      }
    };

    calculateMaxHeight();

    const handleResize = () => {
      calculateMaxHeight();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { elementRef, maxHeight };
}

export default useMaxHeight;
