import { ApiPartnerActivity, IPartnerActivity } from '../../../types/api/ApiTypes';

export const transformPartnerActivity = (apiPartnerActivity: ApiPartnerActivity): IPartnerActivity => {
  const transformedPartnerActivity: IPartnerActivity = {
    parkingSpotId: apiPartnerActivity.parking_spot_id,
    parkingSpot: apiPartnerActivity.parking_spot,
    licensePlate: apiPartnerActivity.license_plate,
    entitlementReason: apiPartnerActivity.entitlement_reason,
    start: apiPartnerActivity.start,
    end: apiPartnerActivity.end,
    eventStart: apiPartnerActivity.event_start,
    eventEnd: apiPartnerActivity.event_end,
    totalPrice: apiPartnerActivity.total_price,
  };

  return transformedPartnerActivity;
};
