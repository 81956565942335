import { Link } from 'react-router-dom';
import { TabSwitcherRoute } from './TabSwitcher';

interface TabSwitcherButtonProps {
  route: TabSwitcherRoute;
  isActive: boolean;
}

const TabSwitcherButton = ({ route, isActive }: TabSwitcherButtonProps) => {
  return (
    <Link className="flex-1" to={route.routeTo} key={route.text}>
      <button
        className={`whitespace-nowrap uppercase relative flex-1 text-center font-semibold lg:font-bold text-base lg:text-sm py-2.5 px-5 lg:p-4 cursor-pointer lg:w-full rounded-[2.5rem] lg:rounded-none ${
          isActive
            ? 'text-white900 lg:text-blue500 bg-blue500 lg:bg-transparent border border-blue500 lg:border-none'
            : 'text-grey500 lg:text-grey700 border border-grey200 lg:border-none'
        }`}
      >
        {isActive && (
          <div className="absolute hidden lg:block w-full h-[3px] bg-blue500 bottom-0 left-0 rounded-tl-[100px] rounded-tr-[100px]" />
        )}
        {route.text}
      </button>
    </Link>
  );
};

export default TabSwitcherButton;
