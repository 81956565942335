import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useEditGroupName = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, displayName }: { id: string; displayName: string }) =>
      axiosInstance.put(api.endpoints.SINGLE_GROUP({ id }), { display_name: displayName }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryAliases.USER_GROUPS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editGroupNameFailed`));
      },
    },
  );

  return { editGroupName: mutateAsync, isLoading };
};

export default useEditGroupName;
