import AuthWrapper from '../../components/wrappers/authWrapper/AuthWrapper';
import LoginForm from './components/loginForm/LoginForm';

const Login = () => {
  return (
    <AuthWrapper>
      <LoginForm />
    </AuthWrapper>
  );
};

export default Login;
