import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiParkingRulesItem, IParkingRulesItem } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformParkingRulesItem } from '../../../../utils/dataTransformers/transformParkingRulesItem/transformParkingRulesItem';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useParkingRulesProps {
  parkingId: string;
}

const useParkingRules = ({ parkingId }: useParkingRulesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.PARKING_RULES}-${parkingId}`],
    () => axiosInstance.get(api.endpoints.PARKING_RULES({ id: parkingId })),
    { cacheTime: 1 },
  );

  const apiRules: ApiParkingRulesItem[] | undefined = data?.data;

  const parkingRules: IParkingRulesItem[] | undefined = !!apiRules
    ? apiRules.map(rule => transformParkingRulesItem(rule))
    : undefined;

  return {
    parkingRules,
  };
};

export default useParkingRules;
