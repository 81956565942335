import { BottomActionBox } from '../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../components/common/buttons/BackButton';
import MyDataTable from '../../components/dataTable/MyDataTable';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import useSingleChargerConnectorsPageTable from '../../hooks/pages/useSingleChargerConnectorsPageTable';

const SingleChargerConnectorsTable = () => {
  const { headers, data } = useSingleChargerConnectorsPageTable();
  const { isTablet } = useIsTablet();

  return (
    <div className="flex flex-col justify-between h-full">
      {data && (
        <div className="w-full pt-5 pb-[11rem] lg:pb-0 ">
          <MyDataTable headers={headers} data={data} />
        </div>
      )}
      {isTablet && (
        <BottomActionBox>
          <BackButton />
        </BottomActionBox>
      )}
    </div>
  );
};

export default SingleChargerConnectorsTable;
