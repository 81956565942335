import { ApiSingleZoneParkingRulesItem, ISingleZoneParkingRulesItem } from '../../../types/api/ApiTypes';

export const transformSingleZoneParkingRulesItem = (
  apiRule: ApiSingleZoneParkingRulesItem,
): ISingleZoneParkingRulesItem => {
  const transformedRule: ISingleZoneParkingRulesItem = {
    id: apiRule.id,
    name: apiRule.name,
    groups: apiRule.groups,
    priceList: apiRule.price_list,
    parkingSpots: apiRule.parking_spots,
    availabilities: apiRule.availabilities,
  };

  return transformedRule;
};
