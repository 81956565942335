import { useState } from 'react';
import EditIcon from '../../../components/icons/EditIcon';
import { GroupPermission, IGroupUser } from '../../../types/api/ApiTypes';
import EditUserPermissionsModal from './EditUserPermissionsModal';

interface TableEditGroupUserButtonProps {
  user: IGroupUser;
  onConfirm: (newPermissions: GroupPermission[]) => void;
  disabled?: boolean;
}

const TableEditGroupUserButton = ({ user, onConfirm, disabled }: TableEditGroupUserButtonProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditUserPermissions = async (newPermissions: GroupPermission[]) => {
    onConfirm(newPermissions);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <button disabled={disabled} className="p-3" onClick={() => setIsDeleteModalOpen(true)}>
        <EditIcon />
      </button>

      <EditUserPermissionsModal
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        currentPermissions={user.permissions}
        onUpdatePermissions={handleEditUserPermissions}
      />
    </>
  );
};

export default TableEditGroupUserButton;
