import { useTranslation } from 'react-i18next';
import { TimeUnits } from '../../types/api/ApiTypes';

export const useTimeUnitTypes = () => {
  const { t } = useTranslation();

  const timeUnitTypes = [
    {
      id: TimeUnits.MINUTE.toString(),
      value: t('timeUnits.minutes'),
    },
    {
      id: TimeUnits.HOUR.toString(),
      value: t('timeUnits.hours'),
    },
    {
      id: TimeUnits.DAY.toString(),
      value: t('timeUnits.days'),
    },
    {
      id: TimeUnits.MONTH.toString(),
      value: t('timeUnits.months'),
    },
  ];

  return { timeUnitTypes };
};
