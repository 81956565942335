import { colors } from '../../styles/variables';

interface ChargerIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const ChargerIcon = ({ color, width, height }: ChargerIconProps) => {
  return (
    <svg
      width={width ?? '24'}
      height={height ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 15V16.75C16.5 17.9926 17.5074 19 18.75 19C19.9926 19 21 17.9926 21 16.75V11"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22.5 5.5V7" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 5H5.5V11H13.5V5Z" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5 5.5V7" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 7H23V9C23 10.1046 22.1046 11 21 11C19.8954 11 19 10.1046 19 9V7Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 22V4C2.5 2.89543 3.39543 2 4.5 2H14.5C15.6046 2 16.5 2.89543 16.5 4V22"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 22H18" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChargerIcon;
