import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../../static/api';
import { getApiErrorMessage } from '../../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';

const useDeleteParkingRule = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ parkingId, ruleId }: { parkingId: string; ruleId: string }) =>
      axiosInstance.delete(api.endpoints.PARKING_RULE({ parkingId, ruleId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.deletingParkingRuleFailed`));
      },
    },
  );

  return { deleteRule: mutateAsync, isLoading };
};

export default useDeleteParkingRule;
