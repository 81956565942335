import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingDetails, IParkingDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingDetails } from '../../../utils/dataTransformers/transformParkingDetails/transformParkingDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useSingleParkingProps {
  parkingId: string;
}

const useSingleParking = ({ parkingId }: useSingleParkingProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING}-${parkingId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING({ id: parkingId })),
    {
      cacheTime: 1,
    },
  );

  const apiParking: ApiParkingDetails | undefined = data?.data;

  const parking: IParkingDetails | undefined = !!apiParking ? transformParkingDetails(apiParking) : undefined;

  return { parking };
};

export default useSingleParking;
