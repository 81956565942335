import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomActionBox } from '../../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import MyDataTable from '../../../../components/dataTable/MyDataTable';
import useParkingRules from '../../../../hooks/api/parkings/rules/useParkingRules';
import useParkingSpots from '../../../../hooks/api/parkings/useParkingSpots';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import { routes } from '../../../../static/routes';
import ParkingBasicWrapper from '../ParkingBasicWrapper';
import useParkingRulesParkingTable from './hooks/useParkingRulesParkingTable';

const ParkingRules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const params = useParams();
  const parkingId = params.parkingId as string;

  const { parkingRules } = useParkingRules({ parkingId });
  const { parkingSpots } = useParkingSpots({ parkingId });
  const { headers } = useParkingRulesParkingTable({ parkingId, parkingSpots: parkingSpots ?? [] });

  const submitBtnText = t('parkingDetails.parkingRules.addRuleBtn');
  const submitBtnIcon = <PlusIcon height={16} />;
  const submitBtnOnClick = () => {
    navigate(routes.addParkingRule({ parkingId }));
  };

  return (
    <ParkingBasicWrapper>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <PrimaryButton onClick={submitBtnOnClick} className="w-fit self-end" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}

      <MyDataTable headers={headers} data={parkingRules ?? []} />

      {isTablet && (
        <BottomActionBox>
          <PrimaryButton onClick={submitBtnOnClick} className="w-full" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </ParkingBasicWrapper>
  );
};

export default ParkingRules;
