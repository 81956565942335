import { ApiEntryDevice, IEntryDevice } from '../../../types/api/ApiTypes';

export const transformEntryDevice = (apiEntryDevice: ApiEntryDevice): IEntryDevice => {
  const transformedEntryDevice: IEntryDevice = {
    id: apiEntryDevice.id,
    name: apiEntryDevice.name,
    gateType: apiEntryDevice.gate_type,
    description: apiEntryDevice.description,
    hierarchyPosition: apiEntryDevice.hierarchy_position,
    direction: apiEntryDevice.direction,
    publicId: apiEntryDevice.public_id,
    parkingSpots: apiEntryDevice.parking_spots,
  };

  return transformedEntryDevice;
};
