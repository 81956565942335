import { PriceListType } from '../types/price-lists/PriceListTypes';

export const routes = {
  home: '/',
  parkings: '/parkings',
  parkingBasic: {
    generalInfo: (parkingId: string) => `/parkings/basic/${parkingId}/info`,
    parkingRules: (parkingId: string) => `/parkings/basic/${parkingId}/rules`,
    parkingSpots: (parkingId: string) => `/parkings/basic/${parkingId}/spots`,
    activities: (parkingId: string) => `/parkings/basic/${parkingId}/activities`,
    devices: (parkingId: string) => `/parkings/basic/${parkingId}/devices`,
    shareSpot: (parkingId: string) => `/parkings/basic/${parkingId}/share-spot`,
  },
  parkingAdvanced: {
    zones: (parkingId: string) => `/parkings/advanced/${parkingId}/zones`,
    generalInfo: (parkingId: string) => `/parkings/advanced/${parkingId}/info`,
    devices: (parkingId: string) => `/parkings/advanced/${parkingId}/devices`,
    zoneView: {
      generalInfo: (parkingId: string, zoneId: string) => `/parkings/advanced/${parkingId}/zones/${zoneId}/info`,
      parkingRules: (parkingId: string, zoneId: string) => `/parkings/advanced/${parkingId}/zones/${zoneId}/rules`,
      parkingSpots: (parkingId: string, zoneId: string) => `/parkings/advanced/${parkingId}/zones/${zoneId}/spots`,
      activities: (parkingId: string, zoneId: string) => `/parkings/advanced/${parkingId}/zones/${zoneId}/activities`,
    },
  },
  addParkingRule: ({ parkingId, spotId }: { parkingId: string; spotId?: string }) =>
    `/parkings/${parkingId}/rules/add?spotId=${spotId ?? ''}`,
  addParkingZoneRule: ({ parkingId, zoneId }: { parkingId: string; zoneId: string }) =>
    `/parkings/advanced/${parkingId}/zones/${zoneId}/rules/add`,
  editParkingRule: ({ parkingId, ruleId }: { parkingId: string; ruleId: string }) =>
    `/parkings/${parkingId}/rules/${ruleId}/edit`,
  editParkingZoneRule: ({ parkingId, ruleId, zoneId }: { parkingId: string; ruleId: string; zoneId: string }) =>
    `/parkings/advanced/${parkingId}/zones/${zoneId}/rules/${ruleId}/edit`,
  copyParkingRule: ({ parkingId, ruleId }: { parkingId: string; ruleId: string }) =>
    `/parkings/${parkingId}/rules/${ruleId}/copy`,
  addParkingSpot: (parkingId: string, zoneId?: string) => `/parkings/${parkingId}/spots/add?zoneId=${zoneId ?? ''}`,
  editParkingSpot: (spotId: string) => `/parkings/spots/${spotId}/edit`,
  addSubscription: ({ zoneId }: { zoneId?: string }) => `/subscriptions/create?zoneId=${zoneId ?? ''}`,
  login: '/login',
  requestResetPassword: '/request-reset-password',
  resetPassword: ({ phone }: { phone: string }) => `/reset-password/${phone}`,
  groups: '/groups',
  addGroup: '/groups/add-group',
  editGroup: (id: string) => `/groups/${id}/edit`,
  chargers: '/chargers',
  userAccountData: '/user-account/user-data',
  userAccountHelp: '/user-account/help',
  priceLists: {
    parkings: '/price-lists/parkings',
    ev: '/price-lists/ev',
    subscriptions: '/price-lists/subscriptions',
  },
  history: {
    parking: '/history/parking',
    ev: '/history/ev',
  },
  addPriceList: ({ type }: { type: PriceListType }) => `/price-lists/add-price-list/${type}`,
  editPriceList: ({ id, type }: { id: string; type: PriceListType }) => `/price-lists/edit-price-list/${type}/${id}`,
  copyPriceList: ({ id, type }: { id: string; type: PriceListType }) => `/price-lists/copy-price-list/${type}/${id}`,
  singleChargers: (id: string) => `/chargers/${id}/`,
  parkingSpot: (id: string) => `/parkings/${id}/`,
  singleChargerGeneralInfo: (id: string) => `/chargers/${id}/general-info`,
  singleChargerConnectorsTable: (id: string) => `/chargers/${id}/connectors`,
  singleChargerRulesTable: (id: string) => `/chargers/${id}/rules`,
  chargersRules: (id: string) => `/chargers/${id}/charging-rules`,
  addChargingRule: (id: string) => `/chargers/${id}/charging-rules/add-charging-rule`,
  editChargingRule: (id: string, ruleId: string) => `/chargers/${id}/charging-rules/${ruleId}/edit`,
  cloneChargingRule: (id: string, ruleId: string) => `/chargers/${id}/charging-rules/${ruleId}/clone`,

  partner: {
    parkingServiceActivities: '/parking-service/activities',
    parkingServiceDevices: '/parking-service/devices',
    promoCodes: '/promo-codes',
  },
};

export const paths = {
  home: '/',
  parkings: '/parkings',
  parkingBasic: {
    index: '/parkings/basic/:parkingId',
    generalInfo: '/parkings/basic/:parkingId/info',
    parkingRules: '/parkings/basic/:parkingId/rules',
    parkingSpots: '/parkings/basic/:parkingId/spots',
    activities: '/parkings/basic/:parkingId/activities',
    shareSpot: '/parkings/basic/:parkingId/share-spot',
    devices: '/parkings/basic/:parkingId/devices',
  },
  parkingAdvanced: {
    index: '/parkings/advanced/:parkingId',
    zones: '/parkings/advanced/:parkingId/zones',
    generalInfo: '/parkings/advanced/:parkingId/info',
    devices: '/parkings/advanced/:parkingId/devices',
    zoneView: {
      generalInfo: '/parkings/advanced/:parkingId/zones/:zoneId/info',
      parkingRules: '/parkings/advanced/:parkingId/zones/:zoneId/rules',
      parkingSpots: '/parkings/advanced/:parkingId/zones/:zoneId/spots',
      activities: '/parkings/advanced/:parkingId/zones/:zoneId/activities',
    },
  },
  addParkingRule: '/parkings/:parkingId/rules/add',
  addParkingZoneRule: '/parkings/advanced/:parkingId/zones/:zoneId/rules/add',
  editParkingRule: '/parkings/:parkingId/rules/:ruleId/edit',
  editParkingZoneRule: '/parkings/advanced/:parkingId/zones/:zoneId/rules/:ruleId/edit',
  copyParkingRule: '/parkings/:parkingId/rules/:ruleId/copy',
  addParkingSpot: '/parkings/:parkingId/spots/add',
  editParkingSpot: '/parkings/spots/:spotId/edit',
  addSubscription: '/subscriptions/create',
  login: '/login',
  requestResetPassword: '/request-reset-password',
  resetPassword: '/reset-password/:phone',
  groups: '/groups',
  addGroup: '/groups/add-group',
  editGroup: '/groups/:id/edit',
  chargers: '/chargers',
  userAccountData: '/user-account/user-data',
  userAccountHelp: '/user-account/help',
  priceLists: {
    index: '/price-lists',
    parkings: '/price-lists/parkings',
    ev: '/price-lists/ev',
    subscriptions: '/price-lists/subscriptions',
  },
  history: {
    index: '/history',
    parking: '/history/parking',
    ev: '/history/ev',
  },
  addPriceList: '/price-lists/add-price-list/:type',
  editPriceList: '/price-lists/edit-price-list/:type/:id',
  copyPriceList: '/price-lists/copy-price-list/:type/:id',
  singleCharger: '/chargers/:id',
  parkingSpot: '/parkings/:id',
  singleChargerInfo: '/chargers/:id/general-info',
  singleChargerConnectorsTable: '/chargers/:id/connectors',
  singleChargerRulesTable: '/chargers/:id/rules',
  addChargingRule: '/chargers/:id/charging-rules/add-charging-rule',
  editChargingRule: '/chargers/:id/charging-rules/:ruleId/edit',
  cloneChargingRule: '/chargers/:id/charging-rules/:ruleId/clone',

  partner: {
    parkingServiceActivities: '/parking-service/activities',
    parkingServiceDevices: '/parking-service/devices',
    promoCodes: '/promo-codes',
  },
};
