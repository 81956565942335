import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../../../../components/common/buttons/PrimaryButton';
import LightBorderMobileCard from '../../../../../../components/common/cards/LightBorderMobileCard';
import DescriptionEditor from '../../../../../../components/common/detailsPageComponents/descriptionEditor/DescriptionEditor';
import RegulationPicker from '../../../../../../components/common/detailsPageComponents/regulationPicker/RegulationPicker';
import SaveIcon from '../../../../../../components/icons/SaveIcon';
import { useIsTablet } from '../../../../../../hooks/devices/useIsTablet';
import { routes } from '../../../../../../static/routes';
import { ISingleParkingZone } from '../../../../../../types/api/ApiTypes';
import TabInfoCard from '../../../../components/TabInfoCard/TabInfoCard';
import SingleZoneWrapper from '../../components/SingleZoneWrapper';
import useGeneralInfoForm from '../hooks/useGeneralInfoForm';

interface GeneralInfoViewProps {
  parkingId: string;
  zoneId: string;
  parkingZone: ISingleParkingZone;
}

const GeneralInfoView = ({ parkingId, zoneId, parkingZone }: GeneralInfoViewProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const {
    oldRegulation,
    setOldRegulation,
    selectedRegulation,
    setSelectedRegulation,
    newDescription,
    setNewDescription,
    onSubmitForm,
    isLoading,
  } = useGeneralInfoForm({ zoneId, parkingZone });

  const isSubmitBtnDisabled = isLoading;
  const submitBtnText = t('parkingDetails.generalInfo.submitBtn');
  const submitBtnIcon = <SaveIcon />;

  return (
    <SingleZoneWrapper>
      <LightBorderMobileCard className="lg:p-8">
        {!isTablet && (
          <div className="grid grid-cols-3 gap-6">
            <TabInfoCard
              title={t('parkingDetails.parkingRules.title')}
              description={t('parkingDetails.parkingRules.description')}
              routeTo={routes.parkingAdvanced.zoneView.parkingRules(parkingId, zoneId)}
            />
            <TabInfoCard
              title={t('parkingDetails.parkingSpots.title')}
              description={t('parkingDetails.parkingSpots.description')}
              routeTo={routes.parkingAdvanced.zoneView.parkingSpots(parkingId, zoneId)}
            />
            <TabInfoCard
              title={t('parkingDetails.activities.title')}
              description={t('parkingDetails.activities.description')}
              routeTo={routes.parkingAdvanced.zoneView.activities(parkingId, zoneId)}
            />
          </div>
        )}

        <div className="flex flex-col justify-between gap-12 lg:flex-row grow lg:mt-10">
          <div className="lg:max-w-[20rem] w-full">
            <RegulationPicker
              headerText={t('parkingDetails.zones.generalInfo.regulationTitle')}
              oldRegulation={oldRegulation}
              removeOldRegulation={() => setOldRegulation(null)}
              selectedFile={selectedRegulation}
              setSelectedFile={setSelectedRegulation}
            />

            <div className="mt-8">
              <h5 className="pr-20 text-sm font-bold leading-tight uppercase text-black700">
                {t('parkingDetails.zones.generalInfo.zoneType')}
              </h5>
              <div className="mt-1.5">
                <p className="text-sm font-medium text-black400">
                  {t(`parkingDetails.zones.reservationType.${parkingZone.reservationType}`)}
                </p>
              </div>
            </div>
          </div>

          <div className="grow max-w-lg flex-col">
            <DescriptionEditor
              newDescription={newDescription}
              setNewDescription={setNewDescription}
              title={t('parkingDetails.generalInfo.description.title')}
              placeholder={t('parkingDetails.generalInfo.description.placeholder')}
              label={t('parkingDetails.generalInfo.description.label')}
            />

            {!isTablet && (
              <PrimaryButton
                className="mt-4 w-fit ml-auto"
                disabled={isSubmitBtnDisabled}
                onClick={onSubmitForm}
                prefixIcon={submitBtnIcon}
              >
                {submitBtnText}
              </PrimaryButton>
            )}
          </div>

          {isTablet && (
            <BottomActionBox>
              <PrimaryButton disabled={isSubmitBtnDisabled} onClick={onSubmitForm} prefixIcon={submitBtnIcon}>
                {submitBtnText}
              </PrimaryButton>
            </BottomActionBox>
          )}
        </div>
      </LightBorderMobileCard>
    </SingleZoneWrapper>
  );
};

export default GeneralInfoView;
