import { useTranslation } from 'react-i18next';

export type IFilterPeriod = 'MONTH' | 'YEAR';
export type IFilterType = '' | 'PARKING' | 'CHARGING';

export const useDashboardFiltersOptions = () => {
  const { t } = useTranslation();

  const typeDropdownOptions = [
    {
      id: '',
      value: t('dashboard.filters.type.all'),
    },
    {
      id: 'PARKING',
      value: t('dashboard.filters.type.parking'),
    },
    {
      id: 'CHARGING',
      value: t('dashboard.filters.type.charging'),
    },
  ];

  const periodDropdownOptions = [
    {
      id: 'MONTH',
      value: t('dashboard.filters.period.month'),
    },
    {
      id: 'YEAR',
      value: t('dashboard.filters.period.year'),
    },
  ];

  return { typeDropdownOptions, periodDropdownOptions };
};
