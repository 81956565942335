import { ReactNode } from 'react';
import { LightTooltip } from '../../common/tooltips/LightTooltip';
import InfoIcon from '../../icons/InfoIcon';

const HintPopup = ({ info }: { info: ReactNode }) => {
  return (
    <LightTooltip title={info} placement="top" arrow>
      <div>
        <InfoIcon />
      </div>
    </LightTooltip>
  );
};

export default HintPopup;
