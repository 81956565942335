import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomSwitch from '../../components/common/CustomSwitch/CustomSwitch';
import EditIcon from '../../components/icons/EditIcon';
import StatusIndicator from '../../pages/chargers/components/StatusIndicator';
import { connectionStatuses } from '../../static/chargers/chargerConnectionStatus';
import { routes } from '../../static/routes';
import useToggleChargerAvailability from '../api/chargers/useToggleChargerAvailability';
import useUserChargers from '../api/chargers/useUserChargers';

const useChargersPageTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chargers } = useUserChargers();
  const { changeChargerAvailability } = useToggleChargerAvailability();

  const chargersHeaders = [
    {
      field: 'name',
      headerName: t('chargers.tableHeaders.name'),
      minWidth: 200,
      flex: 1,
      sortable: true,
    },
    {
      field: 'parkingName',
      headerName: t('chargers.tableHeaders.parkingName'),
      minWidth: 210,
      flex: 1,
      sortable: true,
    },
    {
      field: 'addressLine1',
      valueGetter: (params: GridValueGetterParams) => params.row.address.addressLine1,
      headerName: t('chargers.tableHeaders.address'),
      minWidth: 160,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="py-4">
            <p>{`${params.row.address.addressLine1} ${params.row.address.addressLine2}`}</p>
            <p>{`${params.row.address.postCode} ${params.row.address.city}`}</p>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: t('chargers.tableHeaders.status'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const status = Object.values(connectionStatuses).find(item => item.value === params.row.status)!;
        const statusName = t(`singleCharger.connectionStatus.${status.value.toLowerCase()}`);

        return <StatusIndicator text={statusName} textColor={status.textColor} bgColor={status.bgColor} />;
      },
    },
    {
      field: 'isEnabled',
      headerName: t('chargers.tableHeaders.turnOnTurnOff'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { isEnabled, id } = params.row;

        return (
          <CustomSwitch
            isChecked={isEnabled}
            onChange={() => {
              changeChargerAvailability(isEnabled, id);
            }}
          />
        );
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 100,
      maxWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end px-7">
            <div
              className="hover:cursor-pointer"
              onClick={() => navigate(routes.singleChargerGeneralInfo(params.row.id))}
            >
              <EditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return { headers: chargersHeaders, data: chargers };
};

export default useChargersPageTable;
