import { TimeInterval } from '../../../../../components/common/modals/TimeIntervalsModal';
import { Availability } from '../../../../../types/api/ApiTypes';
import { Weekdays } from '../../../../../types/weekdays/Weekdays';
import { getDateFromHour } from '../../../../../utils/priceLists/getDateFromHour/getDateFromHour';

export const getInitialIntervals = (availabilities: Availability[]) => {
  const intervals: TimeInterval[] = Object.values(Weekdays).map(item => ({
    time: 'unavailable',
    weekday: item,
  }));

  availabilities.forEach(av => {
    const foundInterval = intervals.find(int => int.weekday === av.weekday);

    if (!!foundInterval) {
      const newTimeItem = {
        from: getDateFromHour(av.start),
        to: getDateFromHour(av.end),
      };

      if (foundInterval.time === 'unavailable') {
        foundInterval.time = [newTimeItem];
      } else {
        foundInterval.time.push(newTimeItem);
      }
    }
  });

  return intervals;
};
