import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingSpot, IParkingSpot } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingSpot } from '../../../utils/dataTransformers/transformParkingSpot/transformParkingSpot';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useParkingSpotProps {
  spotId: string;
}

const useParkingSpot = ({ spotId }: useParkingSpotProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_SPOT}-${spotId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_SPOT({ id: spotId })),
    {
      cacheTime: 1,
    },
  );

  const apiParkingSpot: ApiParkingSpot | undefined = data?.data;

  const parkingSpot: IParkingSpot | undefined = !!apiParkingSpot ? transformParkingSpot(apiParkingSpot) : undefined;

  return { parkingSpot };
};

export default useParkingSpot;
