import { ITarrifsFormInputs } from '../../../pages/priceLists/addPriceList/components/parkingForm/PriceListTarrifsForm';
import { DropdownOption } from '../../../types/Dropdown.types';
import { IPriceListPeriod, TimeUnits } from '../../../types/api/ApiTypes';
import { formatTarrifsToDisplay } from '../formatTarrifsToDisplay/formatTarrifsToDisplay';

export const transformPeriodsToTarrifs = ({
  periods,
  timeUnitTypes,
  pricePerUnitTypes,
}: {
  periods: IPriceListPeriod[];
  timeUnitTypes: DropdownOption[];
  pricePerUnitTypes: DropdownOption[];
}): ITarrifsFormInputs[] => {
  if (periods.length === 0) {
    return [];
  }

  let previousPricingBand = 1;
  let previousAppliesTo = 0;

  const tarrifs = periods.map(period => {
    const isNewPricingBand = !!period.pricingBand && period.pricingBand > previousPricingBand;

    const getAppliesTo = (isNewPricingBand: boolean) => {
      if (!!period.duration && !!period.timeUnit) {
        let appliesTo = period.duration * period.timeUnit;

        if (!isNewPricingBand) {
          appliesTo += previousAppliesTo;
        }

        return appliesTo;
      } else {
        return null;
      }
    };

    const tarrif: ITarrifsFormInputs = {
      id: period.id,
      price: +period.price,
      intervalTimeUnit:
        pricePerUnitTypes.find(type => type.id === period.pricePer?.toString()) ??
        (pricePerUnitTypes[0] as DropdownOption),
      appliesFrom: previousAppliesTo,
      appliesFromTimeUnit: timeUnitTypes.find(type => type.id === TimeUnits.MINUTE.toString()) as DropdownOption,
      appliesTo: getAppliesTo(isNewPricingBand),
      appliesToTimeUnit: timeUnitTypes.find(type => type.id === TimeUnits.MINUTE.toString()) as DropdownOption,
      minuteBilling: !!period.minuteBasedBilling,
      resetPrice: isNewPricingBand,
    };

    if (isNewPricingBand) {
      previousPricingBand++;
    }

    if (!!tarrif.appliesTo) {
      previousAppliesTo = tarrif.appliesTo;
    }

    return tarrif;
  });

  const tarrifsFormatted = formatTarrifsToDisplay({ tarrifs, timeUnitTypes });

  return tarrifsFormatted;
};
