import { ApiUserWallet, IUserWallet } from '../../../types/api/ApiTypes';

export const transformUserWallet = (apiUserWallet: ApiUserWallet): IUserWallet => {
  const transformedUserWallet: IUserWallet = {
    balance: apiUserWallet.balance,
    totalIncome: apiUserWallet.total_income,
    transactionCount: apiUserWallet.transaction_count,
  };

  return transformedUserWallet;
};
