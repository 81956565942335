import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGroup, IGroup } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformGroup } from '../../../utils/dataTransformers/transformGroup/transformGroup';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useGroupDetailsProps {
  groupId: string;
}

const useGroupDetails = ({ groupId }: useGroupDetailsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.GROUP_DETAILS}-${groupId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_GROUP({ id: groupId })),
    {
      cacheTime: 1,
    },
  );

  const apiGroup: ApiGroup | undefined = data?.data;
  const group: IGroup | undefined = !!apiGroup ? transformGroup(apiGroup) : undefined;
  return { group };
};

export default useGroupDetails;
