import dayjs from 'dayjs';
import * as yup from 'yup';
import * as Schema from '../validationFields';

export const addParkingRuleSchema = ({
  areParkingSpotsRequired,
  areGroupsRequired,
  areDatesRequired,
}: {
  areParkingSpotsRequired: boolean;
  areGroupsRequired: boolean;
  areDatesRequired: boolean;
}) =>
  yup.object({
    name: Schema.requiredString,
    ...(areParkingSpotsRequired && { parkingSpots: Schema.requiredArray }),
    ...(areGroupsRequired && { groups: Schema.requiredArray }),
    priceList: Schema.requiredObject,
    ...(areDatesRequired && {
      validFrom: Schema.requiredDate,
      validTo: Schema.requiredDate.test('parking-rule-valid-to', 'endTimeToEarly', function (value) {
        const { validFrom } = this.parent;
        return dayjs(value).diff(dayjs(validFrom), 'day') >= 0;
      }),
    }),
  });
