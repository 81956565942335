import { colors } from '../../styles/variables';

interface ReservationIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const ReservationIcon = ({ color, width, height }: ReservationIconProps) => {
  return (
    <svg
      width={width ?? '17'}
      height={height ?? '16'}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 6.66536H2.39996M11.0666 1.33203V3.9987M5.7333 1.33203V3.9987M6.39996 10.6654L7.7333 11.9987L10.7333 8.9987M5.59996 14.6654H11.2C12.3201 14.6654 12.8801 14.6654 13.3079 14.4474C13.6843 14.2556 13.9902 13.9497 14.182 13.5733C14.4 13.1455 14.4 12.5855 14.4 11.4654V5.86536C14.4 4.74526 14.4 4.18521 14.182 3.75738C13.9902 3.38106 13.6843 3.0751 13.3079 2.88335C12.8801 2.66536 12.3201 2.66536 11.2 2.66536H5.59996C4.47986 2.66536 3.91981 2.66536 3.49198 2.88335C3.11566 3.0751 2.8097 3.38106 2.61795 3.75738C2.39996 4.18521 2.39996 4.74526 2.39996 5.86536V11.4654C2.39996 12.5855 2.39996 13.1455 2.61795 13.5733C2.8097 13.9497 3.11566 14.2556 3.49198 14.4474C3.91981 14.6654 4.47986 14.6654 5.59996 14.6654Z"
        stroke={color ?? colors.black500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReservationIcon;
