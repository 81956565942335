import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { withTolgee, Tolgee, I18nextPlugin, DevTools, LanguageDetector as TolgeeLD } from '@tolgee/i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import de from './static/locales/de.json';
import en from './static/locales/en.json';
import fr from './static/locales/fr.json';
import it from './static/locales/it.json';
import pl from './static/locales/pl.json';
import ua from './static/locales/ua.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .use(TolgeeLD())
  .init({
    // for development
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    projectId: process.env.REACT_APP_TOLGEE_PROJECT_ID,
    defaultLanguage: 'pl',
    staticData: {
      'en:translation': en,
      'pl:translation': pl,
      'de:translation': de,
      'ua:translation': ua,
      'fr:translation': fr,
      'it:translation': it,
    },
  });

withTolgee(i18n, tolgee)
  .use(LanguageDetector)
  .use(ICU)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'pl',
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['pl', 'en', 'fr', 'de', 'ua', 'it'],
  });

export default i18n;
