import { ApiGroupUser, IGroupUser } from '../../../types/api/ApiTypes';

export const transformGroupUser = (user: ApiGroupUser): IGroupUser => {
  return {
    phone: user.phone_number,
    email: user.email,
    permissions: user.permissions,
    status: user.status,
  };
};

export const transformGroupUsers = (users: ApiGroupUser[]): IGroupUser[] => users.map(user => transformGroupUser(user));
