import { ApiChargingRules, IChargingRules } from '../../../types/api/ApiTypes';

export const transformChargingRules = (rules: ApiChargingRules[]): IChargingRules[] => {
  const transformedRules: IChargingRules[] = rules.map(rule => ({
    id: rule.id,
    name: rule.name,
    connectors: rule.connectors,
    groups: rule.groups,
    priceList: rule.price_list,
  }));

  return transformedRules;
};
