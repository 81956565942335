import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../components/icons/TrashIcon';
import { colors } from '../../../styles/variables';

interface TableRemoveGroupUserButtonProps {
  phone: string;
  onConfirm: (phone: string) => void;
}

const TableRemoveGroupUserButton = ({ phone, onConfirm }: TableRemoveGroupUserButtonProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteGroup = async () => {
    onConfirm(phone);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <button className="hover:cursor-pointer p-3" onClick={() => setIsDeleteModalOpen(true)}>
        <TrashIcon />
      </button>

      <ConfirmModal
        title={t('addGroup.usersList.delete.title')}
        confirmQuestion={t('addGroup.usersList.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('addGroup.usersList.delete.cancelBtnText')}
        confirmBtnText={t('addGroup.usersList.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteGroup}
      />
    </>
  );
};

export default TableRemoveGroupUserButton;
