import * as yup from 'yup';
import * as Schema from '../validationFields';

export const addPriceListSubscriptionSchema = (requireApproval: boolean) =>
  yup.object({
    name: Schema.requiredString,
    subscriptionPrice: Schema.requiredNumber,
    subscriptionCurrency: Schema.requiredObject,
    subscriptionDuration: Schema.requiredNumber,
    limits: Schema.requiredBoolean,
    requireApproval: Schema.requiredBoolean,
    ...(requireApproval && { approvalEmail: Schema.email }),
  });
