import { colors } from '../../../styles/variables';

interface SpotCoUsersIconProps {
  color?: string;
}

const SpotCoUsersIcon = ({ color }: SpotCoUsersIconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 18L18.5 20L22.5 16M12.5 15H8.5C6.63623 15 5.70435 15 4.96927 15.3045C3.98915 15.7105 3.21046 16.4892 2.80448 17.4693C2.5 18.2044 2.5 19.1362 2.5 21M16 3.29076C17.4659 3.88415 18.5 5.32131 18.5 7C18.5 8.67869 17.4659 10.1159 16 10.7092M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpotCoUsersIcon;
