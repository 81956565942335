import TabSwitcherButton from './TabSwitcherButton';

export type TabSwitcherRoute = { text: string; routeTo: string };

export interface TabSwicherProps {
  routes: TabSwitcherRoute[];
  currentPath: string;
}

const TabSwitcher = ({ routes, currentPath }: TabSwicherProps) => {
  const isActiveRoute = (routeTo: string) => routeTo === currentPath;

  return (
    <nav
      className={`flex gap-2.5 lg:justify-between lg:flex-nowrap px-4 overflow-x-scroll no-scrollbar lg:overflow-auto lg:border-b lg:border-grey200`}
    >
      {routes.map(route => (
        <TabSwitcherButton key={route.routeTo} route={route} isActive={isActiveRoute(route.routeTo)} />
      ))}
    </nav>
  );
};

export default TabSwitcher;
