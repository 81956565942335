import { useTranslation } from 'react-i18next';
import { parkingFilterType } from '../../../types/parkings/parkingsFilters';

const DataGridCustomChip = ({ accessType }: { accessType: string }) => {
  const { t } = useTranslation();

  const chipColorData = [
    { accessType: parkingFilterType.ZONE_OWNER, bg: 'bg-purple100', border: 'border-purple500', dot: 'bg-purple500' },
    { accessType: parkingFilterType.OWNER, bg: 'bg-blue100', border: 'border-blue500', dot: 'bg-blue500' },
    { accessType: parkingFilterType.SPOT_OWNER, bg: 'bg-yellow100', border: 'border-yellow500', dot: 'bg-yellow500' },
  ];

  const permissionColors = chipColorData.find(colorData => colorData.accessType === accessType);

  return (
    <div className={`relative flex align-middle`}>
      <span
        className={`relative rounded-full py-2 pl-8 pr-3 border font-medium ${permissionColors?.bg} ${permissionColors?.border}`}
      >
        <span
          className={`absolute left-3 top-1/2 -translate-y-1/2 rounded-full w-2 h-2 ${permissionColors?.dot}`}
        ></span>
        {t(`parkings.dataTableChip.${accessType}`)}
      </span>
    </div>
  );
};

export default DataGridCustomChip;
