import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { routes } from '../../static/routes';
import { colors } from '../../styles/variables';
import ParkingIcon from '../icons/ParkingIcon';
import QrCodeIcon from '../icons/QrCodeIcon';
import { Navbar } from '../navigation/Navbar';
import { NavbarMobile } from '../navigation/NavbarMobile';
import NavButton from '../navigation/components/NavButton';
import NavButtonLogout from '../navigation/components/NavButtons/NavButtonLogout';
import LayoutWrapper from './LayoutWrapper';

export interface LayoutPartnerProps {
  children: React.ReactNode;
}

export const LayoutPartner = (props: LayoutPartnerProps) => {
  const { children } = props;
  const { isTablet } = useIsTablet();
  const { t } = useTranslation();

  const buttonsTop = (
    <>
      <NavButton
        href={routes.partner.parkingServiceActivities}
        text={t('navigation.parking-service')}
        icons={{
          default: <ParkingIcon />,
          active: <ParkingIcon color={colors.white900} />,
        }}
      />
      <NavButton
        href={routes.partner.promoCodes}
        text={t('navigation.promo-codes')}
        icons={{
          default: <QrCodeIcon />,
          active: <QrCodeIcon color={colors.white900} />,
        }}
      />
    </>
  );

  const buttonsBottom = (
    <>
      <NavButtonLogout />
    </>
  );

  return (
    <LayoutWrapper
      navbar={
        isTablet ? (
          <NavbarMobile buttonsTop={buttonsTop} buttonsBottom={buttonsBottom} />
        ) : (
          <Navbar buttonsTop={buttonsTop} buttonsBottom={buttonsBottom} />
        )
      }
    >
      {children}
    </LayoutWrapper>
  );
};

export default LayoutPartner;
