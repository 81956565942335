import { twMerge } from 'tailwind-merge';

interface LightBorderMobileCardProps {
  className?: string;
  children: React.ReactNode;
}

const LightBorderMobileCard = ({ className, children }: LightBorderMobileCardProps) => {
  return (
    <div
      className={twMerge(
        `px-4 lg:px-0 py-6 lg:py-0 mb-6 lg:mb-0 border border-grey200 rounded-md lg:border-none ${className}`,
      )}
    >
      {children}
    </div>
  );
};

export default LightBorderMobileCard;
