import i18n from '../../../i18n';
import { DropdownOption } from '../../../types/Dropdown.types';
import Dropdown from '../../common/dropdowns/Dropdown';
import LangIcon from '../../icons/LangIcon';

const locales: DropdownOption[] = [
  {
    id: 'pl',
    value: 'PL',
  },
  {
    id: 'en',
    value: 'EN',
  },
  {
    id: 'de',
    value: 'DE',
  },
  {
    id: 'ua',
    value: 'UA',
  },
  {
    id: 'fr',
    value: 'FR',
  },
  {
    id: 'it',
    value: 'IT',
  },
];

const LanguageSwitcher = () => {
  const currentLang = i18n.resolvedLanguage;

  const currentLangOption: DropdownOption = {
    id: currentLang ?? 'pl',
    value: currentLang?.toUpperCase() ?? 'PL',
  };

  const handleChangeLanguage = (newLangOption: DropdownOption) => {
    i18n.changeLanguage(newLangOption.id);
  };

  return (
    <div className="flex items-center gap-2 px-3 pb-4">
      <LangIcon />
      <Dropdown
        options={locales}
        currentOption={currentLangOption}
        setNewOption={newOption => handleChangeLanguage(newOption)}
        wrapperClassName="outline-0 pl-0"
        optionsClassName="bottom-16 top-auto "
        buttonClassName="justify-start"
      />
    </div>
  );
};

export default LanguageSwitcher;
