import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import Calendar from '../Calendar/Calendar';
import ActivitiesListView from '../ActivitiesList/ActivitiesListView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityType } from '../../../../types/parkings/activityType';

interface ActivitiesTabsProps {
  activityType: string;
  setActivityType: (activityType: string) => void;
  parkingId: string;
  withCalendar: boolean;
}

const ActivitiesTabs = ({ activityType, setActivityType, parkingId, withCalendar }: ActivitiesTabsProps) => {
  const { t } = useTranslation();
  const isCalendar = activityType === ActivityType.Calendar;
  const isActivitiesList = activityType === ActivityType.ActivitiesList;
  return (
    <>
      {!withCalendar && (
        <div className="mt-6 flex gap-2 mb-5 w-1/4">
          <SecondaryButton markActive={isActivitiesList} onClick={() => setActivityType(ActivityType.ActivitiesList)}>
            {t('parkingDetails.activities.activitiesList')}
          </SecondaryButton>
          <SecondaryButton markActive={isCalendar} onClick={() => setActivityType(ActivityType.Calendar)}>
            {t('parkingDetails.activities.calendar')}
          </SecondaryButton>
        </div>
      )}
      {isCalendar && <Calendar parkingId={parkingId} />}
      {isActivitiesList && <ActivitiesListView parkingId={parkingId} />}
    </>
  );
};

export default ActivitiesTabs;
