import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import useOpenAdminEntryDevice from '../../../../hooks/api/parkings/useOpenAdminEntryDevice';

interface DevicesOpenEntryButtonProps {
  parkingId: string;
  entryDeviceId: string;
}
const DevicesOpenEntryButton = ({ parkingId, entryDeviceId }: DevicesOpenEntryButtonProps) => {
  const { t } = useTranslation();

  const { openEntryDevice, isLoading } = useOpenAdminEntryDevice();

  return (
    <div className="w-full flex justify-end py-2.5">
      {isLoading ? (
        <div className="mr-6 py-1">
          <CircularProgress size={36} />
        </div>
      ) : (
        <PrimaryButton onClick={() => openEntryDevice({ parkingId, entryDeviceId })} className="w-fit">
          {t('parkingDetails.devices.table.openDeviceBtn')}
        </PrimaryButton>
      )}
    </div>
  );
};

export default DevicesOpenEntryButton;
