import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DevicesOpenEntryButton from '../../../components/DevicesOpenEntryButton/DevicesOpenEntryButton';

interface UseParkingDevicesTable {
  parkingId: string;
}

const useParkingDevicesTable = ({ parkingId }: UseParkingDevicesTable) => {
  const { t } = useTranslation();

  const headers = [
    {
      field: 'name',
      headerName: t('parkingDetails.devices.table.name'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('parkingDetails.devices.table.type'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <p>{t(`gateTypes.${params.row.gateType}`)}</p>
          </div>
        );
      },
    },
    {
      field: 'description',
      headerName: t('parkingDetails.devices.table.description'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: '',
      headerName: '',
      minWidth: 100,
      maxWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const entryDeviceId = params.row.id;

        return <DevicesOpenEntryButton parkingId={parkingId} entryDeviceId={entryDeviceId} />;
      },
    },
  ];

  return { headers };
};

export default useParkingDevicesTable;
