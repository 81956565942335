import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useAddEvPriceList from '../../../hooks/api/priceLists/useAddEvPriceList';
import useAddParkingPriceList from '../../../hooks/api/priceLists/useAddParkingPriceList';
import useAddSubscriptionPriceList from '../../../hooks/api/priceLists/useAddSubscriptionPriceList';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import {
  ApiEvPriceListRequest,
  ApiParkingPriceListRequest,
  ApiSubscriptionsPriceListRequest,
} from '../../../types/api/ApiTypes';
import { PriceListType } from '../../../types/price-lists/PriceListTypes';
import PriceListForm from './components/PriceListForm';

const AddPriceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();
  const params = useParams();

  const type = params.type as unknown as PriceListType;

  const { addPriceList: addEvPriceList } = useAddEvPriceList();
  const { addPriceList: addParkingPriceList } = useAddParkingPriceList();
  const { addPriceList: addSubscriptionPriceList } = useAddSubscriptionPriceList();

  const handleCreatePriceListEv = async (data: ApiEvPriceListRequest) => {
    await addEvPriceList({ priceList: data });
    navigate(routes.priceLists.ev);
  };

  const handleCreatePriceListParking = async (data: ApiParkingPriceListRequest) => {
    await addParkingPriceList({ priceList: data });
    navigate(routes.priceLists.parkings);
  };

  const handleCreatePriceListSubscription = async (data: ApiSubscriptionsPriceListRequest) => {
    await addSubscriptionPriceList({ priceList: data });
    navigate(routes.priceLists.subscriptions);
  };

  return (
    <TitleWrapper title={t('priceListForm.title')} breadcrumbsProps={{ limit: 2 }}>
      {!isTablet && <BackButton className="mt-8 mb-16 " />}

      <PriceListForm
        onSubmitPriceListEv={handleCreatePriceListEv}
        onSubmitPriceListParking={handleCreatePriceListParking}
        onSubmitPriceListSubscription={handleCreatePriceListSubscription}
        submitBtnText={t('priceListForm.submitBtn')}
        submitBtnIcon={<PlusIcon height={16} />}
        initialValues={{ type }}
      />
    </TitleWrapper>
  );
};

export default AddPriceList;
