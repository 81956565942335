import useEvPriceLists from '../../../../hooks/api/priceLists/useEvPriceLists';
import { DropdownOption } from '../../../../types/Dropdown.types';

const usePriceListDropdownOptions = () => {
  const { priceLists } = useEvPriceLists();

  let priceListDropdownOptions: DropdownOption[] = [];

  if (priceLists) {
    priceListDropdownOptions = priceLists.map(priceList => ({
      id: priceList.id,
      value: priceList.name,
    }));
  }

  return { priceListDropdownOptions };
};

export default usePriceListDropdownOptions;
