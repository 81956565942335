import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRowIdGetter,
  GridRowsProp,
  MuiEvent,
} from '@mui/x-data-grid';
import MaterialTable from './MaterialTable/MaterialTable';

interface MyDataTableProps {
  data: GridRowsProp;
  headers: GridColDef[];
  onCellClick?: ({
    params,
    e,
    details,
  }: {
    params?: GridCellParams;
    e?: MuiEvent<React.MouseEvent>;
    details?: GridCallbackDetails;
  }) => void;

  loading?: boolean;
  getRowId?: GridRowIdGetter;
  showPagination?: boolean;
}

const MyDataTable = ({ data, headers, loading, getRowId, showPagination }: MyDataTableProps) => {
  return (
    <div className="w-full">
      <MaterialTable
        rows={data}
        columns={headers}
        loading={loading}
        getRowId={getRowId}
        showPagination={showPagination}
      />
    </div>
  );
};

export default MyDataTable;
