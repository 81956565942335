import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomActionBox } from '../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import TabSwitcher, { TabSwitcherRoute } from '../../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import { PriceListType } from '../../../types/price-lists/PriceListTypes';

interface PriceListsWrapperProps {
  type: PriceListType;
  children: React.ReactNode;
}

const PriceListsWrapper = ({ type, children }: PriceListsWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('priceLists.parkings.title'),
      routeTo: routes.priceLists.parkings,
    },
    {
      text: t('priceLists.ev.title'),
      routeTo: routes.priceLists.ev,
    },
    {
      text: t('priceLists.subscriptions.title'),
      routeTo: routes.priceLists.subscriptions,
    },
  ];

  const submitBtnText = t('priceLists.addPriceListBtn');
  const submitBtnIcon = <PlusIcon height={16} />;
  const submitBtnOnClick = () => {
    navigate(routes.addPriceList({ type }));
  };

  return (
    <TitleWrapper title={t('priceLists.title')} breadcrumbsProps={{ limit: 2 }}>
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />

      {!isTablet && (
        <div className="flex flex-col mt-6 mb-8">
          <PrimaryButton onClick={submitBtnOnClick} className="w-fit self-end" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}

      {isTablet && (
        <BottomActionBox>
          <PrimaryButton onClick={submitBtnOnClick} className="w-full" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}

      <div className="px-0 mt-6 bg-white900 lg:overflow-y-scroll lg:no-scrollbar mb-40 lg:mb-0">{children}</div>
    </TitleWrapper>
  );
};

export default PriceListsWrapper;
