import { ApiEntryDevice, IEntryDevice } from '../../../types/api/ApiTypes';

export const transformParkingDevice = (apiParkingDevice: ApiEntryDevice): IEntryDevice => {
  const transformedParkingDevice: IEntryDevice = {
    id: apiParkingDevice.id,
    name: apiParkingDevice.name,
    gateType: apiParkingDevice.gate_type,
    description: apiParkingDevice.description,
    hierarchyPosition: apiParkingDevice.hierarchy_position,
    direction: apiParkingDevice.direction,
    publicId: apiParkingDevice.public_id,
    parkingZone: apiParkingDevice.parking_zone,
    parkingSpots: apiParkingDevice.parking_spots,
  };

  return transformedParkingDevice;
};
