import React from 'react';

interface CalendarResourceProps {
  text: string | never;
}

const CalendarResource = ({ text }: CalendarResourceProps) => {
  return <React.Fragment>{text}</React.Fragment>;
};

export default CalendarResource;
