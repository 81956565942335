import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useCreateGroup = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    (displayName: string) => axiosInstance.post(api.endpoints.USER_GROUPS, { display_name: displayName }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.createGroupFailed`));
      },
    },
  );

  return { createGroup: mutateAsync, isLoading };
};

export default useCreateGroup;
