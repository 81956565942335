const ConnectorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.45508 11.543H16.546V15.1793C16.546 15.7762 16.4284 16.3673 16.2 16.9188C15.9716 17.4703 15.6367 17.9714 15.2147 18.3935C14.7926 18.8155 14.2915 19.1504 13.74 19.3788C13.1885 19.6072 12.5974 19.7248 12.0005 19.7248C10.795 19.7248 9.63885 19.2459 8.78641 18.3935C7.93397 17.541 7.45508 16.3849 7.45508 15.1793V11.543Z"
        stroke="#1C1D18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.27344 11.5427V8.81543" stroke="#1C1D18" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.7278 11.5427V8.81543" stroke="#1C1D18" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.61682 17.4518C2.63532 15.9426 2.07946 14.1963 2.0079 12.3974C1.93635 10.5985 2.35176 8.81369 3.21029 7.23125C4.06882 5.64882 5.33862 4.32751 6.88568 3.40676C8.43274 2.48602 10.1997 2 12 2C13.8003 2 15.5673 2.48602 17.1143 3.40676C18.6614 4.32751 19.9312 5.64882 20.7897 7.23125C21.6482 8.81369 22.0636 10.5985 21.9921 12.3974C21.9205 14.1963 21.3647 15.9426 20.3832 17.4518"
        stroke="#1C1D18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0005 19.7251V21.9978" stroke="#1C1D18" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.323 7.45237C7.00438 6.60132 7.86838 5.91435 8.85109 5.44228C9.83379 4.9702 10.9101 4.7251 12.0003 4.7251C13.0905 4.7251 14.1667 4.9702 15.1495 5.44228C16.1322 5.91435 16.9962 6.60132 17.6775 7.45237"
        stroke="#1C1D18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConnectorIcon;
