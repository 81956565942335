import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableValidateButton from '../../../components/ActivitiesList/TableValidateButton';
import { ParkingCalendarEntitlementReason } from '../../../../../types/api/ApiTypes';
import dayjs from 'dayjs';

const useParkingActivitiesTable = ({ parkingId }: { parkingId: string }) => {
  const { t } = useTranslation();

  const activities = [
    {
      field: 'licensePlate',
      headerName: t('parkingDetails.activities.table.licensePlate'),
      minWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4">
            <p className="flex align-middle">{params.row.licensePlate}</p>
          </div>
        );
      },
    },
    {
      field: 'start',
      headerName: t('parkingDetails.activities.table.start'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex flex-col items-start w-full">
            <p className="text-sm font-medium text-black700">{dayjs(params.row.start).format('DD.MM.YYYY HH:mm')}</p>
          </div>
        );
      },
    },
    {
      field: 'end',
      headerName: t('parkingDetails.activities.table.end'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex flex-col items-start w-full">
            <p className="text-sm font-medium text-black700">
              {params.row.end && dayjs(params.row.end).format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const activityId = params.id as string;
        return (
          <div className="flex justify-end w-full py-4 pl-7">
            <TableValidateButton
              id={activityId}
              start={params.row.start}
              licensePlate={params.row.licensePlate}
              disabled={
                !!params.row.end || params.row.entitlementReason !== ParkingCalendarEntitlementReason.ParkingTransaction
              }
              parkingId={parkingId}
            />
          </div>
        );
      },
    },
  ];
  return { headers: activities };
};

export default useParkingActivitiesTable;
