import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { colors } from '../../../../styles/variables';
import { IUserStatistics } from '../../../../types/api/ApiTypes';
import { formatPrice } from '../../../../utils/formatPrice/formatPrice';
import { IFilterPeriod, IFilterType } from '../filters/useDashboardFiltersOptions';

interface DashboardStatisticsProps {
  userStatistics?: IUserStatistics[];
  period?: IFilterPeriod;
  type?: IFilterType;
}

export const DashboardStatistics = ({ userStatistics, period, type }: DashboardStatisticsProps) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer width="100%" height={500} className="bg-stone-50 rounded-xl">
      <AreaChart
        data={userStatistics?.map(item => ({
          chargingTotal: (item.chargingTotal && +item.chargingTotal.split(' ')[0]!) ?? 0,
          date: item.date,
          parkingTotal: (item.parkingTotal && +item.parkingTotal.split(' ')[0]!) ?? 0,
          total: (item.total && +item.total.split(' ')[0]!) ?? 0,
        }))}
        margin={{
          top: 25,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <defs>
          <linearGradient id="colorChargingTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.success} stopOpacity={0.8} />
            <stop offset="60%" stopColor={colors.success} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorParkingTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.blue500} stopOpacity={0.8} />
            <stop offset="60%" stopColor={colors.blue500} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.grey700} stopOpacity={0.8} />
            <stop offset="60%" stopColor={colors.grey700} stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date"
          fontSize={14}
          tickLine={false}
          stroke={colors.black500}
          axisLine={{ stroke: undefined }}
          tickFormatter={(value, idx) => {
            if (period === 'MONTH') {
              const dayOfMonth = new Date(value).getDate();

              if (idx % 2 === 0) {
                return dayOfMonth.toString();
              } else {
                return '';
              }
            } else {
              return new Date(value).toLocaleString('default', { month: 'short' });
            }
          }}
          interval={0}
        />

        {type !== 'PARKING' && (
          <Area
            dataKey="chargingTotal"
            name={'chargingTotal'}
            type="monotone"
            stroke={colors.success}
            fillOpacity={1}
            fill="url(#colorChargingTotal)"
            strokeWidth={2}
            dot={false}
          />
        )}

        {type !== 'CHARGING' && (
          <Area
            dataKey="parkingTotal"
            name={'parkingTotal'}
            type="monotone"
            stroke={colors.blue500}
            fillOpacity={1}
            fill="url(#colorParkingTotal)"
            strokeWidth={2}
            dot={false}
          />
        )}

        {type === '' && (
          <Area
            dataKey="total"
            name={'total'}
            type="monotone"
            stroke={colors.grey700}
            fillOpacity={1}
            fill="url(#colorTotal)"
            strokeWidth={2}
            dot={false}
          />
        )}

        <Tooltip
          contentStyle={{
            border: 'white',
            borderRadius: '8px',
            fontWeight: '500',
          }}
          formatter={(value, name, _, idx) => [
            <span key={idx}>
              {formatPrice(value as number)} {t('dashboard.currency')}
            </span>,
            t(`dashboard.chart.${name}`),
          ]}
          labelFormatter={value => <span>{value}</span>}
          labelStyle={{ marginBottom: '1rem' }}
        />

        <Legend
          iconType="circle"
          height={36}
          formatter={(value: string) => {
            return <span className="mr-4 text-black400">{t(`dashboard.chart.${value}`)}</span>;
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
