import { createTheme } from '@mui/material';
import { colors } from './variables';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue500,
      contrastText: '#ffffff',
    },
  },
});
