import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import useAxiosInstance from '../../axios/useAxiosInstance';
import { api } from '../../../static/api';
import useToast from '../../useToast';
import { AxiosError } from 'axios';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';

interface useValidateTransactionProps {
  parkingId: string;
  transactionId: string;
}

const useValidateTransaction = ({ parkingId, transactionId }: useValidateTransactionProps) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { mutateAsync, isLoading } = useMutation(
    ({ validTo }: { validTo: Date }) =>
      axiosInstance.post(api.endpoints.VALIDATE_TRANSACTION({ parkingId, transactionId }), {
        valid_to: validTo,
      }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.validateTransactionFailed`));
      },
    },
  );
  return { validateTransaction: mutateAsync, isLoading };
};

export default useValidateTransaction;
