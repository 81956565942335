import { TypeFromConstObject } from '../Types';

export const PartnerParkingActivities = Object.freeze({
  ALL: 'ALL',
  CHARGING_TRANSACTION: 'CHARGING_TRANSACTION',
  PARKING_SUBSCRIPTION: 'PARKING_SUBSCRIPTION',
  PARKING_TRANSACTION: 'PARKING_TRANSACTION',
  RESERVATION: 'RESERVATION',
} as const);

export type PartnerParkingActivity = TypeFromConstObject<typeof PartnerParkingActivities>;

export type PartnerParkingActivityWithoutAll = Exclude<PartnerParkingActivity, 'ALL'>;
