import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import {
  ApiSingleZoneParkingSubscriptionsItem,
  ISingleZoneParkingSubscriptionsItem,
} from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformSingleZoneParkingSubscriptionsItem } from '../../../../utils/dataTransformers/transformSingleZoneParkingSubscriptionsItem/transformSingleZoneParkingSubscriptionsItem';
import useAxiosInstance from '../../../axios/useAxiosInstance';

const useZonesSubscriptions = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([QueryAliases.SUBSCRIPTION_ZONES], () =>
    axiosInstance.get(api.endpoints.SUBSCRIPTION_PRICE_LISTS),
  );

  const apiSubscriptions: ApiSingleZoneParkingSubscriptionsItem[] | undefined = data?.data;

  const subscriptions: ISingleZoneParkingSubscriptionsItem[] | undefined = !!apiSubscriptions
    ? apiSubscriptions.map(subscription => transformSingleZoneParkingSubscriptionsItem(subscription))
    : undefined;

  return {
    subscriptions,
  };
};

export default useZonesSubscriptions;
