import { Availability } from '../../../../../types/api/ApiTypes';

export const isFullAvailability = (availabilities: Availability[]) => {
  return (
    availabilities.length === 7 &&
    availabilities.every(av => {
      const isDayStart = av.start === '00:00:00' || av.start === '00:00';
      const isDayEnd = av.end === '23:59:59' || av.end == '23:59';

      return isDayStart && isDayEnd;
    })
  );
};
