import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import TabSwitcher, { TabSwitcherRoute } from '../../components/common/tabSwitcher/TabSwitcher';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useSingleCharger from '../../hooks/api/chargers/useSingleCharger';
import { routes } from '../../static/routes';

const SingleChargerTabWrapper = () => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const tabRoutes: TabSwitcherRoute[] = [
    {
      text: t('singleCharger.tabSwitcher.generalInfo'),
      routeTo: routes.singleChargerGeneralInfo(id as string),
    },
    {
      text: t('singleCharger.tabSwitcher.connectorsTable'),
      routeTo: routes.singleChargerConnectorsTable(id as string),
    },
    {
      text: t('singleCharger.tabSwitcher.rulesTable'),
      routeTo: routes.singleChargerRulesTable(id as string),
    },
  ];

  const { charger } = useSingleCharger({ id: id as string });
  const modifiedBreadcrumbs =
    !!charger?.id && !!charger?.name ? [{ path: charger?.id as string, text: charger?.name as string }] : [];

  return (
    <TitleWrapper
      title={charger ? `${charger.name} (${charger.parkingName})` : ''}
      breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}
    >
      <TabSwitcher routes={tabRoutes} currentPath={location.pathname} />
      <div className="lg:px-8 px-0 pt-8 lg:pb-8 bg-white900 lg:overflow-y-scroll lg:no-scrollbar h-full">
        <Outlet />
      </div>
    </TitleWrapper>
  );
};

export default SingleChargerTabWrapper;
