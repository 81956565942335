import { DropdownOption } from '../../types/Dropdown.types';
import useParkingPriceLists from '../api/priceLists/useParkingPriceLists';

const useParkingPriceListsDropdownOptions = () => {
  const { priceLists } = useParkingPriceLists();

  let priceListsDropdownOptions: DropdownOption[] | undefined;

  if (priceLists) {
    priceListsDropdownOptions = priceLists.map(priceList => ({
      id: priceList.id,
      value: priceList.name,
    }));
  }

  return { priceListsDropdownOptions };
};

export default useParkingPriceListsDropdownOptions;
