import { DropdownOption } from '../../types/Dropdown.types';
import useZonesSubscriptionIds from '../api/parkings/zones/useZonesSubscriptions';

const useSubscriptionsDropdownOptions = () => {
  const { subscriptions } = useZonesSubscriptionIds();

  let subscriptionsDropdownOptions: DropdownOption[] = [];

  if (subscriptions) {
    subscriptionsDropdownOptions = subscriptions.map(subscription => ({
      id: subscription.id,
      value: subscription.name,
    }));
  }

  return { subscriptionsDropdownOptions };
};

export default useSubscriptionsDropdownOptions;
