import { ApiParkingSpotRule, IParkingSpotRule } from '../../../types/api/ApiTypes';

export const transformParkingSpotRule = (apiSpotRule: ApiParkingSpotRule): IParkingSpotRule => {
  const transformedSpotRule: IParkingSpotRule = {
    id: apiSpotRule.id,
    name: apiSpotRule.name,
    groups: apiSpotRule.groups,
    priceList: apiSpotRule.price_list,
    availabilities: apiSpotRule.availabilities,
    parkingSpots: apiSpotRule.parking_spots.map(spot => ({
      id: spot.id,
      parkingSpotNumber: spot.parking_spot_number,
    })),
  };

  return transformedSpotRule;
};
