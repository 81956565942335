import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiUserLatestTransactions, IUserLatestTransactions } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformUserLatestTransactions } from '../../../../utils/dataTransformers/transformUserLatestTransactions/transformUserLatestTransactions';
import useAxiosInstance from '../../../axios/useAxiosInstance';

const useUserLatestTransactions = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery([QueryAliases.USER_DETAILS_TRANSACTIONS_LATEST], () =>
    axiosInstance.get(api.endpoints.USER_DETAILS_TRANSACTIONS_LATEST),
  );

  const apiUserLatestTransactions: ApiUserLatestTransactions[] | undefined = data?.data;

  const userLatestTransactions: IUserLatestTransactions[] | undefined = !!apiUserLatestTransactions
    ? transformUserLatestTransactions(apiUserLatestTransactions)
    : undefined;

  return { userLatestTransactions, refetch };
};

export default useUserLatestTransactions;
