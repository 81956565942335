import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/common/buttons/SecondaryButton';
import { Modal, ModalProps } from '../../../components/common/modals/Modal';
import SaveIcon from '../../../components/icons/SaveIcon';
import XMarkIcon from '../../../components/icons/XmarkIcon';
import { DefaultGroupPermissions } from '../../../static/groups/GroupPermissionsStatics';
import { ButtonSize } from '../../../types/ButtonSizes';
import { GroupPermission, GroupPermissionsState } from '../../../types/api/ApiTypes';
import { getAcceptedGroupPermissions } from '../../../utils/groups/getAcceptedGroupPermissions/getAcceptedGroupPermissions';

interface EditUserPermissionsModalProps extends Omit<ModalProps, 'children'> {
  currentPermissions: GroupPermission[];
  onUpdatePermissions: (newPermissions: GroupPermission[]) => void;
}

const EditUserPermissionsModal = ({
  isOpen,
  setIsOpen,
  currentPermissions,
  onUpdatePermissions,
}: EditUserPermissionsModalProps) => {
  const { t } = useTranslation();

  const currentPermissionsState = {
    [GroupPermission.GroupPreview]: currentPermissions.includes(GroupPermission.GroupPreview),
    [GroupPermission.GroupDelete]: currentPermissions.includes(GroupPermission.GroupDelete),
    [GroupPermission.AssignPermission]: currentPermissions.includes(GroupPermission.AssignPermission),
    [GroupPermission.GroupEdit]: currentPermissions.includes(GroupPermission.GroupEdit),
    [GroupPermission.AddUsers]: currentPermissions.includes(GroupPermission.AddUsers),
    [GroupPermission.RemoveUsers]: currentPermissions.includes(GroupPermission.RemoveUsers),
  };

  const [permissions, setPermissions] = useState<GroupPermissionsState>(currentPermissionsState);

  useEffect(() => {
    if (!isOpen) {
      setPermissions(currentPermissionsState);
    }
  }, [isOpen]);

  const handleChangePermissions = (key: GroupPermission) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [key]: !prevPermissions[key],
    }));
  };

  const handleClear = () => {
    setPermissions(DefaultGroupPermissions);
  };

  const handleConfirm = () => {
    const newPermissions = getAcceptedGroupPermissions(permissions);
    onUpdatePermissions(newPermissions);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{t('addGroup.usersList.edit.title')}</h2>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="p-6">
          <p className="font-bold">{t('addGroup.usersList.edit.subtitle')}</p>

          <div className="mt-4 pl-4">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t('addGroup.usersForm.permissions.view_userparkinggroup')}
                disabled
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.delete_userparkinggroup}
                    onChange={() => handleChangePermissions(GroupPermission.GroupDelete)}
                  />
                }
                label={t('addGroup.usersForm.permissions.delete_userparkinggroup')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.permission_assign_userparkinggroup}
                    onChange={() => handleChangePermissions(GroupPermission.AssignPermission)}
                  />
                }
                label={t('addGroup.usersForm.permissions.permission_assign_userparkinggroup')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.change_userparkinggroup}
                    onChange={() => handleChangePermissions(GroupPermission.GroupEdit)}
                  />
                }
                label={t('addGroup.usersForm.permissions.change_userparkinggroup')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.add_user_userparkinggroup}
                    onChange={() => handleChangePermissions(GroupPermission.AddUsers)}
                  />
                }
                label={t('addGroup.usersForm.permissions.add_user_userparkinggroup')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.remove_user_userparkinggroup}
                    onChange={() => handleChangePermissions(GroupPermission.RemoveUsers)}
                  />
                }
                label={t('addGroup.usersForm.permissions.remove_user_userparkinggroup')}
              />
            </FormGroup>
          </div>

          <div className="flex gap-4 mt-12 justify-end">
            <SecondaryButton size={ButtonSize.Medium} className="lg:w-fit" onClick={handleClear}>
              {t('addGroup.usersList.edit.clearBtn')}
            </SecondaryButton>
            <PrimaryButton
              size={ButtonSize.Medium}
              className="lg:w-fit"
              onClick={() => {
                handleConfirm();
              }}
              prefixIcon={<SaveIcon />}
            >
              {t('addGroup.usersList.edit.saveBtn')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserPermissionsModal;
