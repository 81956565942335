import { colors } from '../../../styles/variables';

interface SpotCheckedParamIconProps {
  color?: string;
}

const SpotCheckedParamIcon = ({ color }: SpotCheckedParamIconProps) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 7.89309V8.50642C14.6658 9.94404 14.2003 11.3429 13.3396 12.4943C12.4788 13.6457 11.2689 14.4881 9.89023 14.8957C8.51162 15.3033 7.03817 15.2544 5.68964 14.7561C4.34112 14.2579 3.18976 13.3372 2.4073 12.1311C1.62484 10.9251 1.25319 9.49846 1.34778 8.06396C1.44237 6.62946 1.99813 5.26397 2.93218 4.17114C3.86623 3.0783 5.12852 2.31667 6.53079 1.99985C7.93306 1.68303 9.40017 1.82798 10.7133 2.41309M14.6667 3.16927L8 9.8426L6 7.8426"
        stroke={color ?? colors.white900}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpotCheckedParamIcon;
