import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingSpotRule, IParkingSpotRule } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingSpotRule } from '../../../utils/dataTransformers/transformParkingSpotRule/transformParkingSpotRule';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useParkingSpotRulesProps {
  spotId: string;
}

const useParkingSpotRules = ({ spotId }: useParkingSpotRulesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_SPOT_RULES}-${spotId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_SPOT_RULES({ id: spotId })),
    {
      cacheTime: 1,
    },
  );

  const apiSpotRules: ApiParkingSpotRule[] | undefined = data?.data;

  const spotRules: IParkingSpotRule[] | undefined = !!apiSpotRules
    ? apiSpotRules.map(rule => transformParkingSpotRule(rule))
    : undefined;

  return {
    spotRules,
  };
};

export default useParkingSpotRules;
