import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EditIcon from '../../../components/icons/EditIcon';
import { routes } from '../../../static/routes';
import TableRemoveGroupButton from '../components/TableRemoveGroupButton';

const useGroupsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const groupsHeaders = [
    {
      field: 'display_name',
      headerName: t('groups.tableHeaders.name'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center px-2 gap-4">
            <p className="flex align-middle">{params.row.display_name}</p>
          </div>
        );
      },
    },

    {
      field: 'user_count',
      headerName: t('groups.tableHeaders.user_count'),
      minWidth: 130,
      flex: 1,
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      maxWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end gap-2.5 px-7">
            <button className="p-3" onClick={() => navigate(routes.editGroup(params.row.id))}>
              <EditIcon />
            </button>
            <TableRemoveGroupButton id={params.row.id} />
          </div>
        );
      },
    },
  ];

  return { groupsHeaders };
};

export default useGroupsTableHeaders;
