import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export enum RuleTab {
  Parkings = 'parkings',
  Subscriptions = 'subscriptions',
}

interface ParkingRulesTabsProps {
  currentTab: RuleTab;
  allTabs: RuleTab[];
  setActiveTab: Dispatch<SetStateAction<RuleTab>>;
}

const ParkingRulesTabs = ({ currentTab, allTabs, setActiveTab }: ParkingRulesTabsProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 bg-grey100 w-fit my-4 rounded-md p-[3px] gap-1 overflow-x-scroll no-scrollbar">
      {allTabs.map(tab => {
        const isActive = tab === currentTab;

        return (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`py-3.5 px-3 min-w-[10rem] lg:min-w-[13rem] rounded-[3px] flex items-center gap-2 justify-center ${
              isActive ? 'bg-blue500' : 'bg-grey200'
            }`}
          >
            <h6
              className={`text-[0.8125rem] font-semibold whitespace-nowrap uppercase ${
                isActive ? 'text-white900' : 'text-black500'
              }`}
            >
              {t(`parkingDetails.parkingRules.tabs.${tab}`)}
            </h6>
          </button>
        );
      })}
    </div>
  );
};

export default ParkingRulesTabs;
