interface QrFrameIconProps {
  color?: string;
  className?: string;
}

const QrFrameIcon = ({ color, className }: QrFrameIconProps) => {
  return (
    <div className={className}>
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.0808 1.97576H2.02861V23.9273"
          stroke={color ?? '#1D71B8'}
          strokeWidth="3.50877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default QrFrameIcon;
