import UserAccountWrapper from '../components/UserAccountWrapper';
import UserInfo from './components/UserInfo/UserInfo';

const UserAccountData = () => {
  return (
    <UserAccountWrapper>
      <UserInfo />
    </UserAccountWrapper>
  );
};

export default UserAccountData;
