import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../components/common/buttons/DeleteIconButton';
import ConfirmModal from '../../../components/common/modals/ConfirmModal';
import SaveIcon from '../../../components/icons/SaveIcon';
import TrashIcon from '../../../components/icons/TrashIcon';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useChargingRuleDetails from '../../../hooks/api/chargers/useChargingRuleDetails';
import useDeleteChargingRule from '../../../hooks/api/chargers/useDeleteChargingRule';
import useEditChargingRule from '../../../hooks/api/chargers/useEditChargingRule';
import useSingleCharger from '../../../hooks/api/chargers/useSingleCharger';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import useGroupsDropdownOptions from '../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';
import { ChargingRuleForm, IChargingRuleFormInputs } from './components/ChargingRuleForm';
import usePriceListDropdownOptions from './hooks/usePriceListDropdownOptions';

const EditChargingRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const { id, ruleId } = useParams();

  const { charger } = useSingleCharger({ id: id as string });
  const { rule } = useChargingRuleDetails({ id: id as string, ruleId: ruleId as string });
  const { deleteRule } = useDeleteChargingRule();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { editChargingRule, isLoading } = useEditChargingRule({
    chargerId: id ?? '',
    ruleId: ruleId ?? '',
  });

  const handleAddChargingRule = async (props: IChargingRuleFormInputs, isApplyToAllChecked: boolean) => {
    await editChargingRule({
      name: props.name,
      connector_ids: props.connectors.map(item => item.id),
      group_ids: isApplyToAllChecked ? [] : props.groups.map(item => item.id),
      price_list_id: props.priceList.id,
    });

    navigate(routes.singleChargerRulesTable(id ?? ''));
  };

  const modifiedBreadcrumbs =
    !!charger?.id && !!charger?.name ? [{ path: charger?.id as string, text: charger?.name as string }] : [];

  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListDropdownOptions } = usePriceListDropdownOptions();

  const handleDeleteRule = async () => {
    await deleteRule({ id: id as string, ruleId: ruleId as string });
    setIsDeleteModalOpen(false);
    navigate(routes.singleChargerRulesTable(id as string));
  };

  const initialValues: IChargingRuleFormInputs | undefined =
    rule && charger && groupsDropdownOptions
      ? {
          name: rule.name,
          groups: rule.groups.map(item => {
            const group = groupsDropdownOptions.find(group => group.id === item.id)!;
            return {
              id: group?.id,
              value: group?.value,
            };
          }),
          connectors: rule.connectors.map(item => {
            const connector = charger.connectors.find(connector => connector.id === item)!;
            return {
              id: connector?.id,
              value: `${t('chargingRuleForm.connector')} ${connector?.port}`,
            };
          }),
          priceList: priceListDropdownOptions.find(item => item.id === rule.priceList)!,
        }
      : undefined;

  return (
    <>
      <TitleWrapper title={charger?.name ?? ''} breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}>
        {!isTablet && (
          <div className="flex justify-between items-center mt-8 mb-16">
            <BackButton />
            <DeleteIconButton onClick={() => setIsDeleteModalOpen(true)} />
          </div>
        )}

        {initialValues && (
          <ChargingRuleForm
            onSubmitForm={handleAddChargingRule}
            submitBtnText={t('chargingRuleForm.saveChanges')}
            submitBtnIcon={<SaveIcon width={20} height={20} />}
            isSubmitBtnDisabled={isLoading}
            charger={charger}
            initialValues={initialValues}
            onDelete={() => setIsDeleteModalOpen(true)}
          />
        )}
      </TitleWrapper>

      <ConfirmModal
        title={t('chargingRuleForm.delete.title')}
        confirmQuestion={t('chargingRuleForm.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('chargingRuleForm.delete.cancelBtnText')}
        confirmBtnText={t('chargingRuleForm.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteRule}
      />
    </>
  );
};

export default EditChargingRule;
