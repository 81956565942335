import { GroupPermission, GroupPermissionsState } from '../../types/api/ApiTypes';

export const DefaultGroupPermissions: GroupPermissionsState = {
  [GroupPermission.GroupPreview]: true,
  [GroupPermission.GroupDelete]: false,
  [GroupPermission.AssignPermission]: false,
  [GroupPermission.GroupEdit]: false,
  [GroupPermission.AddUsers]: false,
  [GroupPermission.RemoveUsers]: false,
};
