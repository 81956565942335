import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../components/common/buttons/DeleteIconButton';
import ConfirmModal from '../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../components/icons/TrashIcon';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useDeleteEvPriceList from '../../../hooks/api/priceLists/useDeleteEvPriceList';
import useDeleteParkingPriceList from '../../../hooks/api/priceLists/useDeleteParkingPriceList';
import useDeleteSubscriptionPriceList from '../../../hooks/api/priceLists/useDeleteSubscriptionPriceList';
import useEditEvPriceList from '../../../hooks/api/priceLists/useEditEvPriceList';
import useEditParkingPriceList from '../../../hooks/api/priceLists/useEditParkingPriceList';
import useEditSubscriptionPriceList from '../../../hooks/api/priceLists/useEditSubscriptionPriceList';
import useSingleEvPriceList from '../../../hooks/api/priceLists/useSingleEvPriceList';
import useSingleParkingPriceList from '../../../hooks/api/priceLists/useSingleParkingPriceList';
import useSingleSubscriptionPriceList from '../../../hooks/api/priceLists/useSingleSubscriptionPriceList';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';
import {
  ApiEvPriceListRequest,
  ApiParkingPriceListRequest,
  ApiSubscriptionsPriceListRequest,
} from '../../../types/api/ApiTypes';
import { PriceListType, PriceListTypes } from '../../../types/price-lists/PriceListTypes';
import PriceListForm from '../addPriceList/components/PriceListForm';

const EditPriceList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const id = params.id as string;
  const type = params.type as unknown as PriceListType;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { priceList: parkingPriceList } = useSingleParkingPriceList({ id, enabled: type === PriceListTypes.PARKING });
  const { priceList: evPriceList } = useSingleEvPriceList({ id, enabled: type === PriceListTypes.EV });
  const { priceList: subscriptionPriceList } = useSingleSubscriptionPriceList({
    id,
    enabled: type === PriceListTypes.SUBSCRIPTION,
  });

  const { editParkingPriceList } = useEditParkingPriceList();
  const { editEvPriceList } = useEditEvPriceList();
  const { editSubscriptionPriceList } = useEditSubscriptionPriceList();

  const { deleteParkingPriceList } = useDeleteParkingPriceList();
  const { deleteEvPriceList } = useDeleteEvPriceList();
  const { deleteSubscriptionPriceList } = useDeleteSubscriptionPriceList();

  const handleEditPriceListEv = async (data: ApiEvPriceListRequest) => {
    await editEvPriceList({ id: id, priceList: data });
    navigate(routes.priceLists.ev);
  };

  const handleEditPriceListParking = async (data: ApiParkingPriceListRequest) => {
    await editParkingPriceList({ id: id, priceList: data });
    navigate(routes.priceLists.parkings);
  };

  const handleEditPriceListSubscription = async (data: ApiSubscriptionsPriceListRequest) => {
    await editSubscriptionPriceList({ id: id, priceList: data });
    navigate(routes.priceLists.subscriptions);
  };

  const handleDeletePriceList = async () => {
    if (type === PriceListTypes.EV) {
      await deleteEvPriceList(id);
      navigate(routes.priceLists.ev);
    }

    if (type === PriceListTypes.PARKING) {
      await deleteParkingPriceList(id);
      navigate(routes.priceLists.parkings);
    }

    if (type === PriceListTypes.SUBSCRIPTION) {
      await deleteSubscriptionPriceList(id);
      navigate(routes.priceLists.subscriptions);
    }

    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <TitleWrapper title={t('priceListForm.title')} breadcrumbsProps={{ limit: 2 }}>
        {!isTablet && (
          <div className="flex justify-between items-center mt-8 mb-16">
            <BackButton />
            <DeleteIconButton onClick={() => setIsDeleteModalOpen(true)} />
          </div>
        )}

        {(!!evPriceList || !!parkingPriceList || !!subscriptionPriceList) && (
          <PriceListForm
            onSubmitPriceListEv={handleEditPriceListEv}
            onSubmitPriceListParking={handleEditPriceListParking}
            onSubmitPriceListSubscription={handleEditPriceListSubscription}
            onDeletePriceList={() => setIsDeleteModalOpen(true)}
            submitBtnText={t('priceListForm.submitBtn')}
            submitBtnIcon={<PlusIcon height={16} />}
            disableTypeChange
            initialValues={{
              type,
              evValues: evPriceList,
              parkingValues: parkingPriceList,
              subscriptionValues: subscriptionPriceList,
            }}
          />
        )}
      </TitleWrapper>

      <ConfirmModal
        title={t('priceListForm.delete.title')}
        confirmQuestion={t('priceListForm.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('priceListForm.delete.cancelBtnText')}
        confirmBtnText={t('priceListForm.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeletePriceList}
      />
    </>
  );
};

export default EditPriceList;
