import { OnClickDefault } from '../../../../../types/Types';

interface FaqButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: OnClickDefault;
  isActive: boolean;
  icons: {
    default: React.ReactNode;
    active: React.ReactNode;
  };
}

const FaqButton = ({ children, onClick, isActive, icons }: FaqButtonProps) => {
  const icon = isActive ? icons.active : icons.default;

  return (
    <button
      className={`rounded-[2.5rem] lg:rounded-[3px] lg:w-full py-2.5 lg:py-3.5 px-5 lg:px-3 font-semibold text-base lg:text-[.8125rem] border lg:border-none flex items-center gap-2.5 ${
        isActive ? 'bg-blue500 text-white900 border-blue500' : 'bg-transparent text-black500 border-grey200'
      }`}
      onClick={onClick}
    >
      {icon}
      {children}
    </button>
  );
};

export default FaqButton;
