import { colors } from '../../styles/variables';

interface LocationIconProps {
  color?: string;
}

const LocationIcon = ({ color }: LocationIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99999 8.66927C9.10456 8.66927 9.99999 7.77384 9.99999 6.66927C9.99999 5.5647 9.10456 4.66927 7.99999 4.66927C6.89542 4.66927 5.99999 5.5647 5.99999 6.66927C5.99999 7.77384 6.89542 8.66927 7.99999 8.66927Z"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99999 14.6693C10.6667 12.0026 13.3333 9.61479 13.3333 6.66927C13.3333 3.72375 10.9455 1.33594 7.99999 1.33594C5.05447 1.33594 2.66666 3.72375 2.66666 6.66927C2.66666 9.61479 5.33332 12.0026 7.99999 14.6693Z"
        stroke={color ?? colors.blue700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
