import { useParams } from 'react-router-dom';
import useSingleParking from '../../../../hooks/api/parkings/useSingleParking';
import GeneralInfoView from './components/GeneralInfoView';

const ZonesGeneralInfo = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;

  const { parking } = useSingleParking({ parkingId });

  return !!parking ? <GeneralInfoView parkingId={parkingId} parking={parking} /> : <></>;
};

export default ZonesGeneralInfo;
