import { DropdownOption } from '../../../../../types/Dropdown.types';
import { IParkingSpotDetailsByParking, ParkingType } from '../../../../../types/api/ApiTypes';
import { AppliesTo } from '../../components/ParkingRuleForm';

export const getParkingSpotIds = ({
  appliesTo,
  spots,
  parkingType,
  allParkingSpots,
}: {
  appliesTo: AppliesTo;
  spots: DropdownOption[];
  parkingType: ParkingType;
  allParkingSpots: IParkingSpotDetailsByParking[] | undefined;
}) => {
  if (appliesTo === AppliesTo.SPOTS) {
    return { parking_spot_ids: spots.map(spot => spot.id) };
  }

  if (parkingType === ParkingType.BASIC && !!allParkingSpots) {
    return { parking_spot_ids: allParkingSpots.map(spot => spot.id) };
  }

  return undefined;
};
