import { ApiUserResponse, IUser } from '../../../types/api/ApiTypes';
import { transformProfile } from '../transformProfile/transformProfile';

export const transformUser = (apiUser: ApiUserResponse): IUser => {
  const transformedUser: IUser = {
    id: apiUser.id,
    profile: transformProfile(apiUser.profile),
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    phoneNumber: apiUser.phone_number,
    email: apiUser.email,
    isActive: apiUser.is_active,
    dateJoined: new Date(apiUser.date_joined),
    isRegistered: apiUser.is_registered,
    wallet: apiUser.wallet,
    partner: apiUser.partner,
  };

  return transformedUser;
};
