import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import dayjs from 'dayjs';
import { muiStyles } from '../../../styles/muiStyles';

export interface DatetimepickerProps {
  date: Date | null | undefined;
  setDate: (date: Date | null | undefined) => void;
}

const Datetimepicker = ({ date, setDate }: DatetimepickerProps) => {
  return (
    <DesktopDateTimePicker
      value={dayjs(date)}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: null,
      }}
      onChange={date => {
        setDate(!!date ? date.toDate() : null);
      }}
      className="text-sm text-black500 font-medium w-full placeholder:text-grey500 placeholder:text-sm"
      sx={muiStyles.dateTimePickersSx}
    />
  );
};

export default Datetimepicker;
