import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BottomActionBox } from '../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import MyDataTable from '../../components/dataTable/MyDataTable';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useUserGroups from '../../hooks/api/groups/useUserGroups';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { routes } from '../../static/routes';
import useGroupsTableHeaders from './hooks/useGroupsTableHeaders';

const Groups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const { groupsHeaders } = useGroupsTableHeaders();
  const { groups } = useUserGroups();

  const submitBtnText = t('groups.addGroupBtn');
  const submitBtnIcon = <PlusIcon height={16} />;
  const submitBtnOnClick = () => {
    navigate(routes.addGroup);
  };

  return (
    <TitleWrapper title={t('groups.title')}>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <PrimaryButton onClick={submitBtnOnClick} className="w-fit self-end" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}

      <MyDataTable headers={groupsHeaders} data={groups ?? []} />

      {isTablet && (
        <BottomActionBox>
          <PrimaryButton onClick={submitBtnOnClick} className="w-full" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </TitleWrapper>
  );
};

export default Groups;
