import { DropdownOption } from '../../types/Dropdown.types';
import useUserGroups from '../api/groups/useUserGroups';

const useGroupsDropdownOptions = () => {
  const { groups } = useUserGroups();

  let groupsDropdownOptions: DropdownOption[] | undefined;

  if (groups) {
    groupsDropdownOptions = groups.map(group => ({
      id: group.id,
      value: group.display_name,
    }));
  }

  return { groupsDropdownOptions };
};

export default useGroupsDropdownOptions;
