import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiCoUser, ICoUser } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformBasicUser } from '../../../utils/dataTransformers/transformBasicUser/transformBasicUser';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useParkingSpotCoUsersProps {
  spotId: string;
}

const useParkingSpotCoUsers = ({ spotId }: useParkingSpotCoUsersProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_SPOT_COUSERS}-${spotId}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_SPOT_COUSERS({ id: spotId })),
    {
      cacheTime: 1,
    },
  );

  const apiSpotCoUsers: ApiCoUser[] | undefined = data?.data;

  const spotCoUsers: ICoUser[] = !!apiSpotCoUsers ? apiSpotCoUsers.map(couser => transformBasicUser(couser)) : [];

  return { spotCoUsers };
};

export default useParkingSpotCoUsers;
