import { useCallback, useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import IconButton from '../../components/common/buttons/IconButton';
import ArrowLeft from '../../components/icons/ArrowLeft';
import ArrowRight from '../../components/icons/ArrowRight';
import { colors } from '../../styles/variables';

import slideDesktop1 from '../../assets/images/loginInfoSlides/desktop/slideDesktop1.png';
import slideMobile1 from '../../assets/images/loginInfoSlides/mobile/slideMobile1.png';

import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import AuthInfoPagination from './AuthInfoPagination';

//TODO: add another slides
const slidesDesktop = [
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
  { text: 'reservationsAndSubscriptions', image: slideDesktop1 },
];

//TODO: add another slides
const slidesMobile = [
  { text: 'manageParkingSpots', image: slideMobile1 },
  { text: 'manageParkingSpots', image: slideMobile1 },
  { text: 'manageParkingSpots', image: slideMobile1 },
  { text: 'manageParkingSpots', image: slideMobile1 },
  { text: 'manageParkingSpots', image: slideMobile1 },
  { text: 'manageParkingSpots', image: slideMobile1 },
];

const AuthInfoSection = () => {
  const { isTablet } = useIsTablet();
  const { t } = useTranslation();
  const swiperRef = useRef<SwiperRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const slides = isTablet ? slidesMobile : slidesDesktop;

  return (
    <div className="bg-blue500 h-full p-8 w-full flex flex-col items-center justify-center">
      <div className="w-full max-w-2xl relative">
        <h1 className="text-white900 text-center lg:text-left">{t('login.infoSection.title')}</h1>

        <Swiper
          ref={swiperRef}
          spaceBetween={50}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          slidesPerView={1}
          loop
          onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
          className="mt-10"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <h2 className="text-white900 mb-10 font-medium text-2xl lg:text-[1.375rem] text-center lg:text-left">
                {t(`login.infoSection.description.${slide.text}`)}
              </h2>

              <img
                src={slide.image}
                alt={`slide ${index + 1}`}
                loading="lazy"
                className="w-[200px] md:w-[300px] lg:w-auto mx-auto"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <AuthInfoPagination numOfSlides={slides.length} currentSlideIndex={activeIndex} className="mt-10" />

        <div className="flex gap-5 mt-10 justify-center lg:justify-start">
          <IconButton
            onClick={handlePrev}
            icon={<ArrowLeft color={colors.white900} />}
            className="w-10 lg:w-8 h-10 lg:h-8"
          />
          <IconButton
            onClick={handleNext}
            icon={<ArrowRight color={colors.white900} />}
            className="w-10 lg:w-8 h-10 lg:h-8"
          />
        </div>
      </div>
    </div>
  );
};

export default AuthInfoSection;
