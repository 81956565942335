import { useTranslation } from 'react-i18next';
import useUser from '../../../../hooks/api/user/useUser';
import { routes } from '../../../../static/routes';
import { colors } from '../../../../styles/variables';
import UserIcon from '../../../icons/UserIcon';
import NavButton from '../NavButton';

const NavButtonAccount = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const getUserAccountIcon = ({ isActive }: { isActive?: boolean }) => {
    if (user?.profile.avatar) {
      return (
        <div className="rounded-full bg-grey100 h-6 w-6 flex justify-center items-center">
          <img src={user.profile.avatar} alt="avatar" className="rounded-full" />
        </div>
      );
    } else {
      return <UserIcon small color={isActive ? colors.white900 : colors.grey700} />;
    }
  };

  return (
    <NavButton
      href={routes.userAccountData}
      text={t('navigation.user-account')}
      icons={{
        default: getUserAccountIcon({}),
        active: getUserAccountIcon({ isActive: true }),
      }}
    />
  );
};

export default NavButtonAccount;
