import { ApiChargersType, IChargersType } from '../../../types/api/ApiTypes';
import { transformAddress } from '../transformAddress/transformAddress';

export const transformChargers = (chargers: ApiChargersType[] | undefined) => {
  if (chargers) {
    const transformedData: IChargersType[] = chargers.map(charger => ({
      id: charger.id,
      manufacturer: charger.manufacturer,
      name: charger.name,
      status: charger.status,
      isEnabled: charger.is_enabled,
      parkingName: charger.parking_name,
      address: transformAddress(charger.address),
    }));

    return transformedData;
  }
  return [];
};
