/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackButton from '../../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../../components/wrappers/titleWrapper/TitleWrapper';
import useAddParkingRule from '../../../../hooks/api/parkings/rules/useAddParkingRule';
import useSingleParking from '../../../../hooks/api/parkings/useSingleParking';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import useParkingSpotsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { routes } from '../../../../static/routes';
import { ApiEditParkingRuleRequest, ApiParkingRuleRequest } from '../../../../types/api/ApiTypes';
import ParkingRuleForm from '../components/ParkingRuleForm';

const AddParkingRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const parkingId = params.parkingId as string;
  const paramSpotId = searchParams.get('spotId');

  const { parking } = useSingleParking({ parkingId });

  const { addRule, isLoading } = useAddParkingRule();

  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });

  const predefinedSpotOption = spotsDropdownOptions?.find(spot => spot.id === paramSpotId);

  const handleAddRule = async (rule: ApiEditParkingRuleRequest) => {
    const newRule: ApiParkingRuleRequest = {
      ...rule,
      parking_id: parkingId,
    };
    await addRule({ parkingId, rule: newRule });

    navigate(routes.parkingBasic.parkingRules(parkingId));
  };

  const modifiedBreadcrumbs =
    !!parkingId && !!parking?.name ? [{ path: parkingId, text: parking?.name as string }] : [];

  return (
    <TitleWrapper
      title={`${t('addParkingRule.title')}${!!parking?.name && ` (${parking.name})`}`}
      breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}
    >
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      {!!parking && !!spotsDropdownOptions && (
        <ParkingRuleForm
          onSubmitForm={handleAddRule}
          isSubmitBtnLoading={isLoading}
          parkingId={parkingId}
          parkingType={parking.type}
          submitBtnText={t('addParkingRule.submitBtn')}
          predefinedSpotOption={predefinedSpotOption}
        />
      )}
    </TitleWrapper>
  );
};

export default AddParkingRule;
