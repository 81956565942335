/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DataGridCustomChip from '../../components/dataTable/components/DataGridCustomChip';
import ArrowRight from '../../components/icons/ArrowRight';
import { routes } from '../../static/routes';
import { ParkingType } from '../../types/api/ApiTypes';
import useUserParkings from '../api/parkings/useUserParkings';

const useParkingsPageTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { parkings } = useUserParkings();

  const parkingHeaders = [
    {
      field: 'name',
      headerName: t('parkings.tableHeaders.name'),
      minWidth: 250,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex items-center w-full gap-4 px-0">
            <div className="max-w-[36px] w-full h-9 rounded-full overflow-hidden">
              <img
                className="object-cover w-full h-full rounded-full bg-grey300"
                src={params.row.mainImage}
                alt={params.row.name}
              />
            </div>
            <p className="flex align-middle">{params.row.name}</p>
          </div>
        );
      },
    },
    {
      field: 'addressLine1',
      valueGetter: (params: GridValueGetterParams) => params.row.address.addressLine1,

      headerName: t('parkings.tableHeaders.address'),
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="py-4">
            <p>{`${params.row.address.addressLine1} ${params.row.address.addressLine2}`}</p>
            <p>{`${params.row.address.postCode} ${params.row.address.city}`}</p>
          </div>
        );
      },
    },
    {
      field: 'parkingSpotsCount',
      headerName: t('parkings.tableHeaders.parking_spots_count'),
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'accessType',
      headerName: t('parkings.tableHeaders.access_type'),
      minWidth: 110,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCustomChip accessType={params.row.accessType} />;
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex justify-end w-full px-7">
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                if (params.row.type === ParkingType.BASIC) {
                  navigate(routes.parkingBasic.generalInfo(params.row.id));
                } else if (params.row.type === ParkingType.ADVANCED) {
                  navigate(routes.parkingAdvanced.zones(params.row.id));
                }
              }}
            >
              <ArrowRight />
            </div>
          </div>
        );
      },
    },
  ];

  return { headers: parkingHeaders, data: parkings };
};

export default useParkingsPageTable;
