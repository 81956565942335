import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/uk';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LocaleMap {
  pl: string;
  en: string;
  de: string;
  fr: string;
  it: string;
  ua: string;
  [key: string]: string;
}

const localeMap: LocaleMap = {
  pl: 'pl',
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it',
  ua: 'uk',
};

interface MuiLocalizationProviderProps {
  children: React.ReactNode;
}

const MuiLocalizationProvider = ({ children }: MuiLocalizationProviderProps) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.resolvedLanguage ?? 'pl';

  const locale = localeMap[currentLocale];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};

export default MuiLocalizationProvider;
