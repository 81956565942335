import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BottomActionBox } from '../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../components/common/buttons/BackButton';
import LightBorderMobileCard from '../../components/common/cards/LightBorderMobileCard';
import { LabeledStateInput } from '../../components/common/inputs/LabeledStateInput';
import EditIcon from '../../components/icons/EditIcon';
import useEditSingleChargerName from '../../hooks/api/chargers/useEditSingleChargerName';
import useSingleCharger from '../../hooks/api/chargers/useSingleCharger';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { connectionStatuses } from '../../static/chargers/chargerConnectionStatus';
import { editChargerNameVAlidationSchema } from '../../static/validationSchema/editChargerNameVAlidationSchema';
import { ComponentState, ComponentStates } from '../../types/ComponentStates.types';
import StatusIndicator from './components/StatusIndicator';

interface IFormInputs {
  name: string;
}

type InputsKeys = keyof IFormInputs;

const SingleChargerGeneralInfo = () => {
  const { isTablet } = useIsTablet();
  const { id } = useParams();
  const { charger } = useSingleCharger({ id: id as string });
  const { t } = useTranslation();
  const [editName, setEditName] = useState(false);
  const { editChargerName } = useEditSingleChargerName(id as string);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(editChargerNameVAlidationSchema),
    defaultValues: {
      name: charger?.name,
    },
  });

  const watchNameField = watch('name', charger?.name);

  const cancelEditName = () => {
    setEditName(false);
    reset();
  };
  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async ({ name }) => {
    if (name !== charger?.name) {
      await editChargerName({ name });
    }
    setEditName(false);
    reset();
  };

  const status = Object.values(connectionStatuses).find(item => item.value === charger?.status)!;
  const statusName = t(`singleCharger.connectionStatus.${status?.value.toLowerCase()}`);

  return (
    <>
      <LightBorderMobileCard className="mb-20">
        {!isTablet && <BackButton className="mb-16" />}
        {charger && (
          <div className="flex w-full gap-4 justify-between flex-col lg:flex-row lg:items-center ">
            <div className="max-w-[240px] w-full flex flex-col gap-9">
              <div>
                <p className="font-bold uppercase mb-1.5 pr-11 relative">
                  {t('singleCharger.info.name')}
                  {!editName && (
                    <button
                      onClick={() => setEditName(true)}
                      className="absolute right-0 top-1 rounded-full w-8 h-8 bg-gray-100 grid place-items-center"
                    >
                      <EditIcon width="16" height="16" />
                    </button>
                  )}
                </p>
                {editName ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <LabeledStateInput
                      id="charger-name"
                      state={getInputState('name')}
                      labeledProps={{
                        wrapperClassName: 'mb-2 mt-6 max-w-xl',
                        label: t('singleCharger.info.name'),
                        errorMessage: t(`errorMessages.${errors.name?.message}`),
                      }}
                      inputProps={{
                        defaultValue: charger?.name,
                        register: {
                          ...register('name'),
                        },
                      }}
                    />
                    {watchNameField && (
                      <div className="float-right">
                        <button onClick={() => cancelEditName()} className="pr-4 text-blue-500 font-medium text-sm">
                          {t('singleCharger.info.cancel')}
                        </button>
                        <button className="text-blue-500 font-medium text-sm"> {t('singleCharger.info.save')}</button>
                      </div>
                    )}
                  </form>
                ) : (
                  <p className="text-sm font-medium text-black400">{charger.name}</p>
                )}
              </div>
              <div>
                <p className="font-bold uppercase mb-1.5">{t('singleCharger.info.status')}</p>
                <StatusIndicator
                  text={statusName}
                  textColor={status.textColor}
                  bgColor={status.bgColor}
                  textClassName="text-black400 text-sm"
                />
              </div>
              <div>
                <p className="font-bold uppercase mb-1.5">{t('singleCharger.info.parkingName')}</p>
                <p className="text-sm font-medium text-black400">{charger.parkingName}</p>
              </div>
              <div>
                <p className="font-bold uppercase mb-1.5">{t('singleCharger.info.manufacturer')}</p>
                <p className="text-sm font-medium text-black400">{charger.specification.manufacturer}</p>
              </div>
              <div>
                <p className="font-bold uppercase mb-1.5">{t('singleCharger.info.serialNumber')}</p>
                <p className="text-sm font-medium text-black400">{charger.serialNumber}</p>
              </div>
            </div>
            <div className="max-w-[512px] max-h-[512px] w-full h-full bg-chargerCircleBg bg-no-repeat bg-contain bg-center grid place-items-center">
              <img
                src={charger.specification.image}
                alt={charger.name}
                className="object-contain max-w-[512px] max-h-[512px] w-full h-[90vw] lg:h-auto"
              />
            </div>
          </div>
        )}
      </LightBorderMobileCard>
      {isTablet && (
        <BottomActionBox>
          <BackButton />
        </BottomActionBox>
      )}
    </>
  );
};

export default SingleChargerGeneralInfo;
