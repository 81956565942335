import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import { requestResetPasswordPhoneSchema } from '../../../static/validationSchema/requestResetPasswordPhoneSchema';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';

interface IFormInput {
  phone: string;
}

type InputsKeys = keyof IFormInput;

interface RequestResetPasswordPhoneFormProps {
  onSubmitForm: (phone: string) => Promise<void>;
  isLoading: boolean;
}

const RequestResetPasswordPhoneForm = ({ onSubmitForm, isLoading }: RequestResetPasswordPhoneFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(requestResetPasswordPhoneSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ phone }) => {
    await onSubmitForm(phone);

    reset();
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  return (
    <>
      <p className="mt-4 text-black500 font-medium leading-snug">{t('requestResetPassword.stepPhone.description')}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        <LabeledStateInput
          id="user-phone-number"
          state={getInputState('phone')}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('requestResetPassword.stepPhone.inputLabel'),
            errorMessage: t(`errorMessages.${errors.phone?.message}`),
          }}
          inputProps={{
            placeholder: t('requestResetPassword.stepPhone.inputPlaceholder') as string,
            register: {
              ...register('phone'),
            },
          }}
        />

        <PrimaryButton type="submit" disabled={isLoading}>
          {t('requestResetPassword.stepPhone.submitBtn')}
        </PrimaryButton>
      </form>
    </>
  );
};

export default RequestResetPasswordPhoneForm;
