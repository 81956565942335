import { Listbox } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { colors } from '../../../styles/variables';
import { DropdownOption, DropdownStyleProps } from '../../../types/Dropdown.types';

export interface FilterDropdownProps extends DropdownStyleProps {
  label: string;
  options: DropdownOption[];
  currentOption: DropdownOption | undefined;
  setNewOption: (option: DropdownOption) => void;
  buttonDataTestId?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  showIndicator?: boolean;
  translationPath?: string;
}

export const FilterDropdown = ({
  label,
  options,
  currentOption,
  setNewOption,
  buttonClassName,
  iconLeft,
  optionsClassName,
  optionClassName,
  buttonDataTestId,
  fullWidth,
  disabled,
  showIndicator = false,
  translationPath,
}: FilterDropdownProps) => {
  const { t } = useTranslation();

  return (
    <div className={twMerge('relative', `${fullWidth ? 'w-full' : 'w-auto'}`)}>
      <Listbox value={currentOption} onChange={setNewOption} by="id" disabled={disabled}>
        {({ open }) => (
          <>
            <Listbox.Button
              data-testid={buttonDataTestId}
              className={twMerge(
                `w-full flex gap-1 items-center justify-between bg-grey100 py-2 px-3 border border-grey300 rounded-md ${
                  buttonClassName ?? ''
                }`,
              )}
            >
              {iconLeft && <div className="mr-1.5">{iconLeft}</div>}
              <p className="text-black700">{label}</p>

              {showIndicator && (
                <div className="flex items-center justify-center w-6 h-6 ml-1 text-xs rounded-full bg-blue500 text-white900">
                  1
                </div>
              )}

              {open ? (
                <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
              ) : (
                <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
              )}
            </Listbox.Button>
            <Listbox.Options
              className={twMerge(
                `absolute mt-1 py-1 bg-white z-50 rounded-md shadow-dropdown max-lg:right-0 ${optionsClassName ?? ''}`,
              )}
            >
              {options?.length > 0 ? (
                options.map(option => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    className={twMerge(`cursor-pointer py-2 px-3 hover:bg-blue100 ${optionClassName ?? ''}`)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center gap-2">
                        <input type="radio" checked={selected} readOnly className="w-4 h-4 cursor-pointer" />
                        <p className="whitespace-nowrap">
                          {t(`${translationPath ?? 'parkings.filters'}.${option.value}`)}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <li className={twMerge(`py-2 px-3 ${optionClassName}`)}>{t('errorMessages.noData')}</li>
              )}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default FilterDropdown;
