import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomSwitch from '../../components/common/CustomSwitch/CustomSwitch';
import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../components/common/buttons/SecondaryButton';
import HintPopup from '../../components/dataTable/components/HintPopup';
import ConnectorIcon from '../../components/icons/ConnectorIcon';
import XMarkIcon from '../../components/icons/XmarkIcon';
import StatusIndicator from '../../pages/chargers/components/StatusIndicator';
import { connectorStatuses } from '../../static/chargers/chargerConnectorStatuses';
import { colors } from '../../styles/variables';
import { ButtonSize } from '../../types/ButtonSizes';
import findConnectorStatus from '../../utils/findConnectorStatus/findConnectorStatus';
import useSingleCharger from '../api/chargers/useSingleCharger';
import useToggleChargerConnectorAvailability from '../api/chargers/useToggleChargerConnectorAvailability';
import useToggleChargingState from '../api/chargers/useToggleChargingState';

const useSingleChargerConnectorsPageTable = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const { charger } = useSingleCharger({ id: id as string });
  const { changeChargerConnectorAvailability } = useToggleChargerConnectorAvailability();
  const { toggleCharging } = useToggleChargingState(id as string);

  const connectorsHeaders = [
    {
      field: 'port',
      headerName: t('singleCharger.connectorsTableHeaders.portNumber'),
      minWidth: 250,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex gap-3 items-center pl-2 py-5">
            <ConnectorIcon />
            <span>{params.row.port}</span>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: t('singleCharger.connectorsTableHeaders.status'),
      minWidth: 250,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const status = Object.values(connectorStatuses).find(item => item.value === params.row.status)!;
        const statusName = t(`singleCharger.connectorStatus.${status.value.toLowerCase()}.name`);
        const statusHintText = t(`singleCharger.connectorStatus.${status.value.toLowerCase()}.hintText`);

        return (
          <div className="flex gap-3 items-center">
            <StatusIndicator text={statusName} textColor={status.textColor} bgColor={status.bgColor} />
            <HintPopup info={statusHintText} />
          </div>
        );
      },
    },
    {
      field: 'isEnabled',
      headerName: t('singleCharger.connectorsTableHeaders.turnOnTurnOff'),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { status, id } = params.row;
        const isAvailable = status !== connectorStatuses.unavailable.value;

        return (
          <div className="pl-3">
            <CustomSwitch
              isChecked={isAvailable}
              onChange={(active: boolean) => {
                changeChargerConnectorAvailability(active, id);
              }}
            />
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { status, id } = params.row;
        const { turnOn, turnOff, disable } = findConnectorStatus(status);

        const showPrimaryButton = turnOn || disable;

        const handleOnClik = () => {
          toggleCharging({ turnOn, connectorId: id });
        };

        return (
          <div className="flex w-full justify-end pr-2 py-3">
            {showPrimaryButton && (
              <PrimaryButton size={ButtonSize.Small} className="w-fit" disabled={disable} onClick={handleOnClik}>
                {t('singleCharger.connectorsTableHeaders.turnOnCharging')}
              </PrimaryButton>
            )}
            {turnOff && (
              <SecondaryButton
                size={ButtonSize.Small}
                className="w-fit text-blue-700 "
                onClick={handleOnClik}
                prefixIcon={<XMarkIcon size="10" color={colors.blue700} />}
              >
                {t('singleCharger.connectorsTableHeaders.turnOffCharging')}
              </SecondaryButton>
            )}
          </div>
        );
      },
    },
  ];

  return { headers: connectorsHeaders, data: charger?.connectors };
};

export default useSingleChargerConnectorsPageTable;
