import { useState } from 'react';
import useEditParkingZone from '../../../../../../hooks/api/parkings/zones/useEditParkingZone';
import useDeletePdf from '../../../../../../hooks/api/useDeletePdf';
import { IPdfDetails, ISingleParkingZone } from '../../../../../../types/api/ApiTypes';

interface useGeneralInfoFormProps {
  zoneId: string;
  parkingZone: ISingleParkingZone;
}

const useGeneralInfoForm = ({ zoneId, parkingZone }: useGeneralInfoFormProps) => {
  const { updateParkingZone, uploadRegulation, isLoading } = useEditParkingZone();
  const { deletePdf } = useDeletePdf();

  const [oldRegulation, setOldRegulation] = useState<IPdfDetails | null>(
    !!parkingZone && parkingZone.pdfFiles.length !== 0 ? parkingZone.pdfFiles[0]! : null,
  );

  const [selectedRegulation, setSelectedRegulation] = useState<File | null>(null);
  const [newDescription, setNewDescription] = useState(parkingZone?.description);

  const handleRegulations = async () => {
    if (parkingZone.pdfFiles.length !== 0 && !oldRegulation) {
      await deletePdf({ pdfEntryId: parkingZone.pdfFiles[0]!.id });
    }

    if (!!selectedRegulation) {
      await uploadRegulation({ zoneId, file: selectedRegulation });
    }
  };

  const onSubmitForm = async () => {
    if (!!newDescription && newDescription !== parkingZone.description) {
      await updateParkingZone({
        zoneId,
        body: {
          description: newDescription,
        },
      });
    }

    await handleRegulations();
  };

  return {
    oldRegulation,
    setOldRegulation,
    selectedRegulation,
    setSelectedRegulation,
    newDescription,
    setNewDescription,
    onSubmitForm,
    isLoading,
  };
};

export default useGeneralInfoForm;
