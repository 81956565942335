import { TimeInterval } from '../../../components/common/modals/TimeIntervalsModal';
import { ParkingSubscriptionTimeRestriction } from '../../../types/api/ApiTypes';
import { Weekdays } from '../../../types/weekdays/Weekdays';
import { getDateFromHour } from '../getDateFromHour/getDateFromHour';

export const getTimeIntervalsFromTimeRestrictions = (
  timeRestrictions: ParkingSubscriptionTimeRestriction[],
): TimeInterval[] => {
  const intervals: TimeInterval[] = Object.values(Weekdays).map(item => ({
    time: 'unavailable',
    weekday: item,
  }));

  timeRestrictions.forEach(restriction => {
    const foundInterval = intervals.find(int => int.weekday === restriction.weekday);

    if (!!foundInterval) {
      const newTimeItem = {
        from: getDateFromHour(restriction.start),
        to: getDateFromHour(restriction.end),
      };

      if (foundInterval.time === 'unavailable') {
        foundInterval.time = [newTimeItem];
      } else {
        foundInterval.time.push(newTimeItem);
      }
    }
  });

  return intervals;
};
