import { Dispatch, SetStateAction } from 'react';
import QrCodeIcon from '../../../../../components/icons/QrCodeIcon';
import { colors } from '../../../../../styles/variables';
import { IPromotion } from '../../../../../types/api/ApiTypes';

interface PromoTabsProps {
  promotions: IPromotion[];
  activePromotion: IPromotion;
  setActivePromotion: Dispatch<SetStateAction<IPromotion>>;
}

const PromoTabs = ({ promotions, activePromotion, setActivePromotion }: PromoTabsProps) => {
  return (
    <div className="flex lg:bg-grey100 lg:w-fit mx-auto my-4 lg:border lg:border-grey400 rounded-md p-[3px] gap-2.5 lg:gap-1 overflow-x-scroll no-scrollbar">
      {promotions.map(promotion => {
        const isActive = promotion.priceList === activePromotion.priceList;

        return (
          <button
            key={promotion.priceList}
            onClick={() => setActivePromotion(promotion)}
            className={`lg:py-3.5 py-2.5 lg:px-3 px-5 rounded-full lg:rounded-[3px] flex items-center gap-2 ${
              isActive ? 'bg-blue500' : 'bg-transparent'
            }`}
          >
            <QrCodeIcon color={isActive ? colors.white900 : colors.black500} />
            <p
              className={`text-[0.8125rem] font-semibold whitespace-nowrap ${
                isActive ? 'text-white900' : 'text-black500'
              }`}
            >
              {promotion.priceListName}
            </p>
          </button>
        );
      })}
    </div>
  );
};

export default PromoTabs;
