import { useLayoutEffect, useRef, useState } from 'react';

interface LayoutWrapperProps {
  children: React.ReactNode;
  navbar: React.ReactNode;
}

const LayoutWrapper = ({ children, navbar }: LayoutWrapperProps) => {
  const navbarWrapperRef = useRef<HTMLDivElement>(null);

  const [navWidth, setNavWidth] = useState(0);

  useLayoutEffect(() => {
    setNavWidth(navbarWrapperRef.current?.clientWidth ?? 0);
  });

  return (
    <main className="flex h-screen">
      <div ref={navbarWrapperRef} className="lg:fixed z-20">
        {navbar}
      </div>
      <div
        style={{
          marginLeft: navWidth + 'px',
        }}
        className="relative lg:grow w-full lg:w-fit pt-20 lg:pt-0 h-auto"
      >
        {children}
      </div>
    </main>
  );
};

export default LayoutWrapper;
