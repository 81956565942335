import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import MyDataTable from '../../../components/dataTable/MyDataTable';
import HintPopup from '../../../components/dataTable/components/HintPopup';
import { GroupPermission, IGroupUser } from '../../../types/api/ApiTypes';
import TableEditGroupUserButton from './TableEditGroupUserButton';
import TableRemoveGroupUserButton from './TableRemoveGroupUserButton';

interface GroupUsersTableProps {
  users: IGroupUser[];
  onDeleteUser: ({ phone }: { phone: string }) => void;
  onEditUserPermision: ({ phone, newPermissions }: { phone: string; newPermissions: GroupPermission[] }) => void;
}

const GroupUsersTable = ({ users, onDeleteUser, onEditUserPermision }: GroupUsersTableProps) => {
  const { t } = useTranslation();

  const handleEditUsersPermissions = ({
    phone,
    newPermissions,
  }: {
    phone: string;
    newPermissions: GroupPermission[];
  }) => {
    onEditUserPermision({ phone, newPermissions });
  };

  const usersHeaders = [
    {
      field: 'phone',
      headerName: t('addGroup.usersList.tableHeaders.phone'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center px-2 gap-4">
            <p className="flex align-middle">{params.row.phone}</p>
          </div>
        );
      },
    },

    {
      field: 'email',
      headerName: t('addGroup.usersList.tableHeaders.email'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center px-2 gap-4">
            <p className="flex align-middle">{params.row.email}</p>
          </div>
        );
      },
    },

    {
      field: 'type',
      headerName: t('addGroup.usersList.tableHeaders.type'),
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const isAdminType = params.row.permissions.length !== 0;

        const getTooltip = () => {
          const tooltipContent = (
            <div className="flex flex-col">
              <p className="text-xs w-full pb-1">{`${t('addGroup.usersList.tooltipTitle')}:`}</p>
              <ul>
                {params.row.permissions.map((perm: string) => (
                  <li key={perm} className="text-xs">
                    - {t(`addGroup.usersForm.permissions.${perm}`)}
                  </li>
                ))}
              </ul>
            </div>
          );

          return <HintPopup info={tooltipContent} />;
        };

        return (
          <div className="w-full flex items-center px-2 gap-4">
            <p className="flex align-middle">
              {params.row.status !== 'pending'
                ? isAdminType
                  ? t('addGroup.usersForm.permissions.adminUser')
                  : t('addGroup.usersForm.permissions.normalUser')
                : '-'}
            </p>
            {isAdminType && getTooltip()}
          </div>
        );
      },
    },

    {
      field: 'status',
      headerName: t('addGroup.usersList.tableHeaders.status'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center px-2 gap-4">
            <p className="flex align-middle">
              {params.row.status === 'pending'
                ? t('addGroup.usersList.status.waiting')
                : t('addGroup.usersList.status.accepted')}
            </p>
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isAdminType = params.row.permissions.length !== 0;

        return (
          <div className="flex w-full justify-end gap-2.5 px-7">
            {isAdminType && (
              <TableEditGroupUserButton
                user={params.row}
                onConfirm={(newPermissions: GroupPermission[]) => {
                  handleEditUsersPermissions({ phone: params.row.phone, newPermissions });
                }}
              />
            )}
            <TableRemoveGroupUserButton
              phone={params.row.phone}
              onConfirm={() => onDeleteUser({ phone: params.row.phone })}
            />
          </div>
        );
      },
    },
  ];

  return <MyDataTable headers={usersHeaders} data={users ?? []} getRowId={row => row.phone} />;
};

export default GroupUsersTable;
