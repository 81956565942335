import { ApiParkingDetails, IParkingDetails } from '../../../types/api/ApiTypes';
import { transformAddress } from '../transformAddress/transformAddress';

export const transformParkingDetails = (apiParkingDetails: ApiParkingDetails): IParkingDetails => {
  const transformedParkingDetails: IParkingDetails = {
    name: apiParkingDetails.name,
    type: apiParkingDetails.type,
    description: apiParkingDetails.description,
    owner: apiParkingDetails.owner,
    address: transformAddress(apiParkingDetails.address),
    latitude: apiParkingDetails.latitude,
    longitude: apiParkingDetails.longitude,
    mainImage: apiParkingDetails.main_image,
    pdfFiles: apiParkingDetails.pdf_files?.map(file => ({
      id: file.id,
      file: file.file,
      fileName: file.file_name,
    })),
    gallery: apiParkingDetails.gallery,
    accessType: apiParkingDetails.access_type,
  };

  return transformedParkingDetails;
};
