/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import useSingleParking from '../../../../../hooks/api/parkings/useSingleParking';
import useParkingSpotsDropdownOptions from '../../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { ApiEditParkingRuleRequest, ApiParkingRuleRequest } from '../../../../../types/api/ApiTypes';
import { routes } from '../../../../../static/routes';
import TitleWrapper from '../../../../../components/wrappers/titleWrapper/TitleWrapper';
import BackButton from '../../../../../components/common/buttons/BackButton';
import ParkingRuleForm from '../../../../parkings/rules/components/ParkingRuleForm';
import useSingleParkingZone from '../../../../../hooks/api/parkings/zones/useSingleParkingZone';
import useAddParkingZoneRule from './hooks/useAddParkingZoneRule';

const AddParkingZoneRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const params = useParams();

  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;

  const { parking } = useSingleParking({ parkingId });
  const { parkingZone } = useSingleParkingZone({ zoneId });

  const { addRule, isLoading } = useAddParkingZoneRule();

  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });

  const handleAddRule = async (rule: ApiEditParkingRuleRequest) => {
    const newRule: ApiParkingRuleRequest = {
      ...rule,
      parking_id: parkingId,
    };
    await addRule({ zoneId, rule: newRule });

    navigate(routes.parkingAdvanced.zoneView.parkingRules(parkingId, zoneId));
  };

  const extraBreadcrumbs = [
    {
      text: `${parking?.name} ${t('parkingDetails.zones.zone')} ${parkingZone?.name}` ?? '',
    },
  ];

  return (
    <TitleWrapper
      title={`${t('parkingDetails.title')}${
        !!parking?.name && !!parkingZone?.name
          ? ` ${parking.name} - ${t('parkingDetails.zones.zone')} ${parkingZone.name}`
          : ''
      }`}
      breadcrumbsProps={{ limit: 3, extraBreadcrumbs }}
    >
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      {!!parking && !!spotsDropdownOptions && (
        <ParkingRuleForm
          onSubmitForm={handleAddRule}
          isSubmitBtnLoading={isLoading}
          parkingId={parkingId}
          parkingType={parking.type}
          submitBtnText={t('addParkingRule.submitBtn')}
        />
      )}
    </TitleWrapper>
  );
};

export default AddParkingZoneRule;
