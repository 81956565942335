import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LayoutAdmin from '../components/layout/LayoutAdmin';
import Chargers from '../pages/chargers/Chargers';
import SingleChargerConnectorsTable from '../pages/chargers/SingleChargerConnectorsTable';
import SingleChargerGeneralInfo from '../pages/chargers/SingleChargerGeneralInfo';
import SingleChargerRulesTable from '../pages/chargers/SingleChargerRulesTable';
import SingleChargerTabWrapper from '../pages/chargers/SingleChargerTabWrapper';
import AddChargingRule from '../pages/chargers/chargingRules/AddChargingRule';
import CloneChargingRule from '../pages/chargers/chargingRules/CloneChargingRule';
import EditChargingRule from '../pages/chargers/chargingRules/EditChargingRule';
import Dashboard from '../pages/dashboard/Dashboard';
import EditGroup from '../pages/editGroup/EditGroup';
import AddGroup from '../pages/groups/AddGroup';
import Groups from '../pages/groups/Groups';
import HistoryEv from '../pages/history/ev/HistoryEv';
import HistoryParking from '../pages/history/parking/HistoryParking';
import Login from '../pages/login/Login';
import DevicesAdvanced from '../pages/parking/parkingAdvanced/devices/Devices';
import ZonesGeneralInfo from '../pages/parking/parkingAdvanced/generalInfo/ZonesGeneralInfo';
import ParkingZones from '../pages/parking/parkingAdvanced/parkingZones/ParkingZones';
import SingleZoneActivities from '../pages/parking/parkingAdvanced/singleZone/activities/SingleZoneActivities';
import SingleZoneGeneralInfo from '../pages/parking/parkingAdvanced/singleZone/generalInfo/GeneralInfo';
import SingleZoneParkingRules from '../pages/parking/parkingAdvanced/singleZone/parkingRules/SingleZoneParkingRules';
import SingleZoneParkingSpots from '../pages/parking/parkingAdvanced/singleZone/parkingSpots/SingleZoneParkingSpots';
import Activities from '../pages/parking/parkingBasic/activities/Activities';
import ShareSpot from '../pages/parking/components/ShareSpot/ShareSpot';
import DevicesBasic from '../pages/parking/parkingBasic/devices/Devices';
import GeneralInfoBasic from '../pages/parking/parkingBasic/generalInfo/GeneralInfo';
import ParkingRules from '../pages/parking/parkingBasic/parkingRules/ParkingRules';
import ParkingSpots from '../pages/parking/parkingBasic/parkingSpots/ParkingSpots';
import AddParkingSpot from '../pages/parkings/addParkingSpot/AddParkingSpot';
import EditParkingSpot from '../pages/parkings/editParkingSpot/EditParkingSpot';
import Parkings from '../pages/parkings/parkingList/Parkings';
import AddParkingRule from '../pages/parkings/rules/addParkingRule/AddParkingRule';
import CopyParkingRule from '../pages/parkings/rules/copyParkingRule/CopyParkingRule';
import EditParkingRule from '../pages/parkings/rules/editParkingRule/EditParkingRule';
import AddPriceList from '../pages/priceLists/addPriceList/AddPriceList';
import CopyPriceList from '../pages/priceLists/copyPriceList/CopyPriceList';
import EditPriceList from '../pages/priceLists/editPriceList/EditPriceList';
import PriceListsEv from '../pages/priceLists/priceListsEv/PriceListsEv';
import PriceListsParkings from '../pages/priceLists/priceListsParkings/PriceListsParkings';
import PriceListsSubscriptions from '../pages/priceLists/priceListsSubscriptions/PriceListsSubscriptions';
import RequestResetPassword from '../pages/requestResetPassword/RequestResetPassword';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import AddSubscription from '../pages/subscriptions/AddSubscription';
import UserAccountData from '../pages/userAccount/userAccountData/UserAccountData';
import UserAccountHelp from '../pages/userAccount/userAccountHelp/UserAccountHelp';
import { paths, routes } from '../static/routes';
import { ProtectedRoute } from './ProtectedRoute';
import { NavigateToAdvancedParkingWithId } from './components/NavigateToAdvancedParkingWithId';
import { NavigateToBasicParkingWithId } from './components/NavigateToBasicParkingWithId';
import AddParkingZoneRule from '../pages/parking/parkingAdvanced/singleZone/parkingRules/AddParkingZoneRule';

const withLayout = (Component: React.ReactNode) => <LayoutAdmin>{Component}</LayoutAdmin>;

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path={paths.login} element={<Login />} />
      <Route path={paths.requestResetPassword} element={<RequestResetPassword />} />
      <Route path={paths.resetPassword} element={<ResetPassword />} />

      <Route element={<ProtectedRoute />}>
        <Route path={paths.home} element={withLayout(<Dashboard />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.parkings} element={withLayout(<Parkings />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.parkingBasic.index} element={withLayout(<Outlet />)}>
          <Route index element={<NavigateToBasicParkingWithId />} />
          <Route path={paths.parkingBasic.generalInfo} element={<GeneralInfoBasic />} />
          <Route path={paths.parkingBasic.parkingRules} element={<ParkingRules />} />
          <Route path={paths.parkingBasic.parkingSpots} element={<ParkingSpots />} />
          <Route path={paths.parkingBasic.activities} element={<Activities />} />
          <Route path={paths.parkingBasic.shareSpot} element={<ShareSpot />} />
          <Route path={paths.parkingBasic.devices} element={<DevicesBasic />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.parkingAdvanced.index} element={withLayout(<Outlet />)}>
          <Route index element={<NavigateToAdvancedParkingWithId />} />
          <Route path={paths.parkingAdvanced.zones} element={<ParkingZones />} />
          <Route path={paths.parkingAdvanced.generalInfo} element={<ZonesGeneralInfo />} />
          <Route path={paths.parkingAdvanced.devices} element={<DevicesAdvanced />} />

          <Route path={paths.parkingAdvanced.zoneView.generalInfo} element={<SingleZoneGeneralInfo />} />
          <Route path={paths.parkingAdvanced.zoneView.parkingRules} element={<SingleZoneParkingRules />} />
          <Route path={paths.parkingAdvanced.zoneView.parkingSpots} element={<SingleZoneParkingSpots />} />
          <Route path={paths.parkingAdvanced.zoneView.activities} element={<SingleZoneActivities />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addParkingSpot} element={withLayout(<AddParkingSpot />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editParkingSpot} element={withLayout(<EditParkingSpot />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.addParkingRule} element={withLayout(<AddParkingRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addParkingZoneRule} element={withLayout(<AddParkingZoneRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editParkingRule} element={withLayout(<EditParkingRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editParkingZoneRule} element={withLayout(<EditParkingRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.copyParkingRule} element={withLayout(<CopyParkingRule />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.chargers} element={withLayout(<Chargers />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.addSubscription} element={withLayout(<AddSubscription />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.singleCharger} element={withLayout(<SingleChargerTabWrapper />)}>
          <Route index element={<SingleChargerGeneralInfo />} />
          <Route path={paths.singleChargerInfo} element={<SingleChargerGeneralInfo />} />
          <Route path={paths.singleChargerConnectorsTable} element={<SingleChargerConnectorsTable />} />
          <Route path={paths.singleChargerRulesTable} element={<SingleChargerRulesTable />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addChargingRule} element={withLayout(<AddChargingRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editChargingRule} element={withLayout(<EditChargingRule />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.cloneChargingRule} element={withLayout(<CloneChargingRule />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.history.index} element={withLayout(<Outlet />)}>
          <Route index element={<Navigate to={routes.history.parking} />} />
          <Route path={paths.history.parking} element={<HistoryParking />} />
          <Route path={paths.history.ev} element={<HistoryEv />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.priceLists.index} element={withLayout(<Outlet />)}>
          <Route index element={<Navigate to={routes.priceLists.parkings} />} />
          <Route path={paths.priceLists.parkings} element={<PriceListsParkings />} />
          <Route path={paths.priceLists.ev} element={<PriceListsEv />} />
          <Route path={paths.priceLists.subscriptions} element={<PriceListsSubscriptions />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addPriceList} element={withLayout(<AddPriceList />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editPriceList} element={withLayout(<EditPriceList />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.copyPriceList} element={withLayout(<CopyPriceList />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.groups} element={withLayout(<Groups />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addGroup} element={withLayout(<AddGroup />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editGroup} element={withLayout(<EditGroup />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.userAccountData} element={withLayout(<UserAccountData />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.userAccountHelp} element={withLayout(<UserAccountHelp />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate replace to={routes.home} />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
