import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../components/common/buttons/BackButton';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useAddUserToGroup from '../../hooks/api/groups/useAddUserToGroup';
import useCreateGroup from '../../hooks/api/groups/useCreateGroup';
import { useIsTablet } from '../../hooks/devices/useIsTablet';
import { routes } from '../../static/routes';
import { ApiGroup, IGroupUser } from '../../types/api/ApiTypes';
import GroupForm from './components/GroupForm';

const AddGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const { addUser, isLoading: isLoadingAddUser } = useAddUserToGroup();
  const { createGroup, isLoading: isLoadingCreateGroup } = useCreateGroup();

  const isLoading = isLoadingAddUser || isLoadingCreateGroup;

  const addUsersToGroup = async (groupId: string, users: IGroupUser[]) => {
    users.forEach(async user => {
      await addUser({ groupId, user });
    });
  };
  const handleCreateGroup = async ({ displayName, groupUsers }: { displayName: string; groupUsers: IGroupUser[] }) => {
    const res = await createGroup(displayName);
    const data: ApiGroup = res.data;

    await addUsersToGroup(data.id, groupUsers);

    navigate(routes.groups);
  };

  return (
    <TitleWrapper title={t('addGroup.title')} breadcrumbsProps={{ limit: 2 }}>
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      <GroupForm
        onSubmitForm={handleCreateGroup}
        submitBtnText={t('addGroup.submitBtn')}
        submitBtnIcon={<PlusIcon height={16} />}
        isSubmitBtnDisabled={isLoading}
      />
    </TitleWrapper>
  );
};

export default AddGroup;
