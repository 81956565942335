import * as yup from 'yup';
import * as Schema from '../validationFields';
import dayjs from 'dayjs';

export const shareParkingSpotSchema = () =>
  yup.object({
    phone: Schema.phoneNumber,
    parkingSpot: Schema.requiredObject,
    validFrom: Schema.requiredDate,
    validTo: Schema.requiredDate.test('parking-spot-valid-to', 'endTimeToEarly', function (value) {
      const { validFrom } = this.parent;
      return dayjs(value).diff(dayjs(validFrom), 'day') >= 0;
    }),
  });
