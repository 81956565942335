import { ApiSimpleParking, ISimpleParking } from '../../../types/api/ApiTypes';
import { transformAddress } from '../transformAddress/transformAddress';

export const transformSimpleParking = (apiSimpleParking: ApiSimpleParking): ISimpleParking => {
  const transformedSimpleParking: ISimpleParking = {
    id: apiSimpleParking.id,
    name: apiSimpleParking.name,
    latitude: apiSimpleParking.latitude,
    longitude: apiSimpleParking.longitude,
    address: transformAddress(apiSimpleParking.address),
    mainImage: apiSimpleParking.main_image,
  };

  return transformedSimpleParking;
};
