export const breakpoints = {
  smallMobile: '640px',
  mobile: '768px',
  tablet: '1024px',
  desktop: '1280px',
  large: '1536px',
};

export const breakpointsNum = {
  smallMobile: 640,
  mobile: 768,
  tablet: 1024,
  desktop: 1280,
};

export const colors = {
  error: '#E31925',
  errorBg: '#FEF0F1',

  success: '#23AC29',
  successBg: '#E2EFDA',

  grey700: '#797979',
  grey500: '#B4B4B4',
  grey400: '#D2D2D2',

  black700: '#1C1D18',
  black500: '#464646',

  blue700: '#175A93',
  blue500: '#1D71B8',
  blueBtnBg: '#F3F6FC',

  white900: '#FFFFFF',
  white700: '#FDFDFD',
};
