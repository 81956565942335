import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { DropdownOption } from '../../../types/Dropdown.types';
import { ApiEntryDevice, IEntryDevice } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingDevice } from '../../../utils/dataTransformers/transformParkingDevice/transformParkingDevice';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useParkingDevicesProps {
  parkingId: string;
}

const useParkingEntryDevices = ({ parkingId }: useParkingDevicesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([`${QueryAliases.PARKING_ENTRY_DEVICES}-${parkingId}`], () =>
    axiosInstance.get(api.endpoints.PARKING_ENTRY_DEVICES({ id: parkingId })),
  );

  const apiEntryDivices: ApiEntryDevice[] | undefined = data?.data;

  const entryDevices: IEntryDevice[] | undefined = !!apiEntryDivices
    ? apiEntryDivices.map(device => transformParkingDevice(device))
    : [];

  const entryDevicesOptions: DropdownOption[] = entryDevices?.map(device => {
    const option: DropdownOption = {
      id: device.id,
      value: device.name,
    };
    return option;
  });

  return { entryDevices, entryDevicesOptions };
};

export default useParkingEntryDevices;
