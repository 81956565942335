import { useParams } from 'react-router-dom';
import useParkingZones from '../../../../hooks/api/parkings/zones/useParkingZones';
import ParkingZonesView from './ParkingZonesView';

const ParkingZones = () => {
  const params = useParams();
  const parkingId = params.parkingId as string;

  const { parkingZones } = useParkingZones({ parkingId });

  return <ParkingZonesView zones={parkingZones} parkingId={parkingId} />;
};

export default ParkingZones;
