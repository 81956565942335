import { CalendarNav, CalendarNext, CalendarPrev, Select } from '@mobiscroll/react';
import React from 'react';
import { parkingSpotCalendarOptions } from '../../../../types/parkings/parkingSpotCalendarOptions';
import i18n from '../../../../i18n';

interface CalendarHeaderProps {
  viewChange: ({ value }: { value: React.SetStateAction<string> }) => void;
  spotChange: ({ value }: { value: React.SetStateAction<string> }) => void;
  spotsDropdownOptions: { text: string; value: string; id: string }[];
  spotOption: string;
  selectedView: string;
}

const CalendarHeader = ({
  viewChange,
  spotChange,
  spotsDropdownOptions,
  spotOption,
  selectedView,
}: CalendarHeaderProps) => {
  const currentLocale = i18n.resolvedLanguage ?? 'pl';
  const views = [
    {
      text: i18n.t('parkingSpotCalendar.day'),
      value: parkingSpotCalendarOptions.DAY,
    },
    {
      text: i18n.t('parkingSpotCalendar.calendar'),
      value: parkingSpotCalendarOptions.CALENDAR,
    },
  ];
  return (
    <React.Fragment>
      <CalendarPrev className="md-header-filter-prev" />
      <CalendarNav className="md-header-filter-nav" />
      <CalendarNext className="md-header-filter-next" />

      <Select
        key={`${spotOption}-${currentLocale}`}
        data={spotsDropdownOptions}
        value={spotOption}
        onChange={spotChange}
        inputStyle="box"
        themeVariant="light"
        theme="material"
      />
      <Select
        key={`${selectedView}-${currentLocale}`}
        data={views}
        value={selectedView}
        onChange={viewChange}
        inputStyle="box"
        themeVariant="light"
        theme="material"
      />
    </React.Fragment>
  );
};

export default CalendarHeader;
