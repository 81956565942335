import { ApiEvPriceListDetails, IEvPriceListDetails } from '../../../types/api/ApiTypes';

export const transformEvPriceListDetails = (apiPriceListDetails: ApiEvPriceListDetails): IEvPriceListDetails => {
  const transformedEvPriceListDetails: IEvPriceListDetails = {
    id: apiPriceListDetails.id,
    name: apiPriceListDetails.name,
    description: apiPriceListDetails.description,
    billingMethod: apiPriceListDetails.billing_method,
    pricePerKwh: apiPriceListDetails.price_per_kwh,
    pricePerH: apiPriceListDetails.price_per_h,
    backoffTime: apiPriceListDetails.backoff_time,
    currency: apiPriceListDetails.currency,
  };

  return transformedEvPriceListDetails;
};
