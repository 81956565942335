import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/common/buttons/SecondaryButton';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import useValidateUsersPhone from '../../../hooks/api/useValidateUsersPhone';
import { DefaultGroupPermissions } from '../../../static/groups/GroupPermissionsStatics';
import { addUserToGroupValidationSchema } from '../../../static/validationSchema/addUserToGroupValidationSchema';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';
import { GroupPermission, GroupPermissionsState, IGroupUser } from '../../../types/api/ApiTypes';

enum UserType {
  User = 'user',
  Admin = 'admin',
}

interface IFormInputs {
  phone: string;
}

type InputsKeys = keyof IFormInputs;

interface AddUserToGroupFormProps {
  existingPhoneNumbers: string[];
  onAddUser: (newUser: IGroupUser) => void;
}

const AddUserToGroupForm = ({ existingPhoneNumbers, onAddUser }: AddUserToGroupFormProps) => {
  const { t } = useTranslation();
  const { validatePhone } = useValidateUsersPhone();

  const [userType, setUserType] = useState(UserType.User);
  const isNormalUser = userType === UserType.User;
  const isAdminUser = userType === UserType.Admin;

  const [permissions, setPermissions] = useState<GroupPermissionsState>(DefaultGroupPermissions);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addUserToGroupValidationSchema(existingPhoneNumbers)),
    defaultValues: {
      phone: '',
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async ({ phone }) => {
    try {
      await validatePhone({
        phone,
      });

      const permissionsArray = isAdminUser
        ? (Object.keys(permissions).filter(key => permissions[key as GroupPermission]) as GroupPermission[])
        : [];

      const newUser = {
        phone,
        permissions: permissionsArray,
      };

      onAddUser(newUser);
      reset();
    } catch (e) {
      const error = e as { message?: string };
      setError('phone', {
        message: error.message,
      });
    }
  };

  const handleChangePermissions = (key: GroupPermission) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [key]: !prevPermissions[key],
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LabeledStateInput
        id="group-user-phone"
        state={getInputState('phone')}
        labeledProps={{
          wrapperClassName: 'mb-5 mt-6 max-w-xl',
          label: t('addGroup.usersForm.phone.label'),
          errorMessage: t(`errorMessages.${errors.phone?.message}`),
        }}
        inputProps={{
          placeholder: t('addGroup.usersForm.phone.placeholder'),
          register: {
            ...register('phone'),
          },
        }}
      />

      <p className="font-bold mt-8">{t('addGroup.usersForm.permissions.title')}</p>

      <div className="mt-6 flex gap-4">
        <SecondaryButton
          markActive={isNormalUser}
          onClick={() => setUserType(UserType.User)}
          prefixIcon={isNormalUser && <CheckIcon width={16} />}
        >
          {t('addGroup.usersForm.permissions.normalUser')}
        </SecondaryButton>
        <SecondaryButton
          markActive={isAdminUser}
          onClick={() => setUserType(UserType.Admin)}
          prefixIcon={isAdminUser && <CheckIcon width={16} />}
        >
          {t('addGroup.usersForm.permissions.adminUser')}
        </SecondaryButton>
      </div>

      <div className="mt-4 pl-4">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={t('addGroup.usersForm.permissions.view_userparkinggroup')}
            disabled
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.delete_userparkinggroup}
                onChange={() => handleChangePermissions(GroupPermission.GroupDelete)}
              />
            }
            label={t('addGroup.usersForm.permissions.delete_userparkinggroup')}
            disabled={!isAdminUser}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.permission_assign_userparkinggroup}
                onChange={() => handleChangePermissions(GroupPermission.AssignPermission)}
              />
            }
            label={t('addGroup.usersForm.permissions.permission_assign_userparkinggroup')}
            disabled={!isAdminUser}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.change_userparkinggroup}
                onChange={() => handleChangePermissions(GroupPermission.GroupEdit)}
              />
            }
            label={t('addGroup.usersForm.permissions.change_userparkinggroup')}
            disabled={!isAdminUser}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.add_user_userparkinggroup}
                onChange={() => handleChangePermissions(GroupPermission.AddUsers)}
              />
            }
            label={t('addGroup.usersForm.permissions.add_user_userparkinggroup')}
            disabled={!isAdminUser}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.remove_user_userparkinggroup}
                onChange={() => handleChangePermissions(GroupPermission.RemoveUsers)}
              />
            }
            label={t('addGroup.usersForm.permissions.remove_user_userparkinggroup')}
            disabled={!isAdminUser}
          />
        </FormGroup>
      </div>

      <PrimaryButton type="submit" className="w-fit h-fit mt-9" prefixIcon={<PlusIcon height={16} />}>
        {t('addGroup.usersForm.submitUser')}
      </PrimaryButton>
    </form>
  );
};

export default AddUserToGroupForm;
