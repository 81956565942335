import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';
import { ICoUser } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';

const useAddParkingSpotCoUser = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    ({ spotId, user }: { spotId: string; user: ICoUser }) =>
      axiosInstance.post(api.endpoints.SINGLE_PARKING_SPOT_COUSERS({ id: spotId }), {
        ...user,
        group_id: user.groupId,
        phone_number: user.phoneNumber,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData([`${QueryAliases.SINGLE_PARKING_SPOT_COUSERS}-${variables.spotId}`], data);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.addingSpotCoUserFailed`));
      },
    },
  );

  return { addCoUser: mutateAsync, isLoading };
};

export default useAddParkingSpotCoUser;
