import { colors } from '../../styles/variables';

interface ArrowNarrowProps {
  color?: string;
}

const ArrowNarrow = ({ color }: ArrowNarrowProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.59315 7.99963H13.9069M13.9069 7.99963L10.1356 4.22839M13.9069 7.99963L10.1356 11.7709"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowNarrow;
