import MyDataTable from '../../../components/dataTable/MyDataTable';
import useEvPriceLists from '../../../hooks/api/priceLists/useEvPriceLists';
import { PriceListTypes } from '../../../types/price-lists/PriceListTypes';
import PriceListsWrapper from '../components/PriceListsWrapper';
import useEvPriceListsTableHeaders from './hooks/useEvPriceListsTableHeaders';

const PriceListsEv = () => {
  const { headers } = useEvPriceListsTableHeaders();
  const { priceLists } = useEvPriceLists();

  return (
    <PriceListsWrapper type={PriceListTypes.EV}>
      {!!priceLists && <MyDataTable headers={headers} data={priceLists} />}
    </PriceListsWrapper>
  );
};

export default PriceListsEv;
