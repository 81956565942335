import * as yup from 'yup';
import { BillingMethodEnum } from '../../../types/api/ApiTypes';
import * as Schema from '../validationFields';

export const addPriceListChargerSchema = (billingMethod: BillingMethodEnum) =>
  yup.object({
    name: Schema.requiredString,
    billingMethod: Schema.requiredString,
    ...(billingMethod !== BillingMethodEnum.PER_KWH && { pricePerH: Schema.requiredNumber }),
    currency: Schema.requiredObject,
    ...(billingMethod !== BillingMethodEnum.PER_TIME && { pricePerKwh: Schema.requiredNumber }),
    ...(billingMethod === BillingMethodEnum.MIXED && { backoffTime: Schema.requiredNumber }),
  });
