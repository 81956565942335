import axios, { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../queryClient';
import { api } from '../../static/api';
import { routes } from '../../static/routes';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
} from '../../utils/cookieHelpers/cookieHelpers';
import useToast from '../useToast';

const useAxiosInstance = (): AxiosInstance => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const axiosInstance = axios.create({
    baseURL: api.BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const onRefreshError = () => {
    queryClient.removeQueries();
    navigate(routes.login);
    removeAccessToken();
    removeRefreshToken();
    showErrorToast(t('errorMessages.loggedOut'));
  };

  axiosInstance.interceptors.request.use(config => {
    const lang = i18n.resolvedLanguage ?? 'pl';

    config.headers['Accept-Language'] = lang;

    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    res => {
      return res;
    },
    async err => {
      const originalConfig = err.config;

      const refreshToken = getRefreshToken();

      const shouldRefresh =
        !!refreshToken &&
        originalConfig.url !== api.endpoints.REFRESH &&
        originalConfig.url !== api.endpoints.LOGIN &&
        err.response;

      if (shouldRefresh) {
        if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
          try {
            originalConfig._retry = true;
            const response = await axiosInstance.post(api.endpoints.REFRESH, { refresh: refreshToken });
            const newAccessToken = response.data.access;
            setAccessToken(newAccessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            onRefreshError();
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    },
  );

  return axiosInstance;
};

export default useAxiosInstance;
