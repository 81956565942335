import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiPartnerActivity, IPartnerActivity } from '../../../types/api/ApiTypes';
import { PartnerParkingActivity } from '../../../types/partnerParkingActivities/partnerParkingActivities';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformPartnerActivity } from '../../../utils/dataTransformers/transformPartnerActivity/transformPartnerActivity';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface usePartnerActivitiesProps {
  activityType: PartnerParkingActivity | null;
}

const usePartnerActivities = ({ activityType }: usePartnerActivitiesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery([`${QueryAliases.PARTNER_ACTIVITIES}-${activityType}`], () =>
    axiosInstance.get(api.endpoints.PARTNER.ACTIVITIES, { params: { type: activityType ?? undefined } }),
  );

  const apiActivities: ApiPartnerActivity[] | undefined = data?.data;

  const activities: IPartnerActivity[] | undefined = !!apiActivities
    ? apiActivities.map(act => transformPartnerActivity(act))
    : undefined;

  return { activities };
};

export default usePartnerActivities;
