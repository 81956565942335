import * as yup from 'yup';
import { calculatePriceListDuration } from '../../utils/priceLists/calculateDuration/calculatePriceListDuration';
import {
  emailRegex,
  min1NumberRegex,
  min1SpecialCharRegex,
  min1UppercaseRegex,
  phoneRegex,
  verificationCodeRegex,
} from '../regex';

export const requiredArray = yup.array().min(1, 'required');

export const requiredString = yup.string().required('required');
export const requiredNumber = yup
  .number()
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value))
  .required('required');

export const requiredDate = yup.date().required('required');

export const notLessThanZeroNumber = yup
  .number()
  .min(0, 'min0')
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value))
  .required('required');

export const requiredPositiveNumber = yup
  .number()
  .positive('positiveNumber')
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value))
  .required('required');

export const requiredBoolean = yup.boolean().required('required');
export const requiredObject = yup.object().required('required');

export const email = yup.string().email('email').required('required');

export const phoneOrEmail = yup
  .string()
  .required('required')
  .test('phoneOrEmail', 'phoneOrEmail', function (value) {
    const isValidEmail = emailRegex.test(value);
    const isValidPhone = phoneRegex.test(value);
    if (!isValidEmail && !isValidPhone) {
      return false;
    }
    return true;
  });

export const password = yup
  .string()
  .required('required')
  .min(8, 'atLeast8Char')
  .matches(min1UppercaseRegex, 'min1Uppercase')
  .matches(min1NumberRegex, 'min1Number')
  .matches(min1SpecialCharRegex, 'min1SpecialChar');

export const repeatPassword = yup
  .string()
  .required('required')
  .test('passwordsDontMatch', 'passwordsDontMatch', function (value) {
    const { password } = this.parent;

    return value === password;
  });

export const phoneNumber = yup.string().matches(phoneRegex, 'phoneNumberIsNotValid');

export const uniquePhoneNumber = (existingPhoneNumbers: string[]) =>
  yup
    .string()
    .required('required')
    .test('unique-phone', 'phoneAlreadyUsed', function (value) {
      return !existingPhoneNumbers.includes(value);
    });

export const dropdownOption = yup.object().shape({
  id: yup.string(),
  value: yup.string(),
});

export const conditionalPhoneNumber = (isPhoneRequired: boolean) => {
  if (isPhoneRequired) {
    return phoneNumber;
  }

  return yup.string();
};

export const parkingSpotName = yup.string().required('required').max(8, 'max8Char');
export const priceListAppliesTo = requiredPositiveNumber.test(
  'price-list-applies-to',
  'endTimeToEarly',
  function (value) {
    const { appliesFrom, appliesFromTimeUnit, appliesToTimeUnit } = this.parent;

    const duration = calculatePriceListDuration({
      appliesFrom,
      appliesFromTimeUnit: appliesFromTimeUnit.id,
      appliesTo: value,
      appliesToTimeUnit: appliesToTimeUnit.id,
    });

    return duration > 0;
  },
);
export const conditionallyRequiredArray = (isRequired: boolean) => {
  if (isRequired) {
    return requiredArray;
  }

  return yup.array();
};

export const resetPasswordCode = yup
  .string()
  .required('required')
  .matches(verificationCodeRegex, 'invalidVerificationCode');
