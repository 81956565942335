import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteSpot from '../../../../../hooks/api/parkings/useDeleteSpot';
import { useParams } from 'react-router-dom';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import ConfirmModal from '../../../../../components/common/modals/ConfirmModal';
import { colors } from '../../../../../styles/variables';

interface TableRemoveParkingSpotButtonProps {
  id: string;
}

const TableRemoveParkingSpotButton = ({ id }: TableRemoveParkingSpotButtonProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const parkingId = params.parkingId as string;
  const parkingZoneId = params.zoneId as string;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { deleteSpot } = useDeleteSpot({ parkingId, parkingZoneId });

  const handleDeleteSpot = async () => {
    await deleteSpot(id);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div className="hover:cursor-pointer" onClick={() => setIsDeleteModalOpen(true)}>
        <TrashIcon />
      </div>

      <ConfirmModal
        title={t('parkingDetails.parkingSpots.table.deleteModal.title')}
        confirmQuestion={t('parkingDetails.parkingSpots.table.deleteModal.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('parkingDetails.parkingSpots.table.deleteModal.cancelBtnText')}
        confirmBtnText={t('parkingDetails.parkingSpots.table.deleteModal.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteSpot}
      />
    </>
  );
};

export default TableRemoveParkingSpotButton;
