import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectFile from '../../../../hooks/useSelectFile';
import { ParkingGalleryEntry } from '../../../../types/api/ApiTypes';
import AddImgIcon from '../../../icons/AddImgIcon';
import TrashIcon from '../../../icons/TrashIcon';
import IconButton from '../../buttons/IconButton';
import ImageSpotCard from '../ImageSpotCard';

interface MultiImagePickerProps {
  numOfImages: number;
  title: string;
  subtitle?: string;
  oldImageUrls: ParkingGalleryEntry[];
  removeOldImage: (id: string) => void;
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const MultiImagePicker = ({
  numOfImages,
  title,
  subtitle,
  oldImageUrls,
  removeOldImage,
  selectedFiles,
  setSelectedFiles,
}: MultiImagePickerProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { selectFile } = useSelectFile();

  const handleClickChangeImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const getImages = () => {
    const images = [];

    for (let i = 0; i < oldImageUrls.length && i < numOfImages; i++) {
      images.push(
        <ImageSpotCard key={i}>
          <img src={oldImageUrls[i]?.image} alt={`Image ${i}`} className="" />
          <IconButton
            icon={<TrashIcon width="16" />}
            onClick={() => removeOldImage(oldImageUrls[i]?.id as string)}
            className="absolute top-1.5 right-1.5 bg-white900 bg-opacity-90 w-6 h-6"
          />
        </ImageSpotCard>,
      );
    }

    if (images.length < numOfImages) {
      for (let i = 0; i < selectedFiles.length && oldImageUrls.length + i < numOfImages; i++) {
        if (!!selectedFiles[i]) {
          images.push(
            <ImageSpotCard key={i}>
              <img src={URL.createObjectURL(selectedFiles[i]!)} alt={`Image ${i}`} className="w-full h-full" />
              <IconButton
                icon={<TrashIcon width="16" />}
                onClick={() => handleRemoveSelectedImage(i)}
                className="absolute top-1.5 right-1.5 bg-white900 bg-opacity-90 w-6 h-6"
              />
            </ImageSpotCard>,
          );
        }
      }
    }

    if (images.length < numOfImages) {
      images.push(
        <ImageSpotCard>
          <button onClick={handleClickChangeImg} className="w-full h-full flex items-center justify-center ">
            <div className="bg-white900 border border-grey700 rounded-full p-3">
              <AddImgIcon />
            </div>
          </button>
        </ImageSpotCard>,
      );
    }

    return images;
  };

  return (
    <div className="flex flex-col gap-4">
      <h5 className="text-sm font-bold text-black700 leading-tight uppercase">
        {title} <span className="text-black500 normal-case font-medium">{subtitle}</span>
      </h5>

      <div className="grid  gap-1 grid-cols-[102px,2fr]">{getImages()}</div>

      <p className="text-xs text-black400">{`${t('galleryPicker.max')} ${numOfImages} ${t('galleryPicker.photos')}`}</p>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={async event => {
          await selectFile({
            event,
            onSelectFile: file => {
              if (file) {
                setSelectedFiles([...selectedFiles, file]);
              }
            },
            sizeLimitKb: 2048,
            allowedFileTypes: ['image/jpeg', 'image/png'],
          });
        }}
        className="hidden"
      />
    </div>
  );
};

export default MultiImagePicker;
