import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingPriceListDetails, IParkingPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingPriceListDetails } from '../../../utils/dataTransformers/transformParkingPriceListDetails/transformParkingPriceListDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useSingleParkingPriceList = ({ id, enabled }: { id: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_PARKING_PRICE_LIST}-${id}`],
    () => axiosInstance.get(api.endpoints.SINGLE_PARKING_PRICE_LIST(id)),
    {
      refetchOnMount: true,
      enabled,
    },
  );

  const apiPriceList: ApiParkingPriceListDetails | undefined = data?.data;

  const priceList: IParkingPriceListDetails | undefined = !!apiPriceList
    ? transformParkingPriceListDetails(apiPriceList)
    : undefined;

  return { priceList };
};

export default useSingleParkingPriceList;
