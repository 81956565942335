import { CheckIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentState, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../../components/common/buttons/BackButton';
import DeleteIconButton from '../../../../../components/common/buttons/DeleteIconButton';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import { LabeledStateDropdown } from '../../../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../../components/common/inputs/LabeledStateInput';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import { useTimeUnitTypes } from '../../../../../hooks/timeUnit/useTimeUnitTypes';
import { addPriceListChargerSchema } from '../../../../../static/validationSchema/priceList/addPriceListChargerSchema';
import { ComponentStates } from '../../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { ApiEvPriceListRequest, BillingMethodEnum, TimeUnits } from '../../../../../types/api/ApiTypes';
import { currencyDropdownOptions } from '../../../../../types/currency/CurrencyTypes';
import { PriceListFormProps } from '../PriceListForm';

interface IFormInputs {
  name: string;
  billingMethod: BillingMethodEnum;
  pricePerH: number | undefined;
  currency: DropdownOption;
  pricePerKwh: number | undefined;
  backoffTime: number | null;
  backoffTimeUnit: DropdownOption;
}

type InputsKeys = keyof IFormInputs;

interface PriceListChargerFormProps {
  priceListFormProps: PriceListFormProps;
}

export const PriceListChargerForm = ({ priceListFormProps }: PriceListChargerFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();
  const { timeUnitTypes } = useTimeUnitTypes();
  const minuteUnitType = timeUnitTypes.find(unit => unit.id === TimeUnits.MINUTE.toString())!;
  const initialValues = priceListFormProps.initialValues?.evValues;

  const [billingMethod, setChargerType] = useState<BillingMethodEnum>(
    initialValues?.billingMethod ?? BillingMethodEnum.PER_TIME,
  );

  const isPerTime = billingMethod === BillingMethodEnum.PER_TIME;
  const isPerKwh = billingMethod === BillingMethodEnum.PER_KWH;
  const isMixed = billingMethod === BillingMethodEnum.MIXED;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addPriceListChargerSchema(billingMethod)),
    defaultValues: {
      name: initialValues?.name ?? '',
      billingMethod: initialValues?.billingMethod ?? BillingMethodEnum.PER_TIME,
      pricePerH: !!initialValues?.pricePerH ? +initialValues.pricePerH : undefined,
      currency: currencyDropdownOptions.find(option => option.id === initialValues?.currency),
      pricePerKwh: !!initialValues?.pricePerKwh ? +initialValues.pricePerKwh : undefined,
      backoffTime: initialValues?.backoffTime,
      backoffTimeUnit: minuteUnitType,
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const requestData: ApiEvPriceListRequest = {
      name: data.name,
      billing_method: data.billingMethod,
      currency: data.currency.id,
      price_per_h: isPerTime || isMixed ? data.pricePerH?.toString() : null,
      price_per_kwh: isPerKwh || isMixed ? data.pricePerKwh?.toString() : null,
      backoff_time: isMixed ? data.backoffTime : null,
    };

    await priceListFormProps.onSubmitPriceListEv(requestData);
    reset();
  };

  return (
    <>
      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={2} title={t('priceListForm.name.title')} />
        <div className="lg:pl-8">
          <LabeledStateInput
            id="price-list-name"
            state={getInputState('name')}
            labeledProps={{
              wrapperClassName: 'mb-5 mt-6 max-w-xl',
              label: t('priceListForm.name.label'),
              errorMessage: t(`errorMessages.${errors.name?.message}`),
            }}
            inputProps={{
              register: {
                ...register('name'),
              },
            }}
          />
        </div>
      </div>

      <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
        <FormSectionTitle number={3} title={t('priceListForm.chargerForm.chargingType')} />
        <Controller
          name="billingMethod"
          control={control}
          render={({ field }) => (
            <div className="flex flex-col lg:flex-row max-w-xl gap-4 mt-6 lg:ml-8">
              <SecondaryButton
                markActive={field.value === BillingMethodEnum.PER_TIME}
                onClick={() => {
                  field.onChange(BillingMethodEnum.PER_TIME);
                  setChargerType(BillingMethodEnum.PER_TIME);
                }}
                prefixIcon={field.value === BillingMethodEnum.PER_TIME && <CheckIcon width={16} />}
              >
                {t('priceListForm.chargerForm.chargingTypeTime')}
              </SecondaryButton>
              <SecondaryButton
                markActive={field.value === BillingMethodEnum.PER_KWH}
                onClick={() => {
                  field.onChange(BillingMethodEnum.PER_KWH);
                  setChargerType(BillingMethodEnum.PER_KWH);
                }}
                prefixIcon={field.value === BillingMethodEnum.PER_KWH && <CheckIcon width={16} />}
              >
                {t('priceListForm.chargerForm.chargingTypeKwh')}
              </SecondaryButton>
              <SecondaryButton
                markActive={field.value === BillingMethodEnum.MIXED}
                onClick={() => {
                  field.onChange(BillingMethodEnum.MIXED);
                  setChargerType(BillingMethodEnum.MIXED);
                }}
                prefixIcon={field.value === BillingMethodEnum.MIXED && <CheckIcon width={16} />}
              >
                {t('priceListForm.chargerForm.chargingTypeMixed')}
              </SecondaryButton>
            </div>
          )}
        />
      </div>

      {(billingMethod === BillingMethodEnum.PER_TIME || isMixed) && (
        <div className={`pb-12 mt-16 lg:pb-16 ${isMixed && 'border-b  border-grey300'}`}>
          <FormSectionTitle number={4} title={t('priceListForm.chargerForm.pricePerHour')} />
          <div className="lg:pl-8">
            <div className="flex flex-col lg:flex-row items-center max-w-xl gap-3 mt-6 mb-5">
              <LabeledStateInput
                id="hour-price"
                state={getInputState('pricePerH')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.chargerForm.price'),
                  errorMessage: t(`errorMessages.${errors.pricePerH?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('pricePerH'),
                  },
                  type: 'number',
                  placeholder: t('priceListForm.pricePlaceholder'),
                }}
              />
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="hour-currency"
                    state={getInputState('currency')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.chargerForm.currency'),
                      errorMessage: t(`errorMessages.${errors.currency?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('priceListForm.currencyPlaceholder'),
                      options: currencyDropdownOptions,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}

      {(isPerKwh || isMixed) && (
        <div className={`pb-12 mt-16  lg:pb-16  ${isMixed && 'border-b border-grey300'}`}>
          <FormSectionTitle number={isMixed ? 5 : 4} title={t('priceListForm.chargerForm.pricePerKwh')} />
          <div className="lg:pl-8">
            <div className="flex flex-col lg:flex-row items-center max-w-xl gap-3 mt-6 mb-5">
              <LabeledStateInput
                id="kwh-price"
                state={getInputState('pricePerKwh')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.chargerForm.price'),
                  errorMessage: t(`errorMessages.${errors.pricePerKwh?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('pricePerKwh'),
                  },
                  type: 'number',
                  placeholder: t('priceListForm.pricePlaceholder'),
                }}
              />
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="kwh-currency"
                    state={getInputState('currency')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.chargerForm.currency'),
                      errorMessage: t(`errorMessages.${errors.currency?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('priceListForm.currencyPlaceholder'),
                      options: currencyDropdownOptions,
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}

      {isMixed && (
        <div className="pb-12 mt-16 lg:pb-16">
          <FormSectionTitle number={6} title={t('priceListForm.chargerForm.backoffTimeTitle')} />
          <div className="lg:pl-8">
            <div className="flex items-center max-w-xl gap-3 mt-6 mb-5">
              <LabeledStateInput
                id="time-fee-time"
                state={getInputState('backoffTime')}
                labeledProps={{
                  wrapperClassName: 'w-full',
                  label: t('priceListForm.chargerForm.backoffTime'),
                  errorMessage: t(`errorMessages.${errors.backoffTime?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('backoffTime'),
                  },
                  type: 'number',
                  placeholder: t('priceListForm.time60MinutesPlaceholder'),
                }}
              />
              <Controller
                name="backoffTimeUnit"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="time-fee-unit"
                    state={getInputState('backoffTimeUnit')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      label: t('priceListForm.chargerForm.backoffTimeUnit'),
                      errorMessage: t(`errorMessages.${errors.backoffTimeUnit?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('priceListForm.timeUnitMinutesPlaceholder'),
                      options: [minuteUnitType],
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}

      {!isTablet && (
        <div className="absolute right-0 -top-4">
          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            className="w-fit h-fit"
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </div>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
            {!!priceListFormProps.onDeletePriceList && (
              <DeleteIconButton onClick={priceListFormProps.onDeletePriceList} />
            )}
          </div>

          <PrimaryButton
            disabled={priceListFormProps.isSubmitBtnDisabled}
            onClick={handleSubmit(onSubmit)}
            prefixIcon={priceListFormProps.submitBtnIcon}
          >
            {priceListFormProps.submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </>
  );
};
