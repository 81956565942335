import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformChargers } from '../../../utils/dataTransformers/transformChargers/transformChargers';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useUserChargers = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery(
    [QueryAliases.USER_CHARGERS],
    () => axiosInstance.get(api.endpoints.USER_CHARGERS),
    {
      refetchOnMount: true,
      refetchInterval: 120000,
    },
  );

  return { chargers: transformChargers(data?.data), refetchChargers: refetch };
};

export default useUserChargers;
