import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../../components/wrappers/titleWrapper/TitleWrapper';
import useAddParkingRule from '../../../../hooks/api/parkings/rules/useAddParkingRule';
import useParkingRule from '../../../../hooks/api/parkings/rules/useParkingRule';
import useSingleParking from '../../../../hooks/api/parkings/useSingleParking';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import useGroupsDropdownOptions from '../../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import useParkingPriceListsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingPriceListsDropdownOptions';
import useParkingSpotsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { routes } from '../../../../static/routes';
import { ApiEditParkingRuleRequest, ApiParkingRuleRequest } from '../../../../types/api/ApiTypes';
import ParkingRuleForm from '../components/ParkingRuleForm';

const CopyParkingRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();
  const params = useParams();

  const parkingId = params.parkingId as string;
  const ruleId = params.ruleId as string;

  const { parking } = useSingleParking({ parkingId });
  const { rule } = useParkingRule({ parkingId, ruleId });
  const { addRule, isLoading } = useAddParkingRule();

  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });
  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListsDropdownOptions } = useParkingPriceListsDropdownOptions();

  const handleAddRule = async (rule: ApiEditParkingRuleRequest) => {
    const newRule: ApiParkingRuleRequest = {
      ...rule,
      parking_id: parkingId,
    };
    await addRule({ parkingId, rule: newRule });

    navigate(routes.parkingBasic.parkingRules(parkingId));
  };

  const isReady =
    !!rule && !!spotsDropdownOptions && !!groupsDropdownOptions && !!priceListsDropdownOptions && !!parking;

  const modifiedBreadcrumbs =
    !!parkingId && !!parking?.name ? [{ path: parkingId as string, text: parking?.name as string }] : [];

  return (
    <TitleWrapper
      title={`${t('addParkingRule.title')}${!!parking?.name && ` (${parking.name})`}`}
      breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}
    >
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      {isReady && (
        <ParkingRuleForm
          onSubmitForm={handleAddRule}
          isSubmitBtnLoading={isLoading}
          parkingId={parkingId}
          parkingType={parking.type}
          submitBtnText={t('addParkingRule.submitBtn')}
          initialValues={{
            rule: {
              ...rule,
              name: '',
            },
            spotsOptions: spotsDropdownOptions.filter(option => rule.parkingSpots.some(spot => spot === option.id)),
            groupsOptions: groupsDropdownOptions.filter(option => rule.groups.some(group => group.id === option.id)),
            priceListOption: priceListsDropdownOptions.find(option => option.id === rule.priceList),
          }}
        />
      )}
    </TitleWrapper>
  );
};

export default CopyParkingRule;
