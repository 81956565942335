import * as yup from 'yup';
import * as Schema from '../validationFields';

export const addPriceListTarrifsSchema = yup.object({
  price: Schema.notLessThanZeroNumber,
  intervalTimeUnit: Schema.requiredObject,
  appliesFrom: Schema.notLessThanZeroNumber,
  appliesFromTimeUnit: Schema.requiredObject,
  appliesTo: Schema.priceListAppliesTo,
  appliesToTimeUnit: Schema.requiredObject,
  minuteBilling: Schema.requiredBoolean,
  resetPrice: Schema.requiredBoolean,
});
