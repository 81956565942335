import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useChargingRuleDetails from '../../../hooks/api/chargers/useChargingRuleDetails';
import useCreateChargingRule from '../../../hooks/api/chargers/useCreateChargingRule';
import useSingleCharger from '../../../hooks/api/chargers/useSingleCharger';
import useGroupsDropdownOptions from '../../../hooks/dopdownOptions/useGroupsDropdownOptions';
import { routes } from '../../../static/routes';
import { ChargingRuleForm, IChargingRuleFormInputs } from './components/ChargingRuleForm';
import usePriceListDropdownOptions from './hooks/usePriceListDropdownOptions';

const CloneChargingRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, ruleId } = useParams();

  const { charger } = useSingleCharger({ id: id as string });
  const { rule } = useChargingRuleDetails({ id: id as string, ruleId: ruleId as string });

  const { createChargingRule, isLoading } = useCreateChargingRule(id ?? '');

  const handleAddChargingRule = async (props: IChargingRuleFormInputs, isApplyToAllChecked: boolean) => {
    await createChargingRule({
      name: props.name,
      connector_ids: props.connectors.map(item => item.id),
      group_ids: isApplyToAllChecked ? [] : props.groups.map(item => item.id),
      price_list_id: props.priceList.id,
    });

    navigate(routes.singleChargerRulesTable(id ?? ''));
  };

  const modifiedBreadcrumbs =
    !!charger?.id && !!charger?.name ? [{ path: charger?.id as string, text: charger?.name as string }] : [];

  const { groupsDropdownOptions } = useGroupsDropdownOptions();
  const { priceListDropdownOptions } = usePriceListDropdownOptions();

  const initialValues: IChargingRuleFormInputs | undefined =
    rule && charger && groupsDropdownOptions
      ? {
          name: rule.name,
          groups: rule.groups.map(item => {
            const group = groupsDropdownOptions.find(group => group.id === item.id)!;
            return {
              id: group?.id,
              value: group?.value,
            };
          }),
          connectors: rule.connectors.map(item => {
            const connector = charger.connectors.find(connector => connector.id === item)!;
            return {
              id: connector?.id,
              value: `${t('chargingRuleForm.connector')} ${connector?.port}`,
            };
          }),
          priceList: priceListDropdownOptions.find(item => item.id === rule.priceList)!,
        }
      : undefined;

  return (
    <TitleWrapper title={charger?.name ?? ''} breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}>
      <BackButton className="mt-8 mb-16 " />
      {initialValues && (
        <ChargingRuleForm
          onSubmitForm={handleAddChargingRule}
          submitBtnText={t('priceListForm.submitBtn')}
          submitBtnIcon={<PlusIcon height={16} />}
          isSubmitBtnDisabled={isLoading}
          charger={charger}
          initialValues={initialValues}
        />
      )}
    </TitleWrapper>
  );
};

export default CloneChargingRule;
