import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSearchBar from '../../../context/searchBarContextProvider/useSearchBar';
import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';
import SearchIcon from '../../icons/SearchIcon';
import XMarkIcon from '../../icons/XmarkIcon';
import IconButton from '../buttons/IconButton';
import { Input } from '../inputs/Input';

interface SearchBarProps {
  onOpen?: CallbackDefault;
  onClose?: CallbackDefault;
  showCancelBtn?: boolean;
}

const SearchBar = ({ onOpen, onClose, showCancelBtn }: SearchBarProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const { searchValue: globalSearchValue, setSearchValue: setGlobalSearchValue } = useSearchBar();
  const [localSearchValue, setLocalSearchValue] = useState(globalSearchValue);
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = () => {
    setGlobalSearchValue(localSearchValue);
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleClear = () => setLocalSearchValue('');

  const handleSearchButtonClick = () => {
    if (!isOpen) {
      handleOpen();
    } else {
      handleSearch();
    }
  };

  const handleClearButtonClick = () => {
    handleClear();
    handleClose();
  };

  useEffect(() => {
    if (!isOpen && !!onClose) {
      onClose();
    }

    if (isOpen && !!onOpen) {
      onOpen();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    containerRef.current?.querySelector('input')?.focus();
  }, [isOpen]);

  return (
    <div ref={containerRef} className="flex h-fit grow">
      <div
        className={`relative flex rounded-md grow ${
          isOpen ? 'outline outline-2 outline-blue500 transition-all duration-300' : ''
        }`}
      >
        <div className="relative grow">
          <Input
            value={localSearchValue}
            onChange={e => setLocalSearchValue(e.target.value)}
            placeholder={t('searchBar.placeholder')}
            className={`py-2 px-0 h-full outline-0 focus:outline-0 bg-white900 focus:bg-grey100 ${
              isOpen ? 'w-full transition-all duration-300 pl-3 pr-8' : 'w-0 max-w-0 transition-all duration-300 px-0'
            }`}
          />

          {!!localSearchValue && isOpen && (
            <button
              onClick={handleClearButtonClick}
              className="px-3 bg-transparent absolute right-0 top-1/2 -translate-y-1/2"
            >
              <XMarkIcon color={colors.black500} />
            </button>
          )}
        </div>

        <IconButton
          onClick={handleSearchButtonClick}
          icon={<SearchIcon color={isOpen ? 'white' : 'black'} />}
          className={`${isOpen ? 'bg-blue500 bg-opacity-100 rounded-none' : 'border border-grey300 rounded-md'}`}
        />
      </div>

      {showCancelBtn && isOpen && (
        <button onClick={handleClose} className="ml-2 text-sm text-grey700">
          {t('searchBar.clear')}
        </button>
      )}
    </div>
  );
};

export default SearchBar;
