import { useTranslation } from 'react-i18next';
import MyDataTable from '../../../components/dataTable/MyDataTable';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useParkingsPageTable from '../../../hooks/pages/useParkingsPageTable';
import ParkingsFilter from './components/ParkingsFilter';

const Parkings = () => {
  const { t } = useTranslation();
  const { headers, data } = useParkingsPageTable();

  return (
    <TitleWrapper title={t('parkings.title')}>
      {data && (
        <div className="relative w-full">
          <div className="mb-6">
            <ParkingsFilter />
          </div>
          <div className="w-full">
            <MyDataTable headers={headers} data={data} />
          </div>
        </div>
      )}
    </TitleWrapper>
  );
};

export default Parkings;
