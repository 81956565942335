import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../../../../components/dataTable/components/HoverableTooltip';
import CopyIcon from '../../../../../../components/icons/CopyIcon';
import EditIcon from '../../../../../../components/icons/EditIcon';
import InfoIcon from '../../../../../../components/icons/InfoIcon';
import { routes } from '../../../../../../static/routes';
import { colors } from '../../../../../../styles/variables';
import { Availability, IParkingSpotDetailsByParking } from '../../../../../../types/api/ApiTypes';
import { formatStringList } from '../../../../../../utils/formatStringList/formatStringList';
import { isFullAvailability } from '../../../../../parkings/rules/utils/isFullAvailability/isFullAvailability';

interface UseParkingRulesParkingTable {
  parkingId: string;
  zoneId: string;
  parkingSpots: IParkingSpotDetailsByParking[];
}

const useParkingRulesParkingTable = ({ parkingId, parkingSpots, zoneId }: UseParkingRulesParkingTable) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headers = [
    {
      field: 'name',
      headerName: t('parkingDetails.parkingRules.table.name'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.name}</p>;
      },
    },

    {
      field: 'users',
      headerName: t('parkingDetails.parkingRules.table.users'),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const users: string[] = params.row.groups.map((group: { id: string; name: string }) => group.name);

        const usersToDisplay = formatStringList(users);

        return <p className="flex align-middle">{usersToDisplay}</p>;
      },
    },

    {
      field: 'priceList',
      headerName: t('parkingDetails.parkingRules.table.priceList'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="flex align-middle">{params.row.priceList}</p>;
      },
    },

    {
      field: 'parkingSpot',
      headerName: t('parkingDetails.parkingRules.table.parkingSpot'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const spotIds: string[] = params.row.parkingSpots;

        const areAllSpots = spotIds.length === parkingSpots.length;

        const spotNames = spotIds.map(spotId => {
          const spotDetails = parkingSpots.find(spot => spot.id === spotId);

          if (!!spotDetails) {
            return spotDetails.parkingSpotNumber;
          } else {
            return '';
          }
        });

        const spotNamesToDisplay = formatStringList(spotNames);

        return (
          <p className="flex align-middle">
            {areAllSpots ? t('parkingDetails.parkingRules.table.allSpots') : spotNamesToDisplay}
          </p>
        );
      },
    },

    {
      field: 'availability',
      headerName: t('parkingDetails.parkingRules.table.availability'),
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const availabilities: Availability[] = params.row.availabilities;

        const isFull = isFullAvailability(availabilities);

        return (
          <div className="my-2">
            {isFull ? (
              <p>{t('parkingDetails.parkingRules.table.fullAvailability')}</p>
            ) : (
              <div className="flex items-center gap-2">
                <p>{t('parkingDetails.parkingRules.table.notFullAvailability')}</p>
                <HoverableTooltip
                  tooltipPlacement="left"
                  tooltipContent={
                    <div>
                      {availabilities.map(av => (
                        <div key={av.weekday} className="flex justify-between gap-3">
                          <p>{`${t(`weekdays.${av.weekday}`)}: `}</p>
                          <p>
                            {av.start.slice(0, 5)}-{av.end.slice(0, 5)}
                          </p>
                        </div>
                      ))}
                    </div>
                  }
                >
                  {isHovered => <InfoIcon color={isHovered ? colors.blue500 : colors.grey700} />}
                </HoverableTooltip>
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: '',
      headerName: '',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const handleNavigate = (
          route: {
            ({ parkingId, ruleId, zoneId }: { parkingId: string; ruleId: string; zoneId?: string }): string;
          },
          withZone?: boolean,
        ) => {
          navigate(route({ parkingId: parkingId, ruleId: params.row.id, ...(withZone && { zoneId: zoneId }) }));
        };

        return (
          <div className="flex justify-end w-full">
            <button className="p-3" onClick={() => handleNavigate(routes.copyParkingRule, false)}>
              <HoverableTooltip tooltipContent={<p>{t('common.copy')}</p>}>
                {isHovered => <CopyIcon color={isHovered ? colors.blue500 : colors.grey700} />}
              </HoverableTooltip>
            </button>
            <button
              className="p-3"
              onClick={() => handleNavigate(zoneId ? routes.editParkingZoneRule : routes.editParkingRule, !!zoneId)}
            >
              <HoverableTooltip tooltipContent={<p>{t('common.edit')}</p>}>
                {isHovered => <EditIcon color={isHovered ? colors.blue500 : colors.grey700} />}
              </HoverableTooltip>
            </button>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useParkingRulesParkingTable;
