import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import CopyIcon from '../../../../components/icons/CopyIcon';
import QrFrameIcon from '../../../../components/icons/QrFrameIcon';
import ScanIcon from '../../../../components/icons/ScanIcon';
import { IPromotion } from '../../../../types/api/ApiTypes';
import PromoTabs from './promoTabs/PromoTabs';

interface PromoCodesProps {
  promotions: IPromotion[];
}

const PromoCodes = ({ promotions }: PromoCodesProps) => {
  const { t } = useTranslation();

  const [activePromotion, setActivePromotion] = useState<IPromotion>(promotions[0]!);

  const copyCode = () => {
    navigator.clipboard.writeText(activePromotion.qrPromo);
  };

  return (
    <>
      <PromoTabs promotions={promotions} activePromotion={activePromotion} setActivePromotion={setActivePromotion} />

      <div className="p-4 lg:p-8 mt-12 mb-10 bg-blueBtnBg rounded-xl shadow-lightBlue max-w-[30rem] mx-auto relative">
        <div className="rounded-full flex items-center justify-center w-12 min-w-[3rem] h-12 bg-white900 shadow-mediumBlue absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2">
          <ScanIcon />
        </div>

        <div className="p-4 rounded-md bg-white900 flex flex-col items-center gap-8">
          <div className="">
            <h3 className="text-2xl font-semibold mb-4 text-black700 text-center">
              {t('partnerPromoCodes.scanCodeTitle')}
            </h3>
            <p className="text-sm text-black700 leading-normal text-center">
              {t('partnerPromoCodes.scanCodeSubtitle')}
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="w-[12.5rem] h-[13.375rem] flex items-center justify-center relative">
              <QrFrameIcon className="absolute top-2 left-2" />
              <QrFrameIcon className="absolute top-2 right-2 rotate-90" />
              <QrFrameIcon className="absolute bottom-2 left-2 -rotate-90" />
              <QrFrameIcon className="absolute bottom-2 right-2 rotate-180" />

              <div className="px-2.5 py-4">
                <QRCode value={activePromotion.qrPromo} size={121} />
              </div>
            </div>

            {!!activePromotion?.qrExpirationDate && !!activePromotion.qrExpirationDate && (
              <p className="mt-2 text-center text-xs text-grey700">
                {t('partnerPromoCodes.validUntil')} {activePromotion.qrExpirationDate.toLocaleDateString()}{' '}
                {t('partnerPromoCodes.validHour')} {activePromotion.qrExpirationDate.toLocaleTimeString()}
              </p>
            )}
          </div>

          <div className="py-2 px-4 flex items-center">
            <div className="h-[1px] w-6 bg-black400" />
            <p className="text-black400 font-semibold mx-4">{t('partnerPromoCodes.writeManually')}</p>
            <div className="h-[1px] w-6 bg-black400" />
          </div>

          <div className="w-full px-4">
            <div className="relative border border-grey700 rounded-md flex items-center py-3.5 pl-5 pr-12">
              <p className="grow truncate pr-4">{activePromotion.qrPromo}</p>
              <button
                onClick={copyCode}
                className="absolute right-0 top-1/2 -translate-y-1/2 px-4 py-2 h-full border-l border-grey700 bg-grey100 rounded-r-md"
              >
                <CopyIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCodes;
