import { ApiParkingRulesItem, IParkingRulesItem } from '../../../types/api/ApiTypes';

export const transformParkingRulesItem = (apiRule: ApiParkingRulesItem): IParkingRulesItem => {
  const transformedRule: IParkingRulesItem = {
    id: apiRule.id,
    name: apiRule.name,
    groups: apiRule.groups,
    priceList: apiRule.price_list,
    parkingSpots: apiRule.parking_spots,
    availabilities: apiRule.availabilities,
    permissions: {
      canEdit: apiRule.permissions.can_edit,
      canDelete: apiRule.permissions.can_delete,
    },
  };

  return transformedRule;
};
