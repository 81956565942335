import useAxiosInstance from '../../axios/useAxiosInstance';
import { useQuery } from 'react-query';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { api } from '../../../static/api';
import { ApiActivity, IActivity } from '../../../types/api/ApiTypes';
import { transformActivitiesList } from '../../../utils/dataTransformers/transformActivitiesList/transformActivitiesList';

interface useActivitiesListProps {
  parkingId: string;
}

const useActivitiesList = ({ parkingId }: useActivitiesListProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.ACTIVITIES_LIST}-${parkingId}`],
    () => axiosInstance.get(api.endpoints.ACTIVITIES_LIST({ parkingId })),
    {
      cacheTime: 1,
    },
  );

  const apiActivitiesList: ApiActivity[] | undefined = data?.data;

  const activities: IActivity[] | undefined = !!apiActivitiesList
    ? apiActivitiesList.map(activity => transformActivitiesList(activity))
    : [];

  return { activities };
};

export default useActivitiesList;
