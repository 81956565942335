import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../components/common/buttons/BackButton';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { LabeledStateDropdown } from '../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../components/common/formSectionTitle/FormSectionTitle';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import useSubscriptionsDropdownOptions from '../../../hooks/dopdownOptions/useSubscriptionsDropdownOptions';
import { addSubscriptionValidationSchema } from '../../../static/validationSchema/addSubscriptionValidationSchema';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';
import { DropdownOption } from '../../../types/Dropdown.types';

interface IFormInputs {
  subscriptionId: DropdownOption;
}

type InputsKeys = keyof IFormInputs;

interface AddSubscriptionFormProps {
  onSubmitForm: ({ subscriptionId }: { subscriptionId: string }) => void;
  submitBtnText: string;
  submitBtnIcon: React.ReactNode;
  isSubmitBtnDisabled?: boolean;
}

const AddSubscriptionForm = ({
  onSubmitForm,
  submitBtnText,
  submitBtnIcon,
  isSubmitBtnDisabled = false,
}: AddSubscriptionFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();
  const { subscriptionsDropdownOptions } = useSubscriptionsDropdownOptions();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(addSubscriptionValidationSchema),
    defaultValues: {
      subscriptionId: undefined,
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async ({ subscriptionId }) => {
    onSubmitForm({ subscriptionId: subscriptionId.id });
    reset();
  };

  return (
    <div className="flex justify-between gap-16 pb-8 mb-52 lg:mb-0 grow">
      <div className="w-full max-w-5xl grow">
        <div className="pb-12 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={1} title={t('parkingDetails.zones.subscriptions.create.label')} />
          <div className="lg:pl-8">
            <Controller
              name="subscriptionId"
              control={control}
              render={({ field }) => (
                <LabeledStateDropdown
                  id="subscription-id"
                  state={getInputState('subscriptionId')}
                  labeledProps={{
                    wrapperClassName: 'mb-5 mt-6 max-w-xl',
                    errorMessage: t(`errorMessages.${errors.subscriptionId?.message}`),
                  }}
                  dropdownProps={{
                    placeholder: t('parkingDetails.zones.subscriptions.create.placeholder'),
                    options: subscriptionsDropdownOptions,
                    currentOption: field.value,
                    setNewOption: field.onChange,
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={isSubmitBtnDisabled}
          onClick={handleSubmit(onSubmit)}
          className="w-fit h-fit"
          prefixIcon={submitBtnIcon}
        >
          {submitBtnText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
          </div>

          <PrimaryButton disabled={isSubmitBtnDisabled} onClick={handleSubmit(onSubmit)} prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};

export default AddSubscriptionForm;
