import { useTranslation } from 'react-i18next';
import MyDataTable from '../../components/dataTable/MyDataTable';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useChargersPageTable from '../../hooks/pages/useChargersPageTable';

const Chargers = () => {
  const { t } = useTranslation();

  const { headers, data } = useChargersPageTable();

  return (
    <TitleWrapper title={t('chargers.title')}>
      {data && (
        <div className="w-full relative lg:mt-24">
          <MyDataTable headers={headers} data={data} />
        </div>
      )}
    </TitleWrapper>
  );
};

export default Chargers;
