import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActionBox } from '../../../../../../components/common/bottomActionBox/BottomActionBox';
import BackButton from '../../../../../../components/common/buttons/BackButton';
import { PrimaryButton } from '../../../../../../components/common/buttons/PrimaryButton';
import BorderCard from '../../../../../../components/common/cards/BorderCard';
import LightBorderMobileCard from '../../../../../../components/common/cards/LightBorderMobileCard';
import { LabeledStateDatepicker } from '../../../../../../components/common/datepicker/LabeledDatepicker';
import { LabeledStateDropdown } from '../../../../../../components/common/dropdowns/LabeledStateDropdown';
import { LabeledStateInput } from '../../../../../../components/common/inputs/LabeledStateInput';
import SaveIcon from '../../../../../../components/icons/SaveIcon';
import { useIsTablet } from '../../../../../../hooks/devices/useIsTablet';
import { userValidationSchema } from '../../../../../../static/validationSchema/userValidationSchema';
import { ComponentState, ComponentStates } from '../../../../../../types/ComponentStates.types';
import { DropdownOption } from '../../../../../../types/Dropdown.types';
import { ApiUserRequest, IUser } from '../../../../../../types/api/ApiTypes';
import { Country } from '../../../../../../types/countries/Countries';
import UserBasicInfo from './UserBasicInfo';
import UserImage from './UserImage';

const findCountryOptionById = ({
  allOptions,
  id,
}: {
  allOptions: DropdownOption[];
  id: string;
}): DropdownOption | undefined => {
  return allOptions.find(option => option.id === id);
};

export interface IFormInputs {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  address: string;
  postCode: string;
  city: string;
  country: DropdownOption;
}

type InputsKeys = keyof IFormInputs;

interface UserFormProps {
  onSubmitForm: ({ newUser, file }: { newUser: ApiUserRequest; file: File | null }) => void;
  user: IUser;
  isSubmitBtnDisabled: boolean;
}

const UserForm = ({ onSubmitForm, user, isSubmitBtnDisabled }: UserFormProps) => {
  const { t } = useTranslation();
  const { isTablet } = useIsTablet();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const countriesTranslations = t('countries', {
    returnObjects: true,
  });

  const countryOptions: DropdownOption[] = Object.entries(countriesTranslations).map(([id, value]) => ({ id, value }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(userValidationSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      dateOfBirth: user.profile.birthDate ? new Date(user.profile.birthDate) : null,
      address: user.profile.address.addressLine2,
      postCode: user.profile.address.postCode,
      city: user.profile.address.city,
      country: findCountryOptionById({ allOptions: countryOptions, id: user.profile.address.country as string }),
    },
  });

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const newUser: ApiUserRequest = {
      first_name: data.firstName,
      last_name: data.lastName,
      profile: {
        address: {
          country: findCountryOptionById({ allOptions: countryOptions, id: data.country.id })!.id as Country,
          ...(!!data.address && { address_line_2: data.address }),
          ...(!!data.postCode && { post_code: data.postCode }),
          ...(!!data.city && { city: data.city }),
        },
        ...(!!data.dateOfBirth && { birth_date: dayjs(data.dateOfBirth).format('YYYY-MM-DD') }),
      },
    };

    onSubmitForm({ newUser, file: selectedFile });
  };

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const submitButtonText = t('userAccount.userInfo.submitBtn');
  const submitButtonHandler = handleSubmit(onSubmit);
  const submitButtonIcon = <SaveIcon height={24} width={24} />;

  return (
    <div className="grow flex justify-between gap-16">
      <div className="pb-44 grow w-full max-w-5xl">
        <LightBorderMobileCard>
          <UserImage oldAvatarUrl={user.profile.avatar} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />

          <UserBasicInfo phone={user.phoneNumber} email={user.email} />

          <BorderCard className="mt-12 flex flex-col gap-4">
            <LabeledStateInput
              id="user-first-name"
              state={getInputState('firstName')}
              labeledProps={{
                wrapperClassName: 'mb-4 max-w-md',
                label: t('userAccount.userInfo.firstName'),
                errorMessage: t(`errorMessages.${errors.firstName?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('firstName'),
                },
              }}
            />
            <LabeledStateInput
              id="user-last-name"
              state={getInputState('lastName')}
              labeledProps={{
                wrapperClassName: 'mb-4 max-w-md',
                label: t('userAccount.userInfo.lastName'),
                errorMessage: t(`errorMessages.${errors.lastName?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('lastName'),
                },
              }}
            />

            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field }) => (
                <LabeledStateDatepicker
                  id="user-date-of-birth"
                  state={getInputState('dateOfBirth')}
                  labeledProps={{
                    wrapperClassName: 'mb-4 max-w-md',
                    label: t('userAccount.userInfo.dateOfBirth'),
                    errorMessage: t(`errorMessages.${errors.dateOfBirth?.message}`),
                  }}
                  datepickerProps={{
                    date: field.value,
                    setDate: field.onChange,
                  }}
                />
              )}
            />
          </BorderCard>

          <BorderCard className="mt-12 flex flex-col gap-4">
            <LabeledStateInput
              id="user-address"
              state={getInputState('address')}
              labeledProps={{
                wrapperClassName: 'mb-4 max-w-md',
                label: t('userAccount.userInfo.address'),
                errorMessage: t(`errorMessages.${errors.address?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('address'),
                },
              }}
            />
            <LabeledStateInput
              id="user-post-code"
              state={getInputState('postCode')}
              labeledProps={{
                wrapperClassName: 'mb-4 max-w-md',
                label: t('userAccount.userInfo.postCode'),
                errorMessage: t(`errorMessages.${errors.postCode?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('postCode'),
                },
              }}
            />

            <LabeledStateInput
              id="user-city"
              state={getInputState('city')}
              labeledProps={{
                wrapperClassName: 'mb-4 max-w-md',
                label: t('userAccount.userInfo.city'),
                errorMessage: t(`errorMessages.${errors.city?.message}`),
              }}
              inputProps={{
                register: {
                  ...register('city'),
                },
              }}
            />

            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <LabeledStateDropdown
                  id="user-country"
                  state={getInputState('country')}
                  labeledProps={{
                    wrapperClassName: 'mb-4 max-w-md',
                    label: t('userAccount.userInfo.country'),
                    errorMessage: t(`errorMessages.${errors.country?.message}`),
                  }}
                  dropdownProps={{
                    placeholder: t('userAccount.userInfo.country'),
                    options: countryOptions,
                    currentOption: field.value,
                    setNewOption: field.onChange,
                  }}
                />
              )}
            />
          </BorderCard>
        </LightBorderMobileCard>
      </div>

      {!isTablet && (
        <PrimaryButton
          disabled={isSubmitBtnDisabled}
          onClick={submitButtonHandler}
          className="w-fit h-fit"
          prefixIcon={submitButtonIcon}
        >
          {submitButtonText}
        </PrimaryButton>
      )}

      {isTablet && (
        <BottomActionBox>
          <div className="flex justify-between">
            <BackButton className="mb-4" />
          </div>

          <PrimaryButton disabled={isSubmitBtnDisabled} onClick={submitButtonHandler} prefixIcon={submitButtonIcon}>
            {submitButtonText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </div>
  );
};

export default UserForm;
