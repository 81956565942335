import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAxiosInstance from '../../axios/useAxiosInstance';
import { ApiShareParkingSpot } from '../../../types/api/ApiTypes';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { AxiosError } from 'axios';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useToast from '../../useToast';
const useShareSpot = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ parkingId, shareData }: { parkingId: string; shareData: ApiShareParkingSpot }) =>
      axiosInstance.post(api.endpoints.PARKING_SHARE_SPOT({ id: parkingId }), {
        ...shareData,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([QueryAliases.PARKING_CALENDAR]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.addingParkingRuleFailed`));
      },
    },
  );
  return { shareSpot: mutateAsync, isLoading };
};

export default useShareSpot;
