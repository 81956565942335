export const calculatePriceListDuration = ({
  appliesFrom,
  appliesFromTimeUnit,
  appliesTo,
  appliesToTimeUnit,
  ignoreFromTime,
}: {
  appliesFrom: number;
  appliesFromTimeUnit: number;
  appliesTo: number;
  appliesToTimeUnit: number;
  ignoreFromTime?: boolean;
}) => {
  const fromTime = appliesFrom * appliesFromTimeUnit;
  const toTime = appliesTo * appliesToTimeUnit;

  if (ignoreFromTime) {
    return toTime;
  }

  return toTime - fromTime;
};
