import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../static/routes';
import EditIcon from '../../../../components/icons/EditIcon';
import TableRemoveParkingSpotButton from '../../parkingBasic/parkingSpots/components/TableRemoveParkingSpotButton';

interface ActionButtonProps {
  canEdit: boolean;
  canDelete: boolean;
  spotId: string;
}

const ParkingSpotListActionButtons = ({ canEdit, canDelete, spotId }: ActionButtonProps) => {
  const navigate = useNavigate();

  return (
    <>
      {(canEdit || canDelete) && (
        <div className="flex w-full justify-end pl-7 py-4 gap-2.5">
          {canEdit && (
            <div className="hover:cursor-pointer" onClick={() => navigate(routes.editParkingSpot(spotId))}>
              <EditIcon />
            </div>
          )}
          {canDelete && <TableRemoveParkingSpotButton id={spotId} />}
        </div>
      )}
    </>
  );
};

export default ParkingSpotListActionButtons;
