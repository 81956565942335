import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const CustomSwitch = ({
  isChecked,
  onChange,
  className,
}: {
  isChecked: boolean;
  onChange: (active: boolean) => void;
  className?: string;
}) => {
  const [active, setActive] = useState(isChecked);

  const onClick = () => {
    onChange(!active);
    setActive(!active);
  };

  return (
    <div
      onClick={() => onClick()}
      className={twMerge(
        `w-[3.25rem] h-[2rem] rounded-full relative transition-all  duration-200 cursor-pointer ${
          active ? 'bg-blue-500' : 'bg-gray-300'
        } ${className}`,
      )}
    >
      <span
        className={twMerge(
          `w-6 h-6 rounded-full absolute top-1/2 left-1 bg-white900 translate-y-[-50%] ${
            active ? 'translate-x-5' : 'translate-x-0'
          }`,
        )}
      ></span>
    </div>
  );
};

export default CustomSwitch;
