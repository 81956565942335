import { ComponentState } from '../../../types/ComponentStates.types';
import { LabeledComponent, LabeledComponentProperties, LabeledComponentProps } from '../inputs/LabeledComponent';
import Dropdown, { DropdownProps } from './Dropdown';

interface LabeledProps extends Omit<LabeledComponentProps, 'children'> {
  errorMessage?: string | undefined;
}

interface LabeledStateDropdownProps {
  id: string;
  state: ComponentState;

  labeledProps: LabeledProps;
  dropdownProps: DropdownProps;
}

export const LabeledStateDropdown = ({ id, labeledProps, dropdownProps, state }: LabeledStateDropdownProps) => {
  const { errorMessage, stateProperties: labeledStateProperties } = labeledProps;

  const myStateProperties: LabeledComponentProperties = {
    error: {
      message: errorMessage,
      color: 'text-error',
    },
    default: {},
    ...labeledStateProperties,
  };

  const labeledComponentProps = {
    id,
    state,
    stateProperties: myStateProperties,
    ...labeledProps,
  };

  return (
    <LabeledComponent {...labeledComponentProps}>
      <Dropdown {...dropdownProps} />
    </LabeledComponent>
  );
};
