import useParkingSpots from '../api/parkings/useParkingSpots';
import { useTranslation } from 'react-i18next';

interface useParkingSpotsCalendarDropdownOptions {
  parkingId: string;
  parkingZoneId?: string;
}

const useParkingSpotsCalendarDropdownOptions = ({
  parkingId,
  parkingZoneId,
}: useParkingSpotsCalendarDropdownOptions) => {
  const { parkingSpots, isLoading } = useParkingSpots({ parkingId, parkingZoneId });
  const { t } = useTranslation();
  const spotsDropdownOptions: { text: string; value: string; id: string }[] = [
    {
      id: 'all',
      value: 'all',
      text: t('parkingSpotCalendar.all'),
    },
  ];
  if (parkingSpots) {
    const spots = parkingSpots.map(spot => ({
      id: spot.id,
      value: spot.id,
      text: spot.parkingSpotNumber,
    }));
    spotsDropdownOptions.push(...spots);
  }

  return { spotsDropdownOptions, isLoading };
};

export default useParkingSpotsCalendarDropdownOptions;
