/* eslint-disable @typescript-eslint/no-unused-vars */
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import LabeledStateCodeInput from '../../../components/common/inputs/LabeledStateCodeInput';
import { ComponentState, ComponentStates } from '../../../types/ComponentStates.types';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requestResetPasswordCodeSchema } from '../../../static/validationSchema/requestResetPasswordCodeSchema';

interface IFormInput {
  code: string;
}

type InputsKeys = keyof IFormInput;

interface RequestResetPasswordCodeFormProps {
  onSubmitForm: (code: string) => Promise<void>;
  isLoading: boolean;
}

const RequestResetPasswordCodeForm = ({ onSubmitForm, isLoading }: RequestResetPasswordCodeFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(requestResetPasswordCodeSchema),
    defaultValues: {
      code: '',
    },
  });

  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({ code }) => {
    await onSubmitForm(code);

    reset();
  };

  return (
    <>
      <p className="mt-4 text-black500 font-medium leading-snug">{t('requestResetPassword.stepCode.description')}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <LabeledStateCodeInput
              id="code"
              state={getInputState('code')}
              labeledProps={{
                wrapperClassName: 'mt-10 mb-8',
                errorMessage: t(`errorMessages.${errors.code?.message}`),
              }}
              codeInputProps={{
                value: field.value,
                setValue: newValue => {
                  field.onChange(newValue);
                },
              }}
            />
          )}
        />
        <PrimaryButton type="submit" disabled={isLoading}>
          {t('requestResetPassword.stepCode.submitBtn')}
        </PrimaryButton>
      </form>
    </>
  );
};

export default RequestResetPasswordCodeForm;
