import { colors } from '../../styles/variables';

interface LoadingIconProps {
  color?: string;
}

const LoadingIcon = ({ color }: LoadingIconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19995 1.5V3.16667M8.19995 12V14.6667M4.03328 8H1.69995M14.3666 8H13.3666M12.5047 12.3047L12.0333 11.8333M12.6428 3.61052L11.7 4.55333M3.481 12.719L5.36662 10.8333M3.61907 3.47245L5.03328 4.88667"
        stroke={color ?? colors.success}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoadingIcon;
