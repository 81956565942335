import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiChargingRule, IChargingRule } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformChargingRule } from '../../../utils/dataTransformers/transformChargingRule.ts/transformChargingRule';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useChargingRuleDetailsProps {
  id: string;
  ruleId: string;
}

const useChargingRuleDetails = ({ id, ruleId }: useChargingRuleDetailsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.CHARGER_DETAILS}-${id}/${QueryAliases.RULE_DETAILS}-${ruleId}`],
    () => axiosInstance.get(api.endpoints.CHARGING_RULE({ id: id, ruleId: ruleId })),
    {
      cacheTime: 1,
    },
  );

  const apiRule: ApiChargingRule | undefined = data?.data;

  const rule: IChargingRule | undefined = !!apiRule ? transformChargingRule(apiRule) : undefined;

  return { rule };
};

export default useChargingRuleDetails;
