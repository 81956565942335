import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { api } from '../../../static/api';
import { ApiChargingRule, IChargingRules } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformChargingRules } from '../../../utils/dataTransformers/transformChargingRules/transformChargingRules';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useSingleChargerRules = () => {
  const { id } = useParams();
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery(
    [QueryAliases.USER_SINGLE_CHARGER_RULES],
    () => axiosInstance.get(api.endpoints.SINGLE_CHARGER.RULES({ id: id as string })),
    {
      cacheTime: 1,
    },
  );

  const apiRules: ApiChargingRule[] | undefined = data?.data;

  const rules: IChargingRules[] | undefined = !!apiRules ? transformChargingRules(apiRules) : undefined;

  return { rules, refetchRules: refetch };
};

export default useSingleChargerRules;
