import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiEntryDevice, IEntryDevice } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformEntryDevice } from '../../../utils/dataTransformers/transformEntryDevice/transformEntryDevice';
import useAxiosInstance from '../../axios/useAxiosInstance';

const usePartnerDevices = () => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.PARTNER_DEVICES}}`],
    () => axiosInstance.get(api.endpoints.PARTNER.DEVICES),
    {
      refetchOnMount: true,
    },
  );

  const apiDevices: ApiEntryDevice[] | undefined = data?.data;

  const devices: IEntryDevice[] | undefined = !!apiDevices
    ? apiDevices.map(dev => transformEntryDevice(dev))
    : undefined;

  return { devices };
};

export default usePartnerDevices;
