import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiSubscriptionsPriceListDetails, ISubscriptionsPriceListDetails } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformSubscriptionsPriceListDetails } from '../../../utils/dataTransformers/transformSubscriptionsPriceListsDetails/transformSubscriptionsPriceListsDetails';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useSingleSubscriptionPriceList = ({ id, enabled }: { id: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.SINGLE_SUBSCRIPTION_PRICE_LIST}-${id}`],
    () => axiosInstance.get(api.endpoints.SINGLE_SUBSCRIPTION_PRICE_LIST(id)),
    {
      refetchOnMount: true,
      enabled,
    },
  );

  const apiSubPriceList: ApiSubscriptionsPriceListDetails | undefined = data?.data;

  const priceList: ISubscriptionsPriceListDetails | undefined = !!apiSubPriceList
    ? transformSubscriptionsPriceListDetails(apiSubPriceList)
    : undefined;

  return { priceList };
};

export default useSingleSubscriptionPriceList;
