import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../queryClient';
import { api } from '../../../../static/api';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';
import useSingleZoneParkingSubscriptions from './useSingleZoneParkingSubscriptions';

interface useCreateSubscriptionProps {
  zoneId: string;
}

const useCreateSubscription = ({ zoneId }: useCreateSubscriptionProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const axiosInstance = useAxiosInstance();

  const { refetch } = useSingleZoneParkingSubscriptions({ zoneId });

  const { mutateAsync, isLoading } = useMutation(
    ({ subscriptionId }: { subscriptionId: string }) =>
      axiosInstance.post(api.endpoints.ZONES_SUBSCRIPTIONS({ id: subscriptionId }), { parking_zone_ids: [zoneId] }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: [`${QueryAliases.SINGLE_PARKING_ZONE_SUBSCRIPTIONS}-${zoneId}`],
        });
        refetch();
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.createZoneSubscriptionFailed'));
      },
    },
  );
  return { createSubscription: mutateAsync, isLoading };
};

export default useCreateSubscription;
