import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CopyIcon from '../../components/icons/CopyIcon';
import EditIcon from '../../components/icons/EditIcon';
import { routes } from '../../static/routes';
import useSingleCharger from '../api/chargers/useSingleCharger';
import useSingleChargerRules from '../api/chargers/useSingleChargerRules';

const useSingleChargerRulesPageTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { charger } = useSingleCharger({ id: id as string });
  const { rules } = useSingleChargerRules();

  const setConnectorsCellContent = (connectors: { port: number }[]) => {
    if (connectors.length === charger?.connectors.length) {
      return t('singleCharger.rulesTableHeaders.allConnectors');
    }
    return connectors.map((connector: { port: number }) => (
      <span key={connector.port}>{`${t('chargingRuleForm.connector')} ${connector.port}`}</span>
    ));
  };

  const setGroupsCellContent = (groups: { name: string }[]) => {
    if (groups.length === 0) {
      return t('singleCharger.rulesTableHeaders.allUsers');
    }
    return groups.map(group => <span key={group.name}>{group.name}</span>);
  };

  const rulesHeaders = [
    {
      field: 'name',
      headerName: t('singleCharger.rulesTableHeaders.name'),
      minWidth: 150,
      flex: 1,
      sortable: true,
    },
    {
      field: 'groups',
      headerName: t('singleCharger.rulesTableHeaders.groups'),
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <div className="flex gap-3 py-5">{setGroupsCellContent(params.row.groups)}</div>;
      },
    },
    {
      field: 'priceList',
      headerName: t('singleCharger.rulesTableHeaders.priceList'),
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: 'connectors',
      headerName: t('singleCharger.rulesTableHeaders.connectors'),
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <div className="flex gap-3 py-5">{setConnectorsCellContent(params.row.connectors)}</div>;
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex w-full justify-end px-7 gap-8">
            <div
              className="hover:cursor-pointer"
              onClick={() => navigate(routes.cloneChargingRule(id as string, params.row.id as string))}
            >
              <CopyIcon />
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => navigate(routes.editChargingRule(id as string, params.row.id as string))}
            >
              <EditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return { headers: rulesHeaders, data: rules };
};

export default useSingleChargerRulesPageTable;
