import { ApiPromotion, IPromotion } from '../../../types/api/ApiTypes';

export const transformPromotion = (apiPromotion: ApiPromotion): IPromotion => {
  const transformedPromotion: IPromotion = {
    parking: apiPromotion.parking,
    priceListName: apiPromotion.price_list_name,
    priceList: apiPromotion.price_list,
    qrPromo: apiPromotion.qr_promo,
    qrPromoInterval: apiPromotion.qr_promo_interval,
    qrAscii: apiPromotion.qr_ascii,
    qrExpirationDate: !!apiPromotion.qr_expiration_date ? new Date(apiPromotion.qr_expiration_date) : null,
    splitPayment: apiPromotion.split_payment,
    walletLimit: apiPromotion.wallet_limit,
    walletAmount: apiPromotion.wallet_amount,
  };

  return transformedPromotion;
};

export const transformPromotions = (promotions: ApiPromotion[]): IPromotion[] =>
  promotions.map(promotin => transformPromotion(promotin));
