import ChargerIcon from '../../components/icons/ChargerIcon';
import ReservationIcon from '../../components/icons/ReservationIcon';
import SubscriptionIcon from '../../components/icons/SubscriptionIcon';
import TicketIcon from '../../components/icons/TicketIcon';
import { colors } from '../../styles/variables';
import { PartnerParkingActivityWithoutAll } from '../../types/partnerParkingActivities/partnerParkingActivities';

export const partnerParkingActivityIcons: Record<PartnerParkingActivityWithoutAll, React.ReactNode> = {
  CHARGING_TRANSACTION: <ChargerIcon width="16" color={colors.black500} />,
  PARKING_SUBSCRIPTION: <SubscriptionIcon color={colors.black500} />,
  PARKING_TRANSACTION: <TicketIcon color={colors.black500} />,
  RESERVATION: <ReservationIcon color={colors.black500} />,
};
