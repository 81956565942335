import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { ApiParkingPriceListRequest } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useEditParkingPriceList = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, priceList }: { id: string; priceList: ApiParkingPriceListRequest }) =>
      axiosInstance.put(api.endpoints.SINGLE_PARKING_PRICE_LIST(id), { ...priceList }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries([`${QueryAliases.SINGLE_PARKING_PRICE_LIST}-${data.data.id}`]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.editPriceListFailed`));
      },
    },
  );

  return { editParkingPriceList: mutateAsync, isLoading };
};

export default useEditParkingPriceList;
