import { useTranslation } from 'react-i18next';
import BorderCard from '../../../../../../components/common/cards/BorderCard';

interface UserBasicInfoProps {
  phone?: string;
  email: string;
}

const UserBasicInfo = ({ phone, email }: UserBasicInfoProps) => {
  const { t } = useTranslation();

  return (
    <BorderCard className="mt-12 flex flex-col gap-4">
      {phone && (
        <>
          <h5 className="text-black700 text-sm font-bold">{t('userAccount.userInfo.phone')}</h5>
          <p className="text-black400 text-sm font-medium">{phone}</p>
        </>
      )}
      <h5 className="text-black700 text-sm font-bold">{t('userAccount.userInfo.email')}</h5>
      <p className="text-black400 text-sm font-medium">{email}</p>
    </BorderCard>
  );
};

export default UserBasicInfo;
