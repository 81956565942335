import { colors } from '../../../styles/variables';

interface SpotRulesIconProps {
  color?: string;
}

const SpotRulesIcon = ({ color }: SpotRulesIconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0714 6.64844H21.5" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.64286 4H3.5V9.2944H8.64286V4Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0714 17.2344H21.5" stroke={color ?? colors.grey700} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.5 17.2327L5.21429 18.9975L10.3571 13.7031"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpotRulesIcon;
