import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiUserWallet, IUserWallet } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformUserWallet } from '../../../../utils/dataTransformers/transformUserWallet/transformUserWallet';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useUserWalletProps {
  period?: string;
  type?: string;
}

const useUserWallet = ({ period, type }: useUserWalletProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch } = useQuery([`${QueryAliases.USER_DETAILS_WALLET}-${period}-${type}`], () =>
    axiosInstance.get(api.endpoints.USER_DETAILS_WALLET({ period, type })),
  );

  const apiUserWallet: ApiUserWallet | undefined = data?.data;

  const userWallet: IUserWallet | undefined = !!apiUserWallet ? transformUserWallet(apiUserWallet) : undefined;

  return { userWallet, refetch };
};

export default useUserWallet;
