import useToast from '../../useToast';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import { AxiosError } from 'axios';
import useAxiosInstance from '../../axios/useAxiosInstance';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { useTranslation } from 'react-i18next';

const useDeleteParkingSpotSubscription = () => {
  const { showErrorToast } = useToast();
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const { mutateAsync } = useMutation(
    ({ subscriptionId, parkingId }: { subscriptionId: string; parkingId: string }) =>
      axiosInstance.delete(api.endpoints.DELETE_PARKING_SPOT_SUBSCRIPTION({ subscriptionId, parkingId })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t('errorMessages.cancelSubscriptionFailed'));
      },
    },
  );
  return { deleteParkingSpotSubscription: mutateAsync };
};

export default useDeleteParkingSpotSubscription;
