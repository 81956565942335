import { ApiParkingPriceListDetails, IParkingPriceListDetails } from '../../../types/api/ApiTypes';
import { transformPriceListPeriods } from '../transformPriceListPeriod/transformPriceListPeriod';

export const transformParkingPriceListDetails = (
  apiPriceListDetails: ApiParkingPriceListDetails,
): IParkingPriceListDetails => {
  const transformedParkingPriceListDetails: IParkingPriceListDetails = {
    id: apiPriceListDetails.id,
    user: apiPriceListDetails.user,
    name: apiPriceListDetails.name,
    description: apiPriceListDetails.description,
    identifier: apiPriceListDetails.identifier,
    currency: apiPriceListDetails.currency,
    pricePerFirstPeriod: apiPriceListDetails.price_per_first_period,
    pricePerSecondPeriod: apiPriceListDetails.price_per_second_period,
    pricePerThirdPeriod: apiPriceListDetails.price_per_third_period,
    periods: transformPriceListPeriods(apiPriceListDetails.periods),
  };

  return transformedParkingPriceListDetails;
};
