import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useAddEvPriceList from '../../../hooks/api/priceLists/useAddEvPriceList';
import useAddParkingPriceList from '../../../hooks/api/priceLists/useAddParkingPriceList';
import useAddSubscriptionPriceList from '../../../hooks/api/priceLists/useAddSubscriptionPriceList';
import useSingleEvPriceList from '../../../hooks/api/priceLists/useSingleEvPriceList';
import useSingleParkingPriceList from '../../../hooks/api/priceLists/useSingleParkingPriceList';
import useSingleSubscriptionPriceList from '../../../hooks/api/priceLists/useSingleSubscriptionPriceList';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import {
  ApiEvPriceListRequest,
  ApiParkingPriceListRequest,
  ApiSubscriptionsPriceListRequest,
} from '../../../types/api/ApiTypes';
import { PriceListType, PriceListTypes } from '../../../types/price-lists/PriceListTypes';
import PriceListForm from '../addPriceList/components/PriceListForm';

const CopyPriceList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const id = params.id as string;
  const type = params.type as unknown as PriceListType;

  const { priceList: parkingPriceList } = useSingleParkingPriceList({ id, enabled: type === PriceListTypes.PARKING });
  const { priceList: evPriceList } = useSingleEvPriceList({ id, enabled: type === PriceListTypes.EV });
  const { priceList: subscriptionPriceList } = useSingleSubscriptionPriceList({
    id,
    enabled: type === PriceListTypes.SUBSCRIPTION,
  });

  const { addPriceList: addParkingPriceList } = useAddParkingPriceList();
  const { addPriceList: addEvPriceList } = useAddEvPriceList();
  const { addPriceList: addSubscriptionPriceList } = useAddSubscriptionPriceList();

  const handleCreatePriceListEv = async (data: ApiEvPriceListRequest) => {
    await addEvPriceList({ priceList: data });
    navigate(routes.priceLists.ev);
  };

  const handleCreatePriceListParking = async (data: ApiParkingPriceListRequest) => {
    await addParkingPriceList({ priceList: data });
    navigate(routes.priceLists.parkings);
  };

  const handleCreatePriceListSubscription = async (data: ApiSubscriptionsPriceListRequest) => {
    await addSubscriptionPriceList({ priceList: data });
    navigate(routes.priceLists.subscriptions);
  };

  return (
    <TitleWrapper title={t('priceListForm.title')} breadcrumbsProps={{ limit: 2 }}>
      {!isTablet && <BackButton className="mt-8 mb-16 " />}

      {(!!evPriceList || !!parkingPriceList || !!subscriptionPriceList) && (
        <PriceListForm
          onSubmitPriceListEv={handleCreatePriceListEv}
          onSubmitPriceListParking={handleCreatePriceListParking}
          onSubmitPriceListSubscription={handleCreatePriceListSubscription}
          submitBtnText={t('priceListForm.submitBtn')}
          submitBtnIcon={<PlusIcon height={16} />}
          disableTypeChange
          initialValues={{
            type,
            evValues: !!evPriceList
              ? {
                  ...evPriceList,
                  name: '',
                }
              : undefined,
            parkingValues: !!parkingPriceList
              ? {
                  ...parkingPriceList,
                  name: '',
                }
              : undefined,
            subscriptionValues: !!subscriptionPriceList
              ? {
                  ...subscriptionPriceList,
                  name: '',
                }
              : undefined,
          }}
        />
      )}
    </TitleWrapper>
  );
};

export default CopyPriceList;
