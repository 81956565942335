import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingPriceListRequest } from '../../../types/api/ApiTypes';
import { getApiErrorMessage } from '../../../utils/getApiErrorMessage/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddParkingPriceList = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(
    ({ priceList }: { priceList: ApiParkingPriceListRequest }) =>
      axiosInstance.post(api.endpoints.PARKING_PRICE_LISTS, {
        ...priceList,
      }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err);

        showErrorToast(errorMessage ?? t(`errorMessages.addingPriceListFailed`));
      },
    },
  );

  return { addPriceList: mutateAsync, isLoading };
};

export default useAddParkingPriceList;
