import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiParkingZoneDetailsByParking, IParkingZoneDetailsByParking } from '../../../../types/api/ApiTypes';
import { QueryAliases } from '../../../../types/queries/QueryAliases';
import { transformParkingZoneDetailsByParking } from '../../../../utils/dataTransformers/transformParkingZoneDetailsByParking/transformParkingZoneDetailsByParking';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useParkingZonesProps {
  parkingId: string;
}

const useParkingZones = ({ parkingId }: useParkingZonesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data } = useQuery(
    [`${QueryAliases.PARKING_ZONES}-${parkingId}`],
    () => axiosInstance.get(api.endpoints.PARKING_ZONES({ id: parkingId })),
    {
      cacheTime: 1,
    },
  );

  const apiParkingZones: ApiParkingZoneDetailsByParking[] | undefined = data?.data;

  const parkingZones: IParkingZoneDetailsByParking[] | undefined = !!apiParkingZones
    ? apiParkingZones?.map(zone => transformParkingZoneDetailsByParking(zone))
    : undefined;

  return { parkingZones: !!parkingZones ? parkingZones : undefined };
};

export default useParkingZones;
