import AuthInfoSection from '../../auth/AuthInfoSection';

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper = ({ children }: AuthWrapperProps) => {
  return (
    <div className="lg:grid lg:grid-cols-2 lg:min-h-screen lg:items-center">
      <div className="px-8 py-16 lg:py-8 lg:max-w-[400px] w-full mx-auto">{children}</div>
      <AuthInfoSection />
    </div>
  );
};

export default AuthWrapper;
