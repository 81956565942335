import { useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { historyEvFilters } from '../../../static/history/historyEvFilters';
import { DropdownOption } from '../../../types/Dropdown.types';
import { historyEvFilterType } from '../../../types/history/historyEvFilters';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformHistoryCharging } from '../../../utils/dataTransformers/transformHistoryCharging/transformHistoryCharging';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useHistoryEv = () => {
  const noFilterOption = (historyEvFilters.find(prop => prop.value === historyEvFilterType.ALL) ??
    historyEvFilters[0]) as DropdownOption;

  const [filter, setFilter] = useState<DropdownOption>(noFilterOption);
  const filterOptions = historyEvFilters;

  const axiosInstance = useAxiosInstance();

  const status = filter.value === historyEvFilterType.ALL ? undefined : { status: filter.value };

  const { data } = useQuery([`${QueryAliases.HISTORY_EV}-${filter.value}`], () =>
    axiosInstance.get(api.endpoints.HISTORY.CHARGING, { params: status }),
  );

  return { historyEv: transformHistoryCharging(data?.data.results), noFilterOption, filter, setFilter, filterOptions };
};

export default useHistoryEv;
