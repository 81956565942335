import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiParkingSpotDetailsByParking, IParkingSpotDetailsByParking } from '../../../types/api/ApiTypes';
import { QueryAliases } from '../../../types/queries/QueryAliases';
import { transformParkingSpotDetailsByParking } from '../../../utils/dataTransformers/transformParkingSpotDetailsByParking/transformParkingSpotDetailsByParking';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useParkingSpotsProps {
  parkingId: string;
  parkingZoneId?: string;
}

const useParkingSpots = ({ parkingId, parkingZoneId }: useParkingSpotsProps) => {
  const axiosInstance = useAxiosInstance();
  let url = api.endpoints.PARKING_SPOTS({ id: parkingId });
  let queryKey = [`${QueryAliases.PARKING_SPOTS}-${parkingId}`];
  if (parkingZoneId) {
    url = api.endpoints.SINGLE_PARKING_ZONE_SPOTS({ id: parkingZoneId });
    queryKey = [`${QueryAliases.SINGLE_PARKING_ZONE_SPOTS}-${parkingZoneId}`];
  }
  const { data, isLoading } = useQuery([queryKey], () => axiosInstance.get(url), {
    cacheTime: 1,
  });

  const apiParkingSpots: ApiParkingSpotDetailsByParking[] | undefined = data?.data;

  const parkingSpots: IParkingSpotDetailsByParking[] | undefined = !!apiParkingSpots
    ? apiParkingSpots?.map(spot => transformParkingSpotDetailsByParking(spot))
    : undefined;

  return { parkingSpots: !!parkingSpots ? parkingSpots : undefined, isLoading };
};

export default useParkingSpots;
