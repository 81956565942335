import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../../components/icons/TrashIcon';
import { colors } from '../../../../styles/variables';

interface TableRemoveCoUserButtonProps {
  phone: string;
  onConfirm: (phone: string) => void;
}

const TableRemoveCoUserButton = ({ phone, onConfirm }: TableRemoveCoUserButtonProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteGroup = async () => {
    onConfirm(phone);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <button className="hover:cursor-pointer p-3" onClick={() => setIsDeleteModalOpen(true)}>
        <TrashIcon />
      </button>

      <ConfirmModal
        title={t('editParkingSpot.cousers.delete.title')}
        confirmQuestion={t('editParkingSpot.cousers.delete.confirmQuestion')}
        isOpen={isDeleteModalOpen}
        setIsOpen={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelBtnText={t('editParkingSpot.cousers.delete.cancelBtnText')}
        confirmBtnText={t('editParkingSpot.cousers.delete.confirmBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        confirmBtnAction={handleDeleteGroup}
      />
    </>
  );
};

export default TableRemoveCoUserButton;
